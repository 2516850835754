import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Pane, Heading, Strong, Paragraph } from 'evergreen-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrganizationRoleForm from 'components/onboarding/OrganizationRoleForm';
import onboardingSlice, {
  organizationSelector,
  planSelector,
  zipCodeSelector
} from 'redux/onboarding';
import { currentUserSelector } from 'redux/global';
import { page, track } from 'lib/analytics';

const { setOrganizationCredentials } = onboardingSlice.actions;

const OrgnizationRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const plan = useSelector(planSelector);
  const zipCode = useSelector(zipCodeSelector);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    page('Onboarding Organization Role', {
      selected_agency_id: organization.id,
      selected_plan_id: plan.id
    });
  }, [organization, plan]);

  return (
    <Pane>
      <Helmet>
        <title>Your credential at {organization.name}</title>
      </Helmet>
      <Pane textAlign="center" marginBottom={24}>
        <Heading size={600} marginBottom={8}>
          Credential details
        </Heading>
        <Paragraph color="muted">
          Muru is customized to your individual credential level. <br />
          <Strong>
            {currentUser.name} at {organization.name}
          </Strong>
        </Paragraph>

        <OrganizationRoleForm
          agency={organization}
          selectedState={zipCode.state}
          credentialRequired={plan.type === 'INDIVIDUAL'}
          onComplete={(values: any) => {
            track('Onboarding Organization Credentials Provided', {
              selected_credential_id: values.credentialId
            });

            dispatch(setOrganizationCredentials(values));
            history.push(`/onboarding/plans/${plan.type.toLowerCase()}`);
          }}
        />
      </Pane>
    </Pane>
  );
};

export default OrgnizationRole;
