import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  CREDENTIAL_LIST,
  listRequestVariables
} from 'components/organizations/CredentialList';

const CredentialSelectorDataSource = ({ organizationId, render }: any) => {
  const { data, loading } = useQuery(CREDENTIAL_LIST, {
    variables: listRequestVariables({ id: organizationId }),
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'organization.credentials.edges', []).map(
    // @ts-expect-error TS(7031): Binding element 'credential' implicitly has an 'an... Remove this comment to see the full error message
    ({ node: credential }) => ({
      label: credential.name,
      value: credential.id
    })
  );

  return render({
    items,
    isLoading: loading
  });
};

export default CredentialSelectorDataSource;
