import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import MedicationList, {
  MEDICATION_LIST
} from 'components/organizations/MedicationList';
import DeleteDialog from 'components/shared/DeleteDialog';
import {
  withDefaultPageInfo,
  parseFilters,
  onPageInfoUpdate,
  onFilterUpdate
} from 'lib/pagination';
import useIsChildOrganization from 'hooks/useIsChildOrganization';

const DELETE_MEDICATION = gql`
  mutation DeleteMedication($input: DeleteMedicationForOrganizationInput!) {
    result: deleteMedicationForOrganization(input: $input) {
      medication {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);
  const isChildOrg = useIsChildOrganization();

  const variables = {
    ...pageInfo,
    filters: { ...filters, publishedState: 'DRAFT' },
    organizationId: organization.id
  };

  const refetchQuery = {
    variables,
    query: MEDICATION_LIST
  };

  const [deleteMedication, { loading: isDeleteLoading }] = useMutation(
    DELETE_MEDICATION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.medication) {
          toaster.success('Medication deleted');
        } else {
          toaster.danger('Could not delete medication', {
            description:
              'Check there are no medications that are diluted by this medication, and that there are no doses.'
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage medications"
        renderButtons={
          <Button
            iconBefore="add"
            marginLeft="auto"
            disabled={isChildOrg}
            onClick={() =>
              history.push(`/orgs/${organization.slug}/medications/new`)
            }
          >
            New Medication
          </Button>
        }
      />

      <MedicationList
        organization={organization}
        variables={variables}
        filters={filters}
        refetchQuery={refetchQuery}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onDeleteClick={(medication: any) => setShowDeleteDialog(medication)}
        onListDosesClick={(medication: any) =>
          history.push(
            `/orgs/${organization.slug}/medications/${medication.id}/doses`
          )
        }
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteMedication({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
