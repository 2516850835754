import React from 'react';
import { Menu } from 'evergreen-ui';

const EquipmentMenu = ({
  onEditClick,
  onDeleteClick,
  onManageFunctionsClick,
  onPublishClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit equipment</Menu.Item>
        <Menu.Item onSelect={onManageFunctionsClick}>
          Manage functions
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="success" onSelect={onPublishClick}>
          Publish equipment
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete equipment
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default EquipmentMenu;
