import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const EQUIPMENT = gql`
  query EquipmentFunctionsDataSource(
    $filters: FilterOrganizationEquipmentInput!
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      equipments(first: 999, filters: $filters) {
        edges {
          node {
            id
            name
            functions {
              id
              name
              # We add the equipment here so that the query to display doses works
              equipment {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

function gatherEquipmentFunctions(equipments: any) {
  // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
  return equipments.edges.reduce((acc: any, { node: equipment }) => {
    return equipment.functions.reduce((acc: any, equipmentFunction: any) => {
      return [
        ...acc,
        {
          value: equipmentFunction.id,
          label: `${equipment.name} - ${equipmentFunction.name}`
        }
      ];
    }, acc);
  }, []);
}

const EquipmentFunctionsDataSource = ({
  render,
  organizationId,
  filters = {}
}: any) => {
  const { data, loading, error } = useQuery(EQUIPMENT, {
    variables: { filters, organizationId },
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? gatherEquipmentFunctions(data.organization.equipments)
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default EquipmentFunctionsDataSource;
