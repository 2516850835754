import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pane, Heading, Paragraph, SegmentedControl } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { zipCodeSelector } from 'redux/onboarding';
import {
  IndividualWaitlist,
  OrganizationWaitlist
} from 'components/onboarding/WaitlistForm';
import { page } from 'lib/analytics';
import { currentUserSelector } from 'redux/global';

const Types = {
  INDIVIDUAL: 'INDIVIDUAL',
  ORGANIZATION: 'ORGANIZATION'
};

const Waitlist = () => {
  const [type, setType] = useState(Types.INDIVIDUAL);
  const history = useHistory();
  const zipCode = useSelector(zipCodeSelector);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    page('Add Agency Waitlist');
  }, []);

  return (
    <Pane>
      <Pane
        textAlign="center"
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading size={600} marginBottom={8}>
          Muru isn't available in {zipCode.zipCode} just yet
        </Heading>
        <Paragraph color="muted">
          Bad news: we're not in your area just yet, but sign up below and we'll
          let you know as soon as Muru is available.
        </Paragraph>
      </Pane>

      <Heading size={400} marginBottom={8}>
        I want to join the waitlist:
      </Heading>

      <SegmentedControl
        value={type}
        options={[
          { label: 'For myself', value: Types.INDIVIDUAL },
          { label: 'On behalf of my agency', value: Types.ORGANIZATION }
        ]}
        onChange={(value: any) => setType(value)}
        marginBottom={24}
      />

      {type === Types.INDIVIDUAL ? (
        <IndividualWaitlist
          zipCode={zipCode}
          currentUser={currentUser}
          onSignup={() => history.push('/waitlist/thanks')}
        />
      ) : (
        <OrganizationWaitlist
          zipCode={zipCode}
          currentUser={currentUser}
          onSignup={() => history.push('/waitlist/thanks')}
        />
      )}
    </Pane>
  );
};

export default Waitlist;
