import React from 'react';
import { SideSheet } from 'evergreen-ui';
import { useWindowSize } from 'react-use';

const MuruSideSheet = ({ children, ...props }: any) => {
  const { height } = useWindowSize();

  return (
    <SideSheet
      {...props}
      shouldCloseOnEscapePress={false}
      shouldCloseOnOverlayClick={false}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        height
      }}
    >
      {children}
    </SideSheet>
  );
};

export default MuruSideSheet;
