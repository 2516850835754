import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Button,
  Position,
  Badge
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';
import PublishHospital from './PublishHospital';
import FilterMenu from './FilterMenu';
import { PageInfoFragment } from 'components/shared/fragments';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';

const HospitalListItemFragment = gql`
  fragment HospitalListItem on Hospital {
    id
    name
    type
    lastPublishedAt
    updatedAt
    organization {
      id
      name
    }
  }
`;

export const LIST_HOSPITALS = gql`
  query Hospitals(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterHospitalsInput
  ) {
    hospitals(
      first: $first
      after: $after
      before: $before
      last: $last
      filters: $filters
    ) {
      edges {
        node {
          ...HospitalListItem
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${HospitalListItemFragment}
  ${PageInfoFragment}
`;

export const LIST_HOSPITALS_BY_ORG = gql`
  query HospitalsForOrganization(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationHospitalsInput
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      hospitals(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...HospitalListItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${HospitalListItemFragment}
  ${PageInfoFragment}
`;

const BADGE_WIDTH = 100;

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const HospitalsList = ({
  organizationId,
  onEditClick,
  variables,
  filters,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick
}: any) => {
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const { loading, data, error } = useQuery(
    organizationId ? LIST_HOSPITALS_BY_ORG : LIST_HOSPITALS,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  );

  const orderBy = filters.orderBy || defaultOrderBy;
  const hospitals = get(
    data,
    organizationId ? 'organization.hospitals.edges' : 'hospitals.edges',
    []
  );

  return (
    <Pane>
      <Pane display="flex">
        <SearchListControl
          placeholder="Search by hospital name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />

        <Popover
          bringFocusInside
          shouldCloseOnExternalClick={false}
          content={({ close }: any) => (
            <FilterMenu
              close={close}
              filters={filters}
              onApply={(filters: any) => onFilterUpdate(filters)}
              onClearAll={() => onFilterUpdate({})}
              canFilterByNetwork={!organizationId}
            />
          )}
        >
          <Button marginLeft="auto" iconBefore="filter">
            Filter ({Object.keys(filters).length})
          </Button>
        </Popover>
      </Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={BADGE_WIDTH} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={BADGE_WIDTH} flex="none">
              Type
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {hospitals.map(({ node: hospital }) => (
                <Table.Row
                  key={hospital.id}
                  isSelectable
                  height="auto"
                  minHeight={64}
                >
                  <Table.Cell>
                    <Pane>
                      <Text size={300} fontWeight={500} display="block">
                        {hospital.name}
                      </Text>
                      <Text size={300}>
                        {hospital.organization && hospital.organization.name}
                      </Text>
                    </Pane>
                  </Table.Cell>
                  <Table.Cell
                    width={BADGE_WIDTH}
                    display="flex"
                    alignItems="center"
                    flex="none"
                  >
                    {hospital.updatedAt === hospital.lastPublishedAt ? (
                      <Badge color="green">Published</Badge>
                    ) : (
                      <Badge color="blue">Draft</Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell
                    width={BADGE_WIDTH}
                    display="flex"
                    alignItems="center"
                    flex="none"
                  >
                    <Badge>{hospital.type}</Badge>
                  </Table.Cell>
                  <Table.Cell>
                    {hospital.lastPublishedAt ? (
                      <RelativeDate date={hospital.lastPublishedAt} />
                    ) : (
                      <Text size={300}>N/A</Text>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={hospital.updatedAt} />
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <Popover
                      content={
                        <Menu
                          onEditClick={() => onEditClick(hospital)}
                          onPublishClick={() => showPublishDialog(hospital)}
                          onDeleteClick={() => onDeleteClick(hospital)}
                        />
                      }
                      position={Position.BOTTOM_RIGHT}
                    >
                      <IconButton
                        icon="more"
                        height={24}
                        appearance="minimal"
                      />
                    </Popover>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && hospitals.length === 0 && <EmptyContent />}

      <PublishHospital
        hospital={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />

      <PaginationControls
        pageInfo={data && data.hospitals && data.hospitals.pageInfo}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default HospitalsList;
