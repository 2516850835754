import gql from 'graphql-tag';

export const ReferencedSourceFragment = gql`
  fragment ReferencedSource on ReferencedSource {
    id
    name
    version
    url
    file {
      url
    }
  }
`;

export const InputErrorFragment = gql`
  fragment InputError on InputError {
    message
    field
  }
`;

export const PageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const FileFragment = gql`
  fragment File on File {
    name
    url
    meta {
      width
      height
    }
    size
  }
`;

export const HospitalFragment = gql`
  fragment Hospital on Hospital {
    id
    name
    type
    aliases
    role
    notes
    phoneNotification
    phoneNonEmergencies
    address
    emailAddresses
    radioCommunications
    doorCode
    emsLounge
    restockings
    emergencyRadioComms
    nonEmergencyRadioComms
    flightHazards
    mgrs
    epcrInformation {
      emailAddress
      faxNumber
      notes
    }
    authorities {
      authority {
        id
        name
      }
      assignedCapabilities {
        id
        name
      }
    }
    restaurants {
      name
      location
      hours
    }
    cafeterias {
      name
      location
      hours
    }
    coordinates {
      lat
      lng
    }
    helipads {
      weightLimit
      size
      types
    }
    runways {
      types
    }
    image {
      ...File
    }
    sources {
      ...ReferencedSource
    }
    organizationId
    lastPublishedAt
    updatedAt
    insertedAt
  }
  ${FileFragment}
  ${ReferencedSourceFragment}
`;

export const UserFragment = gql`
  fragment User on User {
    id
    name
    email
    role
    insertedAt
  }
`;

export const SeatFragment = gql`
  fragment Seat on Seat {
    id
    insertedAt
    updatedAt
    cancelledAt
    user {
      ...User
    }
    roles {
      id
      name
    }
    credentials {
      id
      name
    }
    subscription {
      id
      cancelAt
      cancelledAt
    }
  }
  ${UserFragment}
`;

export const SubscriptionFragment = gql`
  fragment Subscription on PaymentSubscription {
    id
    totalSeatCount
    occupiedSeatCount
    pendingInviteCount
    totalAmountInCents
    seatAmountInCents
    interval
    plan {
      id
      name
      amountInCents
      seatAmountInCents
      supportsMultiAgencyPurchases
    }
    nextBilledAt
    cancelledAt
    cancelAt
    delinquentAt
    trialEndAt
    insertedAt
    updatedAt
    stripeSubscriptionId
  }
`;

export const OrganizationFragment = gql`
  fragment Organization on Organization {
    id
    name
    type
    slug
    hospitalsLastPublishedAt
    insertedAt
    updatedAt
    subscription {
      ...Subscription
    }
    logo {
      ...File
    }
    permissions
    roles
    address
    website
    phoneNumber
    emailAddress
    supportPhoneNumber
    faxNumber
    notes
    parent {
      id
      slug
      name
    }
  }
  ${SubscriptionFragment}
  ${FileFragment}
`;

export const EquipmentFragment = gql`
  fragment Equipment on Equipment {
    id
    name
    aliases
    category
    units {
      name
      serialNumber
    }
    image {
      ...File
    }
    sources {
      ...ReferencedSource
    }
    metadata {
      key
      value
    }
    sizes {
      name
      size {
        unit
        value
        perAmount {
          unit
          value
        }
      }
    }
    establishesRoutes
    notes
    manufacturerId
    lastPublishedAt
    updatedAt
    insertedAt
  }
  ${FileFragment}
  ${ReferencedSourceFragment}
`;

export const EquipmentFunctionFragment = gql`
  fragment EquipmentFunction on EquipmentFunction {
    id
    name
    displayName
    aliases
    lastPublishedAt
    updatedAt
    insertedAt
    equipment {
      id
      name
    }
  }
`;

export const MoleculeFragment = gql`
  fragment Molecule on Molecule {
    id
    name
    aliases
    updatedAt
    insertedAt
  }
`;

export const Amount = gql`
  fragment Amount on Amount {
    unit
    value
  }
`;

const AmountPerAmount = gql`
  fragment AmountPerAmount on AmountPerAmount {
    unit
    value
    perAmount {
      unit
      value
    }
  }
`;

export const MedicationStorageMethodFragment = gql`
  fragment MedicationStorageMethod on MedicationStorageMethod {
    displayName
    type
    volume {
      unit
      value
    }
    concentration {
      ...AmountPerAmount
    }
    image {
      ...File
    }
  }
  ${FileFragment}
  ${AmountPerAmount}
`;

export const MedicationFragment = gql`
  fragment Medication on Medication {
    id
    brandName
    genericName
    displayName
    aliases
    notes
    isDilutant
    dilutantId
    moleculeId
    metadata {
      key
      value
    }
    concentration {
      ...AmountPerAmount
    }
    concentrationDisplayType
    storageMethods {
      ...MedicationStorageMethod
    }
    sources {
      ...ReferencedSource
    }
    reversalAgents {
      id
    }
    lastPublishedAt
    updatedAt
    insertedAt
  }
  ${MedicationStorageMethodFragment}
  ${AmountPerAmount}
  ${ReferencedSourceFragment}
`;

export const AliasFragment = gql`
  fragment Alias on Alias {
    id
    key
    references
    organizationId
    aliases
    updatedAt
    insertedAt
  }
`;

export const AmendmentFragment = gql`
  fragment ContentAmendment on ContentAmendment {
    id
    type
    user {
      id
      name
    }
    organization {
      id
      name
    }
    updatedAt
    insertedAt
  }
`;

export const ProtocolEntryCriteriaFragment = gql`
  fragment ProtocolEntryCriteria on ProtocolEntryCriteria {
    patientTypes
    notes {
      text
      important
    }
  }
`;

export const ProtocolDoseEntryCriteriaFragment = gql`
  fragment ProtocolDoseEntryCriteria on ProtocolDoseEntryCriteria {
    patientAgeRanges {
      minValue
      maxValue
      unit
    }
    filters {
      operator
      values {
        ... on ProtocolDoseEntryCriteriaPatientWeightFilter {
          property
          value
          unit
          comparisonOperator
        }
        ... on ProtocolDoseEntryCriteriaPatientAgeFilter {
          property
          value
          unit
          comparisonOperator
        }
      }
    }
    notes
  }
`;

export const ProtocolSetFragment = gql`
  fragment ProtocolSet on ProtocolSet {
    id
    name
    aliases
    entryCriteria {
      ...ProtocolEntryCriteria
    }
    sources {
      ...ReferencedSource
    }
    organization {
      id
      name
    }
    amendment {
      ...ContentAmendment
    }
    lastPublishedAt
    updatedAt
    insertedAt
  }
  ${ProtocolEntryCriteriaFragment}
  ${ReferencedSourceFragment}
  ${AmendmentFragment}
`;

export const ProtocolFragment = gql`
  fragment Protocol on Protocol {
    id
    name
    aliases
    entryCriteria {
      ...ProtocolEntryCriteria
    }
    isHiddenFromBrowse
    parent {
      id
      name
      entryCriteria {
        ...ProtocolEntryCriteria
      }
    }
    amendment {
      ...ContentAmendment
    }
    referencedSources {
      pageNumbers
      referencedSource {
        ...ReferencedSource
      }
    }
    lastPublishedAt
    updatedAt
    insertedAt
  }
  ${ProtocolEntryCriteriaFragment}
  ${ReferencedSourceFragment}
  ${AmendmentFragment}
`;

export const ProtocolDoseAmountFragment = gql`
  fragment ProtocolDoseAmount on ProtocolDoseAmount {
    minValue
    maxValue
    value
    unit
    perAmount {
      unit
      value
    }
  }
`;

export const ProtocolDoseUnitFragment = gql`
  fragment ProtocolDoseUnit on ProtocolDoseUnit {
    id
    amount {
      ...ProtocolDoseAmount
    }
    medication {
      id
      brandName
      genericName
    }
    equipmentFunction {
      id
      name
      equipment {
        id
        name
      }
    }
    maxAmount {
      ...AmountPerAmount
    }
    updatedAt
    insertedAt
  }
  ${ProtocolDoseAmountFragment}
  ${AmountPerAmount}
`;

const ProtocolDoseRepeatInformation = gql`
  fragment ProtocolDoseRepeatInformation on ProtocolDoseRepeatInformation {
    numberOfTimes
    asNecessary
    interval {
      value
      minValue
      maxValue
      unit
    }
    requiresMedicalControlAuthorizationFromRepeat
  }
`;

export const ProtocolDoseFragment = gql`
  fragment ProtocolDose on ProtocolDose {
    id
    requiresMedicalControlAuthorization
    routes
    note
    supplementalDisplayText
    updatedAt
    insertedAt
    units {
      ...ProtocolDoseUnit
    }
    administeringEquipment {
      id
      name
    }
    infusionRateInSeconds
    repeatInformation {
      ...ProtocolDoseRepeatInformation
    }
    entryCriteria {
      ...ProtocolDoseEntryCriteria
    }
  }
  ${ProtocolDoseEntryCriteriaFragment}
  ${ProtocolDoseUnitFragment}
  ${ProtocolDoseRepeatInformation}
`;

export const ReferredProtocolFragment = gql`
  fragment ReferredProtocol on Protocol {
    id
    name
    parent {
      id
      name
    }
    protocolSet {
      id
      name
    }
  }
`;

export const ProtocolStepFragment = gql`
  fragment ProtocolStep on ProtocolStep {
    id
    note
    order
    referredProtocol {
      ...ReferredProtocol
    }
    subprotocol {
      id
      name
    }
    source {
      ...ReferencedSource
    }
    entryCriteria {
      ...ProtocolEntryCriteria
    }
    sourcePageNumber
    doses {
      ...ProtocolDose
    }
    credentials {
      id
      name
    }
    updatedAt
    insertedAt
  }
  ${ProtocolEntryCriteriaFragment}
  ${ProtocolDoseFragment}
  ${ReferencedSourceFragment}
  ${ReferredProtocolFragment}
`;

export const SessionFragment = gql`
  fragment Session on Session {
    token
    refreshToken
    user {
      id
      email
      name
      role
      intercomIdentityHash(platform: WEB)
      seats {
        id
        roles {
          id
          name
        }
        organization {
          ...Organization
        }
      }
    }
  }
  ${OrganizationFragment}
`;
