import gql from 'graphql-tag';

export const INVITE_TO_ORG = gql`
  mutation CreateOrganizationInvites($input: CreateOrganizationInviteInput!) {
    createOrganizationInvites(input: $input) {
      errors {
        code
        message
        email
      }
      invites {
        id
        email
        roles {
          id
          name
        }
        user {
          email
        }
      }
    }
  }
`;

export const ROLES_LIST = gql`
  query InviteRolesForOrganization($id: ID!) {
    organization(id: $id) {
      id
      applicableRoles {
        id
        name
      }
    }
  }
`;

export const CREDENTIALS_LIST = gql`
  query InviteCredsForOrganization($id: ID!) {
    organization(id: $id) {
      id
      credentials(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
