import gql from 'graphql-tag';
import {
  SubscriptionFragment,
  InputErrorFragment
} from 'components/shared/fragments';

export const ASSIGN_INDIVIDUAL = gql`
  mutation AssignIndividualToOrganization(
    $input: AssignIndividualToOrganizationInput!
  ) {
    result: assignIndividualToOrganization(input: $input) {
      subscription {
        ...Subscription
      }
      errors {
        ...InputError
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;
