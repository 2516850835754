import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Tooltip,
  Table,
  Text,
  Icon,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';
import { DIRECTORY_CONTACTS_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const DirectoryContactList = ({
  organization,
  filters,
  variables,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick,
  onEditClick
}: any) => {
  const { loading, data, error } = useQuery(DIRECTORY_CONTACTS_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const directoryContacts = get(
    data,
    'organization.directoryContacts.edges',
    []
  );

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Contact Information</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {directoryContacts.map(({ node: directoryContact }) => {
                const isDifferentOrg =
                  directoryContact.organization.id !== organization.id;

                return (
                  <Table.Row key={directoryContact.id} isSelectable>
                    <Table.Cell>
                      {directoryContact.starred && (
                        <Icon
                          icon="star"
                          color="warning"
                          size={12}
                          marginRight={8}
                        />
                      )}
                      <Pane>
                        <Text size={300} fontWeight={500} display="block">
                          {directoryContact.name}
                        </Text>
                        <Text size={300}>
                          {directoryContact.organization.name}
                        </Text>
                      </Pane>
                    </Table.Cell>
                    <Table.TextCell>
                      {directoryContact.phoneNumbers &&
                        directoryContact.phoneNumbers.length > 0 && (
                          <Tooltip
                            content={directoryContact.phoneNumbers.join(', ')}
                          >
                            <Icon
                              icon="phone"
                              size={12}
                              color="muted"
                              marginRight={8}
                            />
                          </Tooltip>
                        )}

                      {directoryContact.faxNumbers &&
                        directoryContact.faxNumbers.length > 0 && (
                          <Tooltip
                            content={directoryContact.faxNumbers.join(', ')}
                          >
                            <Icon
                              icon="document"
                              size={12}
                              color="muted"
                              marginRight={8}
                            />
                          </Tooltip>
                        )}

                      {directoryContact.radioFrequencies &&
                        directoryContact.radioFrequencies.length > 0 && (
                          <Tooltip
                            content={directoryContact.radioFrequencies.join(
                              ', '
                            )}
                          >
                            <Icon
                              icon="cell-tower"
                              size={12}
                              color="muted"
                              marginRight={8}
                            />
                          </Tooltip>
                        )}
                    </Table.TextCell>
                    <Table.Cell>
                      <RelativeDate date={directoryContact.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onEditClick={() => onEditClick(directoryContact)}
                            onDeleteClick={() =>
                              onDeleteClick(directoryContact)
                            }
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                          disabled={isDifferentOrg}
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && directoryContacts.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'organization.directoryContacts.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default DirectoryContactList;
