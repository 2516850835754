import React from 'react';
import { Heading, Paragraph, Button } from 'evergreen-ui';
import * as Sentry from '@sentry/browser';
import BoxLayout from 'components/shared/Layout/BoxLayout';
import { SUPPORT_EMAIL } from '../../../constants';

class GenericErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  // @ts-expect-error TS(6133): 'error' is declared but its value is never read.
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope: any) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
    console.error(error);
  }

  render() {
    // @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.state.error) {
      return (
        <BoxLayout>
          <Heading size={600} marginBottom={16}>
            Uh oh an error occurred
          </Heading>
          <Paragraph color="muted">
            You're seeing this screen because an unrecoverable error occurred
            within the Muru app. Please try and refresh the page or if this
            continues, please contact support.
          </Paragraph>

          <Button marginTop={16} is="a" href={`mailto:${SUPPORT_EMAIL}`}>
            Contact our support team
          </Button>
        </BoxLayout>
      );
    }

    return this.props.children || null;
  }
}

export default GenericErrorBoundary;
