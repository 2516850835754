import React from 'react';
import * as Yup from 'yup';
import ProtocolSetSources from 'components/shared/ProtocolSetSources';
import CredentialSelector from 'components/shared/CredentialSelector';
import buildEntryCriteriaForm from 'components/shared/EntryCriteria/ProtocolForm';
import doseForm from './doseForm';
import referredForm from './referredForm';
import subprotocolForm from './subprotocolForm';
import { StepType } from '@/constants/protocolSteps';

const Schema = (stepType: any) =>
  Yup.object().shape({
    source: Yup.string().required('Must select a source'),
    sourcePageNumber: Yup.number().required('Must enter a page number'),
    credentials: Yup.array()
      .required('Must select one or more applicable credentials for this step')
      .nullable(),
    ...(stepType === StepType.DOSE
      ? {
          doses: Yup.array()
            .required('Must select one or more doses on a dose step')
            .nullable()
        }
      : {}),
    ...(stepType === StepType.REFERRED
      ? {
          referredProtocol: Yup.string()
            .required('Must select a protocol to refer to')
            .nullable()
        }
      : {}),
    ...(stepType === StepType.SUBPROTOCOL
      ? {
          subprotocol: Yup.string()
            .required('Must select a subprotocol')
            .nullable()
        }
      : {})
  });

export const Form = ({
  organizationId,
  protocolId,
  stepType,
  protocolSetId,
  canSetPatientType
}: any) => ({
  name: 'source',
  validationSchema: Schema(stepType),
  fields: [
    ...(stepType === StepType.SUBPROTOCOL
      ? [subprotocolForm({ organizationId, protocolId })]
      : []),
    ...(stepType === StepType.DOSE ? [doseForm({ organizationId })] : []),
    ...(stepType === StepType.REFERRED
      ? [referredForm({ organizationId, protocolId })]
      : []),
    {
      name: 'source',
      label: 'Source',
      type: 'asyncSelect',
      description:
        'The source this step references (note: these are referenced from the protocol set)',
      placeholder: 'Select a source',
      dataSource: (render: any) => (
        <ProtocolSetSources render={render} protocolSetId={protocolSetId} />
      )
    },
    {
      name: 'sourcePageNumber',
      label: 'Source Page #',
      description:
        'The page number of the step within the source (note: should be the start page only)',
      type: 'number'
    },
    {
      name: 'credentials',
      label: 'Applicable credentials',
      description: 'Select one or more',
      type: CredentialSelector,
      organizationId
    },
    ...[buildEntryCriteriaForm(canSetPatientType)],
    {
      name: 'note',
      label: 'Notes',
      description: 'Any notes associated with this step',
      type: 'textarea'
    }
  ]
});
