import React from 'react';
import { Heading, Pane, Paragraph } from 'evergreen-ui';
import Breadcrumbs from '../Breadcrumbs';

const PageHeader = ({ title, subtitle, renderButtons, breadcrumbs }: any) => (
  <Pane
    marginBottom={24}
    paddingTop={24}
    top={-24}
    position="sticky"
    background="white"
    zIndex={10}
    marginTop={-24}
    minHeight={81}
  >
    <Pane display="flex" borderBottom paddingBottom={16}>
      <Pane flex="1">
        <Heading size={600}>{title}</Heading>
        {subtitle && (
          <Paragraph marginTop={8} color="muted">
            {subtitle}
          </Paragraph>
        )}
      </Pane>

      <Pane marginLeft="auto">{renderButtons}</Pane>
    </Pane>

    {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
  </Pane>
);

export default PageHeader;
