import * as Yup from 'yup';

export const AmountPerAmountSchema = Yup.object()
  .shape({
    value: Yup.number().required('Must add a base value'),
    unit: Yup.string().required('Must add a base unit'),
    perAmount: Yup.object()
      .shape({
        value: Yup.number().required('Must add a per amount value'),
        unit: Yup.string().required('Must add a per amount unit')
      })
      .nullable()
  })
  .nullable();

export const AmountSchema = Yup.object()
  .shape({
    value: Yup.number().required('Must add a base value'),
    unit: Yup.string().required('Must add a base unit')
  })
  .nullable();
