import gql from 'graphql-tag';
import { InputErrorFragment } from '../fragments';

export const UPDATE_CARD = gql`
  mutation UpdatePaymentMethodForOrganization(
    $input: UpdatePaymentMethodForOrganizationInput!
  ) {
    updatePaymentMethodForOrganization(input: $input) {
      errors {
        ...InputError
      }
      creditCard {
        last4
      }
    }
  }
  ${InputErrorFragment}
`;
