import ProtocolSelector from 'components/shared/ProtocolSelector';

const ReferredForm = ({ organizationId, protocolId }: any) => ({
  name: 'referredProtocol',
  label: 'Referred protocol',
  description: 'Which protocol is this step referring to?',
  type: ProtocolSelector,
  organizationId,
  ignoreProtocolId: protocolId
});

export default ReferredForm;
