import gql from 'graphql-tag';
import {
  InputErrorFragment,
  SubscriptionFragment
} from 'components/shared/fragments';

export const USER_QUERY = gql`
  query UserProfile($userId: ID!) {
    user(userId: $userId) {
      id
      name
      email
      role
      seats {
        id
        roles {
          id
          name
        }
        organization {
          id
          slug
          name
        }
      }
      subscription {
        ...Subscription
      }
      insertedAt
      updatedAt
    }
  }
  ${SubscriptionFragment}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    result: updateUser(input: $input) {
      user {
        id
        name
        email
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const RESET_PASSWORD_FOR_USER = gql`
  mutation ResetPasswordForUser($input: ResetPasswordForUserInput!) {
    result: resetPasswordForUser(input: $input) {
      user {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const CHANGE_ROLE_FOR_USER = gql`
  mutation ChangeRoleForUser($input: ChangeRoleForUserInput!) {
    result: changeRoleForUser(input: $input) {
      user {
        id
        role
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const CANCEL_SUB_FOR_USER = gql`
  mutation CancelSubscriptionForUser(
    $input: CancelSubscriptionForIndividualInput!
  ) {
    result: cancelSubscriptionForIndividual(input: $input) {
      subscription {
        ...Subscription
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
  ${SubscriptionFragment}
`;
