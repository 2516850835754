import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  zipCode: null,
  agencies: null,
  selectedPlan: null,
  selectedOrganization: null,
  organizationCredentials: {
    licenseNumber: null,
    issuingState: null,
    credentialId: null
  }
};

export default createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setResult: (state: any, action: any) => {
      state.zipCode = action.payload.zipCode;
      state.agencies = action.payload.agencies;
    },
    setOrganization: (state: any, action: any) => {
      state.selectedOrganization = action.payload;
    },
    setOrganizationCredentials: (state: any, action: any) => {
      state.organizationCredentials.licenseNumber =
        action.payload.licenseNumber;

      state.organizationCredentials.issuingState = action.payload.issuingState;

      state.organizationCredentials.credentialId =
        action.payload.credentialId === '' ? null : action.payload.credentialId;
    },
    setPlan: (state: any, action: any) => {
      state.selectedPlan = action.payload;
    }
  }
});

export const zipCodeSelector = (state: any) => state.onboarding.zipCode;
export const agenciesSelector = (state: any) => state.onboarding.agencies;
export const organizationSelector = (state: any) =>
  state.onboarding.selectedOrganization;

export const organizationCredentialsSelector = (state: any) =>
  state.onboarding.organizationCredentials;

export const planSelector = (state: any) => state.onboarding.selectedPlan;
