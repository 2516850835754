import React from 'react';
import { Menu } from 'evergreen-ui';

const ProtocolStepMenu = ({
  onViewSourceClick,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  isViewOnly
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item icon="document-open" onSelect={onViewSourceClick}>
          View source
        </Menu.Item>
      </Menu.Group>
      {!isViewOnly && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item onSelect={onEditClick}>Edit step</Menu.Item>
            <Menu.Item onSelect={onDuplicateClick}>Duplicate step</Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onDeleteClick}>
              Delete step
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default ProtocolStepMenu;
