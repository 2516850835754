import React from 'react';
import queryString from 'query-string';
import { get } from 'lodash';
import { Pane, BackButton, Strong, toaster } from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import PageHeader from 'components/shared/PageHeader';
import CreateOrUpdateProtocolStep from 'components/organizations/CreateOrUpdateProtocolStep';
import { protocolPath } from 'lib/urls';
import { StepType } from '@/constants/protocolSteps';
import { generateBreadcrumbs } from 'lib/protocols';

function buildInitialValues(protocol: any) {
  if (protocol.referencedSources && protocol.referencedSources.length === 1) {
    const { pageNumbers, referencedSource } = protocol.referencedSources[0];

    return {
      sourcePageNumber: pageNumbers[0],
      source: referencedSource
    };
  }

  return {};
}

export default ({ history, protocol, protocolSet, organization }: any) => {
  const qs = queryString.parse(history.location.search);
  const stepType = get(qs, 'stepType', StepType.DOSE);

  // We're only able to set a patient type entry condition
  // if there isn't already one set on the protocol set
  const canSetPatientTypeOnProtocolStep =
    get(protocolSet, 'entryCriteria.patientTypes', []).length === 0 &&
    get(protocol, 'entryCriteria.patientTypes', []).length === 0;

  return (
    <>
      <PageHeader
        title={`Create new ${stepType} step within ${protocol.name}`}
        subtitle={
          <>
            Part of <Strong>{protocolSet.name}</Strong>, last edited{' '}
            <RelativeDate textSize={400} date={protocol.updatedAt} />
          </>
        }
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(protocolPath(organization, protocolSet, protocol))
            }
          >
            Back to protocol
          </BackButton>
        }
        breadcrumbs={generateBreadcrumbs(organization, protocolSet, protocol)}
      />
      <Pane width="500px">
        <CreateOrUpdateProtocolStep
          organizationId={organization.id}
          stepType={stepType}
          protocolStep={{}}
          protocolSetId={protocolSet.id}
          protocolId={protocol.id}
          canSetPatientType={canSetPatientTypeOnProtocolStep}
          initialValues={buildInitialValues(protocol)}
          onComplete={() => {
            toaster.success('Protocol step created successfully');
            history.push(protocolPath(organization, protocolSet, protocol));
          }}
        />
      </Pane>
    </>
  );
};
