import React from 'react';
import { Menu } from 'evergreen-ui';

const ToolsMenu = ({
  onResetOrderClick,
  onReferencingProtocolsClick,
  onSubProtocolsClick,
  isViewOnly
}: any) => {
  return (
    <Menu>
      {/* @ts-expect-error title issue */}
      <Menu.Group title="Protocol">
        <Menu.Item icon="document-open" onSelect={onReferencingProtocolsClick}>
          Referencing protocols
        </Menu.Item>
        {onSubProtocolsClick && (
          <Menu.Item icon="git-branch" onSelect={onSubProtocolsClick}>
            Subprotocols
          </Menu.Item>
        )}
      </Menu.Group>
      {!isViewOnly && (
        <>
          <Menu.Divider />
          {/* @ts-expect-error title issue */}
          <Menu.Group title="Tools">
            <Menu.Item onSelect={onResetOrderClick}>Reset step order</Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default ToolsMenu;
