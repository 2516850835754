import gql from 'graphql-tag';
import { OrganizationFragment } from 'components/shared/fragments';

export const LIST_CHILD_ORGS = gql`
  query ListChildOrganizations($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      children {
        ...Organization
      }
    }
  }
  ${OrganizationFragment}
`;
