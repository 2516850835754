import gql from 'graphql-tag';
import {
  PageInfoFragment,
  MedicationStorageMethodFragment
} from 'components/shared/fragments';

export const MEDICATIONS_LIST = gql`
  query GlobalMedicationsList(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterGlobalMedicationsInput
  ) {
    globalMedications(
      first: $first
      after: $after
      before: $before
      last: $last
      filters: $filters
    ) {
      edges {
        node {
          id
          brandName
          genericName
          storageMethods {
            ...MedicationStorageMethod
          }
          insertedAt
          updatedAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${MedicationStorageMethodFragment}
  ${PageInfoFragment}
`;
