import gql from 'graphql-tag';
import {
  InputErrorFragment,
  OrganizationFragment
} from 'components/shared/fragments';

export const GET_ORGANIZATION = gql`
  query GetFullOrganizationForEdit($orgId: ID!) {
    organization(id: $orgId) {
      id
      name
      type
      roles
      serviceCountyCodes
      availableFeatures {
        name
        status
      }
      medicalAuthorities {
        id
        name
      }
      parent {
        id
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    result: createOrganization(input: $input) {
      organization {
        ...Organization
      }
      errors {
        ...InputError
      }
    }
  }
  ${OrganizationFragment}
  ${InputErrorFragment}
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    result: updateOrganization(input: $input) {
      organization {
        ...Organization
      }
      errors {
        ...InputError
      }
    }
  }
  ${OrganizationFragment}
  ${InputErrorFragment}
`;
