import React, { useState, useEffect } from 'react';
import { Pane, SearchInput, Button } from 'evergreen-ui';

const SearchListControl = ({
  initialValue,
  placeholder = '',
  onSubmit
}: any) => {
  const [searchValue, setSearchValue] = useState(initialValue || '');

  useEffect(() => {
    setSearchValue(initialValue || '');
  }, [initialValue]);

  return (
    <Pane marginBottom={16} display="flex">
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(searchValue);
        }}
      >
        <SearchInput
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
          placeholder={placeholder}
        />
        <Button marginTop={-1} type="submit">
          Go
        </Button>
      </form>
    </Pane>
  );
};

export default SearchListControl;
