import React from 'react';
import { Tooltip, Text } from 'evergreen-ui';
import distanceInWords from 'date-fns/distance_in_words';
import format from 'date-fns/format';

const RelativeDate = ({ date, textSize = 300, styles = {} }: any) => {
  if (!date) {
    return (
      <Text {...styles} size={textSize}>
        N/A
      </Text>
    );
  }

  const formattedDate = format(date, 'MM/DD/YYYY H:m:s');

  return (
    <Tooltip content={formattedDate}>
      <Text {...styles} size={textSize}>
        {distanceInWords(new Date(), date, { addSuffix: true })}
      </Text>
    </Tooltip>
  );
};

export default RelativeDate;
