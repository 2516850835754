import React, { useState } from 'react';
import queryString from 'query-string';
import {
  BackButton,
  Button,
  toaster,
  Heading,
  Pane,
  Strong
} from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import PageHeader from 'components/shared/PageHeader';
import DeleteDialog from 'components/shared/DeleteDialog';
import RelativeDate from 'components/shared/RelativeDate';
import ProtocolList, {
  PROTOCOL_LIST
} from 'components/organizations/ProtocolList';
import CreateOrUpdateSubprotocol from 'components/organizations/CreateOrUpdateProtocol/CreateOrUpdateSubprotocol';
import EntryCriteria from 'components/shared/EntryCriteria';
import SideSheet from 'components/shared/SideSheet';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';
import { protocolPath, protocolSetPath } from 'lib/urls';
import { DELETE_PROTOCOL } from './queries';
import { generateBreadcrumbs } from 'lib/protocols';

export default ({
  history,
  protocol,
  protocolSet,
  isViewOnly,
  organization
}: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters: {
      ...filters,
      publishedState: 'DRAFT',
      type: 'SUBPROTOCOL',
      parentProtocolId: protocol.id
    },
    organizationId: organization.id,
    protocolSetId: protocolSet.id
  };

  const refetchQuery = {
    query: PROTOCOL_LIST,
    variables
  };

  const [deleteProtocol, { loading: isDeleteLoading }] = useMutation(
    DELETE_PROTOCOL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.protocol) {
          toaster.success('Subrotocol has been successfully deleted');
        } else {
          toaster.danger('Could not delete subprotocol, please try again');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title={`Subprotocols for ${protocol.name}`}
        subtitle={
          <>
            Part of <Strong>{protocolSet.name}</Strong>, last edited{' '}
            <RelativeDate textSize={400} date={protocol.updatedAt} />
          </>
        }
        renderButtons={
          <>
            <BackButton
              marginLeft="auto"
              marginRight={8}
              onClick={() =>
                history.push(
                  `${protocolSetPath(organization, protocolSet)}/protocols`
                )
              }
            >
              Back to Protocols
            </BackButton>
            <Button
              onClick={() => setShowForm(true)}
              iconBefore="add"
              appearance="primary"
              disabled={isViewOnly}
            >
              New Subprotocol
            </Button>
          </>
        }
        breadcrumbs={generateBreadcrumbs(organization, protocolSet, protocol)}
      />

      {protocolSet.entryCriteria && (
        <Pane marginBottom={24}>
          <Heading size={100} marginBottom={8}>
            Entry criteria (inherited from {protocolSet.name})
          </Heading>
          <EntryCriteria {...protocolSet.entryCriteria} />
        </Pane>
      )}

      {protocol.entryCriteria && (
        <Pane marginBottom={24}>
          <Heading size={100} marginBottom={8}>
            Entry criteria (inherited from {protocol.name})
          </Heading>
          <EntryCriteria {...protocol.entryCriteria} />
        </Pane>
      )}

      <ProtocolList
        protocolSet={protocolSet}
        organization={organization}
        onDeleteClick={(protocol: any) => setShowDeleteDialog(protocol)}
        onEditClick={(protocol: any) => setShowForm(protocol)}
        onManageContentClick={(protocol: any) => {
          history.push(protocolPath(organization, protocolSet, protocol));
        }}
        onReferencingProtocolsClick={(protocol: any) => {
          history.push(
            `${protocolPath(
              organization,
              protocolSet,
              protocol
            )}/referencing-protocols`
          );
        }}
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateSubprotocol
          protocol={showForm || {}}
          parentProtocol={protocol}
          protocolSet={protocolSet}
          onComplete={() => {
            toaster.success('Subprotocol saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteProtocol({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
