import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import { EquipmentFunctionFragment } from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';
import PublishDialog from './PublishEquipmentFunctionDialog';

export const EQUIPMENT_FUNCTION_LIST = gql`
  query FunctionsForEquipment($equipmentId: ID!) {
    equipment: node(id: $equipmentId, type: EQUIPMENT) {
      ... on Equipment {
        id
        functions(filters: { publishedState: DRAFT }) {
          ...EquipmentFunction
        }
      }
    }
  }
  ${EquipmentFunctionFragment}
`;

const EquipmentFunctionList = ({
  equipment,
  onEditClick,
  onDeleteClick,
  onListDosesClick
}: any) => {
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const { loading, data, error } = useQuery(EQUIPMENT_FUNCTION_LIST, {
    variables: { first: 999, equipmentId: equipment.id },
    fetchPolicy: 'network-only'
  });
  const functions = get(data, 'equipment.functions', []);

  return (
    <Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Published</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Updated</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <>
              <Table.Body>
                {functions.map((equipmentFunction: any) => (
                  <Table.Row key={equipmentFunction.id} isSelectable>
                    <Table.Cell>
                      <Text size={300} fontWeight={500}>
                        {equipmentFunction.name}
                      </Text>
                    </Table.Cell>
                    <Table.Cell
                      width={100}
                      display="flex"
                      alignItems="center"
                      flex="none"
                    >
                      {equipmentFunction.updatedAt ===
                      equipmentFunction.lastPublishedAt ? (
                        <Badge color="green">Published</Badge>
                      ) : (
                        <Badge color="blue">Draft</Badge>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {equipmentFunction.lastPublishedAt ? (
                        <RelativeDate
                          date={equipmentFunction.lastPublishedAt}
                        />
                      ) : (
                        <Text size={300}>N/A</Text>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={equipmentFunction.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onEditClick={() => onEditClick(equipmentFunction)}
                            onDeleteClick={() =>
                              onDeleteClick(equipmentFunction)
                            }
                            onListDosesClick={() =>
                              onListDosesClick(equipmentFunction)
                            }
                            onPublishClick={() =>
                              showPublishDialog(equipmentFunction)
                            }
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && functions.length === 0 && <EmptyContent />}

      <PublishDialog
        equipmentFunction={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />
    </Pane>
  );
};

export default EquipmentFunctionList;
