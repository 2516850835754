import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .email()
    .required()
});

export const Form = {
  name: 'organization',
  validationSchema: Schema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Jane Doe',
      required: true
    },
    {
      name: 'email',
      label: 'Email address',
      placeholder: 'jane.doe@ems.org',
      required: true
    }
  ]
};
