import React from 'react';
import Helmet from 'react-helmet';
import { Pane } from 'evergreen-ui';
import PageHeader from 'components/shared/PageHeader';
import BillingSummary from 'components/organizations/BillingSummary';

export default ({ organization }: any) => {
  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>

      <PageHeader title="Billing" />

      <Pane width="500px">
        <BillingSummary organization={organization} />
      </Pane>
    </>
  );
};
