import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required()
});

// @ts-expect-error TS(6133): 'organizationId' is declared but its value is neve... Remove this comment to see the full error message
export const Form = (organizationId: any) => ({
  name: 'directoryContact',
  validationSchema: Schema,

  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Medical Control'
    },
    {
      name: 'starred',
      label: 'Starred',
      type: 'switch',
      description: 'Starred items appear at the top of the list'
    },
    {
      name: 'phoneNumbers',
      label: 'Phone Numbers',
      type: 'listOf',
      subFieldType: 'text'
    },
    {
      name: 'faxNumbers',
      label: 'Fax Numbers',
      type: 'listOf',
      subFieldType: 'text'
    },
    {
      name: 'radioFrequencies',
      label: 'Radio Frequencies',
      type: 'listOf',
      subFieldType: 'text'
    }
  ]
});
