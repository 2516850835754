import React from 'react';
import * as Yup from 'yup';
import PlanSelectorDataSource from 'components/shared/PlanSelectorDataSource';

const Schema = Yup.object().shape({
  totalSeatCount: Yup.number()
    .required()
    .min(1),
  email: Yup.string().required(),
  planId: Yup.string()
    .required()
    .nullable(),
  coupon: Yup.string().nullable()
});

export const SubscriptionForm = {
  name: 'subscription',
  validationSchema: Schema,
  fields: [
    {
      name: 'totalSeatCount',
      label: 'Total seat count',
      description: 'The total number of paid seats',
      type: 'number'
    },
    {
      name: 'planId',
      label: 'Plan',
      description: 'The plan to put this organization on',
      dataSource: (render: any) => (
        <PlanSelectorDataSource
          render={render}
          filters={{ type: 'ORGANIZATION' }}
        />
      ),
      type: 'asyncSelect'
    },
    {
      name: 'email',
      label: 'Billing Email',
      description: 'Associate a billing email'
    },
    {
      name: 'coupon',
      label: 'Coupon',
      description: 'Apply a coupon to this subscription'
    }
  ]
};
