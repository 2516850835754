import { findIndex } from 'lodash';

function findPositionInParentSteps(protocol: any, parentSteps: any) {
  return findIndex(
    parentSteps,
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    step => step.subprotocol && step.subprotocol.id === protocol.id
  );
}

function filterSubprotocolSteps(parentSteps: any) {
  return parentSteps.filter((step: any) => !step.subprotocol);
}

function markStepsAsDisabled(steps: any) {
  return steps.map((step: any) => ({
    ...step,
    disabled: true
  }));
}

export default function gatherStepsForProtocol(protocol: any) {
  if (!protocol) return [];

  const { steps, parent } = protocol;
  const isSubprotocol = !!parent;

  if (!isSubprotocol) return steps;

  // Otherwise we want to do some fancy step combinations
  const indexOfSubprotocolStep = findPositionInParentSteps(
    protocol,
    parent.steps
  );

  const parentSteps = markStepsAsDisabled(parent.steps);

  // Subprotocol doesn't exist on parent so just concat the steps
  if (indexOfSubprotocolStep === -1)
    return filterSubprotocolSteps(parentSteps.concat(steps));

  // Otherwise we want to include the parent steps and the current steps
  // in the correct order, with the parent steps spliced between
  // we also want to _remove_ all of the subprotocols from displaying
  return filterSubprotocolSteps([
    ...parentSteps.slice(0, indexOfSubprotocolStep),
    ...steps,
    ...parentSteps.slice(indexOfSubprotocolStep)
  ]);
}
