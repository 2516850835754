import React, { useState } from 'react';
import { groupBy } from 'lodash';
import { Pane, Heading, Icon, Dialog, toaster } from 'evergreen-ui';
import posed from 'react-pose';
import { useMutation } from '@apollo/react-hooks';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DELETE_PROFILE_ENTRY } from './queries';
import { MedicationEntry, EquipmentEntry } from './InventoryEntry';
import EditQuestion from './EditQuestion';

const Expandable = posed.div({
  expanded: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: {
    height: 0,
    opacity: 0
  }
});

const Section = ({ children, title }: any) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Pane marginBottom={8}>
      <Pane
        cursor="pointer"
        display="flex"
        padding={16}
        paddingTop={8}
        paddingBottom={8}
        alignItems="center"
        borderBottom
        onClick={() => setExpanded(!expanded)}
      >
        <Heading size={100}>{title}</Heading>
        <Icon
          marginLeft="auto"
          color="muted"
          icon={!expanded ? 'chevron-down' : 'chevron-up'}
          marginRight={4}
        />
      </Pane>
      <Expandable
        pose={expanded ? 'expanded' : 'collapsed'}
        style={{ overflow: 'hidden' }}
      >
        {children}
      </Expandable>
    </Pane>
  );
};

const AnswersList = ({
  entries,
  organization,
  refetchEntries,
  refetchQuestions
}: any) => {
  const [showEdit, setShowEdit] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [deleteEntry, { loading: isDeleteLoading }] = useMutation(
    DELETE_PROFILE_ENTRY,
    {
      onCompleted: ({ result }: any) => {
        if (!result.errors) {
          toaster.success('Inventory entry successfully deleted');
          setShowDeleteDialog(null);

          // Refetch everything (questions and entries)
          // as both have changed
          refetchEntries();
          refetchQuestions();
        }
      }
    }
  );

  const groupedEntries = groupBy(entries, entry => entry.__typename);

  const medicationsByIsCarried = groupBy(
    groupedEntries.MedicationInventoryProfileEntry,
    entry => (entry.isCarried ? 'carried' : 'notCarried')
  );

  const equipmentByIsCarried = groupBy(
    groupedEntries.EquipmentInventoryProfileEntry,
    entry => (entry.equipment ? 'carried' : 'notCarried')
  );

  const medicationsCarried = medicationsByIsCarried.carried || [];
  const medicationsNotCarried = medicationsByIsCarried.notCarried || [];

  const equipmentCarried = equipmentByIsCarried.carried || [];
  const equipmentNotCarried = equipmentByIsCarried.notCarried || [];

  return (
    <Pane overflowY="scroll" flex={1}>
      <Section title={`Medications Carried (${medicationsCarried.length})`}>
        {medicationsCarried.map(entry => (
          <MedicationEntry
            key={entry.id}
            entry={entry}
            onEditClick={() =>
              setShowEdit({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'MEDICATION',
                entryId: entry.id
              })
            }
            onDeleteClick={() =>
              setShowDeleteDialog({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'MEDICATION',
                entryId: entry.id
              })
            }
          />
        ))}
      </Section>
      <Section title={`Equipment Carried (${equipmentCarried.length})`}>
        {equipmentCarried.map(entry => (
          <EquipmentEntry
            key={entry.id}
            entry={entry}
            onEditClick={() =>
              setShowEdit({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'EQUIPMENT',
                entryId: entry.id
              })
            }
            onDeleteClick={() =>
              setShowDeleteDialog({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'EQUIPMENT',
                entryId: entry.id
              })
            }
          />
        ))}
      </Section>
      <Section
        title={`Medications Not Carried (${medicationsNotCarried.length})`}
      >
        {medicationsNotCarried.map(entry => (
          <MedicationEntry
            key={entry.id}
            entry={entry}
            onEditClick={() =>
              setShowEdit({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'MEDICATION',
                entryId: entry.id
              })
            }
            onDeleteClick={() =>
              setShowDeleteDialog({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'MEDICATION',
                entryId: entry.id
              })
            }
          />
        ))}
      </Section>

      <Section title={`Equipment Not Carried (${equipmentNotCarried.length})`}>
        {equipmentNotCarried.map(entry => (
          <EquipmentEntry
            key={entry.id}
            entry={{ ...entry, equipment: entry.genericEquipment }}
            onEditClick={() =>
              setShowEdit({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'EQUIPMENT',
                entryId: entry.id
              })
            }
            onDeleteClick={() =>
              setShowDeleteDialog({
                // @ts-expect-error TS(2345): Argument of type '{ entryType: string; entryId: an... Remove this comment to see the full error message
                entryType: 'EQUIPMENT',
                entryId: entry.id
              })
            }
          />
        ))}
      </Section>

      <Dialog
        isShown={showEdit !== null}
        hasHeader={false}
        hasFooter={false}
        hasClose={true}
        onCloseComplete={() => setShowEdit(null)}
      >
        {showEdit && (
          <EditQuestion
            organization={organization}
            // @ts-expect-error TS(2339): Property 'entryType' does not exist on type 'never... Remove this comment to see the full error message
            entryType={showEdit.entryType}
            // @ts-expect-error TS(2339): Property 'entryId' does not exist on type 'never'.
            entryId={showEdit.entryId}
            refetchEntries={refetchEntries}
            close={() => setShowEdit(null)}
          />
        )}
      </Dialog>

      <DeleteDialog
        isShown={showDeleteDialog !== null}
        onCloseComplete={() => setShowDeleteDialog(null)}
        onConfirm={() =>
          deleteEntry({
            variables: { input: showDeleteDialog }
          })
        }
        isLoading={isDeleteLoading}
      />
    </Pane>
  );
};

export default AnswersList;
