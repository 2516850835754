import React, { useEffect } from 'react';
import { Pane, Heading, Strong, Paragraph } from 'evergreen-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrganizationRoleForm from 'components/onboarding/OrganizationRoleForm';
import onboardingSlice, {
  organizationSelector,
  zipCodeSelector
} from 'redux/onboarding';
import { page } from 'lib/analytics';

const { setOrganizationCredentials } = onboardingSlice.actions;

const OrgnizationRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const zipCode = useSelector(zipCodeSelector);

  useEffect(() => {
    page('Add Agency Credentials');
  }, []);

  return (
    <Pane>
      <Pane textAlign="center" marginBottom={24}>
        <Heading size={600} marginBottom={8}>
          Credential details
        </Heading>
        <Paragraph color="muted">
          We need to know what credential level you are at{' '}
          <Strong>{organization.name}</Strong>.
        </Paragraph>

        <OrganizationRoleForm
          agency={organization}
          selectedState={zipCode.state}
          credentialRequired
          onComplete={(values: any) => {
            dispatch(setOrganizationCredentials(values));
            history.push(`/review`);
          }}
        />
      </Pane>
    </Pane>
  );
};

export default OrgnizationRole;
