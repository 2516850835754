import React from 'react';
import {
  Icon,
  Pane,
  UnorderedList,
  ListItem,
  Tooltip,
  Text
} from 'evergreen-ui';
import pluralize from 'pluralize';
import { groupBy, get } from 'lodash';
import { PatientTypeWithLabel } from 'constants/units';
import { formatAgeRange } from 'lib/protocols';
import Filters from './Filters';

const badgeStyles = {
  alignItems: 'center',
  display: 'inline-flex',
  fontWeight: 400,
  textTransform: 'none',
  background: 'tint2',
  padding: 2
};

const EntryCriteriaPill = ({ item, icon }: any) => (
  <Pane {...badgeStyles} marginRight={4}>
    <Icon icon={icon} marginLeft={4} marginRight={4} size={12} color="muted" />
    <Text size={300} marginRight={4}>
      {item}
    </Text>
  </Pane>
);

const NotesContent = ({ notes }: any) => (
  <UnorderedList>
    {notes.map((note: any, index: any) => (
      <ListItem key={`note-${index}`} size={300}>
        {typeof note === 'string' ? note : note.text}
      </ListItem>
    ))}
  </UnorderedList>
);

const EntryCriteria = ({
  patientTypes,
  filters,
  notes,
  patientAgeRanges
}: any) => {
  const notesByType = groupBy(notes, note =>
    !get(note, 'important', true) ? 'normal' : 'important'
  );

  const importantNoteCount = get(notesByType, 'important.length');
  const normalNoteCount = get(notesByType, 'normal.length');

  return (
    <>
      {patientTypes &&
        patientTypes.length > 0 &&
        patientTypes.map((pt: any) => (
          <EntryCriteriaPill
            key={pt}
            icon="person"
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            item={PatientTypeWithLabel[pt]}
          />
        ))}

      {patientAgeRanges &&
        patientAgeRanges.length > 0 &&
        patientAgeRanges.map((ageRange: any, idx: any) => (
          <EntryCriteriaPill
            key={`pt-age-${idx}`}
            icon="calendar"
            item={formatAgeRange(ageRange)}
          />
        ))}

      {filters && (
        <Pane {...badgeStyles} marginRight={4}>
          <Icon
            icon="warning-sign"
            marginLeft={4}
            marginRight={4}
            size={12}
            color="warning"
          />
          <Filters {...filters} />
        </Pane>
      )}

      {/* @ts-expect-error TS(2365): Operator '>' cannot be applied to constants 'any[]' an... Remove this comment to see the full error message */}
      {importantNoteCount > 0 && (
        <Tooltip
          appearance="card"
          content={<NotesContent notes={notesByType.important} />}
        >
          <Pane {...badgeStyles} marginRight={4}>
            <Icon
              icon="warning-sign"
              marginLeft={4}
              marginRight={4}
              size={12}
              color="warning"
            />
            <Text size={300} marginRight={4}>
              {/* @ts-expect-error */}
              {pluralize('note', importantNoteCount, true)}
            </Text>
          </Pane>
        </Tooltip>
      )}

      {/* @ts-expect-error TS(2365): Operator '>' cannot be applied to constants 'any[]' an... Remove this comment to see the full error message */}
      {normalNoteCount > 0 && (
        <Tooltip
          appearance="card"
          content={<NotesContent notes={notesByType.normal} />}
        >
          <Pane {...badgeStyles} marginRight={4}>
            <Icon
              icon="edit"
              marginLeft={4}
              marginRight={4}
              size={12}
              color="muted"
            />
            <Text size={300} marginRight={4}>
              {/* @ts-expect-error */}
              {pluralize('note', normalNoteCount, true)}
            </Text>
          </Pane>
        </Tooltip>
      )}
    </>
  );
};

export default EntryCriteria;
