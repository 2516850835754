export const LIST_ORGS_PATH = '/admin/organizations';
export const SHOW_ORG_PATH = '/admin/organizations/:id';
export const LIST_USERS_PATH = '/admin/users';
export const SHOW_USER_PATH = '/admin/users/:id';

export const LIST_HOSPITALS_PATH = '/admin/hospitals';
export const CREATE_HOSPITAL_PATH = '/admin/hospitals/new';
export const EDIT_HOSPITAL_PATH = '/admin/hospitals/:id';

export const LIST_MOLECULES_PATH = '/admin/molecules';

export const LIST_ALIASES_PATH = '/admin/aliases';
