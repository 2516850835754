import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toName } from 'lib/medications';

const MEDICATIONS = gql`
  query MedicationsDataSource(
    $filters: FilterOrganizationMedicationsInput!
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      medications(first: 999, filters: $filters) {
        edges {
          node {
            id
            brandName
            genericName
          }
        }
      }
    }
  }
`;

const MedicationDataSource = ({
  render,
  organizationId,
  filters = {}
}: any) => {
  const { data, loading, error } = useQuery(MEDICATIONS, {
    variables: { filters, organizationId },
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? // @ts-expect-error TS(7031): Binding element 'medication' implicitly has an 'an... Remove this comment to see the full error message
        data.organization.medications.edges.map(({ node: medication }) => ({
          label: toName(medication),
          value: medication.id
        }))
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default MedicationDataSource;
