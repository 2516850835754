import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const MOLECULES = gql`
  query MOLECULES {
    molecules(first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const MoleculeDataSource = ({ render }: any) => {
  const { data, loading, error } = useQuery(MOLECULES, {
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? // @ts-expect-error TS(7031): Binding element 'molecule' implicitly has an 'any'... Remove this comment to see the full error message
        data.molecules.edges.map(({ node: molecule }) => ({
          label: molecule.name,
          value: molecule.id
        }))
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default MoleculeDataSource;
