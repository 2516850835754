import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';
import { CREATE_DIRECTORY_CONTACT, UPDATE_DIRECTORY_CONTACT } from './queries';

const initialValues = {
  name: ''
};

const CreateOrUpdateHospitalDirectoryContact = ({
  organizationId,
  directoryContact,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasDirectoryContact = directoryContact && directoryContact.id;
  const [createOrUpdateDirectoryContact, { loading }] = useMutation(
    hasDirectoryContact ? UPDATE_DIRECTORY_CONTACT : CREATE_DIRECTORY_CONTACT,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasDirectoryContact
    ? sanitizeData(directoryContact, ['organization'])
    : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} borderBottom="muted" display="flex">
        <Heading size={500}>
          {hasDirectoryContact
            ? 'Update directory contact'
            : 'Create new directory contact'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={directoryContact?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form(organizationId)}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            const variables = hasDirectoryContact
              ? input
              : { ...input, organizationId };

            setErrors({});
            createOrUpdateDirectoryContact({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateHospitalDirectoryContact;
