import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LoadingScreen from 'components/shared/LoadingScreen';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import { ListPage as ListVersions } from './Versions';

const SOURCE = gql`
  query Source($id: ID!) {
    source: node(id: $id, type: SOURCE) {
      ... on Source {
        id
        name
        issuingBody
        organization {
          id
        }
      }
    }
  }
`;

export default ({ match, organization }: any) => {
  const { url, params } = match;
  const { id: sourceId } = params;
  const { data, loading } = useQuery(SOURCE, {
    variables: { id: sourceId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AuthenticatedRoute
        exact
        path={`${url}/versions`}
        source={data.source}
        organization={organization}
        component={ListVersions}
      />
    </>
  );
};
