import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { PageInfoFragment } from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';

const HospitalCapabilityItemFragment = gql`
  fragment HospitalCapabilityItem on AuthorityHospitalCapability {
    id
    name
    aliases
    parent {
      id
      name
    }
    insertedAt
    updatedAt
  }
`;

export const CAPABILITIES_LIST = gql`
  query CapabilitiesForOrg(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationHospitalCapabilitiesInput
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      hospitalCapabilities(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...HospitalCapabilityItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${HospitalCapabilityItemFragment}
`;

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const CredentialList = ({
  filters,
  variables,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick,
  onEditClick
}: any) => {
  const { loading, data, error } = useQuery(CAPABILITIES_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const capabilities = get(data, 'organization.hospitalCapabilities.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Belongs to</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {capabilities.map(({ node: capability }) => (
                <Table.Row key={capability.id} isSelectable>
                  <Table.Cell>
                    <Text size={300} fontWeight={500}>
                      {capability.name}
                    </Text>
                  </Table.Cell>
                  <Table.TextCell>
                    {capability.parent && capability.parent.name}
                  </Table.TextCell>
                  <Table.Cell>
                    <RelativeDate date={capability.updatedAt} />
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <Popover
                      content={
                        <Menu
                          onEditClick={() => onEditClick(capability)}
                          onDeleteClick={() => onDeleteClick(capability)}
                        />
                      }
                      position={Position.BOTTOM_RIGHT}
                    >
                      <IconButton
                        icon="more"
                        height={24}
                        appearance="minimal"
                      />
                    </Popover>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && capabilities.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'organization.hospitalCapabilities.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default CredentialList;
