import gql from 'graphql-tag';
import { InputErrorFragment, UserFragment } from 'components/shared/fragments';

export const PROVISION_USER = gql`
  mutation ProvisionUser($input: ProvisionUserInput) {
    result: provisionUser(input: $input) {
      errors {
        ...InputError
      }
      user {
        ...User
      }
    }
  }
  ${InputErrorFragment}
  ${UserFragment}
`;
