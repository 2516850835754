import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import {
  InputErrorFragment,
  MoleculeFragment
} from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_MOLECULE = gql`
  mutation CreateMolecule($input: CreateMoleculeInput!) {
    result: createMolecule(input: $input) {
      molecule {
        ...Molecule
      }
      errors {
        ...InputError
      }
    }
  }
  ${MoleculeFragment}
  ${InputErrorFragment}
`;

const UPDATE_MOLECULE = gql`
  mutation UpdateMolecule($input: UpdateMoleculeInput!) {
    result: updateMolecule(input: $input) {
      molecule {
        ...Molecule
      }
      errors {
        ...InputError
      }
    }
  }
  ${MoleculeFragment}
  ${InputErrorFragment}
`;

const initialValues = {
  name: '',
  aliases: []
};

const CreateOrUpdateMolecule = ({
  molecule,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const [createOrUpdateMolecule, { loading }] = useMutation(
    molecule && molecule.id ? UPDATE_MOLECULE : CREATE_MOLECULE,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData =
    molecule && molecule.id
      ? sanitizeData(molecule, ['lastPublishedAt'])
      : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {molecule && molecule.id ? 'Update molecule' : 'Create new molecule'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={molecule?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            setErrors({});
            createOrUpdateMolecule({ variables: { input } });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateMolecule;
