import React, { useState } from 'react';
import { debounce, includes } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import {
  Badge,
  Pane,
  Avatar,
  Heading,
  Text,
  Table,
  Button,
  Strong,
  Icon,
  Link
} from 'evergreen-ui';
import { track } from 'lib/analytics';

function isNorthwell(term: any) {
  return includes(
    ['northwe', 'northwel', 'northwell', 'north well', 'north shore', 'nsuh'],
    term.toLowerCase()
  );
}

const SelectAgency = ({
  agencies,
  existingMembershipIds = [],
  zipCode,
  onSelect
}: any) => {
  const [term, setSearchTerm] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);

  const filteredAgencies =
    term && term !== ''
      ? agencies.filter((agency: any) =>
          // @ts-expect-error TS(2339): Property 'toLowerCase' does not exist on type 'nev... Remove this comment to see the full error message
          agency.name.toLowerCase().includes(term.toLowerCase())
        )
      : agencies;

  const resultsLength = filteredAgencies.length;

  return (
    <Pane>
      <Table marginTop={16} marginBottom={16}>
        <Table.Head>
          <Table.SearchHeaderCell
            onChange={debounce(term => {
              track('Onboarding Agencies Filtered', {
                agency_filter_term: term
              });

              setSearchTerm(term);
            }, 100)}
            placeholder="Search for agency name"
          />
        </Table.Head>
        <Table.Body height={240}>
          {filteredAgencies.map((agency: any) => {
            const isMember = includes(existingMembershipIds, agency.id);

            return (
              <Table.Row
                key={agency.id}
                isSelectable={!isMember}
                onSelect={() => setSelectedAgency(agency)}
                opacity={isMember ? 0.5 : 1}
              >
                <Table.Cell>
                  <Avatar
                    name={agency.name}
                    src={agency.logo && agency.logo.url}
                    size={24}
                    marginRight={8}
                  />
                  <Text>{agency.name}</Text>
                  {isMember && <Badge marginLeft="auto">Member</Badge>}
                  {/* @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'. */}
                  {selectedAgency && selectedAgency.id === agency.id && (
                    <Icon
                      icon="tick-circle"
                      marginLeft="auto"
                      size={16}
                      color="success"
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}

          {resultsLength === 0 && (
            <Pane
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              background="tint1"
              border
              marginTop={24}
              marginBottom={24}
              padding={24}
            >
              {isNorthwell(term) ? (
                <>
                  <Icon
                    icon="info-sign"
                    size={20}
                    marginBottom={8}
                    color="muted"
                  />
                  <Heading size={500} marginBottom={8}>
                    Looking for Northwell?
                  </Heading>
                  <Text color="muted">
                    Unfortunately we're not able to service agencies that use
                    the NYC Protocols just yet.
                  </Text>
                </>
              ) : (
                <Text color="muted">
                  Sorry but we didn't find any matches for agencies that contain{' '}
                  <Strong>{term}</Strong> within{' '}
                  <Strong>
                    {zipCode.county}, {zipCode.state}
                  </Strong>
                  .
                </Text>
              )}

              <Link is={RouterLink} to={'/onboarding/waitlist'} marginTop={8}>
                Sign up for the waitlist
              </Link>
            </Pane>
          )}
        </Table.Body>
      </Table>

      <Button
        height={40}
        appearance="primary"
        width="100%"
        display="flex"
        justifyContent="center"
        marginTop={24}
        disabled={!selectedAgency}
        onClick={() => onSelect(selectedAgency)}
      >
        Next
      </Button>
    </Pane>
  );
};

export default SelectAgency;
