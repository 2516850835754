import React from 'react';
import gql from 'graphql-tag';
import Form from './Form';
import { Heading, Pane, Paragraph } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';

const REQUEST_PW_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`;

const Submitted = () => (
  <Pane textAlign="left" marginTop={16} padding={16} border>
    <Heading size={500} marginBottom={16}>
      Check your email
    </Heading>
    <Paragraph>
      We just sent you an email containing a temporary link that will allow you
      to reset your password. Please check your spam folder if the email doesn’t
      appear within a few minutes.
    </Paragraph>
  </Pane>
);

const ForgotPasswordForm = () => {
  const [requestPasswordReset, { data, loading }] = useMutation(
    REQUEST_PW_RESET
  );

  if (data && data.requestPasswordReset) {
    return <Submitted />;
  }

  return (
    <Form
      onSubmit={(variables: any) => requestPasswordReset({ variables })}
      loading={loading}
    />
  );
};

export default ForgotPasswordForm;
