import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import {
  PageInfoFragment,
  MoleculeFragment
} from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';

export const MOLECULES_LIST = gql`
  query Molecules($first: Int!) {
    molecules(first: $first) {
      edges {
        node {
          ...Molecule
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragment}
  ${MoleculeFragment}
`;

const MoleculesList = ({ onEditClick, onDeleteClick }: any) => {
  const { loading, data, error } = useQuery(MOLECULES_LIST, {
    variables: { first: 999 },
    fetchPolicy: 'network-only'
  });
  const molecules = get(data, 'molecules.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Updated</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {molecules.map(({ node: molecule }) => (
                <Table.Row key={molecule.id} isSelectable>
                  <Table.Cell>
                    <Text size={300} fontWeight={500}>
                      {molecule.name}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={molecule.updatedAt} />
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <Popover
                      content={
                        <Menu
                          onEditClick={() => onEditClick(molecule)}
                          onDeleteClick={() => onDeleteClick(molecule)}
                        />
                      }
                      position={Position.BOTTOM_RIGHT}
                    >
                      <IconButton
                        icon="more"
                        height={24}
                        appearance="minimal"
                      />
                    </Popover>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && molecules.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default MoleculesList;
