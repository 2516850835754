import React from 'react';
import { Menu } from 'evergreen-ui';

const MedicationMenu = ({
  onEditClick,
  onPublishClick,
  onDeleteClick,
  onListDosesClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit medication</Menu.Item>
        <Menu.Item onSelect={onListDosesClick}>List all doses</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="success" onSelect={onPublishClick}>
          Publish medication
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete medication
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default MedicationMenu;
