import React, { FC, useEffect } from 'react';
import { Pane, Paragraph, UnorderedList, ListItem } from 'evergreen-ui';
import Media from 'react-media';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { Route, Redirect, useRouteMatch, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { organizationSelector } from 'redux/onboarding';
import Logo from 'icons/muru-logotype-primary.svg';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import OnboardingContext from 'components/onboarding/OnboardingContext';
import { showIntercomBubble, hideIntercomBubble } from 'lib/analytics';
import AgencySelect from './AgencySelect';
import ZipEntry from './ZipEntry';
import Waitlist from './Waitlist';
import WaitlistThanks from './Waitlist/Thanks';
import Signup from './Signup';
import OrganizationRole from './OrganizationRole';
import JoinIndividual from './Individual';
import Authenticate from './Authenticate';
import Organization from './Organization';
import GetStarted from './GetStarted';
import PlanType from './PlanType';
import NotEligible from './NotEligible';
import SignupAsOrganization from './SignupAsOrganization';
import OrgGetStarted from './OrganizationGetStarted';
import { brandColor } from 'constants/colors.ts';

const StyledLogo = styled(Logo)`
  width: 160px;
  height: auto;

  g {
    fill: #ffffff;
  }
` as FC;

const queries = {
  phone: '(max-width: 799px)',
  everythingElse: '(min-width: 800px)'
};

const Onboarding = () => {
  const { path } = useRouteMatch();
  const { height } = useWindowSize();
  const currentOrg = useSelector(organizationSelector);

  useEffect(() => {
    showIntercomBubble();
    return () => {
      hideIntercomBubble();
    };
  }, []);

  return (
    <Pane display="flex" height={height}>
      <Media queries={queries}>
        {(matches: any) => (
          <>
            {!matches.phone && (
              <Pane
                display="flex"
                backgroundColor={brandColor}
                flex="1"
                alignItems="center"
                justifyContent="center"
                maxWidth="420px"
                flexDirection="column"
              >
                <StyledLogo />

                <Pane opacity={0.9} width={320} marginTop={16}>
                  {/*@ts-expect-error Invalid size */}
                  <Paragraph size={600} textAlign="center" color="white">
                    Your patient care assistant.
                  </Paragraph>
                  <UnorderedList marginTop={24} icon="tick" iconColor="success">
                    <ListItem color="white">
                      Notifications of changes to your protocols, hospitals,
                      etc.
                    </ListItem>
                    <ListItem color="white">
                      Your protocols, medications and equipment
                    </ListItem>
                    <ListItem color="white">Does all your med math</ListItem>
                    <ListItem color="white">Fully functional offline</ListItem>
                  </UnorderedList>
                </Pane>
              </Pane>
            )}
          </>
        )}
      </Media>
      <Pane
        display="flex"
        flex="1"
        alignItems="center"
        justifyContent="center"
        padding={32}
      >
        <Pane maxWidth="400px" margin="auto" flex="1">
          <OnboardingContext />
          <Switch>
            <Route exact path={`${path}/`} component={ZipEntry} />
            <Route path={`${path}/select`} component={AgencySelect} />
            <Route
              path={`${path}/join/:organizationId`}
              component={Organization}
            />

            <Route
              exact
              path={`${path}/waitlist/thanks`}
              component={WaitlistThanks}
            />

            <Route path={`${path}/waitlist`} component={Waitlist} />

            <Route
              path={`${path}/organization-not-eligible`}
              component={NotEligible}
            />

            <AuthenticatedRoute
              path={`${path}/plans/organization`}
              component={SignupAsOrganization}
            />

            <AuthenticatedRoute
              path={`${path}/individual/get-started`}
              component={GetStarted}
            />

            <AuthenticatedRoute
              path={`${path}/organization/get-started`}
              component={OrgGetStarted}
            />

            {/* *WARNING* All routes below this line will only work is currentOrg is True */}
            {!currentOrg && <Redirect to="/onboarding" />}

            <Route path={`${path}/plan-type`} component={PlanType} />

            <Route path={`${path}/signup`} component={Signup} />

            <Route path={`${path}/auth`} component={Authenticate} />

            <AuthenticatedRoute
              path={`${path}/role`}
              component={OrganizationRole}
            />

            <AuthenticatedRoute
              path={`${path}/plans/individual`}
              component={JoinIndividual}
            />
          </Switch>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default Onboarding;
