import * as Yup from 'yup';
import buildEntryCriteriaForm from 'components/shared/EntryCriteria/ProtocolForm';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this protocol')
});

export const Form = ({
  // @ts-expect-error TS(6133): 'protocolSetId' is declared but its value is never... Remove this comment to see the full error message
  protocolSetId,
  canSetPatientType,
  canSetHiddenFromBrowse = true
}: any) => ({
  name: 'source',
  validationSchema: Schema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Cardiac Arrest - VF / Pulseless VT'
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    },
    {
      name: 'isHiddenFromBrowse',
      label: 'Hidden from browse?',
      description:
        'Should this protocol be hidden from the browse page of the app?',
      type: 'switch',
      disabled: !canSetHiddenFromBrowse
    },
    ...[buildEntryCriteriaForm(canSetPatientType)]
  ]
});
