import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Pane, Heading, Paragraph, Link } from 'evergreen-ui';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import SignupForm from 'components/accounts/SignupForm';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import { page } from 'lib/analytics';

const NEXT_PATH = '/onboarding/role';

const Signup = () => {
  const history = useHistory();

  useEffect(() => {
    page('Onboarding Signup');
  }, []);

  return (
    <Pane position="relative">
      <Helmet>
        <title>Signup for Muru</title>
      </Helmet>
      <Pane marginBottom={24} textAlign="center">
        <MuruLogo />

        <Heading marginBottom={4} size={600}>
          Create a Muru account
        </Heading>

        <Paragraph color="muted" marginTop={4}>
          Already have an account?{' '}
          <Link is={RouterLink} to={`/login?from=${NEXT_PATH}`}>
            Login
          </Link>
        </Paragraph>
      </Pane>

      <SignupForm onSignup={() => history.push(NEXT_PATH)} />

      <Paragraph color="muted" textAlign="center" size={300} margin={16}>
        By creating an account you agree to the{' '}
        <Link
          target="_blank"
          href="https://murumed.com/terms-of-service"
          size={300}
        >
          Muru Terms of Service
        </Link>
        .
      </Paragraph>
    </Pane>
  );
};

export default Signup;
