import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const CREATE_AMENDMENT = gql`
  mutation CreateContentAmendment(
    $input: CreateContentAmendmentForOrganizationInput!
  ) {
    result: createContentAmendmentForOrganization(input: $input) {
      contentAmendment {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const DELETE_AMENDMENT = gql`
  mutation DelteContentAmendment(
    $input: DeleteContentAmendmentForOrganizationInput!
  ) {
    result: deleteContentAmendmentForOrganization(input: $input) {
      contentAmendment {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export default (opts = {}) => {
  const [createAmendment, { loading: isLoadingCreate }] = useMutation(
    CREATE_AMENDMENT,
    opts
  );
  const [deleteAmendment, { loading: isLoadingDelete }] = useMutation(
    DELETE_AMENDMENT,
    opts
  );

  return {
    deleteAmendment,
    createAmendment,
    loading: isLoadingCreate || isLoadingDelete
  };
};
