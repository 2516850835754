import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Dialog, toaster, Spinner } from 'evergreen-ui';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SeatFragment } from 'components/shared/fragments';
import MultiSelect from 'components/shared/FormBuilder/Fields/MultiSelect';
import {
  CREDENTIAL_LIST,
  listRequestVariables
} from 'components/organizations/CredentialList';

const ASSIGN_CREDENTIALS = gql`
  mutation AssignCredentials($input: AssignCredentialsForSeatInput!) {
    result: assignCredentialsForSeat(input: $input) {
      seat {
        ...Seat
      }
    }
  }
  ${SeatFragment}
`;

const CredentialSelector = ({
  organization,
  onSelect,
  selectedCredentialIds
}: any) => {
  const { data, loading } = useQuery(CREDENTIAL_LIST, {
    variables: listRequestVariables(organization),
    fetchPolicy: 'network-only'
  });

  if (loading) return <Spinner size={16} />;

  const formattedValues = get(data, 'organization.credentials.edges', []).map(
    // @ts-expect-error TS(7031): Binding element 'credential' implicitly has an 'an... Remove this comment to see the full error message
    ({ node: credential }) => ({
      label: credential.name,
      value: credential.id
    })
  );

  return (
    <MultiSelect
      values={formattedValues}
      value={selectedCredentialIds}
      label="Assigned credentials"
      description="Select credential"
      formikBag={{
        // TODO: we probably shouldn't be imitating the internal API of formik
        setFieldTouched: () => {},
        // @ts-expect-error TS(6133): 'name' is declared but its value is never read.
        setFieldValue: (name: any, value: any) => onSelect(value)
      }}
    />
  );
};

const UpdateCredentialsDialog = ({
  isShown,
  organization,
  member,
  onCloseComplete
}: any) => {
  const [selectedCredentials, setCredentials] = useState([]);

  // We want to reset the initial credentials whenever the member changes
  // this ensures that when we re-open the component the correct values get set
  useEffect(() => {
    const credentialIds = member.credentials
      ? member.credentials.map((credential: any) => credential.id)
      : [];
    setCredentials(credentialIds);
  }, [member]);

  const [changeSeatRole, { loading }] = useMutation(ASSIGN_CREDENTIALS, {
    onCompleted: (response: any) => {
      if (response.result.seat) {
        toaster.success('Users credentials updated');
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Assign credentials"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="Update"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onConfirm={() =>
        changeSeatRole({
          variables: {
            input: { id: member.id, credentials: selectedCredentials }
          }
        })
      }
    >
      {isShown && (
        <CredentialSelector
          onSelect={setCredentials}
          organization={organization}
          selectedCredentialIds={selectedCredentials}
        />
      )}
    </Dialog>
  );
};

export default UpdateCredentialsDialog;
