import React, { useState, useEffect } from 'react';
import { Alert, Dialog, TextInputField } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import { CLONE_ORGANIZATION } from './queries';

const CloneDialog = ({ isShown, onClose, refetchQuery, organization }: any) => {
  const [name, setName] = useState(null);
  const [cloneOrganization, { loading }] = useMutation(CLONE_ORGANIZATION, {
    refetchQueries: [refetchQuery],
    onCompleted: ({ result }: any) => {
      if (result.organization.id) {
        onClose();
      }
    }
  });

  useEffect(() => setName(null), [isShown, setName]);

  return (
    <Dialog
      isShown={isShown}
      title="Clone organization"
      onCloseComplete={onClose}
      confirmLabel="Clone organization"
      hasClose={false}
      onConfirm={() => {
        cloneOrganization({
          variables: {
            input: {
              organizationId: organization.id,
              name
            }
          }
        });
      }}
      isConfirmDisabled={name === null || name === ''}
      isConfirmLoading={loading}
    >
      <Alert
        intent="warning"
        title="Cloning will copy all content to a new organization"
        marginBottom={16}
      >
        Please only do this if you're certain the content you're cloning is
        ready.
      </Alert>

      <TextInputField
        label="Organization name"
        description="Enter a name for this organization"
        name="name"
        placeholder={`${organization ? organization.name : ''} - CLONE`}
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
    </Dialog>
  );
};

export default CloneDialog;
