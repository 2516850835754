import { get } from 'lodash';
import queryString from 'query-string';

export function parseRedirectPath(location: any) {
  const stateLocationPath = get(location, 'state.from.pathname');
  const { from: queryPath } = queryString.parse(get(location, 'search', {}));
  const from = stateLocationPath || queryPath;

  if (from && from.startsWith('/')) {
    return from;
  }

  return '/';
}

export function isInPath(path: any, pathName: any) {
  return pathName.startsWith(path);
}

export function orgPath(org: any) {
  return `/orgs/${org.slug}`;
}

export function protocolSetPath(org: any, protocolSet: any) {
  return [orgPath(org), 'protocol-sets', protocolSet.id].join('/');
}

export function protocolPath(org: any, protocolSet: any, protocol: any) {
  return [protocolSetPath(org, protocolSet), 'protocols', protocol.id].join(
    '/'
  );
}

export function protocolStepPath(
  org: any,
  protocolSet: any,
  protocol: any,
  protocolStep: any
) {
  return [
    protocolPath(org, protocolSet, protocol),
    'steps',
    protocolStep.id
  ].join('/');
}
