import React from 'react';
import MedicationQuestion from './MedicationQuestion';
import EquipmentQuestion from './EquipmentQuestion';

const TypesToComponent = {
  MedicationInventoryProfileQuestion: MedicationQuestion,
  EquipmentInventoryProfileQuestion: EquipmentQuestion
};

// @ts-expect-error TS(7031): Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
const Question = ({ __typename: type, ...props }) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Component = TypesToComponent[type];

  return <Component {...props} />;
};

export default Question;
