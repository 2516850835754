import React from 'react';
import { Link, TextInput, Text } from 'evergreen-ui';
import { includes } from 'lodash';
import * as Yup from 'yup';
import HospitalNetworkDataSource from 'components/shared/HospitalNetworkDataSource';
import HospitalReferencedSources from 'components/shared/HospitalReferencedSources';
import { Types, TypesWithLabels, RolesWithLabels } from '@/constants/hospitals';
import uppy from 'lib/uppy';
import { formatEnumToOptions } from 'lib/formHelpers';
import HospitalCapabilitiesSelector from 'components/shared/HospitalCapabilitiesSelector';
import MedicalAuthorityDataSource from 'components/shared/MedicalAuthorityDataSource';
import AuthorityAndCapabilities from './AuthorityAndCapabilities';

const isMilitary = ({ type }: any) =>
  includes([Types.MILITARY, Types.BOTH], type);

const isCivilian = ({ type }: any) =>
  includes([Types.CIVILIAN, Types.BOTH], type);

const HelipadSchema = Yup.object().shape({
  weightLimit: Yup.string().required(),
  size: Yup.string().required(),
  types: Yup.array()
    .of(Yup.string())
    .nullable()
    .required()
});

const HospitalRestaurantSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .nullable(),
  location: Yup.string().nullable(),
  hours: Yup.array()
    .of(Yup.string())
    .nullable()
});

const EpcrSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email()
    .nullable(),
  faxNumber: Yup.string().nullable()
});

const CoordinatesSchema = Yup.object().shape({
  lng: Yup.number()
    .required()
    .min(-180)
    .max(180),
  lat: Yup.number()
    .required()
    .min(-90)
    .max(90)
});

const AuthoritySchema = Yup.object().shape({
  authorityId: Yup.string()
    .required('You must select an authority')
    .nullable(),
  capabilities: Yup.array()
    .of(Yup.string())
    .required('You must select one or more capabilities for this authority')
    .nullable()
});

const HospitalSchema = Yup.object().shape({
  name: Yup.string().required(),
  organizationId: Yup.string()
    .required('You must select an organization')
    .nullable(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this hospital'),
  authorities: Yup.array()
    .of(Yup.object())
    .required('You must enter in authorities for this hospital'),
  role: Yup.string()
    .when('type', {
      is: type => isMilitary({ type }),
      then: Yup.string().required()
    })
    .nullable(),
  epcrEmailAddress: Yup.string()
    .email()
    .nullable(),
  emailAddresses: Yup.array()
    .of(Yup.string().email())
    .nullable(),
  heliPads: Yup.array()
    .of(HelipadSchema)
    .nullable()
});

const restaurantFormFields = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Au bon pain',
    required: true
  },
  {
    name: 'location',
    label: 'Location',
    placeholder: 'Where the location is in the hospital'
  },
  {
    type: 'listOf',
    label: 'Hours',
    name: 'hours',
    description: 'A list of operating hours for this restaurant',
    placeholder: 'M - T, 9am - 6pm',
    subFieldType: 'text'
  }
];

// @ts-expect-error TS(6133): 'organizationId' is declared but its value is neve... Remove this comment to see the full error message
export const HospitalForm = (organizationId, canChangeOrganization) => ({
  name: 'hospital',
  validationSchema: HospitalSchema,

  fields: [
    {
      type: 'section',
      label: 'Basic information',
      description: 'All of the basic information for the hospital',
      fields: [
        {
          name: 'name',
          label: 'Name',
          description: 'The full-name of the hospital',
          placeholder: 'Northwell University Hospital',
          required: true
        },
        {
          name: 'aliases',
          label: 'Aliases',
          placeholder: 'NSUH, Northwell',
          type: 'tag'
        },
        {
          name: 'organizationId',
          type: 'asyncSelect',
          label: 'Hospital Network',
          description: 'The network that this hospital belongs to',
          placeholder: 'Select a network',
          dataSource: (render: any) => (
            <HospitalNetworkDataSource render={render} />
          ),
          required: true,
          disabled: !canChangeOrganization
        },
        {
          name: 'type',
          label: 'Type',
          type: 'select',
          required: true,
          values: formatEnumToOptions(TypesWithLabels)
        },
        {
          name: 'authorities',
          type: 'listOfSubFields',
          label: 'Authorities & Capabilities',
          titleExtractor: (fields: any) => (
            <AuthorityAndCapabilities {...fields} />
          ),
          validationSchema: AuthoritySchema,
          fields: [
            {
              type: 'asyncSelect',
              name: 'authorityId',
              label: 'Governing authority',
              description:
                'First, select a medical authority this hostpial operates within',
              dataSource: (render: any) => (
                <MedicalAuthorityDataSource render={render} />
              )
            },
            {
              type: HospitalCapabilitiesSelector,
              name: 'capabilities',
              label: 'Capabilities',
              description: 'Select one or more capabilities'
            }
          ]
        },
        {
          name: 'coordinates',
          label: 'Coordinates',
          description: 'The latitude longitude of the hospital entrance',
          type: 'subFields',
          validationSchema: CoordinatesSchema,
          renderPreview: ({ lat, lng }: any) => (
            <TextInput
              width="100%"
              disabled
              value={lat ? `${lat}, ${lng}` : null}
            />
          ),
          fields: [
            {
              type: 'number',
              name: 'lat',
              label: 'Latitude',
              step: 'any'
            },
            {
              type: 'number',
              name: 'lng',
              label: 'Longitude',
              step: 'any'
            }
          ]
        },
        {
          type: 'listOf',
          name: 'notes',
          label: 'General notes',
          description: 'Any generic notes about the hospital (in bullets)',
          subFieldType: 'text'
        },
        {
          type: 'upload',
          name: 'image',
          label: 'Hospital image',
          description: 'Generic image for the hospital',
          renderPreview: ({ name, size, url }: any) => (
            <Link href={url} target="_blank">
              {name} ({size} bytes)
            </Link>
          ),
          uppyInstance: ({ onComplete }: any) =>
            uppy(
              {
                restrictions: {
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['image/*'],
                  maxFileSize: 1024 * 1024 * 200
                }
              },
              // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
              ([result]) => onComplete(result)
            )
        }
      ]
    },
    {
      type: 'section',
      label: 'Contact information',
      collapsed: true,
      description: 'Contact information for the hospital',
      shownWhen: isCivilian,
      fields: [
        {
          type: 'text',
          label: 'Ambulance notifications phone number',
          placeholder: '917-443-7643',
          name: 'phoneNotification'
        },
        {
          type: 'listOf',
          label: 'Non-emergency phone number',
          name: 'phoneNonEmergencies',
          placeholder: '917-443-7643',
          subFieldType: 'text'
        },
        {
          type: 'textarea',
          label: 'Address',
          description: 'The EMS entrance address of the hospital.',
          placeholder: '123 Main Street, New York, NY, 10011',
          name: 'address'
        },
        {
          type: 'subFields',
          name: 'epcrInformation',
          label: 'ePCR Information',
          validationSchema: EpcrSchema,
          renderPreview: ({ emailAddress, faxNumber }: any) => (
            <Text size={300}>{emailAddress || faxNumber || 'N/A'}</Text>
          ),
          fields: [
            {
              name: 'emailAddress',
              label: 'ePCR Email Address'
            },
            {
              name: 'faxNumber',
              label: 'ePCR Fax Number'
            },
            {
              name: 'notes',
              label: 'Any ePCR specific notes',
              type: 'textarea'
            }
          ]
        },
        {
          type: 'listOf',
          label: 'Email address(es)',
          name: 'emailAddresses',
          placeholder: 'hello@hospital.org',
          subFieldType: 'text'
        },
        {
          type: 'listOf',
          label: 'Radio communication(s)',
          name: 'radioCommunications',
          subFieldType: 'text'
        },
        {
          type: 'text',
          label: 'Door code (if any)',
          placeholder: '#599-455',
          name: 'doorCode'
        }
      ]
    },
    {
      type: 'section',
      label: 'Amenities',
      description: 'Amenities provided at the hospital',
      collapsed: true,
      shownWhen: isCivilian,
      fields: [
        {
          type: 'text',
          name: 'emsLounge',
          label: 'EMS Lounge',
          description:
            'Is there an EMS lounge in the hospital? Leave blank for no.'
        },
        {
          type: 'listOf',
          name: 'restockings',
          label: 'Restocking capabilities',
          description: 'Does the hospital include any restocking capabilities?',
          subFieldType: 'text'
        },
        {
          type: 'listOfSubFields',
          name: 'restaurants',
          label: 'Restaurants',
          description: 'Any restaraunts that are present in this hospital',
          reorderable: true,
          // @ts-expect-error TS(6133): 'index' is declared but its value is never read.
          titleExtractor: (fields: any, index: any) => fields.name,
          validationSchema: HospitalRestaurantSchema,
          fields: restaurantFormFields
        },
        {
          type: 'listOfSubFields',
          name: 'cafeterias',
          label: 'Cafeteria(s)',
          reorderable: true,
          description:
            'Any hospital owned cafeterias available in this hospital',
          // @ts-expect-error TS(6133): 'index' is declared but its value is never read.
          titleExtractor: (fields: any, index: any) => fields.name,
          validationSchema: HospitalRestaurantSchema,
          fields: restaurantFormFields
        }
      ]
    },
    {
      type: 'section',
      label: 'Military information',
      description: 'Information specific to military bases and facilities',
      collapsed: true,
      shownWhen: isMilitary,
      fields: [
        {
          name: 'role',
          type: 'select',
          label: 'Role',
          values: formatEnumToOptions(RolesWithLabels)
        },
        {
          name: 'mgrs',
          label: 'MGRS',
          description: 'MGRS coordinates for this base',
          type: 'text'
        },
        {
          type: 'listOf',
          label: 'Emergency Radio(s)',
          name: 'emergencyRadioComms',
          subFieldType: 'text'
        },
        {
          type: 'listOf',
          label: 'Radio communication(s)',
          name: 'nonEmergencyRadioComms',
          subFieldType: 'text'
        },
        {
          type: 'listOfSubFields',
          name: 'helipads',
          label: 'Helipad Information',
          // @ts-expect-error TS(6133): 'fields' is declared but its value is never read.
          titleExtractor: (fields: any, index: any) => `Helipad #${index + 1}`,
          validationSchema: HelipadSchema,
          fields: [
            {
              label: 'Weight Limit',
              name: 'weightLimit',
              type: 'text'
            },
            {
              label: 'Size',
              name: 'size',
              type: 'text'
            },
            {
              name: 'types',
              label: 'Heli constants',
              type: 'tag'
            }
          ]
        },
        {
          type: 'listOfSubFields',
          name: 'runways',
          label: 'Runway Information',
          // @ts-expect-error TS(6133): 'fields' is declared but its value is never read.
          titleExtractor: (fields: any, index: any) => `Runway #${index + 1}`,
          fields: [
            {
              name: 'types',
              label: 'Fixed wing constants',
              type: 'tag'
            }
          ]
        },
        {
          type: 'textarea',
          label: 'Flight hazards and restrictions',
          name: 'flightHazards'
        }
      ]
    },
    {
      type: HospitalReferencedSources,
      name: 'sources',
      label: 'Sources',
      description:
        'A list of sources attached to this hospital. Sources originate from the medical authorities attached to the hospital.'
    }
  ]
});
