import React from 'react';
import { Pane, Heading, Paragraph, Link, Button } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import { SUPPORT_EMAIL } from '../../../constants';

const NoOrgsAvailable = () => {
  return (
    <Pane
      textAlign="center"
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Pane background="white" padding={32} maxWidth={400} elevation={1}>
        <Heading marginTop={8} size={600}>
          Join an agency to get started
        </Heading>
        <Paragraph marginTop={8} color="muted">
          To get access to Muru you're going to need to join an agency that you
          work for. If you can't find your agency, please contact support.
        </Paragraph>

        <Button
          is={RouterLink}
          to="/onboarding"
          display="flex"
          width="100%"
          justifyContent="center"
          height={40}
          marginTop={16}
          appearance="primary"
        >
          Next
        </Button>

        <Link
          is="a"
          marginTop={16}
          display="flex"
          width="100%"
          justifyContent="center"
          href={`mailto:${SUPPORT_EMAIL}`}
        >
          Contact support
        </Link>
      </Pane>
    </Pane>
  );
};

export default NoOrgsAvailable;
