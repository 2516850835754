import React, { useState } from 'react';
import { Button, BackButton, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import SourceVersionList from 'components/organizations/SourceVersionList';
import DeleteDialog from 'components/shared/DeleteDialog';
import SideSheet from 'components/shared/SideSheet';
import {
  SOURCE_VERSIONS_LIST,
  listSourceVersionsVariables
} from 'components/organizations/SourceVersionList/SourceVersionList';
import CreateOrUpdateSourceVersion from 'components/organizations/CreateOrUpdateSourceVersion';
import { useHistory } from 'react-router-dom';

const DELETE_VERSION = gql`
  mutation DeleteSourceVersion($input: DeleteSourceVersionInput!) {
    result: deleteSourceVersion(input: $input) {
      sourceVersion {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ source, organization }: any) => {
  const history = useHistory();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const refetchQuery = {
    query: SOURCE_VERSIONS_LIST,
    variables: listSourceVersionsVariables(source)
  };

  const [deleteSourceVersion, { loading: isDeleteLoading }] = useMutation(
    DELETE_VERSION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.sourceVersion) {
          toaster.success('Source version successfully deleted');
        } else {
          toaster.danger('Could not delete version', {
            description:
              "Check that it's not currently referenced in other entities"
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  const isDifferentOrg = source.organization.id !== organization.id;

  return (
    <>
      <PageHeader
        title={`Manage source versions`}
        renderButtons={
          <>
            <BackButton
              marginLeft="auto"
              marginRight={8}
              onClick={() => history.push(`/orgs/${organization.slug}/sources`)}
            >
              Back to Sources
            </BackButton>
            <Button
              onClick={() => setShowForm(true)}
              iconBefore="add"
              marginLeft="auto"
              disabled={isDifferentOrg}
            >
              New Version
            </Button>
          </>
        }
      />

      <SourceVersionList
        isDifferentOrg={isDifferentOrg}
        source={source}
        organization={organization}
        onEditClick={(version: any) => setShowForm(version)}
        onDeleteClick={(version: any) => setShowDeleteDialog(version)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateSourceVersion
          sourceId={source.id}
          sourceVersion={showForm || {}}
          onComplete={() => {
            toaster.success('Version saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteSourceVersion({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
