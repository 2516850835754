import React, { useState } from 'react';
import queryString from 'query-string';
import PageHeader from 'components/shared/PageHeader';
import { Button, toaster } from 'evergreen-ui';
import SideSheet from 'components/shared/SideSheet';
import OrgsList, { LIST_ORGS } from 'components/admin/OrganizationsList';
import CreateOrganization from 'components/admin/CreateOrUpdateOrganization';
import {
  withDefaultPageInfo,
  parseFilters,
  onPageInfoUpdate,
  onFilterUpdate
} from 'lib/pagination';

export default ({ history }: any) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  return (
    <>
      <PageHeader
        title="Manage organizations"
        renderButtons={
          <Button
            onClick={() => setShowCreateForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New organization
          </Button>
        }
      />

      <OrgsList
        page={pageInfo}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
      />

      <SideSheet
        isShown={showCreateForm}
        onCloseComplete={() => setShowCreateForm(false)}
      >
        <CreateOrganization
          onComplete={() => {
            toaster.success('Organization created successfully');
            setShowCreateForm(false);
          }}
          refetchQuery={{
            query: LIST_ORGS,
            variables: { ...pageInfo, filters }
          }}
        />
      </SideSheet>
    </>
  );
};
