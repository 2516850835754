import React, { useEffect } from 'react';
import { get, pick } from 'lodash';
import { Pane, Heading, Combobox } from 'evergreen-ui';
import { useLazyQuery } from '@apollo/react-hooks';
import { SOURCES_FOR_ORGANIZATION } from './queries';
import { ReferencedSourceFragment } from '../fragments';

const emptyCell = {
  label: 'N/A',
  value: null
};

function gatherSources(sources: any) {
  // @ts-expect-error TS(7031): Binding element 'source' implicitly has an 'any' t... Remove this comment to see the full error message
  return sources.reduce((acc: any, { node: source }) => {
    return source.versions.reduce((acc: any, version: any) => {
      const item = {
        value: version.id,
        label: `${source.name} (${version.version})`
      };

      return [...acc, item];
    }, acc);
  }, []);
}

// This function accepts a list of sources, with their versions,
// and writes all versions to the store as `ReferencedSource`s.
// we do this so we can read the values back out in a consistent
// way from how we receive them from fetchiing
function writeSourcesToApolloCache(client: any, sources: any) {
  // @ts-expect-error TS(7031): Binding element 'source' implicitly has an 'any' t... Remove this comment to see the full error message
  sources.forEach(({ node: source }) => {
    source.versions.forEach((version: any) => {
      client.writeFragment({
        id: `ReferencedSource:${version.id}`,
        fragment: ReferencedSourceFragment,
        data: {
          id: version.id,
          name: source.name,
          version: version.version,
          url: version.url,
          ...(version.file
            ? { file: pick(version.file, ['url', '__typename']) }
            : { file: null }),
          __typename: 'ReferencedSource'
        }
      });
    });
  });
}

const SelectSource = ({ organizationId, source, onSelect }: any) => {
  const [sourcesForOrganization, { data, loading, client }] = useLazyQuery(
    SOURCES_FOR_ORGANIZATION
  );

  useEffect(() => {
    if (organizationId) {
      sourcesForOrganization({ variables: { organizationId } });
    }
  }, [organizationId, sourcesForOrganization]);

  const sources = get(data, 'organization.sources.edges', []);

  // When the data received changes, we want to write it back
  // into the apollo cache
  useEffect(() => {
    if (sources.length) {
      writeSourcesToApolloCache(client, sources);
    }
  }, [sources, client]);

  const items = gatherSources(sources);

  const defaultSelectedItem =
    source && items.find((item: any) => item.value === source);

  return (
    <Pane>
      <Pane marginBottom={8}>
        <Heading size={400}>Select a source</Heading>
      </Pane>

      <Combobox
        isLoading={loading}
        defaultSelectedItem={defaultSelectedItem}
        items={[emptyCell].concat(items)}
        itemToString={(item: any) => (item ? item.label : '')}
        onChange={onSelect}
        width="100%"
      />
    </Pane>
  );
};

export default SelectSource;
