import React, { useState } from 'react';
import { Dialog, Paragraph, toaster, Checkbox } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_PROTOCOL_SET = gql`
  mutation PublishProtocolSet($input: PublishProtocolSetForOrganizationInput!) {
    result: publishProtocolSetForOrganization(input: $input) {
      protocolSet {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
      }
    }
  }
`;

const PublishProtocolSetDialog = ({
  isShown,
  protocolSet,
  onCloseComplete
}: any) => {
  const [shouldPublishChildren, setPublishChildren] = useState(false);
  const [publishProtocolSet, { loading }] = useMutation(PUBLISH_PROTOCOL_SET, {
    variables: {
      input: { id: protocolSet.id, publishProtocols: shouldPublishChildren }
    },
    onCompleted: (response: any) => {
      if (response.result.protocolSet) {
        toaster.success(`${protocolSet.name} successfully published!`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishProtocolSet()}
    >
      <Paragraph marginBottom={16}>
        Publishing this protocol set will make it available to{' '}
        <strong>all users of the app within this organization</strong>. Please
        only do this if you know what you're doing.
      </Paragraph>

      <Paragraph>
        Optionally, you can also publish all protocols within this protocol set:
      </Paragraph>

      <Checkbox
        label="Publish all unpublished protocols"
        checked={shouldPublishChildren}
        onChange={(e: any) => setPublishChildren(e.target.checked)}
      />
    </Dialog>
  );
};

export default PublishProtocolSetDialog;
