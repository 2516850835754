import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { MedicationForm } from './form';
import { CREATE_MEDICATION, UPDATE_MEDICATION } from './queries';

const initialValues = {
  brandName: '',
  genericName: '',
  aliases: [],
  sources: [],
  concentration: null,
  isDilutant: false
};

const toId = (item: any) => item.id;

const CreateOrUpdateMedication = ({
  medication,
  organizationId,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const [createOrUpdateMedication, { loading }] = useMutation(
    medication ? UPDATE_MEDICATION : CREATE_MEDICATION,
    {
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  let formData = sanitizeData(medication, ['lastPublishedAt']) || initialValues;

  // Normalize sources to be a list of id values
  // @ts-expect-error TS(2339): Property 'sources' does not exist on type 'object'... Remove this comment to see the full error message
  formData.sources = formData.sources.map(toId);

  // Normalize reversal agents to be a list of id values
  // @ts-expect-error TS(2339): Property 'reversalAgents' does not exist on type '... Remove this comment to see the full error message
  if (formData.reversalAgents) {
    // @ts-expect-error TS(2339): Property 'reversalAgents' does not exist on type '... Remove this comment to see the full error message
    formData.reversalAgents = formData.reversalAgents.map(toId);
  }

  return (
    <FormBuilder
      key={medication?.id ?? -1}
      initialValues={formData}
      form={MedicationForm(organizationId)}
      errors={errors}
      isLoading={loading}
      onSubmit={(input: any) => {
        setErrors({});
        createOrUpdateMedication({
          variables: { input: { ...input, organizationId } }
        });
      }}
    />
  );
};

export default CreateOrUpdateMedication;
