import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Icon,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge,
  Alert
} from 'evergreen-ui';
import pluralize from 'pluralize';
import RelativeDate from 'components/shared/RelativeDate';
import EntryCriteria from 'components/shared/EntryCriteria';
import EmptyContent from 'components/shared/EmptyContent';
import SearchListControl from 'components/shared/SearchListControl';
import PaginationControls from 'components/shared/PaginationControls';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import Menu from './Menu';
import {
  amendmentByType,
  Types as AmendmentTypes
} from 'lib/contentAmendments';
import { hasPermission } from 'lib/organizations';
import PublishDialog from './PublishProtocolDialog';
import ExcludeDialog from './ExcludeDialog';
import { PROTOCOL_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const ProtocolList = ({
  filters,
  variables,
  onFilterUpdate,
  onPageInfoUpdate,
  protocolSet,
  organization,
  isViewOnly,
  onEditClick,
  onDeleteClick,
  onManageContentClick,
  onReferencingProtocolsClick,
  onSubProtocolsClick
}: any) => {
  const [showExcludeDialog, setShowExcludeDialog] = useState(false);
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const { loading, data, error } = useQuery(PROTOCOL_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const refetchQuery = {
    query: PROTOCOL_LIST,
    variables
  };

  const orderBy = filters.orderBy || defaultOrderBy;
  const protocols = get(data, 'organization.protocolSet.protocols.edges', []);

  const isParentExcluded = !!amendmentByType(
    protocolSet,
    AmendmentTypes.EXCLUDE
  );

  const isAllowedExclusions = hasPermission(
    organization,
    'content_amendments:manage'
  );

  return (
    <Pane>
      {isParentExcluded && (
        <Alert
          title="This protocol set is excluded and none of these protocols will be visible to child organizations"
          marginBottom={24}
        />
      )}
      <Pane background="white">
        <SearchListControl
          placeholder="Search by name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell width={250} flex="none">
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Entry Criteria</Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {protocols.map(({ node: protocol }: any) => {
                const isSubprotocol = !!protocol.parent;
                const subProtocolClick = () => onSubProtocolsClick(protocol);

                const exclusionAmendment = amendmentByType(
                  protocol,
                  AmendmentTypes.EXCLUDE
                );

                // We only want to be able to manage exclusions for this protocol
                // if we have the permission to manage exclusions in this org, the parent
                // protocol set is not already excluded, and we either don't already
                // have an exclusion or that exclusion belongs to this org
                const canManageExclusion =
                  isAllowedExclusions &&
                  isViewOnly &&
                  !isParentExcluded &&
                  (!exclusionAmendment ||
                    exclusionAmendment.organization.id === organization.id);

                return (
                  <Table.Row
                    key={protocol.id}
                    onDoubleClick={() => onManageContentClick(protocol)}
                    isSelectable
                    height="auto"
                    minHeight={48}
                    opacity={exclusionAmendment ? 0.5 : 1}
                  >
                    <Table.Cell width={250} flex="none">
                      <Pane>
                        <Text size={300} fontWeight={500}>
                          {protocol.name}
                        </Text>

                        {protocol.subprotocols &&
                          protocol.subprotocols.length > 0 && (
                            <Text size={300} color="muted" marginLeft={4}>
                              (+
                              {pluralize(
                                'subprotocol',
                                protocol.subprotocols.length,
                                true
                              )}
                              )
                            </Text>
                          )}

                        {protocol.isHiddenFromBrowse && (
                          <Icon
                            icon="eye-off"
                            color="muted"
                            size={12}
                            marginLeft={6}
                          />
                        )}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell flexWrap="wrap">
                      {protocol.entryCriteria && (
                        <EntryCriteria {...protocol.entryCriteria} />
                      )}
                    </Table.Cell>
                    <Table.Cell
                      width={100}
                      display="flex"
                      alignItems="center"
                      flex="none"
                    >
                      {protocol.updatedAt === protocol.lastPublishedAt ? (
                        <Badge color="green">Published</Badge>
                      ) : (
                        <Badge color="blue">Draft</Badge>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {protocol.lastPublishedAt ? (
                        <RelativeDate date={protocol.lastPublishedAt} />
                      ) : (
                        <Text size={300}>N/A</Text>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={protocol.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            canBeManaged={!isViewOnly}
                            canBeExcluded={canManageExclusion}
                            isExcluded={!!exclusionAmendment}
                            onEditClick={() => onEditClick(protocol)}
                            onDeleteClick={() => onDeleteClick(protocol)}
                            onPublishClick={() => showPublishDialog(protocol)}
                            onManageContentClick={() =>
                              onManageContentClick(protocol)
                            }
                            onReferencingProtocolsClick={() =>
                              onReferencingProtocolsClick(protocol)
                            }
                            onSubProtocolsClick={
                              !isSubprotocol && subProtocolClick
                            }
                            onExcludeToggle={() => {
                              setShowExcludeDialog({
                                // @ts-expect-error TS(2345): Argument of type '{ protocol: any; exclusionAmendm... Remove this comment to see the full error message
                                protocol,
                                exclusionAmendment
                              });
                            }}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && protocols.length === 0 && <EmptyContent />}

      <PublishDialog
        protocol={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />

      <ExcludeDialog
        // Spread the object as props as it includes the protocol set and maybe the
        // exclusion amendment as well (if that exists)
        // @ts-expect-error TS(2698): Spread constants may only be created from object constants... Remove this comment to see the full error message
        {...(showExcludeDialog !== false ? showExcludeDialog : {})}
        isShown={showExcludeDialog !== false}
        organization={organization}
        refetchQuery={refetchQuery}
        onCloseComplete={() => setShowExcludeDialog(false)}
      />

      <PaginationControls
        pageInfo={get(data, 'organization.protocolSet.protocols.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default ProtocolList;
