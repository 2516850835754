import React from 'react';
import { Pane, Paragraph, UnorderedList, ListItem } from 'evergreen-ui';
import Media from 'react-media';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import Logo from 'icons/muru-logotype-primary.svg';
import { brandColor } from 'constants/colors.ts';

const StyledLogo = styled(Logo)`
  width: 160px;
  height: auto;

  g {
    fill: #ffffff;
  }
`;

const queries = {
  phone: '(max-width: 799px)',
  everythingElse: '(min-width: 800px)'
};

const BrandedContent = ({ children }: any) => {
  const { height } = useWindowSize();

  return (
    <Pane display="flex" height={height}>
      <Media queries={queries}>
        {(matches: any) => (
          <>
            {!matches.phone && (
              <Pane
                display="flex"
                backgroundColor={brandColor}
                flex="1"
                alignItems="center"
                justifyContent="center"
                maxWidth="420px"
                flexDirection="column"
              >
                {/* @ts-expect-error StyledLogo */}
                <StyledLogo />

                <Pane opacity={0.9} width={320} marginTop={16}>
                  {/* @ts-expect-error size */}
                  <Paragraph size={600} textAlign="center" color="white">
                    Your patient care assistant.
                  </Paragraph>
                  <UnorderedList marginTop={24} icon="tick" iconColor="success">
                    <ListItem color="white">
                      Notifications of changes to your protocols, hospitals,
                      etc.
                    </ListItem>
                    <ListItem color="white">
                      Your protocols, medications and equipment
                    </ListItem>
                    <ListItem color="white">Does all your med math</ListItem>
                    <ListItem color="white">Fully functional offline</ListItem>
                  </UnorderedList>
                </Pane>
              </Pane>
            )}
            <Pane
              display="flex"
              flex="1"
              alignItems="center"
              justifyContent="center"
              padding={32}
              position="relative"
              overflowX="hidden"
              overflowY="scroll"
            >
              {children}
            </Pane>
          </>
        )}
      </Media>
    </Pane>
  );
};

export default BrandedContent;
