import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';

const HOSPITAL_NETWORKS = gql`
  query ListHospitalNetworks {
    hospitalNetworks(first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const HospitalNetworkDataSource = ({ render }: any) => {
  const { data, loading } = useQuery(HOSPITAL_NETWORKS, {
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'hospitalNetworks.edges', []).map(
    // @ts-expect-error TS(7031): Binding element 'medicalAuthority' implicitly has ... Remove this comment to see the full error message
    ({ node: medicalAuthority }) => ({
      label: medicalAuthority.name,
      value: medicalAuthority.id
    })
  );

  return render({
    items,
    isLoading: loading
  });
};

export default HospitalNetworkDataSource;
