import { pick } from 'lodash';
import queryString from 'query-string';

export const PAGE_SIZE = 50;

const validPageItems = ['before', 'after', 'first', 'last'];

export function withDefaultPageInfo(pageInfo: any) {
  if (typeof pageInfo === 'string') {
    return pick(JSON.parse(pageInfo), validPageItems);
  } else {
    return { first: PAGE_SIZE, last: undefined };
  }
}

export function parseFilters(filters: any) {
  if (typeof filters === 'string') {
    return JSON.parse(filters);
  }

  return {};
}

export function onFilterUpdate(history: any) {
  return (filters: any) =>
    history.push({
      search: queryString.stringify({
        filters: JSON.stringify(filters)
      })
    });
}

export function onPageInfoUpdate(history: any, filters: any) {
  return (page: any) =>
    history.push({
      search: queryString.stringify({
        page: JSON.stringify(page),
        filters: JSON.stringify(filters || {})
      })
    });
}
