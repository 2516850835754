import gql from 'graphql-tag';
import {
  InputErrorFragment,
  SubscriptionFragment
} from 'components/shared/fragments';

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscriptionForOrganization(
    $input: UpdateSubscriptionForOrganizationInput!
  ) {
    result: updateSubscriptionForOrganization(input: $input) {
      subscription {
        ...Subscription
      }
      errors {
        ...InputError
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscriptionForOrganization(
    $input: CreateSubscriptionForOrganizationInput!
  ) {
    result: createSubscriptionForOrganization(input: $input) {
      subscription {
        ...Subscription
      }
      errors {
        ...InputError
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;

export const EXISTING_SEATS = gql`
  query ExistingOrgSeats($organizationId: ID!) {
    organization(id: $organizationId) {
      seats(first: 10) {
        edges {
          node {
            id
            user {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;
