import React, { useState, useEffect } from 'react';
import { Pane, Heading, Paragraph, Button } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { onCompleted } from 'lib/formHelpers';
import {
  organizationSelector,
  organizationCredentialsSelector
} from 'redux/onboarding';
import { page } from 'lib/analytics';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';
import { JOIN_ORGANIZATION } from 'pages/shared/Onboarding/Individual/queries';
import { CURRENT_USER_QUERY } from 'components/shared/queries';
import { SUBSCRIPTIONS } from '../ManageAccount/queries';

const Review = ({
  // @ts-expect-error TS(6133): 'seats' is declared but its value is never read.
  seats,
  subscription
}: any) => {
  const history = useHistory();
  const [errors, setErrors] = useState(null);

  const organization = useSelector(organizationSelector);
  const credentials = useSelector(organizationCredentialsSelector);

  useEffect(() => {
    page('Add Agency Review Purchase');
  }, []);

  const [joinOrganization, { loading }] = useMutation(JOIN_ORGANIZATION, {
    variables: {
      input: {
        planId: subscription.plan.id,
        organizationId: organization.id,
        credentialId: credentials.credentialId,
        medicalLicense: {
          number: credentials.licenseNumber,
          issuingState: credentials.issuingState
        }
      }
    },
    refetchQueries: [
      // Need to refetch the current user and all of their seats
      { query: CURRENT_USER_QUERY },
      { query: SUBSCRIPTIONS }
    ],
    onCompleted: (response: any) =>
      onCompleted({
        response,
        setErrors,
        onComplete: () => history.push('/complete')
      })
  });

  return (
    <Pane width="100%">
      <Pane textAlign="center" marginBottom={24}>
        <Heading size={600} marginBottom={8}>
          Muru is Free
        </Heading>
      </Pane>
      <Pane>
        <Pane
          background="blueTint"
          border="muted"
          marginTop={12}
          paddingY={8}
          paddingX={12}
          textAlign="center"
        >
          <Paragraph color="muted">
            Muru has a basic and premium version. In support of EMS during
            COVID-19, Muru's premium version has and will remain free for
            2020-2024. Next year, you will be given the option to enter your
            payment information and stay on the premium version or move to the
            free, basic version. This can be paid for by your agency or by each
            individual provider. Until then, please enjoy all Muru has to offer
            free of charge.
          </Paragraph>
          <Paragraph color="muted" marginTop={12}>
            Thank you for your service,
          </Paragraph>
          <Paragraph color="muted" marginTop={12}>
            The medics and software developers of Muru
          </Paragraph>
        </Pane>
        {errors && (
          <Pane marginY={16}>
            <Heading color="#BF0E08" size={400}>
              There was a problem, please try again
            </Heading>
            <ValidationErrorMessage error={errors} />
          </Pane>
        )}

        <Button
          height={40}
          appearance="primary"
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          isLoading={loading}
          onClick={() => {
            setErrors(null);
            joinOrganization();
          }}
        >
          Add Organization
        </Button>
      </Pane>
    </Pane>
  );
};

export default Review;
