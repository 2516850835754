import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this molecule')
});

export const Form = {
  name: 'organization',
  validationSchema: Schema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Epinepherine',
      required: true
    },
    {
      name: 'aliases',
      label: 'Aliases',
      placeholder: 'epi adrenalin',
      type: 'tag'
    }
  ]
};
