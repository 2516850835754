import React, { useState, useEffect } from 'react';
import { Dialog, Pane, Heading, Paragraph, Combobox } from 'evergreen-ui';
import SelectSource from '../ReferencedSources/SelectSourceForOrganization';
import authorityIdsToOptions from './authorityOptions';

const SourceSelectionForm = ({
  authorityIds,
  authorityId,
  source,
  setSelectedAuthority,
  setSelectedSource
}: any) => {
  const options = authorityIdsToOptions(authorityIds);

  const selectedAuthority =
    authorityId && options.find((item: any) => item.value === authorityId);

  return (
    <Pane>
      <Pane marginBottom={16}>
        <Pane marginBottom={8}>
          <Heading size={400}>Select a medical authority</Heading>
          <Paragraph>
            These are set by the authorities selected for this hospital
          </Paragraph>
        </Pane>

        <Combobox
          openOnFocus
          selectedItem={selectedAuthority}
          items={options}
          itemToString={(item: any) => (item ? item.label : '')}
          onChange={(item: any) =>
            item ? setSelectedAuthority(item.value) : setSelectedAuthority(null)
          }
          width="100%"
        />
      </Pane>

      <SelectSource
        organizationId={authorityId}
        source={source}
        onSelect={(item: any) =>
          item ? setSelectedSource(item.value) : setSelectedSource(null)
        }
      />
    </Pane>
  );
};

const SourceSelectionDialog = ({
  authorityIds,
  selectSource,
  isShown,
  onClose,
  // @ts-expect-error TS(6133): 'source' is declared but its value is never read.
  source
}: any) => {
  const [selectedAuthority, setSelectedAuthority] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);

  useEffect(() => {
    setSelectedAuthority(null);
    setSelectedSource(null);
  }, [isShown, setSelectedAuthority, setSelectedSource]);

  return (
    <Dialog
      isConfirmDisabled={!selectedSource}
      title="Select source to reference"
      isShown={isShown}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasClose={false}
      onCloseComplete={onClose}
      onConfirm={() => selectSource(selectedSource)}
    >
      {isShown && (
        <SourceSelectionForm
          authorityIds={authorityIds}
          authorityId={selectedAuthority}
          source={selectedSource}
          setSelectedSource={setSelectedSource}
          setSelectedAuthority={setSelectedAuthority}
        />
      )}
    </Dialog>
  );
};

export default SourceSelectionDialog;
