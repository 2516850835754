import React from 'react';
import Helmet from 'react-helmet';
import { Pane, toaster } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/shared/PageHeader';
import UpdateProfile from 'components/organizations/UpdateProfile';

export default ({ organization }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('orgs.select.title')}</title>
      </Helmet>

      <PageHeader title="Update organization profile" />

      <Pane width="500px">
        <UpdateProfile
          organization={organization}
          onComplete={() => {
            toaster.success('Profile successfully updated');
          }}
        />
      </Pane>
    </>
  );
};
