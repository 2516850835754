import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Switch } from 'react-router-dom';
import gql from 'graphql-tag';
import LoadingScreen from 'components/shared/LoadingScreen';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import { MedicationFragment } from 'components/shared/fragments';
import EditPage from './EditPage';
import ListDosesPage from './ListDosesPage';

const MEDICATION = gql`
  query Medication($id: ID!) {
    medication: node(id: $id, type: MEDICATION) {
      ... on Medication {
        ...Medication
      }
    }
  }
  ${MedicationFragment}
`;

export default ({ match, organization }: any) => {
  const { url, params } = match;
  const { id: medicationId } = params;
  const { data: medicationData, loading } = useQuery(MEDICATION, {
    variables: { id: medicationId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Switch>
        <AuthenticatedRoute
          exact
          path={`${url}/`}
          medication={medicationData.medication}
          organization={organization}
          component={EditPage}
        />
        <AuthenticatedRoute
          exact
          path={`${url}/doses`}
          medication={medicationData.medication}
          organization={organization}
          component={ListDosesPage}
        />
      </Switch>
    </>
  );
};
