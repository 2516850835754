import React from 'react';
import { Link } from 'evergreen-ui';
import * as Yup from 'yup';
import uppy from 'lib/uppy';

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required(),
  website: Yup.string()
    .url()
    .nullable(),
  emailAddress: Yup.string()
    .email()
    .nullable()
});

export const ProfileForm = {
  name: 'equipment',
  validationSchema: ProfileSchema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      description: 'The full-name of the organization',
      placeholder: 'FDNY',
      disabled: true,
      required: true
    },
    {
      type: 'upload',
      name: 'logo',
      label: 'Organization logo',
      description: 'Square image only, please',
      renderPreview: ({ name, size, url }: any) => (
        <Link href={url} target="_blank">
          {name} ({size} bytes)
        </Link>
      ),
      uppyInstance: ({ onComplete }: any) =>
        uppy(
          {
            restrictions: {
              maxNumberOfFiles: 1,
              allowedFileTypes: ['image/*'],
              maxFileSize: 1024 * 1024 * 200
            }
          },
          // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
          ([result]) => onComplete(result)
        )
    },
    {
      name: 'address',
      label: 'Address',
      description: 'The main address of the organization',
      placeholder: '123 Main St, New York, NY, 10001'
    },
    {
      name: 'website',
      label: 'Website',
      placeholder: 'https://company.com'
    },
    {
      name: 'emailAddress',
      label: 'Contact email',
      placeholder: 'support@company.com'
    },
    {
      name: 'phoneNumber',
      label: 'Contact phone number',
      placeholder: '(123) 456-7890'
    },
    {
      name: 'supportPhoneNumber',
      label: 'Emergency support phone number',
      placeholder: '(123) 456-7890'
    },
    {
      name: 'faxNumber',
      label: 'Fax number',
      placeholder: '(123) 456-7890'
    },
    {
      type: 'listOf',
      name: 'notes',
      label: 'General notes',
      description: 'Any generic notes about the organization (in bullets)',
      subFieldType: 'text'
    }
  ]
};
