import React, { useState } from 'react';
import {
  Pane,
  Heading,
  Button,
  Paragraph,
  Text,
  FormFieldValidationMessage,
  Dialog
} from 'evergreen-ui';
import { get } from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';
import ProtocolSelection from './ProtocolSelection';
import { ReferredProtocolFragment } from '../fragments';

const ProtocolSelector = ({
  ignoreProtocolId,
  name,
  label,
  description,
  validationMessage,
  value,
  formikBag,
  organizationId
}: any) => {
  const client = useApolloClient();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedProtocolId, setSelectedProtocolId] = useState(value);

  const selectedProtocol =
    value &&
    client.readFragment({
      id: `Protocol:${value}`,
      fragment: ReferredProtocolFragment
    });

  return (
    <Pane marginBottom={24}>
      <Pane display="flex" borderBottom="default" paddingBottom={8}>
        <Pane>
          <Heading size={400}>{label}</Heading>
          {description && <Paragraph color="muted">{description}</Paragraph>}
        </Pane>

        <Button
          type="button"
          appearance="minimal"
          marginLeft="auto"
          height={20}
          onClick={() => setShowDialog(true)}
        >
          Select
        </Button>
      </Pane>

      {selectedProtocol && (
        <Pane marginTop={8}>
          <Text size={300}>{selectedProtocol.name}</Text>
        </Pane>
      )}

      {typeof validationMessage === 'string' && (
        <FormFieldValidationMessage marginTop={4}>
          {validationMessage}
        </FormFieldValidationMessage>
      )}

      <Dialog
        title="Select a protocol"
        isShown={showDialog}
        onCloseComplete={() => setShowDialog(false)}
        confirmLabel="Select protocol"
        onConfirm={() => {
          formikBag.setFieldValue(name, selectedProtocolId);
          setShowDialog(false);
        }}
        width={600}
        hasClose={false}
        isConfirmDisabled={!selectedProtocolId}
      >
        {showDialog ? (
          <ProtocolSelection
            organizationId={organizationId}
            protocolId={selectedProtocolId}
            ignoreProtocolId={ignoreProtocolId}
            protocolSetId={get(selectedProtocol, 'protocolSet.id')}
            onSelect={(protocolId: any) => setSelectedProtocolId(protocolId)}
          />
        ) : (
          <br />
        )}
      </Dialog>
    </Pane>
  );
};

export default ProtocolSelector;
