import React from 'react';
import { Pane, Heading } from 'evergreen-ui';

const SectionCard = ({ title, children }: any) => (
  <Pane width="100%" marginTop={16} elevation={1} background="white">
    {title && (
      <Pane borderBottom padding={16}>
        <Heading size={500}>{title}</Heading>
      </Pane>
    )}

    {children}
  </Pane>
);

export default SectionCard;
