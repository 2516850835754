import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pane, Heading, Paragraph, Link, Strong } from 'evergreen-ui';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import SelectAgency from 'components/onboarding/SelectAgency';
import LoadingScreen from 'components/shared/LoadingScreen';
import onboardingSlice, {
  zipCodeSelector,
  agenciesSelector
} from 'redux/onboarding';
import { currentUserSelector } from 'redux/global';
import { page, launchIntercomWithMessage } from 'lib/analytics';
import { JOINABLE_AGENCY } from './queries';

const { setOrganization } = onboardingSlice.actions;

const AgencySelect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const agencies = useSelector(agenciesSelector);
  const zipCode = useSelector(zipCodeSelector);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    page('Add Agency Select Agency');
  }, []);

  const [fetchAgency, { data, loading }] = useLazyQuery(JOINABLE_AGENCY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ joinableAgency }: any) => {
      dispatch(setOrganization(joinableAgency));
      setTimeout(() => history.replace('/role'), 0);
    },
    onError: (error: any) => {
      const errorCode = get(error, 'graphQLErrors[0].code');

      if (errorCode === 'NOT_FOUND') {
        history.replace('/');
      } else if (errorCode) {
        history.replace(`/not-eligible`);
      }
    }
  });

  if (loading || data) {
    return <LoadingScreen />;
  }

  return (
    <Pane>
      <Pane marginBottom={24} textAlign="center">
        <Heading marginBottom={4} size={600}>
          Select your agency
        </Heading>
        <Paragraph color="muted">
          We found the following agencies that are eligible for Muru within{' '}
          <Strong>
            {zipCode.county}, {zipCode.state}
          </Strong>
          .
        </Paragraph>
      </Pane>

      <SelectAgency
        agencies={agencies}
        zipCode={zipCode}
        existingMembershipIds={currentUser.seats.map(
          (s: any) => s.organization.id
        )}
        onSelect={(agency: any) => {
          fetchAgency({ variables: { id: agency.id } });
        }}
      />

      <Paragraph color="muted" textAlign="center" size={300} margin={16}>
        If you don't see your agency in the above list,{' '}
        {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 0. */}
        <Link href="#" size={300} onClick={() => launchIntercomWithMessage()}>
          contact our support team
        </Link>{' '}
        and we can help.
      </Paragraph>
    </Pane>
  );
};

export default AgencySelect;
