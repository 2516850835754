import React from 'react';
import { defaults } from 'lodash';
import { Pane, Heading, Paragraph, TextInput, Select } from 'evergreen-ui';
import { formatEnumToOptions } from 'lib/formHelpers';
import { CalendarUnits, CalendarUnitsWithLabels } from 'constants/units';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';

const PatientAgeConditionOfUse = ({
  value,
  name,
  onChange,
  validationMessage,
  ...props
}: any) => {
  const currentValue = defaults(props.formikBag.values, {
    unit: CalendarUnits.YEAR,
    minValue: '',
    maxValue: ''
  });

  return (
    <Pane marginBottom={24}>
      <Pane marginBottom={8}>
        <Heading size={400}>Age range</Heading>
        <Paragraph color="muted">
          Specify the age range for this condition. Please only use decimals
          when you absolutely have to e.g for a age of 6 months - 18 years.
        </Paragraph>
      </Pane>

      <TextInput
        width={100}
        name={`minValue`}
        type="number"
        value={currentValue.minValue}
        onChange={onChange}
      />
      <Heading marginLeft={8} marginRight={8} size={300} display="inline-block">
        -
      </Heading>
      <TextInput
        width={100}
        name={`maxValue`}
        type="number"
        value={currentValue.maxValue}
        onChange={onChange}
      />

      <Select name={`unit`} onChange={onChange} value={currentValue.unit}>
        <option />
        {formatEnumToOptions(CalendarUnitsWithLabels).map(
          ({ label, value }) => (
            <option key={label} value={value}>
              {label}
            </option>
          )
        )}
      </Select>

      <ValidationErrorMessage error={validationMessage} />
    </Pane>
  );
};

export default PatientAgeConditionOfUse;
