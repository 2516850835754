import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_ALL = gql`
  mutation PublishAllEquipmentForOrg(
    $input: PublishAllEquipmentForOrganizationInput!
  ) {
    result: publishAllEquipmentForOrganization(input: $input) {
      success
    }
  }
`;

const PublishAllEquipmentDialog = ({
  isShown,
  organization,
  refetchQuery,
  onCloseComplete
}: any) => {
  const [publishEquipment, { loading }] = useMutation(PUBLISH_ALL, {
    variables: { input: { organizationId: organization.id } },
    refetchQueries: [refetchQuery],
    awaitRefetchQueries: true,
    onCompleted: (response: any) => {
      if (response.result.success) {
        toaster.success(`All equipment successfully published`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishEquipment()}
    >
      <Paragraph>
        Publishing all equipment will make them available to{' '}
        <strong>
          all users of the app within this organization and it's descendents
        </strong>
        . Please only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishAllEquipmentDialog;
