import React, { useState } from 'react';
import { map, get } from 'lodash';
import {
  Pane,
  Heading,
  Paragraph,
  TextInput,
  Select,
  SegmentedControl,
  FormFieldValidationMessage
} from 'evergreen-ui';
import { UnitsWithLabels } from 'constants/units';
import { formatEnumToOptions, onChangeAsNull } from 'lib/formHelpers';

const UnitOptions = formatEnumToOptions(UnitsWithLabels);

const UnitSelect = ({ onChange, value, name }: any) => (
  <Select name={name} onChange={onChange} value={value}>
    <option />
    {UnitOptions.map(({ label, value }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ))}
  </Select>
);

const initialState = {
  unit: undefined,
  minValue: undefined,
  maxValue: undefined,
  value: undefined
};

const DoseAmountTypes = {
  FIXED: 'fixed',
  RANGE: 'range'
};

const DoseAmountTypeOptions = [
  { label: 'Fixed', value: DoseAmountTypes.FIXED },
  { label: 'Range', value: DoseAmountTypes.RANGE }
];

const STEP = '0.001';

function AmountValidationMessages({ errors }: any) {
  return (
    <>
      {map(errors, (errorMessage, idx) => (
        <FormFieldValidationMessage key={`error-${idx}`} marginTop={4}>
          {errorMessage}
        </FormFieldValidationMessage>
      ))}
    </>
  );
}

const DoseAmount = ({
  isAbsoluteUnit,
  placeholder,
  label,
  description,
  value,
  validationMessage,
  isInvalid,
  formikBag,
  name,
  onChange,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const currentValue = value || initialState;
  const [doseType, setDoseType] = useState(
    currentValue.minValue && currentValue.maxValue
      ? DoseAmountTypes.RANGE
      : DoseAmountTypes.FIXED
  );

  return (
    <Pane marginBottom={24}>
      <Pane marginBottom={8}>
        <Heading size={400}>{label}</Heading>
        {description && <Paragraph color="muted">{description}</Paragraph>}
      </Pane>

      <Pane marginBottom={8}>
        <SegmentedControl
          name="switch"
          width="100%"
          onChange={(value: any) => {
            setDoseType(value);
            // Reset the values
            currentValue.value = null;
            currentValue.minValue = null;
            currentValue.maxValue = null;
          }}
          value={doseType}
          options={DoseAmountTypeOptions}
          marginBottom={8}
          height={24}
        />
        {doseType === DoseAmountTypes.RANGE ? (
          <>
            <TextInput
              width={100}
              name={`${name}.minValue`}
              type="number"
              step={STEP}
              value={currentValue.minValue}
              onChange={onChange}
            />
            <Heading
              marginLeft={8}
              marginRight={8}
              size={300}
              display="inline-block"
            >
              -
            </Heading>
            <TextInput
              width={100}
              name={`${name}.maxValue`}
              type="number"
              step={STEP}
              value={currentValue.maxValue}
              onChange={onChange}
            />
          </>
        ) : (
          <TextInput
            width={222}
            name={`${name}.value`}
            type="number"
            step={STEP}
            value={currentValue.value}
            onChange={onChange}
          />
        )}
        <UnitSelect
          name={`${name}.unit`}
          value={currentValue.unit}
          onChange={onChange}
        />
        {!isAbsoluteUnit && (
          <>
            <Heading
              marginLeft={16}
              marginRight={16}
              size={100}
              display="inline-block"
            >
              PER
            </Heading>
            <TextInput
              width={100}
              type="number"
              step={STEP}
              value={get(currentValue, 'perAmount.value', '')}
              name={`${name}.perAmount.value`}
              onChange={onChangeAsNull(`${name}.perAmount`, onChange)}
            />
            <UnitSelect
              name={`${name}.perAmount.unit`}
              value={get(currentValue, 'perAmount.unit', '')}
              onChange={onChangeAsNull(`${name}.perAmount`, onChange)}
            />
          </>
        )}
      </Pane>

      {validationMessage && (
        <AmountValidationMessages
          errors={
            typeof validationMessage === 'string'
              ? [validationMessage]
              : validationMessage
          }
        />
      )}
    </Pane>
  );
};

export default DoseAmount;
