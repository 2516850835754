import * as Yup from 'yup';
import ReferencedSources from 'components/shared/ReferencedSources';
import buildEntryCriteriaForm from 'components/shared/EntryCriteria/ProtocolForm';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  sources: Yup.array()
    .of(Yup.string())
    .required('You must enter in sources for this protocol set'),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this protocol set')
});

export const Form = (organizationId: any) => {
  const entryCriteria = buildEntryCriteriaForm(true);

  return {
    name: 'source',
    validationSchema: Schema,
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'ALS Protocols'
      },
      {
        name: 'aliases',
        label: 'Aliases',
        type: 'tag'
      },
      ...[entryCriteria],
      {
        type: ReferencedSources,
        name: 'sources',
        label: 'Sources',
        description: 'A list of sources attached to this protocol set',
        organizationId: organizationId
      }
    ]
  };
};
