import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Pane, Button, TextInputField, majorScale } from 'evergreen-ui';
import ValidationErrors from 'components/shared/ValidationErrors';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  name: Yup.string().required(),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const initialValues = {
  email: '',
  name: '',
  password: '',
  passwordConfirmation: ''
};

const FormikForm = ({ email, onSubmit, loading, validationErrors }: any) => {
  const { t } = useTranslation();
  const values = { ...initialValues, email };

  return (
    <Formik
      key="signup-form"
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }: any) => (
        <Pane
          is={Form}
          marginTop={16}
          textAlign="left"
          data-testid="signup-form"
          onSubmit={handleSubmit}
        >
          {Object.keys(validationErrors).length > 0 && <ValidationErrors />}

          <TextInputField
            required
            isInvalid={!!errors.email}
            placeholder="john@socom.mil"
            type="email"
            name="email"
            label={t('common.email')}
            validationMessage={
              (errors.email && touched.email && errors.email) ||
              validationErrors.email
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            marginBottom={majorScale(2)}
            size={400}
          />

          <TextInputField
            required
            isInvalid={!!errors.email}
            placeholder="Johnny Medic"
            type="text"
            name="name"
            label={t('common.name')}
            validationMessage={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            marginBottom={majorScale(2)}
            size={400}
          />

          <TextInputField
            required
            isInvalid={!!errors.password}
            type="password"
            name="password"
            label={t('common.password')}
            validationMessage={
              errors.password && touched.password && errors.password
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            marginBottom={majorScale(2)}
            size={400}
          />

          <TextInputField
            required
            isInvalid={!!errors.passwordConfirmation}
            type="password"
            name="passwordConfirmation"
            label={t('common.passwordConfirmation')}
            validationMessage={
              errors.passwordConfirmation &&
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            marginBottom={majorScale(2)}
            size={400}
          />

          <Button
            isLoading={loading}
            appearance="primary"
            type="submit"
            display="flex"
            width="100%"
            justifyContent="center"
            height={40}
          >
            {t('signup.signup_btn')}
          </Button>
        </Pane>
      )}
    />
  );
};

export default FormikForm;
