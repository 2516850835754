import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  CREDENTIAL_LIST,
  listRequestVariables
} from 'components/organizations/CredentialList';
import MultiSelect from 'components/shared/FormBuilder/Fields/MultiSelect';

const CredentialSelector = ({ organizationId, ...props }: any) => {
  const { data, loading, error } = useQuery(CREDENTIAL_LIST, {
    variables: listRequestVariables({ id: organizationId }),
    fetchPolicy: 'network-only'
  });

  const options =
    !loading && !error
      ? // @ts-expect-error TS(7031): Binding element 'credential' implicitly has an 'an... Remove this comment to see the full error message
        data.organization.credentials.edges.map(({ node: credential }) => ({
          label: credential.name,
          value: credential.id
        }))
      : [];

  return <MultiSelect {...props} values={options} />;
};

export default CredentialSelector;
