import React from 'react';
import { Link } from 'evergreen-ui';
import * as Yup from 'yup';
import uppy from 'lib/uppy';
import MedicationAmount, {
  AmountSchema,
  AmountPerAmountSchema
} from 'components/shared/MedicationAmount';
import { formatEnumToOptions } from 'lib/formHelpers';
import { StorageVolumeTypesWithLabels } from '@/constants/medications';
import { UnitsWithLabels } from '@/constants/units';

const amountToString = ({
  value,
  unit
}: // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
any) => `${value}${UnitsWithLabels[unit]}`;

const storageMethodToString = (fields: any) => {
  let units = [];

  if (fields.volume) {
    units.push(amountToString(fields.volume));
  }

  // Storage method type
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  units.push(StorageVolumeTypesWithLabels[fields.type] || fields.type);

  if (fields.displayName) {
    units.push(` (${fields.displayName})`);
  }

  // Add the concentration / per amount value (e.g. 1mg / 1mL)
  if (fields.concentration && fields.concentration.perAmount) {
    const concentration = amountToString(fields.concentration);
    const perAmount = amountToString(fields.concentration.perAmount);

    units.push(`@ ${concentration}/${perAmount}`);
  }

  return units.join(' ');
};

const MedicationSchema = Yup.object().shape({
  brandName: Yup.string().required(),
  genericName: Yup.string().required()
});

const MedicationStorageMethodSchema = Yup.object().shape({
  displayName: Yup.string().nullable(),
  type: Yup.string()
    .required()
    .nullable(),
  volume: AmountSchema.nullable().required(),
  concentration: AmountPerAmountSchema.nullable()
});

export const Form = {
  name: 'organization',
  validationSchema: MedicationSchema,
  fields: [
    {
      name: 'brandName',
      label: 'Brand name',
      description: 'The brand name of this medication',
      required: true
    },
    {
      name: 'genericName',
      label: 'Generic name',
      description: 'The generic name of this medication',
      required: true
    },
    {
      name: 'storageMethods',
      label: 'Storage Methods',
      description: 'Ways in which the medication is stored',
      type: 'listOfSubFields',
      titleExtractor: storageMethodToString,
      validationSchema: MedicationStorageMethodSchema,
      reorderable: true,
      initialState: {
        displayName: null,
        type: null,
        image: null,
        volume: null,
        concentration: null
      },
      fields: [
        {
          name: 'displayName',
          label: 'Display name',
          description:
            'An optional display name to show when outputting this storage method as a dose',
          type: 'text'
        },
        {
          name: 'type',
          label: 'Type',
          description: 'What kind of storage method is this?',
          type: 'select',
          values: formatEnumToOptions(StorageVolumeTypesWithLabels)
        },
        {
          type: 'upload',
          name: 'image',
          label: 'Image',
          description: 'An image that represents the stored medicine',
          renderPreview: ({ name, size, url }: any) => (
            <Link href={url} target="_blank">
              {name} ({size} bytes)
            </Link>
          ),
          uppyInstance: ({ onComplete }: any) =>
            uppy(
              {
                restrictions: {
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['image/*'],
                  maxFileSize: 1024 * 1024 * 200
                }
              },
              // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
              ([result]) => onComplete(result)
            )
        },
        {
          type: MedicationAmount,
          name: 'volume',
          label: 'Volume',
          description: 'The volume of the medication in this method',
          isAbsoluteUnit: true
        },
        {
          type: MedicationAmount,
          name: 'concentration',
          label: 'Concentration',
          description: 'The concentration of the medication in this method',
          shownWhen: ({ __parent: medicationFields, concentration }: any) =>
            // Show when we have an existing concentration or when there's no concentration on med
            concentration ? true : !medicationFields.concentration
        }
      ]
    }
  ]
};
