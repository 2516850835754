import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import StripeCreditCardForm from '../StripeCreditCardForm';
import { UPDATE_PAYMENT_METHOD } from './queries';
import { onCompleted } from 'lib/formHelpers';
import { CURRENT_USER_QUERY } from 'components/shared/queries.ts';

const UpdateUsersCreditCard = ({ onComplete, isLoading, ...props }: any) => {
  const [updatePaymentMethod, { loading }] = useMutation(
    UPDATE_PAYMENT_METHOD,
    {
      refetchQueries: [{ query: CURRENT_USER_QUERY }],
      onCompleted: (response: any) => onCompleted({ response, onComplete })
    }
  );

  return (
    <StripeCreditCardForm
      {...props}
      handleResult={({ paymentMethod }: any) => {
        paymentMethod &&
          updatePaymentMethod({
            variables: { paymentMethod: paymentMethod.id }
          });
      }}
      isLoading={loading || isLoading}
    />
  );
};

export default UpdateUsersCreditCard;
