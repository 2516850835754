import gql from 'graphql-tag';
import {
  PageInfoFragment,
  ProtocolFragment
} from 'components/shared/fragments';

export const PROTOCOL_LIST = gql`
  query ProtocolList(
    $organizationId: ID!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterProtocolSetProtocolsInput
    $protocolSetId: ID!
  ) {
    organization(id: $organizationId) {
      id
      protocolSet: node(id: $protocolSetId, type: PROTOCOL_SET) {
        ... on ProtocolSet {
          id
          protocols(
            first: $first
            after: $after
            before: $before
            last: $last
            filters: $filters
          ) {
            edges {
              node {
                ...Protocol
                subprotocols {
                  id
                  name
                }
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
    }
  }
  ${PageInfoFragment}
  ${ProtocolFragment}
`;
