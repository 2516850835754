import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LoadingScreen from 'components/shared/LoadingScreen';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import { ProtocolSetFragment } from 'components/shared/fragments';
import {
  ListPage as ListProtocols,
  ShowPage as ShowProtocol
} from './Protocols';

const PROTOCOL_SET = gql`
  query ProtocolSetForOrg($nodeId: ID!, $organizationId: ID!) {
    organization(id: $organizationId) {
      id
      protocolSet: node(id: $nodeId, type: PROTOCOL_SET) {
        ... on ProtocolSet {
          ...ProtocolSet
        }
      }
    }
  }
  ${ProtocolSetFragment}
`;

export default ({ match, organization }: any) => {
  const { url, params } = match;
  const { id: sourceId } = params;
  const { data, loading } = useQuery(PROTOCOL_SET, {
    variables: { nodeId: sourceId, organizationId: organization.id },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingScreen />;
  }

  const { protocolSet } = data.organization;
  const isViewOnly = protocolSet.organization.id !== organization.id;

  return (
    <>
      <AuthenticatedRoute
        exact
        path={`${url}/protocols`}
        protocolSet={protocolSet}
        organization={organization}
        component={ListProtocols}
        isViewOnly={isViewOnly}
      />
      <AuthenticatedRoute
        path={`${url}/protocols/:id`}
        protocolSet={protocolSet}
        organization={organization}
        component={ShowProtocol}
        isViewOnly={isViewOnly}
      />
    </>
  );
};
