import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get, head, tail } from 'lodash';
import {
  Avatar,
  Table,
  Text,
  Pane,
  Pill,
  Badge,
  Spinner,
  Popover,
  IconButton,
  Position,
  Tooltip
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import { SeatFragment, PageInfoFragment } from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import Menu from './ManageMemberMenu';
import RemoveMemberDialog from './RemoveMemberDialog';
import RemoveIndividualMemberDialog from './RemoveIndividualMemberDialog';
import UpdateRoleDialog from './UpdateRolesDialog';
import UpdateCredentialsDialog from './UpdateCredentialsDialog';

export const ORGANIZATION_MEMBERS = gql`
  query ListOrganizationMembers(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationSeatsInput!
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      seats(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...Seat
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${SeatFragment}
`;

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const OrgMembersList = ({
  organization,
  variables,
  filters,
  permissions,
  onFilterUpdate,
  onPageInfoUpdate
}: any) => {
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [individualMemberToRemove, setIndividualMemberToRemove] = useState(
    null
  );
  const [memberToUpdate, setMemberToUpdate] = useState(null);
  const [memberToCredential, setMemberToCredential] = useState(null);
  const { loading, data, error, refetch } = useQuery(ORGANIZATION_MEMBERS, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const seats = get(data, 'organization.seats.edges', []);

  return (
    <Pane>
      <SearchListControl
        placeholder="Search by user name or email"
        initialValue={filters.nameOrEmail}
        onSubmit={(nameOrEmail: any) => onFilterUpdate({ nameOrEmail })}
      />
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>Roles</Table.TextHeaderCell>
            {permissions.canManageCredentials && (
              <Table.TextHeaderCell>Credentials</Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell>Added</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {seats.map(({ node: seat }) => {
                const primaryRole = head(seat.roles);
                const primaryCred = head(seat.credentials);
                const isCancelled =
                  seat.cancelledAt ||
                  (seat.subscription && seat.subscription.cancelledAt);

                return (
                  <Table.Row key={seat.id} height="auto" minHeight={48}>
                    <Table.Cell>
                      <Avatar name={seat.user.name} />
                      <Text marginLeft={8} size={300} fontWeight={500}>
                        {seat.user.name}
                      </Text>
                    </Table.Cell>
                    <Table.TextCell>{seat.user.email}</Table.TextCell>
                    <Table.Cell>
                      <Pane>
                        {isCancelled && (
                          <Badge color="orange" marginRight={4}>
                            Cancelled
                          </Badge>
                        )}

                        {primaryRole && (
                          // @ts-expect-error TS(2571): Object is of type 'unknown'.
                          <Badge color="neutral">{primaryRole.name}</Badge>
                        )}

                        {seat.roles.length > 1 && (
                          <Tooltip
                            content={tail(seat.roles)
                              // @ts-expect-error TS(2571): Object is of type 'unknown'.
                              .map(r => r.name)
                              .join(', ')}
                          >
                            <Pill marginLeft={4}>+{seat.roles.length - 1}</Pill>
                          </Tooltip>
                        )}
                      </Pane>
                    </Table.Cell>
                    {permissions.canManageCredentials && (
                      <Table.Cell>
                        <Pane>
                          {primaryCred && (
                            // @ts-expect-error TS(2571): Object is of type 'unknown'.
                            <Badge color="neutral">{primaryCred.name}</Badge>
                          )}
                          {seat.credentials.length > 1 && (
                            <Tooltip
                              content={tail(seat.credentials)
                                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                                .map(c => c.name)
                                .join(', ')}
                            >
                              <Pill marginLeft={4}>
                                +{seat.credentials.length - 1}
                              </Pill>
                            </Tooltip>
                          )}
                        </Pane>
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <RelativeDate date={seat.insertedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            seat={seat}
                            permissions={permissions}
                            onUpdateClick={() => setMemberToUpdate(seat)}
                            onRemoveClick={() => setMemberToRemove(seat)}
                            onRemoveIndividualClick={() =>
                              setIndividualMemberToRemove(seat)
                            }
                            onAssignCredentialsClick={() =>
                              setMemberToCredential(seat)
                            }
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && seats.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'organization.seats.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />

      <RemoveMemberDialog
        isShown={memberToRemove !== null}
        member={memberToRemove || {}}
        onComplete={() => {
          refetch(variables);
          setMemberToRemove(null);
        }}
        onCloseComplete={() => setMemberToRemove(null)}
      />

      <RemoveIndividualMemberDialog
        isShown={individualMemberToRemove !== null}
        member={individualMemberToRemove || {}}
        organization={organization}
        onComplete={() => {
          refetch(variables);
          setIndividualMemberToRemove(null);
        }}
        onCloseComplete={() => setIndividualMemberToRemove(null)}
      />

      <UpdateRoleDialog
        isShown={memberToUpdate !== null}
        member={memberToUpdate || {}}
        organization={organization}
        onCloseComplete={() => setMemberToUpdate(null)}
      />

      <UpdateCredentialsDialog
        isShown={memberToCredential !== null}
        member={memberToCredential || {}}
        organization={organization}
        onCloseComplete={() => setMemberToCredential(null)}
      />
    </Pane>
  );
};

export default OrgMembersList;
