import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import DeleteDialog from 'components/shared/DeleteDialog';
import MedicationsList, {
  MEDICATIONS_LIST
} from 'components/admin/MedicationsList';
import CreateOrUpdateMedication from 'components/admin/CreateOrUpdateMedication';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

const DELETE_MEDICATION = gql`
  mutation DeleteGlobalMedication($input: DeleteGlobalMedicationInput!) {
    result: deleteGlobalMedication(input: $input) {
      medication {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters
  };

  const refetchQuery = {
    variables,
    query: MEDICATIONS_LIST
  };

  const [deleteMedication, { loading: isDeleteLoading }] = useMutation(
    DELETE_MEDICATION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.medication) {
          toaster.success('Medication successfully deleted');
        } else {
          toaster.danger('Could not delete medication');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage global medications"
        renderButtons={
          <>
            <Button onClick={() => setShowForm(true)} iconBefore="add">
              New Medication
            </Button>
          </>
        }
      />

      <MedicationsList
        page={pageInfo}
        filters={filters}
        variables={variables}
        refetchQuery={refetchQuery}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onEditClick={(medication: any) => setShowForm(medication)}
        onDeleteClick={(medication: any) => setShowDeleteDialog(medication)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateMedication
          medication={showForm || {}}
          onComplete={() => {
            toaster.success('Medication saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteMedication({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
