import React from 'react';
import queryString from 'query-string';
import PageHeader from 'components/shared/PageHeader';
import SearchResultsList from 'components/organizations/SearchResultsList';
import { parseFilters, onFilterUpdate } from 'lib/pagination';

export default ({ history, organization }: any) => {
  const qs = queryString.parse(history.location.search);
  const filters = parseFilters(qs.filters);

  const variables = {
    searchTerm: '',
    ...filters,
    organizationId: organization.id
  };

  return (
    <>
      <PageHeader
        title="Search organization content"
        subtitle="Matches against aliases and whole names only across the content in the organization."
      />

      <SearchResultsList
        organization={organization}
        filters={filters}
        variables={variables}
        onFilterUpdate={onFilterUpdate(history)}
      />
    </>
  );
};
