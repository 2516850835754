import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import Login from 'pages/shared/Login';
import Signup from 'pages/shared/Signup';
import Onboarding from 'pages/shared/Onboarding';
import ForgotPassword from 'pages/shared/ForgotPassword';
import ResetPassword from 'pages/shared/ResetPassword';
import OrgInvite from 'pages/shared/OrgInvite';
import Account from 'pages/shared/Account';
import AuthRedirect from 'pages/shared/AuthRedirect';
import OrgSelector from 'pages/organization/Selector';
import SetInventoryProfile from 'pages/organization/SetInventoryProfile';
import AdminRoutes from 'pages/admin/Routes';
import OrganizationRoutes from 'pages/organization/Layout';
import * as analytics from 'lib/analytics';

function usePageView() {
  const location = useLocation();
  // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
  useEffect(() => analytics.page(), [location]);
}

function Routes() {
  usePageView();

  return (
    <>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route path="/onboarding" component={Onboarding} />
      <Route exact path="/forgot" component={ForgotPassword} />
      <Route exact path="/reset_password" component={ResetPassword} />
      <Route exact path="/invites/:token" component={OrgInvite} />
      <Route exact path="/auth" component={AuthRedirect} />

      <AuthenticatedRoute exact path="/" component={OrgSelector} />
      <AuthenticatedRoute exact path="/account" component={Account} />

      <Switch>
        <AuthenticatedRoute
          exact
          path="/orgs/:slug/inventory-onboarding"
          component={SetInventoryProfile}
        />
        <AuthenticatedRoute path="/orgs/:slug" component={OrganizationRoutes} />
      </Switch>

      <Route path="/admin" component={AdminRoutes} />
    </>
  );
}

export default Routes;
