import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Pane,
  Heading,
  Paragraph,
  Spinner,
  Text,
  Icon,
  Badge
} from 'evergreen-ui';
import { CREDIT_CARDS_FOR_USER } from './queries';

const CardOnFile = () => {
  const { data, loading } = useQuery(CREDIT_CARDS_FOR_USER, {
    fetchPolicy: 'network-only'
  });

  const card = get(data, 'creditCardsForUser[0]');

  if (loading) {
    return <Spinner size={20} />;
  }

  if (!card) {
    return (
      <Pane textAlign="center" width={260} marginLeft="auto" marginRight="auto">
        <Icon icon="credit-card" size={40} color="muted" marginBottom={4} />
        <Heading marginTop={8}>No cards on file</Heading>
        <Paragraph textAlign="center" color="muted">
          We don't have an active credit card on file for you yet. Please add a
          card to ensure uninterrupted access to Muru.
        </Paragraph>
      </Pane>
    );
  }

  return (
    <Pane>
      <Pane alignItems="center" display="flex">
        <Icon color="muted" icon="credit-card" size={16} marginRight={8} />
        <Text color="muted" display="block">
          **** {card.last4}
        </Text>
        <Text color="muted" marginLeft={8}>
          {card.expMonth}/{card.expYear}
        </Text>
        <Badge marginLeft="auto">Default</Badge>
      </Pane>
    </Pane>
  );
};

export default CardOnFile;
