import React from 'react';
import { CornerDialog } from 'evergreen-ui';

const UpdateAvailableDialog = ({ isShown }: any) => (
  <CornerDialog
    title="New update available"
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    onConfirm={() => window.location.reload(true)}
    confirmLabel="Update now"
    isShown={isShown}
    hasClose={false}
    hasCancel={false}
  >
    There's a new update available. Please reload the page or click here to get
    the latest version of the app.
  </CornerDialog>
);

export default UpdateAvailableDialog;
