import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const CREATE_MEDICATION = gql`
  mutation CreateGlobalMedication($input: CreateGlobalMedicationInput!) {
    result: createGlobalMedication(input: $input) {
      medication {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const UPDATE_MEDICATION = gql`
  mutation UpdateGlobalMedication($input: UpdateGlobalMedicationInput!) {
    result: updateGlobalMedication(input: $input) {
      medication {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;
