import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';
import { UPDATE_PROTOCOL_SET, CREATE_PROTOCOL_SET } from './queries';

const initialValues = {
  name: '',
  aliases: [],
  sources: []
};

const CreateOrUpdateProtocolSet = ({
  organizationId,
  protocolSet,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasProtocolSet = protocolSet && protocolSet.id;
  const [createOrUpdateProtocolSet, { loading }] = useMutation(
    hasProtocolSet ? UPDATE_PROTOCOL_SET : CREATE_PROTOCOL_SET,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  let formData = hasProtocolSet
    ? sanitizeData(protocolSet, [
        'lastPublishedAt',
        'organization',
        'amendment'
      ])
    : initialValues;

  // Normalize sources to be a list of id values
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  formData.sources = formData.sources.map((source: any) => source.id);

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasProtocolSet ? 'Update protocol set' : 'Create new protocol set'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={protocolSet?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form(organizationId)}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            // We only want to add the organizationId to the case where we're creating
            // new sources and not when we're editing an existing source.
            const variables = hasProtocolSet
              ? input
              : { ...input, organizationId };

            setErrors({});
            createOrUpdateProtocolSet({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateProtocolSet;
