import React from 'react';
import { map, isObject } from 'lodash';
import { FormFieldValidationMessage } from 'evergreen-ui';

const ValidationErrors = ({ errors }: any) => (
  <>
    {map(
      errors,
      errorMessage =>
        typeof errorMessage === 'string' && (
          <FormFieldValidationMessage marginTop={4}>
            {errorMessage}
          </FormFieldValidationMessage>
        )
    )}
  </>
);

const ValidationErrorMessage = ({ error }: any) => (
  <>
    {typeof error === 'string' && (
      <FormFieldValidationMessage marginTop={4}>
        {error}
      </FormFieldValidationMessage>
    )}

    {error && isObject(error) && <ValidationErrors errors={error} />}
  </>
);

export default ValidationErrorMessage;
