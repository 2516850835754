import React from 'react';
import { Menu } from 'evergreen-ui';

const HospitalMenu = ({ onPublishClick, onEditClick, onDeleteClick }: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit hospital</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="success" onSelect={onPublishClick}>
          Publish hospital
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete hospital
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default HospitalMenu;
