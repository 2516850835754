export const Units = {
  KILOLITER: 'KILOLITER',
  DECALITER: 'DECALITER',
  LITER: 'LITER',
  MILLILITER: 'MILLILITER',
  MICROLITER: 'MICROLITER',

  KILOGRAM: 'KILOGRAM',
  GRAM: 'GRAM',
  MILLIGRAM: 'MILLIGRAM',
  MICROGRAM: 'MICROGRAM',

  JOULE: 'JOULE',
  PACE: 'PACE',
  METER_OF_WATER: 'METER_OF_WATER',
  INCH_OF_MECURY: 'INCH_OF_MECURY',
  AMP: 'AMP',
  MILLIAMP: 'MILLIAMP',
  DROP: 'DROP',
  UNIT: 'UNIT',
  SPRAY: 'SPRAY',
  EQUIVALENT: 'EQUIVALENT',
  MILLIEQUIVALENT: 'MILLIEQUIVALENT',
  POUND_PER_SQUARE_INCH: 'POUND_PER_SQUARE_INCH',

  SECOND: 'SECOND',
  MINUTE: 'MINUTE',
  HOUR: 'HOUR'
};

export const UnitsWithLabels = {
  KILOLITER: 'kL',
  DECALITER: 'dL',
  LITER: 'L',
  MILLILITER: 'mL',
  MICROLITER: 'μL',

  KILOGRAM: 'kg',
  GRAM: 'g',
  MILLIGRAM: 'mg',
  MICROGRAM: 'μg',

  JOULE: 'J',
  PACE: 'pace',
  METER_OF_WATER: 'mH2O',
  INCH_OF_MECURY: 'inHg',
  AMP: 'A',
  MILLIAMP: 'mA',
  DROP: 'drop',
  UNIT: 'unit',
  SPRAY: 'spray',
  EQUIVALENT: 'eq',
  MILLIEQUIVALENT: 'mEq',
  POUND_PER_SQUARE_INCH: 'PSI',

  SECOND: 'sec',
  MINUTE: 'min',
  HOUR: 'hr'
};

export const Sex = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER'
};

export const CalendarUnits = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY'
};

export const CalendarUnitsWithLabels = {
  YEAR: 'Years',
  MONTH: 'Months',
  WEEK: 'Weeks',
  DAY: 'Days'
};

export const WeightUnits = {
  STONE: 'STONE',
  POUND: 'POUND',
  OUNCE: 'OUNCE',
  KILOGRAM: 'KILOGRAM',
  GRAM: 'GRAM'
};

export const WeightUnitsWithLabels = {
  STONE: 'stone',
  POUND: 'lb',
  OUNCE: 'oz',
  KILOGRAM: 'kg',
  GRAM: 'g'
};

export const PatientType = {
  INFANT: 'INFANT',
  PEDIATRIC: 'PEDIATRIC',
  ADULT: 'ADULT',
  CANINE: 'CANINE'
};

export const PatientTypeWithLabel = {
  INFANT: 'Infant',
  PEDIATRIC: 'Pediatric',
  ADULT: 'Adult',
  CANINE: 'Canine'
};

export const TimeUnits = {
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
  SECOND: 'SECOND'
};

export const TimeUnitsWithLabels = {
  HOUR: 'hours',
  MINUTE: 'mins',
  SECOND: 'second'
};
