import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { uniq, some, includes, filter } from 'lodash';
import {
  Table,
  Pane,
  Spinner,
  Heading,
  SelectMenu,
  Popover,
  Position,
  IconButton,
  TextDropdownButton,
  Alert
} from 'evergreen-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EmptyContent from 'components/shared/EmptyContent';
import { sourceUrl } from 'lib/sources';
import {
  amendmentByType,
  Types as AmendmentTypes
} from 'lib/contentAmendments';
import ProtocolStepRow from './ProtocolStepRow';
import { PROTOCOL_STEPS, REORDER_STEP, RESET_ORDER } from './queries';
import ToolsMenu from './ToolsMenu';
import gatherStepsForProtocol from './gatherSteps';

export const STEP_WIDTH = 400;
export const DRAG_WIDTH = 60;

const credentialNamesFromSteps = (steps: any) =>
  uniq(
    steps.reduce(
      (acc: any, step: any) =>
        acc.concat(step.credentials.map((credential: any) => credential.name)),
      []
    )
  );

const credentialOptionsFromSteps = (steps: any) =>
  credentialNamesFromSteps(steps).map(name => ({
    label: name,
    value: name
  }));

const ProtocolStepList = ({
  variables,
  refetchQuery,
  organization,
  protocol,
  protocolSet,
  canReorderSteps,
  isViewOnly,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  onReferencingProtocolsClick,
  onSubProtocolsClick
}: any) => {
  const [selectedCredentials, setSelectedCredentials] = useState([]);

  const { loading, data, error } = useQuery(PROTOCOL_STEPS, {
    variables,
    fetchPolicy: 'network-only'
  });

  const [reorderStep, { loading: isReordering }] = useMutation(REORDER_STEP, {
    awaitRefetchQueries: true,
    refetchQueries: [refetchQuery]
  });

  const [resetProtocolOrder, { loading: isResettingOrder }] = useMutation(
    RESET_ORDER,
    {
      awaitRefetchQueries: true,
      refetchQueries: [refetchQuery]
    }
  );

  const steps = gatherStepsForProtocol(
    data && data.organization && data.organization.protocol
  );

  const credentialOptions = credentialOptionsFromSteps(steps);
  const totalSelectedCredentials = selectedCredentials.length;

  // Filter the steps to be displayed by the selected credentials
  const filteredSteps =
    totalSelectedCredentials > 0
      ? steps.filter((step: any) =>
          some(step.credentials, credential =>
            includes(selectedCredentials, credential.name)
          )
        )
      : steps;

  const onResetOrderClick = (close: any) => {
    if (window.confirm('Are you sure you want to reset the order?')) {
      resetProtocolOrder({
        variables: { input: { id: protocol.id } }
      });
      close();
    }
  };

  const isExcluded =
    !!amendmentByType(protocolSet, AmendmentTypes.EXCLUDE) ||
    !!amendmentByType(protocol, AmendmentTypes.EXCLUDE);

  return (
    <Pane>
      {isExcluded && (
        <Alert
          title="This protocol is excluded from any child agencies"
          marginBottom={24}
        />
      )}

      <Pane background="white">
        <Pane display="flex">
          <Heading size={100} marginBottom={24}>
            Listing steps for {protocol.name} ({steps.length})
          </Heading>

          <Pane marginLeft="auto">
            <Popover
              content={({ close }: any) => (
                <ToolsMenu
                  isViewOnly={isViewOnly}
                  onReferencingProtocolsClick={onReferencingProtocolsClick}
                  onSubProtocolsClick={onSubProtocolsClick}
                  onResetOrderClick={() => onResetOrderClick(close)}
                />
              )}
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton icon="more" height={24} appearance="minimal" />
            </Popover>
          </Pane>
        </Pane>

        <DragDropContext
          onDragEnd={result => {
            if (!result.destination) return;

            const input = {
              id: result.draggableId,
              position: result.destination.index
            };

            reorderStep({ variables: { input } });
          }}
        >
          <Table border>
            <Table.Head>
              <Table.HeaderCell width={DRAG_WIDTH} flex="none" />
              <Table.TextHeaderCell flex="none" width={STEP_WIDTH}>
                Step
              </Table.TextHeaderCell>
              <Table.TextHeaderCell> </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                <SelectMenu
                  isMultiSelect
                  title="Select credentials"
                  // @ts-expect-error Invalid options
                  options={credentialOptions}
                  selected={selectedCredentials}
                  onSelect={(item: any) =>
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    setSelectedCredentials([...selectedCredentials, item.value])
                  }
                  onDeselect={(item: any) =>
                    setSelectedCredentials(
                      filter(selectedCredentials, i => i !== item.value)
                    )
                  }
                >
                  <TextDropdownButton icon="caret-down">
                    Credentials
                    {totalSelectedCredentials > 0 &&
                      ` (${totalSelectedCredentials})`}
                  </TextDropdownButton>
                </SelectMenu>
              </Table.TextHeaderCell>
              <Table.TextHeaderCell width={120} flex="none">
                Last Updated
              </Table.TextHeaderCell>
              <Table.HeaderCell width={48} flex="none" />
            </Table.Head>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Table.Body>
                    {filteredSteps.map((step: any, idx: any) => (
                      <Draggable
                        key={step.id}
                        draggableId={step.id}
                        index={step.order}
                        isDragDisabled={!!step.disabled || !canReorderSteps}
                      >
                        {provided => (
                          <ProtocolStepRow
                            provided={provided}
                            idx={idx + 1}
                            step={step}
                            organization={organization}
                            protocolSet={protocolSet}
                            protocol={protocol}
                            isReordering={isReordering || isResettingOrder}
                            selectedCredentials={selectedCredentials}
                            onEditClick={onEditClick}
                            onDeleteClick={onDeleteClick}
                            onDuplicateClick={onDuplicateClick}
                            onViewSourceClick={(step: any) =>
                              window.open(
                                sourceUrl(step.source, step.sourcePageNumber),
                                '_blank'
                              )
                            }
                            isViewOnly={isViewOnly}
                            isDisabled={!!step.disabled}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Table.Body>
                </div>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && steps.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default ProtocolStepList;
