import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { MedicationFragment } from 'components/shared/fragments';
import { toName } from 'lib/medications';

const PUBLISH_MEDICATION = gql`
  mutation PublishMedication($input: PublishMedicationForOrganizationInput!) {
    result: publishMedicationForOrganization(input: $input) {
      medication {
        ...Medication
      }
    }
  }
  ${MedicationFragment}
`;

const PublishMedicationDialog = ({
  isShown,
  medication,
  onCloseComplete
}: any) => {
  const [publishMedication, { loading }] = useMutation(PUBLISH_MEDICATION, {
    variables: { input: { id: medication.id } },
    onCompleted: (response: any) => {
      if (response.result.medication) {
        toaster.success(`${toName(medication)} successfully published!`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishMedication()}
    >
      <Paragraph>
        Publishing this medication will make it available to{' '}
        <strong>all users of the app within this organization</strong>. Please
        only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishMedicationDialog;
