import React from 'react';
import { UnorderedList, ListItem, Badge } from 'evergreen-ui';
import { formatRepeatInterval } from 'lib/dosing';

const DoseRepeatInformation = ({
  numberOfTimes,
  asNecessary,
  interval,
  requiresMedicalControlAuthorizationFromRepeat
}: any) => (
  <UnorderedList>
    {numberOfTimes && (
      <ListItem size={300}>Repeat {numberOfTimes} times</ListItem>
    )}
    {asNecessary && <ListItem size={300}>Repeat as necessary</ListItem>}
    {interval && (
      <ListItem size={300}>
        Repeat every {formatRepeatInterval(interval)}
      </ListItem>
    )}
    {requiresMedicalControlAuthorizationFromRepeat && (
      <ListItem size={300}>
        <Badge color="orange">Med Con</Badge> after dose{' '}
        {requiresMedicalControlAuthorizationFromRepeat}
      </ListItem>
    )}
  </UnorderedList>
);

export default DoseRepeatInformation;
