import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import { Redirect, useHistory } from 'react-router-dom';
import { zipCodeSelector } from 'redux/onboarding';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import { IndividualWaitlist } from 'components/onboarding/WaitlistForm';
import { page } from 'lib/analytics';

const Waitlist = () => {
  const history = useHistory();
  const zipCode = useSelector(zipCodeSelector);

  useEffect(() => {
    page('Onboarding Waitlist', {
      zip_code: zipCode && zipCode.zip_code
    });
  }, [zipCode]);

  if (!zipCode) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Pane>
      <Helmet>
        <title>Join the Waitlist</title>
      </Helmet>
      <Pane
        textAlign="center"
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MuruLogo />

        <Heading size={600} marginBottom={8}>
          Muru isn't available in {zipCode.zipCode} just yet
        </Heading>
        <Paragraph color="muted">
          Bad news: we're not in your area just yet, but sign up below and we'll
          let you know as soon as Muru is available.
        </Paragraph>
      </Pane>

      <IndividualWaitlist
        zipCode={zipCode}
        onSignup={() => history.push('/onboarding/waitlist/thanks')}
      />
    </Pane>
  );
};

export default Waitlist;
