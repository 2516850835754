import React from 'react';
import { withRouter } from 'react-router-dom';
import { Heading, Pane, Tablist, SidebarTab, Avatar } from 'evergreen-ui';
import { map, filter } from 'lodash';
import { isInPath, orgPath } from 'lib/urls';
import { hasPermission, isChildOrganization } from 'lib/organizations';
import { isSiteAdmin } from 'lib/auth';
import { OrganizationRolesWithLabels } from '@/constants/organizations';

export const Tabs = {
  Content: [
    {
      title: 'Protocol Sets & Protocols',
      path: '/protocol-sets',
      permission: 'protocols:list_draft'
    },
    {
      title: 'Hospitals',
      path: '/hospitals',
      permission: ['hospitals:list_draft', 'hospitals:list_draft_for_authority']
    },
    {
      title: 'Hospital Capabilities',
      path: '/hospital-capabilities',
      permission: 'hospital_capabilities:list'
    },
    {
      title: 'App Dashboard Items',
      path: '/dashboard-items',
      permission: 'dashboard_items:manage'
    },
    {
      title: 'Directory Contacts',
      path: '/directory-contacts',
      permission: 'directory_contacts:manage'
    },
    {
      title: 'Sources',
      path: '/sources',
      permission: 'sources:list'
    }
  ],
  Inventory: [
    {
      title: 'Medications',
      path: '/medications',
      permission: 'inventory:list_draft'
    },
    {
      title: 'Equipment',
      path: '/equipment',
      permission: 'inventory:list_draft'
    },
    {
      title: 'Manage Inventory Profile',
      path: '/inventory-onboarding',
      permission: 'inventory_profile:manage'
    }
  ],
  'Billing & Settings': [
    {
      title: 'Billing',
      path: '/billing',
      permission: 'subscription:manage'
    },
    {
      title: 'Members',
      path: '/members',
      permission: 'members:list_active'
    },
    {
      title: 'Profile',
      path: '/profile',
      permission: ['organization:update_profile', 'inventory_profile:manage']
    },
    {
      title: 'Certification Level',
      path: '/credentials',
      permission: 'credentials:manage'
    }
  ],
  Tools: [
    {
      title: 'Search Content',
      path: '/search',
      permission: 'search:list_entities'
    },
    {
      title: 'Overlapping Aliases',
      path: '/overlapping-aliases',
      permission: 'search:list_alias_collisions'
    },
    {
      title: 'Child Agencies',
      path: '/child-agencies',
      permission: 'search:list_alias_collisions'
    }
  ]
};

const OrgSidebar = ({
  organization = {},
  currentUser,
  location,
  history
}: any) => {
  const { pathname } = location;
  const isChildOrg = isChildOrganization(organization);
  const isAdmin = isSiteAdmin(currentUser);

  return (
    <Pane width={260} borderRight="default" background="tint1">
      <Pane
        display="flex"
        alignItems="center"
        padding={16}
        borderBottom="default"
      >
        <Pane display="flex" flexDirection="row" alignItems="center">
          <Avatar
            src={organization.logo && organization.logo.url}
            name={organization.name}
            size={32}
          />
          <Pane marginLeft={8}>
            {organization.roles && organization.roles.length > 0 && (
              <Heading size={100}>
                {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {OrganizationRolesWithLabels[organization.roles[0]]}
              </Heading>
            )}
            <Heading size={500}>{organization.name || 'Loading...'}</Heading>
          </Pane>
        </Pane>
      </Pane>
      <Pane padding={8}>
        {isChildOrg && isAdmin && (
          <Tablist marginBottom={24}>
            <Heading size={100} margin={8}>
              Parent Org
            </Heading>

            <SidebarTab
              onSelect={() => history.push(orgPath(organization.parent))}
            >
              {organization.parent.name}
            </SidebarTab>
          </Tablist>
        )}

        {map(Tabs, (items, label) => {
          const itemsToDisplay = filter(items, item =>
            item.permission
              ? hasPermission(organization, item.permission)
              : true
          );

          if (itemsToDisplay.length > 0) {
            return (
              <Tablist marginBottom={24} key={label}>
                <Heading size={100} margin={8}>
                  {label}
                </Heading>
                {itemsToDisplay.map(item => (
                  <SidebarTab
                    key={item.title}
                    onSelect={() =>
                      history.push(`/orgs/${organization.slug}${item.path}`)
                    }
                    isSelected={isInPath(
                      `/orgs/${organization.slug}${item.path}`,
                      pathname
                    )}
                  >
                    {item.title}
                  </SidebarTab>
                ))}
              </Tablist>
            );
          }

          return null;
        })}
      </Pane>
    </Pane>
  );
};

export default withRouter(OrgSidebar);
