import { StorageVolumeTypesWithLabels } from '@/constants/medications';
import { UnitsWithLabels } from 'constants/units';
import { formatAmount } from 'lib/dosing';

export function toName(medication: any) {
  if (!medication) return '';
  return `${medication.genericName} (${medication.brandName})`;
}

export const amountToString = ({ value, unit }: any) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  `${value}${UnitsWithLabels[unit]}`;

export const storageMethodToString = (fields: any) => {
  let units = [];

  if (fields.volume) {
    units.push(amountToString(fields.volume));
  }

  // Storage method type
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  units.push(StorageVolumeTypesWithLabels[fields.type] || fields.type);

  if (fields.displayName) {
    units.push(` (${fields.displayName})`);
  }

  // Add the concentration / per amount value (e.g. 1mg / 1mL)
  if (fields.concentration && fields.concentration.perAmount) {
    units.push(`@ ${formatAmount(fields.concentration)}`);
  }

  return units.join(' ');
};
