import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { some, includes } from 'lodash';
import * as Sentry from '@sentry/browser';
import { toaster } from 'evergreen-ui';
import { onError } from 'apollo-link-error';
import { getAuthToken } from 'lib/auth';
import { GenericError, formatErrorForToaster } from 'lib/errorHandling';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_ENDPOINT || 'http://localhost:4000/graphql'
});

const EXCLUDED_PATHS = ['joinableAgency', 'login'];

function shouldHideToast(graphQLErrors: any) {
  if (!graphQLErrors) return false;
  return some(graphQLErrors, ({ path }) =>
    path ? includes(EXCLUDED_PATHS, path[0]) : false
  );
}

const errorLink = onError(({ response, graphQLErrors, networkError }: any) => {
  let error;

  if (graphQLErrors && graphQLErrors.length) {
    error = formatErrorForToaster(graphQLErrors[0]);
  }

  if (networkError) {
    Sentry.withScope((scope: any) => {
      scope.setExtras(networkError);
      Sentry.captureException(new Error('GraphQL Network error'));
    });
  }

  error = error || GenericError;

  // Show the toaster and make sure at most one will appear
  if (!shouldHideToast(graphQLErrors)) {
    toaster.danger(error.title, {
      description: error.description,
      id: 'gql-error'
    });
  }

  console.error({ response, graphQLErrors, networkError });
});

const authLink = setContext((_: any, { headers }: any) => {
  const token = getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

const links = [authLink, errorLink, httpLink];
const link = ApolloLink.from(links);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

export default client;
