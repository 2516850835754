import React, { useState } from 'react';
import {
  Pane,
  Heading,
  Link,
  Icon,
  Text,
  RadioGroup,
  SegmentedControl,
  Button,
  toaster
} from 'evergreen-ui';
import posed from 'react-pose';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_EQUIPMENT_INVENTORY_PROFILE_ENTRY } from './queries';
import { launchIntercomWithMessage } from 'lib/analytics';

const OptionsContainer = posed.div({
  expanded: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: {
    height: '1px',
    opacity: 0
  }
});

const EquipmentQuestion = ({
  onSkipClick,
  onNextClick,
  equipment,
  options,
  organization
}: any) => {
  const [isCarried, setIsCarried] = useState(true);
  const [equipmentId, setEquipmentId] = useState(options[0] && options[0].id);

  const [saveAnswer, { loading }] = useMutation(
    CREATE_EQUIPMENT_INVENTORY_PROFILE_ENTRY,
    {
      onCompleted: ({ result }: any) => {
        if (!result.errors) {
          onNextClick();
        } else {
          toaster.danger('Could not save your answer. Please try again.');
        }
      }
    }
  );

  const selectionOptions =
    options.map((option: any) => ({
      label: `${option.manufacturer ? `${option.manufacturer.name} - ` : ''}${
        option.name
      }`,

      value: option.id
    })) || [];

  return (
    <Pane elevation={1} background="white" padding={32}>
      <Heading textAlign="center" marginBottom={16}>
        Which {equipment.name} do you have?
      </Heading>

      <SegmentedControl
        options={[
          { label: "We don't carry it", value: false },
          { label: 'We carry it', value: true }
        ]}
        // @ts-expect-error Invalid onChange
        onChange={setIsCarried}
        value={isCarried}
      />

      <OptionsContainer
        style={{ overflow: 'hidden' }}
        pose={isCarried ? 'expanded' : 'collapsed'}
      >
        <RadioGroup
          label="Select the model you most commonly carry"
          size={16}
          value={equipmentId}
          onChange={(equipmentId: any) => setEquipmentId(equipmentId)}
          options={selectionOptions}
          marginTop={16}
        />

        <Pane display="flex" alignItems="center" marginTop={8}>
          <Icon icon="help" color="muted" size={16} marginRight={8} />
          <Text size={400} color="muted">
            Don't see your model?{' '}
            <Link
              href="#"
              onClick={() => {
                launchIntercomWithMessage(
                  `We don't see the model of ${equipment.name} that we have when setting up our inventory.\n\nWe have a: <<what equipment you have>>.`
                );
              }}
            >
              Contact us and we'll add it
            </Link>
            .
          </Text>
        </Pane>
      </OptionsContainer>

      <Button
        appearance="primary"
        width="100%"
        justifyContent="center"
        height={40}
        marginTop={16}
        disabled={!equipmentId}
        isLoading={loading}
        onClick={() =>
          saveAnswer({
            variables: {
              input: {
                organizationId: organization.id,
                genericEquipmentId: equipment.id,
                equipmentId: !isCarried ? null : equipmentId
              }
            }
          })
        }
      >
        Next
      </Button>

      <Pane
        display="flex"
        position="absolute"
        bottom={-48}
        width="100%"
        left={0}
      >
        <Button
          appearance="minimal"
          marginTop={16}
          marginLeft="auto"
          marginRight="auto"
          textAlign="center"
          onClick={() => onSkipClick({ equipmentId: equipment.id })}
        >
          Skip this question for now
        </Button>
      </Pane>
    </Pane>
  );
};

export default EquipmentQuestion;
