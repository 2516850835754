import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  Button,
  Pane,
  Heading,
  Paragraph,
  Strong,
  Link,
  UnorderedList,
  ListItem,
  ButtonAppearance
} from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import {
  organizationSelector,
  planSelector,
  organizationCredentialsSelector
} from 'redux/onboarding';
import { onCompleted } from 'lib/formHelpers';
import { formatCents, trialDurationMessage } from 'lib/subscription';
import ManageSeatQuantities from 'components/shared/ManageSeatsQuantities';
import UpdateOrganizationCreditCard from 'components/shared/UpdateOrganizationCreditCard';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';
import { page, track } from 'lib/analytics';
import { PURCHASE_FOR_ORGANIZATION } from './queries';

function seatCountInvalid(seatCount: any, minimumSeatCount: any) {
  return seatCount === 0 || seatCount < minimumSeatCount;
}

const submitButtonProps = {
  appearance: 'primary' as ButtonAppearance,
  display: 'flex',
  justifyContent: 'center',
  height: 40,
  marginTop: 24,
  width: '100%'
};

const SignupAsOrganization = () => {
  const history = useHistory();
  const [errors, setErrors] = useState(null);
  const organization = useSelector(organizationSelector);
  const credentials = useSelector(organizationCredentialsSelector);
  const plan = useSelector(planSelector);

  useEffect(() => {
    page('Onboarding Organization Signup', {
      selected_agency_id: organization.id,
      selected_plan_id: plan.id,
      selected_plan_interval: plan.interval,
      selected_plan_type: plan.type
    });
  }, [organization, plan]);

  const [seatCount, setSeatCount] = useState(1);
  const [purchaseForOrg, { loading }] = useMutation(PURCHASE_FOR_ORGANIZATION, {
    variables: {
      input: {
        totalSeatCount: seatCount,
        organizationId: organization.id,
        planId: plan.id,
        credentialId: credentials.id,
        medicalLicense: {
          number: credentials.licenseNumber,
          issuingState: credentials.issuingState
        }
      }
    },
    onCompleted: (response: any) =>
      onCompleted({
        response,
        setErrors,
        onComplete: () => {
          history.push('/onboarding/organization/get-started');
        }
      })
  });

  const isSeatCountInvalid = seatCountInvalid(seatCount, 1);
  const isFreeTrial = !!plan.trialPeriodDays;
  const totalCost = plan.amountInCents * seatCount;

  return (
    <Pane position="relative">
      <Helmet>
        <title>Purchase Muru for your agency</title>
      </Helmet>
      <Pane
        textAlign="center"
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MuruLogo />

        <Heading size={600} marginBottom={4}>
          Purchase Muru for {organization.name}
        </Heading>

        {isFreeTrial && (
          <Paragraph>{trialDurationMessage(plan)}. Cancel anytime.</Paragraph>
        )}
      </Pane>

      <Pane positrion="relative" marginLeft={180} width="100%">
        <Pane
          position="absolute"
          width={240}
          left={-100}
          background="tint1"
          padding={16}
          marginRight={32}
        >
          <Heading size={100} textAlign="left" marginBottom={16}>
            Benefits of purchasing
          </Heading>

          <UnorderedList icon="tick" iconColor="success">
            <ListItem>Control who can access Muru in your agency</ListItem>

            <ListItem>Centrally manage your equipment and medications</ListItem>

            <ListItem>
              <Strong color="muted" marginRight={4}>
                (Coming Soon)
              </Strong>
              Easily notify your providers of new changes to inventory, protocol
              sets and more
            </ListItem>
          </UnorderedList>
        </Pane>

        <Pane width={320}>
          {errors && (
            <Pane marginBottom={16}>
              <Heading color="#BF0E08" size={400}>
                There was a problem, please try again
              </Heading>
              <ValidationErrorMessage error={errors} />
            </Pane>
          )}

          <Heading size={400} textAlign="left">
            How many other providers do you want to buy Muru for?
          </Heading>
          <Paragraph color="muted" marginBottom={12}>
            Buy for as many providers as you like now. You can always upgrade or
            downgrade later.
          </Paragraph>

          <ManageSeatQuantities
            minimumSeatCount={1}
            seatCount={seatCount}
            onSeatCountChange={(seatCount: any) => {
              track('Onboarding Organization Seat Count Changed', {
                seat_count: seatCount
              });
              setSeatCount(seatCount);
            }}
            perSeatCost={plan.amountInCents}
            interval={plan.interval}
            invalidSeatCount={isSeatCountInvalid}
          />

          {isFreeTrial && (
            <Pane
              display="flex"
              borderBottom
              paddingBottom={12}
              marginBottom={12}
            >
              <Heading size={400}>Total due today</Heading>
              <Strong size={400} marginLeft="auto" textAlign="right">
                ${formatCents(0, true)}
              </Strong>
            </Pane>
          )}

          <Pane textAlign="left" marginTop={24}>
            {plan.requiresCreditCardForSignUp ? (
              <UpdateOrganizationCreditCard
                isLoading={loading}
                buttonTitle="Confirm Purchase"
                organizationId={organization.id}
                buttonProps={submitButtonProps}
                onComplete={() => {
                  setErrors(null);
                  purchaseForOrg();
                }}
              />
            ) : (
              <Button
                {...submitButtonProps}
                onClick={() => {
                  setErrors(null);
                  purchaseForOrg();
                }}
              >
                Confirm Purchase
              </Button>
            )}
          </Pane>

          {isFreeTrial ? (
            <Paragraph color="#A6B1BB" marginTop={16} size={300}>
              Your membership will start today. I agree for Muru to charge me $
              {formatCents(totalCost)} at the end of my {plan.trialPeriodDays}
              -day trial and renew my subscription at the standard price every{' '}
              {plan.interval.toLowerCase()} according to the{' '}
              <Link
                href="https://murumed.com/terms-of-service"
                target="_blank"
                size={300}
              >
                Terms of Service
              </Link>{' '}
              until I cancel my subscription.
            </Paragraph>
          ) : (
            <Paragraph color="#A6B1BB" marginTop={16} size={300}>
              Your membership will start today. I agree for Muru to charge me $
              {formatCents(totalCost)} and renew my subscription at the standard
              price every {plan.interval.toLowerCase()} according to the{' '}
              <Link
                href="https://murumed.com/terms-of-service"
                target="_blank"
                size={300}
              >
                Terms of Service
              </Link>{' '}
              until I cancel my subscription.
            </Paragraph>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

export default SignupAsOrganization;
