import React from 'react';
import { Dialog, Paragraph } from 'evergreen-ui';
import { Types as AmendmentTypes } from 'lib/contentAmendments';
import useContentAmendmentMutation from 'hooks/useContentAmendmentMutation';

const ExcludeDialog = ({
  isShown,
  protocol,
  exclusionAmendment,
  organization,
  onCloseComplete,
  refetchQuery
}: any) => {
  const {
    loading,
    createAmendment,
    deleteAmendment
  } = useContentAmendmentMutation({
    refetchQueries: [refetchQuery],
    onCompleted: (response: any) => {
      if (response.result.contentAmendment) {
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="Confirm"
      onConfirm={() => {
        exclusionAmendment
          ? deleteAmendment({
              variables: {
                input: { id: exclusionAmendment.id }
              }
            })
          : createAmendment({
              variables: {
                input: {
                  organizationId: organization.id,
                  protocolId: protocol.id,
                  type: AmendmentTypes.EXCLUDE
                }
              }
            });
      }}
    >
      {exclusionAmendment ? (
        <Paragraph marginBottom={16}>
          If you re-include this protocol it will be visible to all descendent
          organizations and agencies.
        </Paragraph>
      ) : (
        <Paragraph marginBottom={16}>
          If you exclude this protocol, it will no longer be able to be accessed
          by any descendent organizations and agencies.
        </Paragraph>
      )}
    </Dialog>
  );
};

export default ExcludeDialog;
