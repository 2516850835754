import React from 'react';
import { useWindowSize } from 'react-use';
import { Pane, Spinner } from 'evergreen-ui';

const LoadingScreen = () => {
  const { height } = useWindowSize();

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={height}
      flex="1"
    >
      <Spinner />
    </Pane>
  );
};

export default LoadingScreen;
