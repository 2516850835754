import React from 'react';
import { Pane, Button } from 'evergreen-ui';
import HospitalNetworkDataSource from 'components/shared/HospitalNetworkDataSource';
import MedicalAuthorityDataSource from 'components/shared/MedicalAuthorityDataSource';
import AsyncSelect from 'components/shared/FormBuilder/Fields/AsyncSelect';
import TextInputField from 'components/shared/FormBuilder/Fields/TextInputField';

const FilterMenu = ({
  close,
  onClearAll,
  canFilterByNetwork,
  onApply,
  filters
}: any) => {
  const [pendingFilters, setPendingFilters] = React.useState(filters);

  const onChange = (name: any, value: any) =>
    setPendingFilters({ ...pendingFilters, [name]: value });

  const formikBag = {
    setFieldTouched: () => {},
    setFieldValue: onChange
  };

  return (
    <Pane width={400}>
      <Pane padding={16}>
        {canFilterByNetwork && (
          <AsyncSelect
            name="organizationId"
            label="Hospital Network"
            description="Only show hospitals that belong to this network"
            dataSource={(render: any) => (
              <HospitalNetworkDataSource render={render} />
            )}
            formikBag={formikBag}
            value={pendingFilters.organizationId}
          />
        )}

        <AsyncSelect
          name="medicalAuthorityId"
          label="Medical Authority"
          description="Only show hospitals that are in this medical authority"
          dataSource={(render: any) => (
            <MedicalAuthorityDataSource render={render} />
          )}
          formikBag={formikBag}
          value={pendingFilters.medicalAuthorityId}
        />

        <TextInputField
          name="capability"
          label="Capability"
          description="Match any hospitals with a capability like"
          value={pendingFilters.capability}
          onChange={(e: any) => onChange('capability', e.target.value)}
        />
      </Pane>
      <Pane padding={16} borderTop background="tint1" display="flex">
        <Button
          marginRight={8}
          marginLeft="auto"
          onClick={() => {
            setPendingFilters({});
            close();
            onClearAll();
          }}
        >
          Clear all
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            onApply(pendingFilters);
            close();
          }}
        >
          Apply
        </Button>
      </Pane>
    </Pane>
  );
};

export default FilterMenu;
