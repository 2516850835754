import React from 'react';
import { Pane } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import Layout from 'components/shared/Layout';
import { currentUserSelector } from 'redux/global';
import OrgSelector from 'components/accounts/OrgSelector';

export default ({ history }: any) => {
  const currentUser = useSelector(currentUserSelector);

  return (
    <Layout>
      <Pane
        background="tint1"
        display="flex"
        flexDirection="column"
        flex={1}
        padding={16}
      >
        {currentUser && (
          <OrgSelector
            user={currentUser}
            seats={currentUser.seats}
            onSelect={(slug: any) => history.push(`/orgs/${slug}`)}
          />
        )}
      </Pane>
    </Layout>
  );
};
