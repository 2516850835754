import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required()
});

export const Form = {
  name: 'credential',
  validationSchema: Schema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'EMT Critical Care (EMT-CC)',
      required: true
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'A short description of this credential',
      type: 'textarea',
      required: true
    }
  ]
};
