import React from 'react';
import { head } from 'lodash';
import { Avatar, Pane, Heading, Paragraph, Button, Icon } from 'evergreen-ui';
import AppMagicLogin from 'components/shared/AppMagicLogin';

const InviteAccepted = ({ invite }: any) => {
  const role = head(invite.roles);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const appAccess = role.name === 'Medic';

  return (
    <Pane>
      <Pane
        position="relative"
        width={64}
        marginLeft="auto"
        marginRight="auto"
        marginBottom={16}
      >
        <Avatar
          src={invite.organization.logo && invite.organization.logo.url}
          name={invite.organization.name}
          size={64}
        />
        <Icon
          icon="endorsed"
          size={24}
          right={-8}
          top={-8}
          color="success"
          position="absolute"
        />
      </Pane>

      <Heading size={600} marginBottom={8}>
        Congratulations, you're now a member of {invite.organization.name} on
        Muru
      </Heading>

      {appAccess ? (
        <>
          <Paragraph color="muted" marginBottom={16}>
            To continue you'll need to get the Muru app. If you're not on your
            mobile device right now you can text yourself a link.
          </Paragraph>
          {/* @ts-expect-error trackingLocation */}
          <AppMagicLogin trackingLocation="INVITE_ACCEPTED" />

          <Paragraph color="muted">
            If you've already got the Muru app, login to get started.
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph color="muted" marginBottom={16}>
            You'll be able to access this organizations content in your
            dashboard.
          </Paragraph>

          <Button
            is="a"
            href="/"
            display="flex"
            width="100%"
            justifyContent="center"
            height={40}
            marginBottom={16}
          >
            Go to your dashboard
          </Button>
        </>
      )}
    </Pane>
  );
};

export default InviteAccepted;
