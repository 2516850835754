import gql from 'graphql-tag';
import {
  SubscriptionFragment,
  PageInfoFragment
} from 'components/shared/fragments';

const OrganizationListItemFragment = gql`
  fragment OrganizationListItem on Organization {
    id
    name
    type
    slug
    insertedAt
    updatedAt
    hospitalsLastPublishedAt
    equipmentLastPublishedAt
    medicationsLastPublishedAt
    aliasesLastPublishedAt
    protocolSetsLastPublishedAt
    directoryContactsLastPublishedAt
    roles
    logo {
      url
    }
    subscription {
      ...Subscription
    }
  }
  ${SubscriptionFragment}
`;

export const LIST_ORGS = gql`
  query ListOrgsForMainOrgListing(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationsInput
  ) {
    organizations(
      first: $first
      after: $after
      before: $before
      last: $last
      filters: $filters
    ) {
      edges {
        node {
          ...OrganizationListItem
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${OrganizationListItemFragment}
  ${PageInfoFragment}
`;

export const CLONE_ORGANIZATION = gql`
  mutation CloneOrganizationContent($input: CloneOrganizationContentInput!) {
    result: cloneOrganizationContent(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    result: deleteOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;
