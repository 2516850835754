import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Pane, Heading, Button, Paragraph, Link } from 'evergreen-ui';
import { Formik, FieldArray } from 'formik';
import { find, get, head } from 'lodash';
import pluralize from 'pluralize';
import ValidationErrors from 'components/shared/ValidationErrors';
import InviteField from './InviteField';
import { ROLES_LIST, CREDENTIALS_LIST, INVITE_TO_ORG } from './queries';

const emptyInvite = { email: '', roles: [] };

const initialValues = {
  invites: [emptyInvite]
};

function hasErrorOnEmail(errors: any, email: any) {
  return find(errors, { email });
}

const InviteToOrg = ({ organization, seatsRemaining, onComplete }: any) => {
  const organizationId = organization.id;
  const { data: rolesData } = useQuery(ROLES_LIST, {
    variables: { id: organizationId },
    fetchPolicy: 'network-only'
  });

  const { data: credsData } = useQuery(CREDENTIALS_LIST, {
    variables: { id: organizationId }
  });

  const [inviteUsers, { data, error }] = useMutation(INVITE_TO_ORG, {
    onCompleted: onComplete
  });

  const selectableRoles = get(
    rolesData,
    'organization.applicableRoles',
    []
  ).map((role: any) => ({
    value: role.id,
    label: role.name
  }));

  const selectableCreds = get(
    credsData,
    'organization.credentials.edges',
    []
    // @ts-expect-error TS(7031): Binding element 'cred' implicitly has an 'any' typ... Remove this comment to see the full error message
  ).map(({ node: cred }) => ({
    value: cred.id,
    label: cred.name
  }));

  const errors = get(data, 'createOrganizationInvites.errors', []);
  const createdInvites = get(data, 'createOrganizationInvites.invites', []);
  const successCount = createdInvites.length;
  const totalAvailableSeats = seatsRemaining - successCount;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane
        padding={16}
        background="tint2"
        borderBottom
        display="flex"
        flexDirection="column"
      >
        <Heading size={500}>Invite to organization</Heading>
        <Paragraph color="muted">
          You have{' '}
          <strong>
            {pluralize('seat', totalAvailableSeats, true)} remaining
          </strong>{' '}
          in your organization.{' '}
          <Link is={RouterLink} to={`/orgs/${organization.slug}/billing`}>
            Buy more
          </Link>
          .
        </Paragraph>
      </Pane>
      <Formik
        initialValues={initialValues}
        onSubmit={(input: any) => {
          inviteUsers({
            variables: {
              input: { ...input, organizationId }
            }
          });
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values }: any) => {
          const totalInvites = values.invites.length;
          const numCreatedInvites = createdInvites.length;

          return (
            <Pane
              onSubmit={handleSubmit}
              is="form"
              flex="1"
              display="flex"
              flexDirection="column"
            >
              <Pane flex="1" padding={16}>
                {error && <ValidationErrors />}
                <FieldArray
                  name="invites"
                  render={(arrayHelpers: any) => (
                    <>
                      {values.invites.map((invite: any, index: any) => {
                        const validationError = hasErrorOnEmail(
                          errors,
                          invite.email
                        );

                        return (
                          <InviteField
                            key={index}
                            email={invite.email}
                            role={head(invite.roles)}
                            credential={head(invite.credentials)}
                            roleOptions={selectableRoles}
                            credentialOptions={selectableCreds}
                            name={`invites[${index}]`}
                            onAddClick={() => arrayHelpers.push(emptyInvite)}
                            onRemoveClick={() => arrayHelpers.remove(index)}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            canRemove={totalInvites !== 1}
                            canAdd={
                              index === totalInvites - 1 &&
                              totalInvites < totalAvailableSeats
                            }
                            validationError={validationError}
                            wasCreated={successCount > 0 && !validationError}
                          />
                        );
                      })}
                    </>
                  )}
                />
              </Pane>
              <Pane
                padding={16}
                borderTop="muted"
                background="blueTint"
                display="flex"
              >
                <Button
                  type="submit"
                  intent="success"
                  appearance="primary"
                  disabled={numCreatedInvites === totalInvites}
                >
                  Invite {totalInvites} members
                </Button>
              </Pane>
            </Pane>
          );
        }}
      </Formik>
    </Pane>
  );
};

export default InviteToOrg;
