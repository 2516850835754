import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading, Paragraph, Strong } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted } from 'lib/formHelpers';
import { Form } from './form';
import { ASSIGN_INDIVIDUAL } from './queries';

const initialData = {
  email: null,
  credentialId: null,
  coupon: null,
  planId: null
};

const AddIndividualToOrganization = ({
  organization,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});

  const [assignUser, { loading }] = useMutation(ASSIGN_INDIVIDUAL, {
    refetchQueries: [refetchQuery],
    awaitRefetchQueries: true,
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  const form = Form({ organizationId: organization.id });

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>Add user to organization</Heading>
      </Pane>
      <Pane padding={16}>
        <Paragraph marginBottom={16}>
          This form will add the user provided to the organization, setting up
          an individual subscription along the way. If the user already has a
          subscription, then this organization will be added to their
          subscription.{' '}
          <Strong>
            This may incur charges to the user (as it modifies the subscription)
          </Strong>
          .
        </Paragraph>

        <FormBuilder
          isSubForm
          initialValues={initialData}
          form={form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            setErrors({});
            assignUser({
              variables: {
                input: { ...input, organizationId: organization.id }
              }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default AddIndividualToOrganization;
