import React from 'react';
import { Pane, Button } from 'evergreen-ui';
import OrgSelectorDataSource from 'components/shared/OrgSelectorDataSource';
import AsyncSelect from 'components/shared/FormBuilder/Fields/AsyncSelect';
import TextInputField from 'components/shared/FormBuilder/Fields/TextInputField';

const FilterMenu = ({ close, onClearAll, onApply, filters }: any) => {
  const [pendingFilters, setPendingFilters] = React.useState(filters);

  const onChange = (name: any, value: any) =>
    setPendingFilters({ ...pendingFilters, [name]: value });

  const formikBag = {
    setFieldTouched: () => {},
    setFieldValue: onChange
  };

  return (
    <Pane width={400}>
      <Pane padding={16}>
        <AsyncSelect
          name="parentOrganizationId"
          label="Parent Organization"
          description="Only show agencies that belong to this parent"
          dataSource={(render: any) => (
            <OrgSelectorDataSource
              filters={{ isParent: true }}
              render={render}
            />
          )}
          formikBag={formikBag}
          value={pendingFilters.parentOrganizationId}
        />

        <TextInputField
          name="countyCode"
          label="County Code"
          description="Only show agencies that operate within this county"
          value={pendingFilters.countyCode}
          onChange={(e: any) => onChange('countyCode', e.target.value)}
        />
      </Pane>
      <Pane padding={16} borderTop background="tint1" display="flex">
        <Button
          marginRight={8}
          marginLeft="auto"
          onClick={() => {
            setPendingFilters({});
            close();
            onClearAll();
          }}
        >
          Clear all
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            onApply(pendingFilters);
            close();
          }}
        >
          Apply
        </Button>
      </Pane>
    </Pane>
  );
};

export default FilterMenu;
