import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_CREDENTIAL = gql`
  mutation CreateCredential($input: CreateCredentialForOrganizationInput!) {
    result: createCredentialForOrganization(input: $input) {
      credential {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_CREDENTIAL = gql`
  mutation UpdateCredential($input: UpdateCredentialForOrganizationInput!) {
    result: updateCredentialForOrganization(input: $input) {
      credential {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const initialValues = {
  name: '',
  description: ''
};

const CreateOrUpdateCredential = ({
  organizationId,
  credential,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasCredential = credential && credential.id;
  const [createOrUpdateCredential, { loading }] = useMutation(
    hasCredential ? UPDATE_CREDENTIAL : CREATE_CREDENTIAL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasCredential
    ? sanitizeData(credential, ['versionCount', 'organization'])
    : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasCredential ? 'Update credential' : 'Create new credential'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={credential?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            // We only want to add the organizationId to the case where we're creating
            // new credentials and not when we're editing an existing credential.
            const variables = hasCredential
              ? input
              : { ...input, organizationId };

            setErrors({});
            createOrUpdateCredential({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateCredential;
