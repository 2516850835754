import React from 'react';
import { Pane } from 'evergreen-ui';

const ContentPanel = ({ children }: any) => (
  <Pane
    background="white"
    display="flex"
    flexDirection="column"
    flex={1}
    padding={24}
    paddingBottom={64}
    height="100%"
    overflow="scroll"
  >
    <Pane>{children}</Pane>
  </Pane>
);

export default ContentPanel;
