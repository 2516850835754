import * as Yup from 'yup';
import { Roles } from '@/constants/hospitals';
import { PrimaryTerms } from '@/constants/aliases';
import { MedicationRoutes } from '@/constants/medications';
import {
  Sex,
  CalendarUnits,
  WeightUnits,
  PatientType
} from 'constants/units.ts';

const StringAliasSchema = Yup.object().shape({
  key: Yup.string().required(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this term')
});

const SystemAliasSchema = Yup.object().shape({
  references: Yup.string().required(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this system term')
});

const allTerms = Object.keys(PrimaryTerms).map(term => `SYSTEM_TERM.${term}`);

const medicationRoutes = Object.keys(MedicationRoutes).map(
  term => `MEDICATION_ROUTE.${term}`
);

const hospitalRoles = Object.keys(Roles).map(term => `HOSPITAL_ROLE.${term}`);

const sex = Object.keys(Sex).map(term => `SEX.${term}`);

const calendarUnits = Object.keys(CalendarUnits).map(
  term => `CALENDAR_UNIT.${term}`
);

const weightUnits = Object.keys(WeightUnits).map(term => `WEIGHT_UNIT.${term}`);

const patientTypes = Object.keys(PatientType).map(
  term => `PATIENT_TYPE.${term}`
);

const SystemTerms = []
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(medicationRoutes)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(hospitalRoles)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(sex)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(calendarUnits)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(weightUnits)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(patientTypes)
  // @ts-expect-error TS(2769): No overload matches this call.
  .concat(allTerms)
  .map(item => ({ label: item, value: item }));

export const StringForm = {
  name: 'stringAlias',
  validationSchema: StringAliasSchema,
  fields: [
    {
      name: 'key',
      label: 'Term',
      placeholder: '',
      required: true
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    }
  ]
};

export const SystemForm = {
  name: 'stringAlias',
  validationSchema: SystemAliasSchema,
  fields: [
    {
      name: 'references',
      label: 'References',
      type: 'combobox',
      values: SystemTerms,
      required: true
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    }
  ]
};
