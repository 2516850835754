import React from 'react';
import { Popover, Position, TextDropdownButton, Menu } from 'evergreen-ui';

export const Order = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export function getIconForOrder(order: any) {
  switch (order) {
    case Order.ASC:
      return 'arrow-up';
    case Order.DESC:
      return 'arrow-down';
    default:
      return 'caret-down';
  }
}

const SortOrder = ({ label, ordering, column, onChange }: any) => (
  <Popover
    position={Position.BOTTOM_LEFT}
    content={({ close }: any) => (
      <Menu>
        <Menu.OptionsGroup
          title="Order"
          options={[
            { label: 'Ascending', value: Order.ASC },
            { label: 'Descending', value: Order.DESC }
          ]}
          selected={ordering.column === column && ordering.direction}
          onChange={(direction: any) => {
            onChange({ column, direction });
            close();
          }}
        />
      </Menu>
    )}
  >
    <TextDropdownButton
      icon={
        ordering.column === column
          ? getIconForOrder(ordering.direction)
          : 'caret-down'
      }
    >
      {label}
    </TextDropdownButton>
  </Popover>
);

export default SortOrder;
