import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';
import { CREDENTIAL_LIST } from './queries';

export const listRequestVariables = (organization: any) => ({
  first: 999,
  organizationId: organization.id
});

const CredentialList = ({
  organization,
  isViewOnly,
  onEditClick,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(CREDENTIAL_LIST, {
    variables: listRequestVariables(organization),
    fetchPolicy: 'network-only'
  });

  const credentials = get(data, 'organization.credentials.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Updated</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <>
              <Table.Body>
                {/* @ts-expect-error TS(7053) */}
                {credentials.map(({ node: credential }) => (
                  <Table.Row key={credential.id} isSelectable>
                    <Table.Cell>
                      <Pane>
                        <Text size={300} fontWeight={500} display="block">
                          {credential.name}
                        </Text>
                        <Text size={300}>{credential.organization.name}</Text>
                      </Pane>
                    </Table.Cell>
                    <Table.TextCell>{credential.description}</Table.TextCell>
                    <Table.Cell>
                      <RelativeDate date={credential.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onEditClick={() => onEditClick(credential)}
                            onDeleteClick={() => onDeleteClick(credential)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                          disabled={isViewOnly}
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && credentials.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default CredentialList;
