import React from 'react';
import { every, includes } from 'lodash';
import {
  Table,
  Text,
  Pane,
  Icon,
  IconButton,
  Popover,
  Position,
  Badge,
  Pill
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EntryCriteria from 'components/shared/EntryCriteria';
import Menu from './Menu';
import { DRAG_WIDTH, STEP_WIDTH } from './ProtocolStepList';
import ProtocolStepContents from './ProtocolStepContents';

const ProtocolStepRow = ({
  provided,
  idx,
  organization,
  protocol,
  protocolSet,
  step,
  isReordering,
  selectedCredentials,
  onEditClick,
  onDeleteClick,
  onDuplicateClick,
  onViewSourceClick,
  isDisabled,
  isViewOnly
}: any) => {
  const { doses, entryCriteria } = step;
  const totalDoses = doses.length;

  return (
    <Table.Row
      height="auto"
      minHeight={56}
      key={step.id}
      isSelectable
      innerRef={provided.innerRef}
      opacity={isReordering || isDisabled ? 0.5 : 1}
      background="white"
      onDoubleClick={() =>
        !isDisabled && !isViewOnly ? onEditClick(step) : null
      }
      {...provided.draggableProps}
    >
      <Table.Cell flex="none" width={DRAG_WIDTH}>
        <span {...provided.dragHandleProps}>
          <Icon icon="drag-handle-vertical" />
        </span>
        <Pill color="neutral" isSolid marginLeft={4}>
          {idx}
        </Pill>
      </Table.Cell>
      <Table.Cell flex="none" width={STEP_WIDTH}>
        <ProtocolStepContents
          organization={organization}
          protocol={protocol}
          protocolSet={protocolSet}
          step={step}
        />
      </Table.Cell>
      <Table.Cell>
        <Pane display="flex" flexWrap="wrap" alignItems="center">
          {totalDoses > 0 &&
            every(doses, dose => dose.requiresMedicalControlAuthorization) && (
              <Badge color="orange" marginRight={8}>
                Med Con
              </Badge>
            )}
          {entryCriteria && <EntryCriteria {...entryCriteria} />}
        </Pane>
      </Table.Cell>
      <Table.Cell>
        <Pane paddingTop={4} paddingBottom={4}>
          {step.credentials.map((
            credential: any // Highlight the credential thats selected
          ) => (
            <Text size={300} key={credential.id}>
              {includes(selectedCredentials, credential.name) ? (
                <strong>{credential.name}</strong>
              ) : (
                credential.name
              )}
              <br />
            </Text>
          ))}
        </Pane>
      </Table.Cell>
      <Table.Cell width={120} flex="none">
        <RelativeDate date={step.updatedAt} />
      </Table.Cell>
      <Table.Cell width={48} flex="none">
        <Popover
          content={
            <Menu
              onEditClick={() => onEditClick(step)}
              onDuplicateClick={() => onDuplicateClick(step)}
              onDeleteClick={() => onDeleteClick(step)}
              onViewSourceClick={() => onViewSourceClick(step)}
              isViewOnly={isViewOnly}
            />
          }
          position={Position.BOTTOM_RIGHT}
        >
          <IconButton
            disabled={isDisabled}
            icon="more"
            height={24}
            appearance="minimal"
          />
        </Popover>
      </Table.Cell>
    </Table.Row>
  );
};

export default ProtocolStepRow;
