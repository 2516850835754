import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_CAPABILITY = gql`
  mutation CreateHospitalCapability(
    $input: CreateHospitalCapabilityForOrganizationInput!
  ) {
    result: createHospitalCapabilityForOrganization(input: $input) {
      hospitalCapability {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_CAPABILITY = gql`
  mutation UpdateHospitalCapability(
    $input: UpdateHospitalCapabilityForOrganizationInput!
  ) {
    result: updateHospitalCapabilityForOrganization(input: $input) {
      hospitalCapability {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const initialValues = {
  name: '',
  aliases: []
};

const CreateOrUpdateHospitalCapability = ({
  organizationId,
  capability,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasCapability = capability && capability.id;
  const [createOrUpdateCapability, { loading }] = useMutation(
    hasCapability ? UPDATE_CAPABILITY : CREATE_CAPABILITY,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasCapability ? sanitizeData(capability) : initialValues;

  // @ts-expect-error TS(2531): Object is possibly 'null'.
  if (formData.parent) {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    formData.parent = formData.parent.id;
  }

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasCapability ? 'Update capability' : 'Create new capability'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={capability?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form(organizationId)}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            const variables = hasCapability
              ? input
              : { ...input, organizationId };

            setErrors({});
            createOrUpdateCapability({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateHospitalCapability;
