import React, { useState } from 'react';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Pane, Heading, Spinner, Paragraph } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';
import { GET_CONFIG, UPDATE_CONFIG } from './queries';

const UpdateOrgConfig = ({ organizationId, onComplete }: any) => {
  const [errors, setErrors] = useState({});
  const { data, loading } = useQuery(GET_CONFIG, {
    fetchPolicy: 'network-only',
    variables: { organizationId }
  });

  const [updateConfig, { loading: isUpdating }] = useMutation(UPDATE_CONFIG, {
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  const config = get(data, 'organization.configuration');
  const formData = config ? sanitizeData(config, ['isEditable']) : {};

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>Update Configuration</Heading>
      </Pane>
      <Pane padding={16}>
        {config && !config.isEditable && (
          <Paragraph size={300} color="muted" marginBottom={16}>
            This configuration is inherited and cannot be edited
          </Paragraph>
        )}

        {loading ? (
          <Spinner size={16} />
        ) : (
          <FormBuilder
            key={data.organization.id ?? -1}
            isSubForm
            initialValues={formData}
            form={Form}
            errors={errors}
            isLoading={isUpdating}
            disabled={!config.isEditable}
            onSubmit={(input: any) => {
              setErrors({});

              const variables = {
                input: {
                  ...input,
                  id: organizationId
                }
              };

              updateConfig({ variables });
            }}
          />
        )}
      </Pane>
    </Pane>
  );
};

export default UpdateOrgConfig;
