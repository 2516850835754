import gql from 'graphql-tag';
import {
  AmendmentFragment,
  ProtocolSetFragment,
  PageInfoFragment
} from 'components/shared/fragments';

export const PROTOCOL_SET_LIST = gql`
  query ProtocolSetList(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationProtocolSetsInput
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      protocolSets(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...ProtocolSet
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${ProtocolSetFragment}
  ${AmendmentFragment}
`;
