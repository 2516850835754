import pluralize from 'pluralize';
import isFuture from 'date-fns/is_future';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

export function seatsRemaining(sub: any) {
  if (!sub) {
    return 0;
  }
  const { totalSeatCount, pendingInviteCount, occupiedSeatCount } = sub;
  return totalSeatCount - (pendingInviteCount + occupiedSeatCount);
}

export const PlanIntervalLabels = {
  MONTH: 'month',
  YEAR: 'year'
};

export const formatCents = (cents: any, forcePrecision = false) => {
  const dollars = cents / 100;

  if (forcePrecision) {
    return dollars.toFixed(2);
  }

  return dollars % 1 === 0 ? dollars : dollars.toFixed(2);
};

export const SubscriptionStates = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  INACTIVE: 'INACTIVE'
};

export const SubscriptionStatesToColors = {
  [SubscriptionStates.ACTIVE]: 'green',
  [SubscriptionStates.CANCELLED]: 'orange',
  [SubscriptionStates.INACTIVE]: 'red'
};

export const SubscriptionStatesToText = {
  [SubscriptionStates.ACTIVE]: 'Active',
  [SubscriptionStates.CANCELLED]: 'Cancelled',
  [SubscriptionStates.INACTIVE]: 'Past due'
};

export function subscriptionStatus(subscription: any) {
  if (!subscription) return SubscriptionStates.ACTIVE;
  if (subscription.cancelledAt) return SubscriptionStates.CANCELLED;
  if (subscription.delinquentAt) return SubscriptionStates.INACTIVE;

  return SubscriptionStates.ACTIVE;
}

export function trialDaysRemaining(subscription: any) {
  const { trialEndAt } = subscription;

  if (!trialEndAt || !isFuture(trialEndAt)) return null;

  return differenceInCalendarDays(trialEndAt, new Date());
}

// TODO: Remove this once COVID-19 free trial is over
export const IS_COVID_FREE_TRIAL = true;

export function trialDurationMessage(plan: any) {
  if (IS_COVID_FREE_TRIAL) {
    return 'Free due to the COVID-19 pandemic';
  }

  return `Try now: get ${plan.trialPeriodDays} days free`;
}

export function trialRemainingMessage(subscription: any) {
  if (IS_COVID_FREE_TRIAL) {
    return "You're on a free trial during the COVID-19 pandemic";
  }

  const trialDays = trialDaysRemaining(subscription);
  // @ts-expect-error Invalid trialDays is not number
  return `${pluralize('day', trialDays, true)} left on your trial`;
}
