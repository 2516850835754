import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import LoadingScreen from 'components/shared/LoadingScreen';
import { ProtocolFragment } from 'components/shared/fragments';
import {
  ListPage as ListProtocolSteps,
  CreatePage as CreateProtocolStep,
  EditPage as EditProtocolStep
} from './ProtocolSteps';
import ListReferencingProtocols from './ListReferencingProtocols';
import ListSubprotocols from './ListSubprotocols';

const PROTOCOL = gql`
  query ProtocolNode($id: ID!, $organizationId: ID!) {
    organization(id: $organizationId) {
      id
      protocol: node(id: $id, type: PROTOCOL) {
        ... on Protocol {
          ...Protocol
        }
      }
    }
  }
  ${ProtocolFragment}
`;

export default ({ match, organization, isViewOnly, protocolSet }: any) => {
  const { url, params } = match;
  const { id: protocolId } = params;
  const { data, loading } = useQuery(PROTOCOL, {
    variables: { id: protocolId, organizationId: organization.id },
    fetchPolicy: 'network-only'
  });

  const protocol =
    data && data.organization.protocol ? data.organization.protocol : {};

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Switch>
        <AuthenticatedRoute
          exact
          path={`${url}/steps/new`}
          protocolSet={protocolSet}
          protocol={protocol}
          organization={organization}
          component={CreateProtocolStep}
          isViewOnly={isViewOnly}
        />
        <AuthenticatedRoute
          exact
          path={`${url}/steps/:id`}
          protocolSet={protocolSet}
          protocol={protocol}
          organization={organization}
          component={EditProtocolStep}
          isViewOnly={isViewOnly}
        />
      </Switch>
      <AuthenticatedRoute
        exact
        path={`${url}/referencing-protocols`}
        protocolSet={protocolSet}
        protocol={protocol}
        organization={organization}
        component={ListReferencingProtocols}
        isViewOnly={isViewOnly}
      />
      <AuthenticatedRoute
        exact
        path={`${url}/subprotocols`}
        protocolSet={protocolSet}
        protocol={protocol}
        organization={organization}
        component={ListSubprotocols}
        isViewOnly={isViewOnly}
      />
      <AuthenticatedRoute
        exact
        path={`${url}`}
        protocolSet={protocolSet}
        protocol={protocol}
        organization={organization}
        component={ListProtocolSteps}
        isViewOnly={isViewOnly}
      />
    </>
  );
};
