import React from 'react';
import { Menu } from 'evergreen-ui';

const SubscriptionMenu = ({ onViewClick, onLeaveClick }: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item icon="document-open" onSelect={onViewClick}>
          Go to organization
        </Menu.Item>
      </Menu.Group>
      {onLeaveClick && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onLeaveClick}>
              Leave organization
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default SubscriptionMenu;
