import React, { ComponentType, useState } from 'react';
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from 'react-stripe-elements';
import {
  Button,
  Heading,
  FormFieldValidationMessage,
  Pane,
  Paragraph
} from 'evergreen-ui';
// @ts-expect-error ElementClass
import ElementClass = React.JSX.ElementClass;

const styles = {
  base: {
    fontSize: '14px',
    color: '#424770',
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    letterSpacing: '0.025em',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#c23d4b'
  }
};

const CardForm = ({
  stripe,
  elements,
  handleResult,
  isLoading,
  subtitle,
  buttonTitle = 'Save',
  buttonProps = {}
}: any) => {
  const [isStripeLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const handleChange = ({
    error,
    complete,
    // @ts-expect-error TS(6133): 'rest' is declared but its value is never read.
    ...rest
  }: any) => {
    setError(error);
    setStatus(complete);
  };

  return (
    <Pane
      is="form"
      display="flex"
      flexDirection="column"
      onSubmit={(evt: any) => {
        evt.preventDefault();
        if (stripe) {
          setIsLoading(true);

          stripe
            .createPaymentMethod({
              type: 'card',
              card: elements.getElement('card')
            })
            .then((result: any) => {
              setIsLoading(false);
              return result;
            })
            .then(handleResult);
        }
      }}
    >
      <Heading size={400} marginBottom={8}>
        Card Information
      </Heading>

      <Pane border padding={8} width="100%">
        <CardElement onChange={handleChange} style={styles} />
      </Pane>

      {error && (
        <FormFieldValidationMessage marginTop={8}>
          {/* @ts-expect-error TS(2339): Property 'message' does not exist on type 'never'. */}
          {error.message}
        </FormFieldValidationMessage>
      )}

      {subtitle && (
        <Paragraph size={300} marginTop={16} color="muted">
          {subtitle}
        </Paragraph>
      )}

      <Button
        marginTop={16}
        type="submit"
        appearance="primary"
        disabled={!status}
        isLoading={isStripeLoading || isLoading}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
    </Pane>
  );
};

const CardFormWithStripe = injectStripe(CardForm) as ComponentType;

const StripeCreditCardForm = (props: any) => (
  <StripeProvider apiKey={String(import.meta.env.VITE_STRIPE_PUBLIC_KEY)}>
    <Elements>
      <CardFormWithStripe {...props} />
    </Elements>
  </StripeProvider>
);

export default StripeCreditCardForm;
