import React, { useState } from 'react';
import { Heading, Pane, Button, toaster } from 'evergreen-ui';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import DeleteDialog from 'components/shared/DeleteDialog';
import EquipmentFunctionList from 'components/organizations/EquipmentFunctionList';
import CreateOrUpdateFunction from 'components/organizations/CreateOrUpdateEquipmentFunction';
import { EQUIPMENT_FUNCTION_LIST } from 'components/organizations/EquipmentFunctionList/EquipmentFunctionList';

const DELETE_FUNCTION = gql`
  mutation DeleteFunction($input: DeleteFunctionForEquipmentInput!) {
    result: deleteFunctionForEquipment(input: $input) {
      equipmentFunction {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ equipment, history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const refetchQuery = {
    query: EQUIPMENT_FUNCTION_LIST,
    variables: {
      equipmentId: equipment.id,
      first: 999
    }
  };

  const [deleteFunction, { loading: isDeleteLoading }] = useMutation(
    DELETE_FUNCTION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.equipmentFunction) {
          toaster.success('Equipment function has been successfully deleted');
        } else {
          toaster.danger('Could not delete equipment function', {
            description:
              'Make sure the function is not referenced in any doses and try again'
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title={`Manage functions for the ${equipment.name}`}
        subtitle={`
      Functions represent the pieces of behavior that this piece of
      equipment can perform. Examples include 'ventillation' or
      'defibrillation'. Only published functions can be referenced in a
      dose, and similarly only published functions will appear in the app
      for medics.
      `}
        renderButtons={
          <Button
            iconBefore="add"
            marginLeft="auto"
            alignSelf="flex-start"
            // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            onClick={() => setShowForm({})}
          >
            New Function
          </Button>
        }
      />

      <EquipmentFunctionList
        organization={organization}
        equipment={equipment}
        onEditClick={(equipmentFunction: any) => setShowForm(equipmentFunction)}
        onListDosesClick={(equipmentFunction: any) =>
          history.push(
            `/orgs/${organization.slug}/equipment/${equipment.id}/functions/${equipmentFunction.id}/doses`
          )
        }
        onDeleteClick={(equipmentFunction: any) =>
          setShowDeleteDialog(equipmentFunction)
        }
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <Pane display="flex" flexDirection="column" flex="1">
          <Pane padding={16} borderBottom="muted" display="flex">
            <Heading size={500}>Manage Function</Heading>
          </Pane>
          <Pane padding={16}>
            <CreateOrUpdateFunction
              equipmentId={equipment.id}
              equipmentFunction={showForm || {}}
              refetchQuery={refetchQuery}
              onComplete={() => {
                toaster.success('Function successfully saved');
                setShowForm(false);
              }}
            />
          </Pane>
        </Pane>
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteFunction({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
