import React from 'react';
import { Pane } from 'evergreen-ui';
import AppPrompt from 'components/accounts/OrgSelector/AppPrompt';

const ContinueInApp = () => (
  <Pane
    background="white"
    position="absolute"
    top={0}
    left={0}
    bottom={0}
    right={0}
    display="flex"
    overflowY="scroll"
  >
    <AppPrompt />
  </Pane>
);

export default ContinueInApp;
