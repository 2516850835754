import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FormBuilder from 'components/shared/FormBuilder';
import { InputErrorFragment } from 'components/shared/fragments';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { normalizeDoseData } from 'lib/dosing';
import { Form } from './form';

const CREATE_PROTOCOL_STEP = gql`
  mutation CreateProtocolStep($input: CreateStepForProtocolInput!) {
    result: createStepForProtocol(input: $input) {
      protocolStep {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_PROTOCOL_STEP = gql`
  mutation UpdateProtocolStep($input: UpdateStepForProtocolInput!) {
    result: updateStepForProtocol(input: $input) {
      protocolStep {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const CreateOrUpdateProtocolStep = ({
  protocolId,
  protocolSetId,
  organizationId,
  canSetPatientType,
  stepType,
  protocolStep,
  initialValues,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasProtocolStep = protocolStep && protocolStep.id;
  const [createOrUpdateProtocolStep, { loading }] = useMutation(
    hasProtocolStep ? UPDATE_PROTOCOL_STEP : CREATE_PROTOCOL_STEP,
    {
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  let formData = hasProtocolStep
    ? sanitizeData(protocolStep, ['lastPublishedAt', 'order'])
    : initialValues;

  if (formData.source) {
    formData.source = formData.source.id;
  }

  if (formData.doses) {
    formData.doses = normalizeDoseData(protocolStep.doses);
  }

  if (formData.referredProtocol) {
    formData.referredProtocol = formData.referredProtocol.id;
  }

  if (formData.subprotocol) {
    formData.subprotocol = formData.subprotocol.id;
  }

  if (formData.credentials) {
    formData.credentials = formData.credentials.map(({ id }: any) => id);
  }

  return (
    <FormBuilder
      key={protocolStep?.id ?? -1}
      initialValues={formData}
      form={Form({
        organizationId,
        protocolSetId,
        protocolId,
        canSetPatientType,
        stepType
      })}
      errors={errors}
      isLoading={loading}
      onSubmit={(input: any) => {
        const variables = hasProtocolStep ? input : { ...input, protocolId };

        setErrors({});
        createOrUpdateProtocolStep({
          variables: { input: variables }
        });
      }}
    />
  );
};

export default CreateOrUpdateProtocolStep;
