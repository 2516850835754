import gql from 'graphql-tag';
import {
  MedicationStorageMethodFragment,
  InputErrorFragment
} from 'components/shared/fragments';

const MedicationInventoryQuestionFragment = gql`
  fragment MedicationInventoryProfileQuestion on MedicationInventoryProfileQuestion {
    isCarried
    medication {
      id
      brandName
      genericName
    }
    storageOptions {
      ...MedicationStorageMethod
    }
  }
  ${MedicationStorageMethodFragment}
`;

const EquipmentInventoryQuestionFragment = gql`
  fragment EquipmentInventoryProfileQuestion on EquipmentInventoryProfileQuestion {
    equipment {
      id
      name
    }
    options {
      id
      name
      manufacturer {
        name
      }
    }
  }
`;

const MedicationInventoryProfileEntryFragment = gql`
  fragment MedicationInventoryProfileEntry on MedicationInventoryProfileEntry {
    id
    isCarried
    medication {
      brandName
      genericName
    }
    author {
      name
      email
    }
    version
    storageMethods {
      ...MedicationStorageMethod
    }
    insertedAt
  }
  ${MedicationStorageMethodFragment}
`;

const EquipmentInventoryProfileEntryFragment = gql`
  fragment EquipmentInventoryProfileEntry on EquipmentInventoryProfileEntry {
    id
    genericEquipment {
      name
    }
    equipment {
      name
      manufacturer {
        name
      }
    }
    author {
      name
      email
    }
    version
    insertedAt
  }
`;

export const GENERATE_QUESTIONS = gql`
  query GenerateInventoryQuestions(
    $organizationId: ID!
    $ignoreMedicationIds: [ID!]
    $ignoreEquipmentIds: [ID!]
  ) {
    generateInventoryProfileQuestions(
      organizationId: $organizationId
      ignoreMedicationIds: $ignoreMedicationIds
      ignoreEquipmentIds: $ignoreEquipmentIds
    ) {
      questions {
        ... on MedicationInventoryProfileQuestion {
          ...MedicationInventoryProfileQuestion
        }
        ... on EquipmentInventoryProfileQuestion {
          ...EquipmentInventoryProfileQuestion
        }
      }
      progress {
        percentComplete
      }
    }
  }
  ${MedicationInventoryQuestionFragment}
  ${EquipmentInventoryQuestionFragment}
`;

export const CREATE_MEDICATION_INVENTORY_PROFILE_ENTRY = gql`
  mutation CreateMedicationInventoryProfileEntry(
    $input: CreateMedicationInventoryProfileEntryInput!
  ) {
    result: createMedicationInventoryProfileEntry(input: $input) {
      errors {
        ...InputError
      }
      entry {
        id
      }
    }
  }
  ${InputErrorFragment}
`;

export const CREATE_EQUIPMENT_INVENTORY_PROFILE_ENTRY = gql`
  mutation CreateEquipmentInventoryProfileEntry(
    $input: CreateEquipmentInventoryProfileEntryInput!
  ) {
    result: createEquipmentInventoryProfileEntry(input: $input) {
      errors {
        ...InputError
      }
      entry {
        id
      }
    }
  }
  ${InputErrorFragment}
`;

export const GENERATE_QUESTION_FOR_ENTRY = gql`
  query GenerateInventoryQuestionForEntry(
    $entryType: InventoryProfileEntryType!
    $entryId: ID!
  ) {
    generateInventoryProfileQuestionFromEntry(
      entryId: $entryId
      entryType: $entryType
    ) {
      entry {
        ... on MedicationInventoryProfileEntry {
          ...MedicationInventoryProfileEntry
        }
        ... on EquipmentInventoryProfileEntry {
          ...EquipmentInventoryProfileEntry
        }
      }
      question {
        ... on MedicationInventoryProfileQuestion {
          ...MedicationInventoryProfileQuestion
        }
        ... on EquipmentInventoryProfileQuestion {
          ...EquipmentInventoryProfileQuestion
        }
      }
    }
  }
  ${MedicationInventoryQuestionFragment}
  ${EquipmentInventoryQuestionFragment}
  ${MedicationInventoryProfileEntryFragment}
  ${EquipmentInventoryProfileEntryFragment}
`;

export const LIST_ENTRIES = gql`
  query InventoryProfileEntriesForOrganization($organizationId: ID!) {
    inventoryProfileEntriesForOrganization(organizationId: $organizationId) {
      ... on MedicationInventoryProfileEntry {
        ...MedicationInventoryProfileEntry
      }
      ... on EquipmentInventoryProfileEntry {
        ...EquipmentInventoryProfileEntry
      }
    }
  }
  ${MedicationInventoryProfileEntryFragment}
  ${EquipmentInventoryProfileEntryFragment}
`;

export const DELETE_PROFILE_ENTRY = gql`
  mutation DeleteInventoryProfileEntry(
    $input: DeleteInventoryProfileEntryInput!
  ) {
    result: deleteInventoryProfileEntry(input: $input) {
      entry {
        ... on MedicationInventoryProfileEntry {
          ...MedicationInventoryProfileEntry
        }
        ... on EquipmentInventoryProfileEntry {
          ...EquipmentInventoryProfileEntry
        }
      }
      errors {
        ...InputError
      }
    }
  }
  ${MedicationInventoryProfileEntryFragment}
  ${EquipmentInventoryProfileEntryFragment}
  ${InputErrorFragment}
`;
