import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { EquipmentFragment } from 'components/shared/fragments';

const PUBLISH_EQUIPMENT = gql`
  mutation PublishEquipment($input: PublishEquipmentForOrganizationInput!) {
    result: publishEquipmentForOrganization(input: $input) {
      equipment {
        ...Equipment
      }
    }
  }
  ${EquipmentFragment}
`;

const PublishEquipmentDialog = ({
  isShown,
  equipment,
  onCloseComplete
}: any) => {
  const [publishHospital, { loading }] = useMutation(PUBLISH_EQUIPMENT, {
    variables: { input: { id: equipment.id } },
    onCompleted: (response: any) => {
      if (response.result.equipment) {
        toaster.success(`${equipment.name} successfully published!`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishHospital()}
    >
      <Paragraph>
        Publishing this equipment will make it available to{' '}
        <strong>all users of the app within this organization</strong>. Please
        only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishEquipmentDialog;
