import gql from 'graphql-tag';
import {
  InputErrorFragment,
  SubscriptionFragment
} from 'components/shared/fragments';

export const SUBSCRIPTIONS = gql`
  query ActiveSubscriptionsForUser {
    currentUser {
      id
      seats {
        id
        cancelAt
        cancelledAt
        organization {
          id
          name
          slug
          logo {
            url
          }
        }
        subscription {
          ...Subscription
        }
        roles {
          name
        }
        credentials {
          name
        }
      }
    }
  }
  ${SubscriptionFragment}
`;

export const CREDIT_CARDS_FOR_USER = gql`
  query CreditCardsForUser {
    creditCardsForUser {
      brand
      last4
      expMonth
      expYear
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscriptionForIndividual(
    $input: CancelSubscriptionForIndividualInput
  ) {
    cancelSubscriptionForIndividual(input: $input) {
      errors {
        ...InputError
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;

export const REMOVE_INDIVIDUAL_FROM_ORG = gql`
  mutation RemoveOrgAccessFromIndividual(
    $input: RemoveOrganizationAccessFromIndividualInput!
  ) {
    result: removeOrganizationAccessFromIndividuall(input: $input) {
      errors {
        ...InputError
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;
