import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Pane, Heading, Text, Paragraph, Link } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import BrandedLayout from 'components/shared/BrandedLayout';
import StyledLogo from 'components/shared/Layout/MuruLogo';
import ForgotPasswordForm from 'components/accounts/ForgotPasswordForm';

function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <BrandedLayout>
      <Pane width={'100%'} maxWidth={380} textAlign="center">
        <StyledLogo />
        <Helmet>
          <title>{t('forgot_password.title')}</title>
        </Helmet>

        <Heading size={600}>{t('forgot_password.welcome')}</Heading>
        <Text color="muted">
          If you can't remember your password, enter your email and we'll send
          you instructions on how to reset it.
        </Text>

        <ForgotPasswordForm data-testid="signup-form" />

        <Paragraph color="muted" marginTop={16}>
          Already have an account?{' '}
          <Link is={RouterLink} to="/login">
            Login
          </Link>
        </Paragraph>
      </Pane>
    </BrandedLayout>
  );
}

export default ForgotPassword;
