import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { OrganizationFragment } from 'components/shared/fragments';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const CurrentOrganizationStateContext = React.createContext();
// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const CurrentOrganizationDispatchContext = React.createContext();

function CurrentOrganizationProvider({ children }: any) {
  const [organizationId, setOrganizationId] = React.useState(null);
  const client = useApolloClient();

  const organization =
    organizationId &&
    client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: OrganizationFragment,
      fragmentName: 'Organization'
    });

  return (
    <CurrentOrganizationStateContext.Provider value={organization}>
      <CurrentOrganizationDispatchContext.Provider value={setOrganizationId}>
        {children}
      </CurrentOrganizationDispatchContext.Provider>
    </CurrentOrganizationStateContext.Provider>
  );
}

function useCurrentOrganization() {
  const context = React.useContext(CurrentOrganizationStateContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentOrganization must be used within a CurrentOrganizationProvider'
    );
  }

  return context;
}

function useCurrentOrganizationDispatch() {
  const context = React.useContext(CurrentOrganizationDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentOrganizationDispatch must be used within a CurrentOrganizationProvider'
    );
  }

  return context;
}

export {
  CurrentOrganizationProvider,
  useCurrentOrganization,
  useCurrentOrganizationDispatch
};
