import gql from 'graphql-tag';
import {
  InputErrorFragment,
  ProtocolFragment
} from 'components/shared/fragments';

export const CREATE_PROTOCOL = gql`
  mutation CreateProtocol($input: CreateProtocolForProtocolSetInput!) {
    result: createProtocolForProtocolSet(input: $input) {
      protocol {
        ...Protocol
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
  ${ProtocolFragment}
`;

export const UPDATE_PROTOCOL = gql`
  mutation UpdateProtocol($input: UpdateProtocolForProtocolSetInput!) {
    result: updateProtocolForProtocolSet(input: $input) {
      protocol {
        ...Protocol
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
  ${ProtocolFragment}
`;
