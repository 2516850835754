import React, { useState } from 'react';
import queryString from 'query-string';
import { toaster, Button } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import DeleteDialog from 'components/shared/DeleteDialog';
import PageHeader from 'components/shared/PageHeader';
import HospitalsList, { LIST_HOSPITALS } from 'components/admin/HospitalsList';
import {
  withDefaultPageInfo,
  parseFilters,
  onPageInfoUpdate,
  onFilterUpdate
} from 'lib/pagination';
import { CREATE_HOSPITAL_PATH } from '../paths';

const DELETE_HOSPITAL = gql`
  mutation DeleteHospital($input: DeleteHospitalInput!) {
    result: deleteHospital(input: $input) {
      hospital {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters
  };

  const refetchQuery = {
    variables,
    query: LIST_HOSPITALS
  };

  const [deleteHospital, { loading: isDeleteLoading }] = useMutation(
    DELETE_HOSPITAL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.hospital) {
          toaster.success('Hospital has been successfully deleted');
        } else {
          toaster.danger('Could not delete hospital, please try again');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage hospitals"
        renderButtons={
          <Button
            iconBefore="add"
            marginLeft="auto"
            onClick={() => history.push(CREATE_HOSPITAL_PATH)}
          >
            New hospital
          </Button>
        }
      />

      <HospitalsList
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onDeleteClick={(hospital: any) => setShowDeleteDialog(hospital)}
        onEditClick={(hospital: any) =>
          history.push(`/admin/hospitals/${hospital.id}`)
        }
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteHospital({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
