import React from 'react';
import { Pane, Heading, IconButton } from 'evergreen-ui';
import { MemoryRouter, Route } from 'react-router-dom';
import ZipEntry from './ZipEntry';
import SelectAgency from './SelectAgency';
import Review from './Review';
import Role from './Role';
import Complete from './Complete';
import Waitlist from './Waitlist';
import NotEligible from './NotEligible';
import WaitlistThanks from './WaitlistThanks';

const AddOrganizationFlow = ({
  isShown,
  onClose,
  seats,
  subscription
}: any) => {
  return (
    <Pane
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      background="white"
      zIndex={2}
      display="flex"
      flexDirection="column"
      opacity={isShown ? 1 : 0}
      transition="opacity 300ms"
      visibility={isShown ? 'visible' : 'hidden'}
    >
      <Pane
        borderBottom
        padding={16}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Heading size={500}>Add another agency</Heading>
        <IconButton
          icon="cross"
          height={40}
          appearance="minimal"
          marginLeft="auto"
          onClick={onClose}
        />
      </Pane>
      <Pane flex="1" display="flex" alignItems="center" justifyContent="center">
        <Pane maxWidth={420} width="100%" padding={16}>
          {isShown && (
            <MemoryRouter initialIndex={0}>
              <Route
                exact
                path="/"
                component={() => <ZipEntry subscription={subscription} />}
              />
              <Route exact path="/waitlist" component={Waitlist} />
              <Route
                exact
                path="/waitlist/thanks"
                component={() => <WaitlistThanks onCloseClick={onClose} />}
              />
              <Route exact path="/select-agency" component={SelectAgency} />
              <Route exact path="/not-eligible" component={NotEligible} />
              <Route exact path="/role" component={Role} />
              <Route
                exact
                path="/review"
                component={() => (
                  <Review seats={seats} subscription={subscription} />
                )}
              />
              <Route
                exact
                path="/complete"
                component={() => <Complete onCloseClick={onClose} />}
              />
            </MemoryRouter>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

export default AddOrganizationFlow;
