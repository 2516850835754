import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Pane, Heading, Paragraph, Link, Strong } from 'evergreen-ui';
import { useHistory, Redirect } from 'react-router-dom';
import SelectAgency from 'components/onboarding/SelectAgency';
import { zipCodeSelector, agenciesSelector } from 'redux/onboarding';
import { page, track } from 'lib/analytics';
import { launchIntercomWithMessage } from 'lib/analytics';

const AgencySelect = () => {
  const history = useHistory();
  const agencies = useSelector(agenciesSelector);
  const zipCode = useSelector(zipCodeSelector);

  useEffect(() => {
    page('Onboarding Select Agencies', {
      zip_code: zipCode && zipCode.zip_code
    });
  }, [zipCode]);

  if (!zipCode) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Pane>
      <Helmet>
        <title>Select your agency</title>
      </Helmet>
      <Pane marginBottom={24} textAlign="center">
        <Heading marginBottom={4} size={600}>
          Select Your First Agency
        </Heading>
        <Paragraph color="muted">
          Please select your first agency. You will be able to select additional
          agencies later. We found the following agencies that are eligible for
          Muru within{' '}
          <Strong>
            {zipCode.county}, {zipCode.state}
          </Strong>
          .
        </Paragraph>
      </Pane>

      <SelectAgency
        agencies={agencies}
        zipCode={zipCode}
        onSelect={(agency: any) => {
          track('Onboarding Selected Agency', {
            selected_agency_id: agency.id,
            selected_agency_name: agency.name,
            zip_code: zipCode.zip_code
          });

          history.push(`/onboarding/join/${agency.id}`);
        }}
      />

      <Paragraph color="muted" textAlign="center" size={300} margin={16}>
        If you don't see your agency in the above list,{' '}
        {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 0. */}
        <Link href="#" size={300} onClick={() => launchIntercomWithMessage()}>
          contact our support team
        </Link>{' '}
        and we can help.
      </Paragraph>
    </Pane>
  );
};

export default AgencySelect;
