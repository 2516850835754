import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'evergreen-ui';
import { GENERATE_LOGIN_TOKEN } from './queries';

const AppMagicLogin = ({ buttonTitle = 'Download the app' }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [generateLoginToken, { loading: isLoading }] = useMutation(
    GENERATE_LOGIN_TOKEN,
    {
      onCompleted: ({ result }: any) => {
        setIsRedirecting(true);

        if (result.token) {
          window.location.assign(
            `https://murumed.app.link/AT4plv8tf3?token=${result.token}`
          );
        } else {
          window.location.replace('https://murumed.app.link/AT4plv8tf3');
        }
      }
    }
  );

  return (
    <Button
      height={40}
      appearance="primary"
      width="100%"
      display="flex"
      justifyContent="center"
      marginTop={24}
      marginBottom={16}
      isLoading={isLoading || isRedirecting}
      onClick={() => generateLoginToken()}
    >
      {isRedirecting ? 'Redirecting you to Muru...' : buttonTitle}
    </Button>
  );
};

export default AppMagicLogin;
