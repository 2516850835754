import React from 'react';
import { Pane, toaster, BackButton } from 'evergreen-ui';
import PageHeader from 'components/shared/PageHeader';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LoadingScreen from 'components/shared/LoadingScreen';
import { HospitalFragment } from 'components/shared/fragments';
import { LIST_HOSPITALS_PATH } from '../paths';
import CreateOrUpdateHospital from 'components/admin/CreateOrUpdateHospital';

const HOSPITAL = gql`
  query Hospital($id: ID!) {
    hospital: node(id: $id, type: HOSPITAL) {
      ... on Hospital {
        ...Hospital
      }
    }
  }
  ${HospitalFragment}
`;

export default ({ match, history }: any) => {
  const { id: hospitalId } = match.params;
  const { data: hospitalData, loading } = useQuery(HOSPITAL, {
    variables: { id: hospitalId },
    fetchPolicy: 'network-only'
  });

  return (
    <>
      <PageHeader
        title="Update hospital"
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() => history.push(LIST_HOSPITALS_PATH)}
          />
        }
      />
      <Pane width="500px">
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <CreateOrUpdateHospital
              hospital={hospitalData && hospitalData.hospital}
              onComplete={() => {
                toaster.success('Hospital successfully updated');
                history.push(LIST_HOSPITALS_PATH);
              }}
            />
          </>
        )}
      </Pane>
    </>
  );
};
