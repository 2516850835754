import React from 'react';
import * as Yup from 'yup';
import HospitalCapabilitiesDataSource from 'components/shared/HospitalCapabilitiesDataSource';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  aliases: Yup.array().required()
});

export const Form = (organizationId: any) => ({
  name: 'hospitalCapability',
  validationSchema: Schema,

  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Adult Trauma 1',
      required: true
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    },
    {
      name: 'parent',
      label: 'Parent Capability',
      type: 'asyncSelect',
      description: 'The parent capability of this capability (if required)',
      placeholder: 'Select a parent',
      dataSource: (render: any) => (
        <HospitalCapabilitiesDataSource
          render={render}
          organizationId={organizationId}
        />
      )
    }
  ]
});
