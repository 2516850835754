import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SeatFragment } from 'components/shared/fragments';

const REMOVE_SEAT = gql`
  mutation UnassignSeat($input: UnassignSeatInput!) {
    unassignSeat(input: $input) {
      seat {
        ...Seat
      }
    }
  }
  ${SeatFragment}
`;

const RemoveMemberDialog = ({
  isShown,
  member,
  onCloseComplete,
  onComplete
}: any) => {
  const [removeMember, { loading }] = useMutation(REMOVE_SEAT, {
    variables: { input: { id: member.id } },
    onCompleted: (response: any) => {
      if (response.unassignSeat.seat) {
        toaster.success('Seat unassigned from organization');
        onComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      intent="danger"
      confirmLabel="Remove member"
      onConfirm={() => removeMember()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <Paragraph>
        Are you sure you want to remove this member from the organization? Doing
        so will mean they lose <strong>all access from the Muru app</strong>.
      </Paragraph>
    </Dialog>
  );
};

export default RemoveMemberDialog;
