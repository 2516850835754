import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Pane,
  Paragraph,
  Button,
  TextInputField,
  majorScale
} from 'evergreen-ui';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required()
});

const FormikForm = ({ login, loading }: any) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(variables: any) => {
        setError(null);
        // @ts-expect-error TS(6133): 'e' is declared but its value is never read.
        login({ variables }).catch((e: any) =>
          setError(t('login.errors.general'))
        );
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }: any) => (
        <Pane
          is={Form}
          marginTop={16}
          textAlign="left"
          data-testid="login-form"
          onSubmit={handleSubmit}
        >
          {error && (
            <Paragraph color="danger" marginBottom={majorScale(2)}>
              {error}
            </Paragraph>
          )}

          <TextInputField
            required
            isInvalid={!!errors.email}
            placeholder="john@socom.mil"
            type="email"
            name="email"
            label={t('common.email')}
            validationMessage={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            size={500}
            marginBottom={majorScale(2)}
          />

          <TextInputField
            required
            className="sensitive-data"
            isInvalid={!!errors.password}
            type="password"
            name="password"
            label={t('common.password')}
            validationMessage={
              errors.password && touched.password && errors.password
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            size={500}
            marginBottom={majorScale(2)}
          />

          <Button
            display="flex"
            width="100%"
            justifyContent="center"
            isLoading={loading}
            appearance="primary"
            type="submit"
            height={40}
          >
            {t('login.login')}
          </Button>
        </Pane>
      )}
    />
  );
};

export default FormikForm;
