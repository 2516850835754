import React from 'react';
import { Prompt } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Pane, Text } from 'evergreen-ui';
import { merge } from 'lodash';
import pluralize from 'pluralize';
import fieldType from './fieldType';
import { normalizeInputs } from 'lib/formHelpers';

const PromptIfDirty = ({ dirty, submitCount }: any) => {
  return (
    <Prompt
      when={dirty && submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes."
    />
  );
};

const ErrorMessage = ({ errorCount }: any) => (
  <Text color="danger" size={300} marginLeft="auto">
    There's {pluralize('error', errorCount, true)} with this form
  </Text>
);

const FormBuilder = ({
  isInline = false,
  isSubForm = false,
  isLoading = false,
  form,
  errors = {},
  parentValues = {},
  initialValues,
  saveButtonTitle = 'Save this item',
  saveButtonProps = {},
  normalizeValuesBeforeSend = true,
  showErrorHint = true,
  disabled = false,
  onSubmit
}: any) => (
  <Formik
    // We want to recursively normalize our values before they
    // go to the server, ensuring we're correctly dealing with
    // weird inputs.

    // Warning: this may be the cause of bugs!
    onSubmit={(values: any) =>
      onSubmit(normalizeValuesBeforeSend ? normalizeInputs(values) : values)
    }
    initialValues={initialValues}
    validationSchema={form.validationSchema}
    validateOnBlur={true}
    validateOnChange={false}
  >
    {(formikBag: any) => {
      const { handleSubmit, handleChange, handleBlur } = formikBag;
      const allErrors = merge({}, errors, formikBag.errors);
      const values = { ...formikBag.values, __parent: parentValues };
      const errorCount = Object.keys(allErrors).length;

      const absoluteFooterStyles = {
        padding: 16,
        paddingLeft: isSubForm ? 16 : 24,
        paddingRight: isSubForm ? 16 : 24,
        borderTop: true,
        background: 'tint1',
        position: isSubForm ? 'absolute' : 'fixed',
        bottom: 0,
        left: isSubForm ? 0 : 260,
        right: 0,
        display: 'flex',
        alignItems: 'center'
      };

      const relativeFooterStyles = {
        display: 'flex',
        alignItems: 'center'
      };

      return (
        <form
          autoComplete="off"
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <Pane paddingBottom={isInline ? 0 : 32}>
            {form.fields.map((field: any, index: any) => {
              const FieldComponent = fieldType(field.type);

              if (field.shownWhen && !field.shownWhen(values)) {
                return null;
              }

              return (
                <FieldComponent
                  {...field}
                  key={index}
                  formikBag={{
                    ...formikBag,
                    values,
                    errors: allErrors
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={!!field.required}
                  isInvalid={!!allErrors[field.name]}
                  value={values[field.name]}
                  validationMessage={allErrors[field.name]}
                />
              );
            })}
          </Pane>
          <PromptIfDirty {...formikBag} />
          <Pane {...(!isInline ? absoluteFooterStyles : relativeFooterStyles)}>
            <Button
              data-testid="form-submit-btn"
              type="submit"
              appearance="primary"
              intent="success"
              disabled={!formikBag.dirty || disabled}
              isLoading={isLoading === true}
              {...saveButtonProps}
            >
              {saveButtonTitle}
            </Button>

            {showErrorHint && errorCount > 0 && (
              <ErrorMessage errorCount={errorCount} />
            )}
          </Pane>
        </form>
      );
    }}
  </Formik>
);

export default FormBuilder;
