import React from 'react';
import { Pane, Select, TextInput, Paragraph, IconButton } from 'evergreen-ui';

const InviteField = ({
  name,
  email,
  role,
  credential,
  roleOptions,
  credentialOptions,
  onAddClick,
  onRemoveClick,
  handleChange,
  handleBlur,
  canRemove,
  canAdd,
  validationError,
  wasCreated
}: any) => {
  return (
    <Pane display="flex" flexDirection="column" marginBottom={8}>
      <Pane flex="1" display="flex" flexDirection="row" alignItems="center">
        <IconButton
          intent="success"
          appearance="minimal"
          icon="plus"
          type="button"
          marginRight={8}
          disabled={!canAdd}
          onClick={(e: any) => {
            e.preventDefault();
            onAddClick();
          }}
        />
        <TextInput
          required
          isInvalid={!!validationError}
          name={`${name}.email`}
          value={email}
          type="email"
          placeholder="email@example.com"
          onChange={handleChange}
          onBlur={handleBlur}
          width="50%"
        />
        <Select
          name={`${name}.roles[0]`}
          value={role}
          width="100px"
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
          <option value={null}>N/A</option>
          {roleOptions.map(({ label, value }: any) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <Select
          name={`${name}.credentials[0]`}
          value={credential}
          width="100px"
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
          <option value={null}>N/A</option>
          {credentialOptions.map(({ label, value }: any) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>

        <IconButton
          appearance="minimal"
          intent="danger"
          icon="cross"
          type="button"
          marginLeft={8}
          disabled={!canRemove}
          onClick={onRemoveClick}
        />
      </Pane>
      {validationError && (
        <Paragraph color="danger" paddingLeft={40} marginTop={4}>
          {validationError.message}
        </Paragraph>
      )}

      {wasCreated && (
        <Paragraph color="success" paddingLeft={40} marginTop={4}>
          Invite successfully sent
        </Paragraph>
      )}
    </Pane>
  );
};

export default InviteField;
