import gql from 'graphql-tag';
import { InputErrorFragment } from '../fragments';

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdateUsersPaymentMethod($paymentMethod: String!) {
    result: updatePaymentMethodForUser(paymentMethod: $paymentMethod) {
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;
