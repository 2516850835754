import gql from 'graphql-tag';
import { ReferredProtocolFragment } from '../fragments';

export const PROTOCOL_SET_LIST = gql`
  query ProtocolSetSelection($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      protocolSets(first: 999, filters: { publishedState: DRAFT }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const PROTOCOL_LIST = gql`
  query ProtocolSelection($protocolSetId: ID!) {
    protocolSet: node(id: $protocolSetId, type: PROTOCOL_SET) {
      ... on ProtocolSet {
        id
        protocols(first: 999, filters: { publishedState: DRAFT }) {
          edges {
            node {
              ...ReferredProtocol
            }
          }
        }
      }
    }
  }
  ${ReferredProtocolFragment}
`;
