import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_ALL_MEDICATIONS = gql`
  mutation PublishAllMedicationsForOrg(
    $input: PublishAllMedicationsForOrganizationInput!
  ) {
    result: publishAllMedicationsForOrganization(input: $input) {
      success
    }
  }
`;

const PublishAllMedicationsDialog = ({
  isShown,
  organization,
  refetchQuery,
  onCloseComplete
}: any) => {
  const [publishMedications, { loading }] = useMutation(
    PUBLISH_ALL_MEDICATIONS,
    {
      variables: { input: { organizationId: organization.id } },
      refetchQueries: [refetchQuery],
      awaitRefetchQueries: true,
      onCompleted: (response: any) => {
        if (response.result.success) {
          toaster.success(`All medications successfully published`);
          onCloseComplete();
        }
      }
    }
  );

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishMedications()}
    >
      <Paragraph>
        Publishing all medications will make them available to{' '}
        <strong>
          all users of the app within this organization and it's descendents
        </strong>
        . Please only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishAllMedicationsDialog;
