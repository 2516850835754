import React, { useState } from 'react';
import { get } from 'lodash';
import {
  Pane,
  Paragraph,
  Heading,
  Spinner,
  toaster,
  IconButton,
  Pill,
  Link
} from 'evergreen-ui';
import { useQuery } from '@apollo/react-hooks';
import posed, { PoseGroup } from 'react-pose';
import { useWindowSize } from 'react-use';
import { GENERATE_QUESTIONS, LIST_ENTRIES } from './queries';
import Completed from './Completed';
import StartOver from './StartOver';
import AnswersList from './AnswersList';
import Question from './Question';
import { launchIntercomWithMessage } from 'lib/analytics';

const RouteContainer = posed.div({
  enter: { y: 0, opacity: 1, delay: 100, beforeChildren: true },
  exit: { y: 50, opacity: 0 }
});

const SIDEBAR_WIDTH = 320;

function toKey(percentComplete: any, currentQuestion: any) {
  if (percentComplete === 100) return 'completed';
  if (!currentQuestion) return 'loading';

  return currentQuestion.__typename === 'MedicationInventoryProfileQuestion'
    ? currentQuestion.medication.id
    : currentQuestion.equipment.id;
}

const InventoryQuestionnaire = ({ organization }: any) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [skippedMedicationIds, setSkippedMedicationIds] = useState([]);
  const [skippedEquipmentIds, setSkippedEquipmentIds] = useState([]);
  const { width } = useWindowSize();
  const [sidebarExpanded, setSidebarExpanded] = useState(width > 600);

  const {
    data: entriesData,
    loading: isLoadingEntries,
    refetch: refetchEntries
  } = useQuery(LIST_ENTRIES, {
    variables: {
      organizationId: organization.id
    },
    fetchPolicy: 'network-only'
  });

  const { data, loading, refetch, fetchMore } = useQuery(GENERATE_QUESTIONS, {
    variables: { organizationId: organization.id },
    fetchPolicy: 'network-only'
  });

  const questions = get(
    data,
    'generateInventoryProfileQuestions.questions',
    []
  );

  const progress = get(data, 'generateInventoryProfileQuestions.progress');
  const entries = get(
    entriesData,
    'inventoryProfileEntriesForOrganization',
    []
  );

  const totalQuestions = questions.length;
  const percentComplete = progress ? progress.percentComplete : 0;
  const currentQuestion = questions[currentIdx];

  function onSkipClick({ medicationId, equipmentId }: any) {
    const skippedMeds = medicationId
      ? [...skippedMedicationIds, medicationId]
      : skippedMedicationIds;

    const skippedEquipment = equipmentId
      ? [...skippedEquipmentIds, equipmentId]
      : skippedEquipmentIds;

    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSkippedMedicationIds(skippedMeds);
    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSkippedEquipmentIds(skippedEquipment);
    setCurrentIdx(currentIdx + 1);

    if (questions.length - 1 === currentIdx) {
      fetchMore({
        variables: {
          organizationId: organization.id,
          ignoreMedicationIds: skippedMeds,
          ignoreEquipmentIds: skippedEquipment
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          return {
            generateInventoryProfileQuestions: {
              ...prev.generateInventoryProfileQuestions,
              progress:
                fetchMoreResult.generateInventoryProfileQuestions.progress,
              questions: [
                ...prev.generateInventoryProfileQuestions.questions,
                ...fetchMoreResult.generateInventoryProfileQuestions.questions
              ]
            }
          };
        }
      });
    }
  }

  return (
    <Pane
      background="tint2"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="row"
    >
      <Pane flex={1} height="100%" width="100%" display="flex">
        <Pane
          width={460}
          display="flex"
          flexDirection="column"
          margin="auto"
          position="relative"
          transform={
            sidebarExpanded ? `translateX(-${SIDEBAR_WIDTH / 2}px)` : 'none'
          }
        >
          {!data && loading && (
            <Pane
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size={20} />
              <Paragraph color="muted" marginTop={16}>
                Fetching your current inventory status
              </Paragraph>
            </Pane>
          )}

          <PoseGroup>
            <RouteContainer key={toKey(percentComplete, currentQuestion)}>
              {currentQuestion && (
                <>
                  <Heading
                    textAlign="right"
                    position="absolute"
                    top={-24}
                    right={8}
                    size={100}
                    marginBottom={8}
                  >
                    {percentComplete}% Complete
                  </Heading>
                  <Question
                    {...currentQuestion}
                    organization={organization}
                    onNextClick={() => {
                      toaster.success('Inventory entry saved', {
                        id: 'inventory-success'
                      });

                      setCurrentIdx(0);
                      refetchEntries();
                      refetch({
                        organizationId: organization.id,
                        // @ts-expect-error ignoreMedicationIds
                        ignoreMedicationIds: skippedMedicationIds,
                        ignoreEquipmentIds: skippedEquipmentIds
                      });
                    }}
                    onSkipClick={onSkipClick}
                  />
                </>
              )}

              {progress &&
                percentComplete < 100 &&
                !currentQuestion &&
                totalQuestions > 0 && (
                  <StartOver
                    onStartOverClick={() => {
                      setCurrentIdx(0);
                      setSkippedEquipmentIds([]);
                      setSkippedMedicationIds([]);
                    }}
                  />
                )}

              {percentComplete >= 100 && <Completed />}
            </RouteContainer>
          </PoseGroup>
        </Pane>
      </Pane>
      <Pane
        borderLeft
        width={SIDEBAR_WIDTH}
        marginLeft="auto"
        display="flex"
        background="white"
        flexDirection="column"
        position="fixed"
        top={60}
        bottom={0}
        transition="right 200ms"
        right={sidebarExpanded ? 0 : -SIDEBAR_WIDTH}
      >
        <Pane position="absolute" width={44} height={44} left={-48} top={16}>
          <Pill color="green" position="absolute">
            {entries.length}
          </Pill>

          <IconButton
            icon={sidebarExpanded ? 'menu-open' : 'menu-closed'}
            appearance="minimal"
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
            height={44}
          />
        </Pane>

        <Pane padding={16}>
          <Heading size={500}>Inventory at {organization.name}</Heading>
        </Pane>

        <AnswersList
          organization={organization}
          entries={entries}
          isLoading={isLoadingEntries}
          refetchQuestions={() => refetch()}
          refetchEntries={() => refetchEntries()}
        />

        <Pane marginTop="auto" borderTop padding={16}>
          <Heading marginTop={4} size={100} marginBottom={8}>
            Inventory setup progress: {percentComplete}%
          </Heading>

          <Pane
            background="tint2"
            height={5}
            borderRadius={3}
            marginBottom={24}
            overflow="hidden"
          >
            <Pane
              transition="width 200ms"
              width={`${percentComplete}%`}
              background="#1070CA"
              height={5}
            />
          </Pane>

          <Paragraph size={300} marginTop={8}>
            Select all inventory items that are applicable to your agency. You
            can resume at any time, so feel free to skip a question if you don't
            know the answer right now.{' '}
            <Link
              size={300}
              href="#"
              // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
              onClick={() => launchIntercomWithMessage()}
            >
              Speak to our support team if you need help
            </Link>
            .
          </Paragraph>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default InventoryQuestionnaire;
