import gql from 'graphql-tag';

export const DELETE_MOLECULE = gql`
  mutation DeleteMolecule($input: DeleteMoleculeInput!) {
    result: deleteMolecule(input: $input) {
      molecule {
        id
      }
    }
  }
`;
