import gql from 'graphql-tag';
import { OrganizationFragment, SubscriptionFragment } from './fragments';

export const CURRENT_USER_QUERY = gql`
  query CurrentUserForLayout {
    currentUser {
      id
      email
      name
      role
      intercomIdentityHash(platform: WEB)
      seats {
        id
        roles {
          id
          name
        }
        organization {
          ...Organization
        }
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${OrganizationFragment}
  ${SubscriptionFragment}
`;
