import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { includes } from 'lodash';
import { Table, Link, Badge, Pane } from 'evergreen-ui';
import { orgPath, protocolSetPath, protocolPath } from 'lib/urls';

const ItemLink = ({ children, to, canLink = true }: any) =>
  canLink ? (
    <Link size={300} is={RouterLink} to={to}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

const badgeStyles = {
  alignItems: 'center',
  display: 'inline-flex',
  fontWeight: 400,
  textTransform: 'none'
};

const ALIAS_CELL_WIDTH = 400;

const rowProps = {
  height: 'auto',
  paddingTop: 12,
  paddingBottom: 12,
  minHeight: 24
};

const AliasPill = ({ item, isSelected, onClick }: any) => (
  <Badge
    {...badgeStyles}
    marginRight={4}
    marginBottom={4}
    isInteractive={!!onClick}
    color={isSelected ? 'yellow' : 'neutral'}
    onClick={onClick}
  >
    {item}
  </Badge>
);

const Aliases = ({ aliases, aliasesToHighlight, onAliasClick }: any) => (
  <Pane display="flex" flexWrap="wrap" marginBottom={-4}>
    {aliases.map((alias: any) => (
      <AliasPill
        key={alias}
        item={alias}
        isSelected={aliasesToHighlight && includes(aliasesToHighlight, alias)}
        onClick={
          onAliasClick
            ? function() {
                onAliasClick(alias);
              }
            : null
        }
      />
    ))}
  </Pane>
);

const MedicationRow = ({
  medication,
  organization,
  // @ts-expect-error TS(6133): 'history' is declared but its value is never read.
  history,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={`${orgPath(organization)}/medications/${medication.id}`}>
        {medication.genericName}
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="green">Medication</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={medication.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const MoleculeRow = ({
  molecule,
  canAccessAllContent,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={`/admin/molecules`} canLink={canAccessAllContent}>
        {molecule.name}
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="teal">Molecule</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={molecule.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const HospitalRow = ({
  hospital,
  canAccessAllContent,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink
        to={`/admin/hospitals/${hospital.id}`}
        canLink={canAccessAllContent}
      >
        {hospital.name} (
        {hospital.authorities.map((a: any) => a.authority.name).join(', ')})
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="orange">Hospital</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={hospital.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const AliasRow = ({
  alias,
  canAccessAllContent,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={`/admin/aliases`} canLink={canAccessAllContent}>
        {alias.key}
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="neutral">Alias</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={alias.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const EquipmentRow = ({
  equipment,
  organization,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={`${orgPath(organization)}/equipment/${equipment.id}`}>
        {equipment.name}
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="yellow">Equipment</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={equipment.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const EquipmentFunctionRow = ({
  equipmentFunction,
  organization,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink
        to={`${orgPath(organization)}/equipment/${
          equipmentFunction.equipment.id
        }/functions`}
      >
        {equipmentFunction.name} ({equipmentFunction.equipment.name})
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="orange">Equipment Function</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={equipmentFunction.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const ProtocolRow = ({
  organization,
  protocol,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={protocolPath(organization, protocol.protocolSet, protocol)}>
        {protocol.name} ({protocol.protocolSet.name})
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="red">Protocol</Badge>
    </Table.Cell>
    <Table.Cell width={ALIAS_CELL_WIDTH} flex="none">
      <Aliases
        aliases={protocol.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const ProtocolSetRow = ({
  organization,
  protocolSet,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Table.Row {...rowProps}>
    <Table.TextCell>
      <ItemLink to={protocolSetPath(organization, protocolSet)}>
        {protocolSet.name}
      </ItemLink>
    </Table.TextCell>
    <Table.Cell>
      <Badge color="orange">Protocol Set</Badge>
    </Table.Cell>
    <Table.Cell flex="none" width={ALIAS_CELL_WIDTH}>
      <Aliases
        aliases={protocolSet.aliases}
        aliasesToHighlight={aliasesToHighlight}
        onAliasClick={onAliasClick}
      />
    </Table.Cell>
  </Table.Row>
);

const ResultRow = ({ result, ...props }: any) => {
  switch (result.__typename) {
    case 'Medication':
      return <MedicationRow medication={result} {...props} />;
    case 'Molecule':
      return <MoleculeRow molecule={result} {...props} />;
    case 'Hospital':
      return <HospitalRow hospital={result} {...props} />;
    case 'Equipment':
      return <EquipmentRow equipment={result} {...props} />;
    case 'EquipmentFunction':
      return <EquipmentFunctionRow equipmentFunction={result} {...props} />;
    case 'Protocol':
      return <ProtocolRow protocol={result} {...props} />;
    case 'ProtocolSet':
      return <ProtocolSetRow protocolSet={result} {...props} />;
    case 'Alias':
      return <AliasRow alias={result} {...props} />;
    default:
      return null;
  }
};

export default ResultRow;
