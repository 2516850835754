import React, { useState } from 'react';
import {
  Alert,
  Pane,
  Badge,
  Button,
  Heading,
  Strong,
  Icon,
  Text,
  Paragraph,
  Link,
  toaster
} from 'evergreen-ui';
import format from 'date-fns/format';
import InvoiceList from './InvoiceList';
import CardOnFile from './CardOnFile';
import ManageSeats from './ManageSeats';
import {
  formatCents,
  PlanIntervalLabels,
  subscriptionStatus,
  SubscriptionStatesToColors,
  SubscriptionStatesToText,
  trialRemainingMessage
} from 'lib/subscription';
import { launchIntercomWithMessage } from 'lib/analytics';

const BillingSummary = ({ organization }: any) => {
  const [showSeats, setShowSeats] = useState(false);
  const { subscription } = organization;

  if (!subscription) {
    return (
      <Pane>
        <Paragraph color="muted">
          Subscription not setup for organization
        </Paragraph>
      </Pane>
    );
  }

  const totalSeats = subscription.totalSeatCount;
  const usedSeats =
    subscription.occupiedSeatCount + subscription.pendingInviteCount;

  const status = subscriptionStatus(subscription);
  const isFreeTrial = subscription.trialEndAt;

  return (
    <Pane marginTop={-16}>
      <Heading size={100}>Billing Summary</Heading>

      {isFreeTrial && (
        <Alert title={trialRemainingMessage(subscription)} marginTop={8}>
          You will be charged at the end of the trial period for the amount
          shown above. Please ensure you have a credit card on file to prevent
          interruption to your service.
        </Alert>
      )}

      <Pane
        marginTop={8}
        marginBottom={8}
        width="100%"
        display="flex"
        background="tint1"
        padding={16}
      >
        <Pane>
          <Text display="block">
            <Strong size={600} lineHeight={1}>
              ${formatCents(subscription.totalAmountInCents)}
            </Strong>
            {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {PlanIntervalLabels[subscription.interval]} ({totalSeats} seats @ $
            {formatCents(subscription.seatAmountInCents)}/seat)
          </Text>
          {subscription.nextBilledAt && (
            <Text color="muted">
              Next billed on {format(subscription.nextBilledAt, 'MM/DD/YYYY')}
            </Text>
          )}
        </Pane>
        {/* @ts-expect-error Invalid color */}
        <Badge marginLeft="auto" color={SubscriptionStatesToColors[status]}>
          {SubscriptionStatesToText[status]}
        </Badge>
      </Pane>

      <Paragraph color="#C7CED4" textAlign="right" size={300}>
        Please contact us if you wish to cancel your plan.
      </Paragraph>

      <Pane marginBottom={16}>
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderBottom
          paddingTop={8}
          paddingBottom={8}
        >
          <Icon size={16} marginRight={8} icon="people" color="muted" />{' '}
          <Heading>Seats</Heading>
        </Pane>
        <Pane paddingTop={16} paddingBottom={16}>
          <Pane
            marginBottom={8}
            display="flex"
            alignItems="center"
            flexDirection="row"
          >
            <Text display="block">
              <Strong>{usedSeats}</Strong> of <Strong>{totalSeats}</Strong>{' '}
              seats used
            </Text>

            <Button
              onClick={() => setShowSeats(true)}
              marginLeft="auto"
              appearance="minimal"
              as="a"
              href="/"
            >
              Add / Remove Seats
            </Button>
          </Pane>

          <Pane
            background="tint2"
            height={5}
            borderRadius={3}
            marginBottom={16}
            overflow="hidden"
          >
            <Pane
              height={5}
              width={`${(usedSeats / totalSeats) * 100}%`}
              background="#1070CA"
            />
          </Pane>

          <Paragraph size={300} width="80%" color="muted">
            You can manage the members your seat is assigned to in the{' '}
            <Link size={300} href={`/orgs/${organization.slug}/members`}>
              members tab
            </Link>
            . Please note: pending invites count towards assigned seats.
          </Paragraph>
        </Pane>
      </Pane>

      <Pane marginBottom={16}>
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderBottom
          paddingTop={8}
          paddingBottom={8}
        >
          <Icon size={16} marginRight={8} icon="credit-card" color="muted" />{' '}
          <Heading>Payment Method</Heading>
        </Pane>
        <Pane paddingTop={16} paddingBottom={16}>
          <CardOnFile organization={organization} />
        </Pane>
      </Pane>

      <Pane marginBottom={16}>
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderBottom
          paddingTop={8}
          paddingBottom={8}
        >
          <Icon size={16} marginRight={8} icon="document" color="muted" />{' '}
          <Heading>Invoices</Heading>
        </Pane>
        <Pane paddingTop={16} paddingBottom={16}>
          <InvoiceList organization={organization} />

          <Paragraph size={300} width="80%" marginTop={16} color="muted">
            If you need any custom information on your invoices, please{' '}
            <Link
              size={300}
              href="#"
              // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
              onClick={() => launchIntercomWithMessage()}
            >
              contact our support team
            </Link>
            .
          </Paragraph>
        </Pane>
      </Pane>

      <ManageSeats
        subscriptionId={subscription.id}
        minimumSeatCount={usedSeats}
        existingSeatCount={totalSeats}
        perSeatCost={subscription.seatAmountInCents}
        interval={subscription.interval}
        isShown={showSeats}
        onClose={() => setShowSeats(false)}
        onComplete={() => {
          toaster.success('Your subscription has been updated');
          setShowSeats(false);
        }}
      />
    </Pane>
  );
};

export default BillingSummary;
