import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import StripeCreditCardForm from '../StripeCreditCardForm';
import { UPDATE_CARD } from './queries';
import { onCompleted } from 'lib/formHelpers';

const UpdateOrganizationCreditCard = ({
  onComplete,
  organizationId,
  isLoading = false,
  ...props
}: any) => {
  const [updatePaymentMethod, { loading }] = useMutation(UPDATE_CARD, {
    onCompleted: (response: any) => onCompleted({ response, onComplete })
  });

  return (
    <StripeCreditCardForm
      {...props}
      handleResult={({ paymentMethod }: any) => {
        paymentMethod &&
          updatePaymentMethod({
            variables: {
              input: { paymentMethod: paymentMethod.id, organizationId }
            }
          });
      }}
      isLoading={loading || isLoading}
    />
  );
};

export default UpdateOrganizationCreditCard;
