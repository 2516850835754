import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { head, get } from 'lodash';
import gql from 'graphql-tag';
import {
  Avatar,
  Table,
  Text,
  Pane,
  Badge,
  Spinner,
  IconButton,
  toaster
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';

const ORGANIZATION_INVITES = gql`
  query ListOrganizationInvites($slug: String!) {
    organization(slug: $slug) {
      id
      invites(first: 200) {
        edges {
          node {
            id
            roles {
              id
              name
            }
            credentials {
              id
              name
            }
            email
            insertedAt
            user {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;

const REVOKE_INVITE = gql`
  mutation RevokeInvite($input: RevokeOrganizationInviteInput!) {
    revokeOrganizationInvite(input: $input) {
      id
    }
  }
`;

const PendingInvitesList = ({ slug, onRemoveComplete }: any) => {
  const queryVariables = { slug };

  const { data, loading, error } = useQuery(ORGANIZATION_INVITES, {
    variables: queryVariables,
    fetchPolicy: 'network-only'
  });

  const [revokeInvite] = useMutation(REVOKE_INVITE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ORGANIZATION_INVITES, variables: queryVariables }
    ],
    onCompleted: (response: any) => {
      if (response.revokeOrganizationInvite) {
        toaster.success('Successfully revoked invitation');
        onRemoveComplete();
      }
    }
  });

  const invites = get(data, 'organization.invites.edges', []);

  return (
    <Pane background="white">
      <Table>
        <Table.Head>
          <Table.TextHeaderCell width={250} flex="none">
            Email
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Role</Table.TextHeaderCell>
          <Table.TextHeaderCell>Added</Table.TextHeaderCell>
          <Table.HeaderCell width={48} flex="none" />
        </Table.Head>
        {!loading && !error && (
          <Table.Body>
            {/* @ts-expect-error TS(7053) */}
            {invites.map(({ node: invite }) => {
              const inviteeRole = head(invite.roles);
              const inviteeCred = head(invite.credentials);

              return (
                <Table.Row key={invite.id}>
                  <Table.Cell width={250} flex="none">
                    <Avatar name={invite.email || invite.user.name} />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                      {invite.email || invite.user.email}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    {inviteeRole && (
                      // @ts-expect-error TS(2571): Object is of type 'unknown'.
                      <Badge color="neutral">{inviteeRole.name}</Badge>
                    )}
                    {inviteeCred && (
                      <Badge color="neutral" marginLeft={4}>
                        {/* @ts-expect-error TS(2571): Object is of type 'unknown'. */}
                        {inviteeCred.name}
                      </Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={invite.insertedAt} />
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <IconButton
                      icon="cross"
                      height={24}
                      intent="danger"
                      appearance="minimal"
                      onClick={() => {
                        revokeInvite({
                          variables: { input: { id: invite.id } }
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        )}
      </Table>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && invites.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default PendingInvitesList;
