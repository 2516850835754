import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  get,
  flatten,
  uniq,
  filter,
  pickBy,
  some,
  includes,
  countBy
} from 'lodash';
import {
  Table,
  Pane,
  Spinner,
  Button,
  SelectMenu,
  TextDropdownButton
} from 'evergreen-ui';
import { useSelector } from 'react-redux';
import EmptyContent from 'components/shared/EmptyContent';
import { currentUserSelector } from 'redux/global';
import { isSiteAdmin } from 'lib/auth';
import ResultRow from '../SearchResultsList/ResultRow';
import { COLLISIONS } from './queries';
import AliasResults from './AliasResults';

function extractOverlappingAliases(aliases: any) {
  const countOfAliases = countBy(aliases);
  // @ts-expect-error TS(6133): 'alias' is declared but its value is never read.
  return pickBy(countOfAliases, (count, alias) => count > 1);
}

const SearchResultsList = ({ organization, variables }: any) => {
  const [selectedAliases, setSelectedAliases] = useState([]);
  const [findOverlappingAliases, { data, loading, error }] = useLazyQuery(
    COLLISIONS,
    {
      variables: { input: variables },
      fetchPolicy: 'no-cache'
    }
  );

  const currentUser = useSelector(currentUserSelector);
  const isAdmin = isSiteAdmin(currentUser);

  const results = get(data, 'results', []);

  const allAliasesReturned = flatten(
    results.map((result: any) => result.aliases)
  ).sort();

  const overlappingAliasesWithCounts = extractOverlappingAliases(
    allAliasesReturned
  );

  const aliasesInSet = uniq(allAliasesReturned).map(alias => ({
    label: alias,
    value: alias
  }));

  const totalSelectedAliases = selectedAliases.length;

  const itemsToShow =
    totalSelectedAliases > 0
      ? results.filter((result: any) =>
          some(result.aliases, alias => includes(selectedAliases, alias))
        )
      : results;

  return (
    <Pane>
      <Button
        height={24}
        marginBottom={24}
        isLoading={!!loading}
        onClick={() => findOverlappingAliases()}
      >
        Find overlapping aliases
      </Button>

      <Pane background="white">
        {overlappingAliasesWithCounts && (
          <AliasResults
            aliasesWithCounts={overlappingAliasesWithCounts}
            aliasesToHighlight={selectedAliases}
            onAliasClick={(alias: any) => {
              if (includes(selectedAliases, alias)) {
                setSelectedAliases(filter(selectedAliases, i => i !== alias));
              } else {
                // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                setSelectedAliases([...selectedAliases, alias]);
              }
            }}
          />
        )}
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Result</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell width={400} flex="none">
              <SelectMenu
                isMultiSelect
                title="Select aliases to filter"
                // @ts-expect-error Invalid options
                options={aliasesInSet}
                selected={selectedAliases}
                onSelect={(item: any) =>
                  // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                  setSelectedAliases([...selectedAliases, item.value])
                }
                onDeselect={(item: any) =>
                  setSelectedAliases(
                    filter(selectedAliases, i => i !== item.value)
                  )
                }
              >
                <TextDropdownButton icon="caret-down">
                  Aliases
                  {totalSelectedAliases > 0 && ` (${totalSelectedAliases})`}
                </TextDropdownButton>
              </SelectMenu>
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {itemsToShow.map((result: any) => (
              <ResultRow
                key={result.id}
                organization={organization}
                result={result}
                canAccessAllContent={isAdmin}
                aliasesToHighlight={selectedAliases}
                onAliasClick={(alias: any) => {
                  if (includes(selectedAliases, alias)) {
                    setSelectedAliases(
                      filter(selectedAliases, i => i !== alias)
                    );
                  } else {
                    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                    setSelectedAliases([...selectedAliases, alias]);
                  }
                }}
              />
            ))}
          </Table.Body>
        </Table>

        {loading && (
          <Pane margin={16}>
            <Spinner size={16} />
          </Pane>
        )}

        {!loading && !error && results.length === 0 && <EmptyContent />}
      </Pane>
    </Pane>
  );
};

export default SearchResultsList;
