import React from 'react';
import {
  Pane,
  Heading,
  Button,
  Paragraph,
  Text,
  TextInput,
  IconButton,
  FormFieldValidationMessage
} from 'evergreen-ui';
import { get } from 'lodash';
import { FieldArray } from 'formik';

const emptyState = (fieldType: any, fields: any) => {
  if (fieldType) return '';

  return fields.reduce((acc: any, field: any) => {
    return { ...acc, [field.name]: '' };
  }, {});
};

const ListOf = ({
  name,
  label,
  description,
  subFieldType,
  formikBag,
  validationMessage,
  ...props
}: any) => {
  const { values } = formikBag;
  const fieldValues = values[name] || [];

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: any) => {
        // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
        const newState = emptyState(subFieldType);

        return (
          <Pane marginBottom={16}>
            <Pane display="flex" alignItems="center" marginBottom={4}>
              <Pane>
                <Heading size={400}>{label}</Heading>
                {description && (
                  <Paragraph color="muted">{description}</Paragraph>
                )}
              </Pane>
              <Button
                type="button"
                appearance="minimal"
                height={20}
                marginLeft="auto"
                onClick={(e: any) => {
                  e.preventDefault();
                  arrayHelpers.push(newState);
                }}
              >
                Add
              </Button>
            </Pane>
            <Pane marginTop={8}>
              {fieldValues.map((value: any, index: any) => {
                const fieldName = `${name}[${index}]`;
                const validationMessage = get(
                  props,
                  `validationMessage[${index}]`
                );

                return (
                  <Pane key={index}>
                    <Pane display="flex" alignItems="center" marginBottom={8}>
                      <Text
                        fontFamily="mono"
                        size={300}
                        width="25px"
                        color="muted"
                      >
                        #{index + 1}
                      </Text>
                      <TextInput
                        {...props}
                        required={true}
                        type={subFieldType}
                        value={value}
                        name={fieldName}
                        label={''}
                        key={index}
                        flex="1"
                        marginLeft={4}
                      />
                      <IconButton
                        type="button"
                        icon="cross"
                        appearance="minimal"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </Pane>
                    {typeof validationMessage === 'string' && (
                      <FormFieldValidationMessage marginTop={4}>
                        {validationMessage}
                      </FormFieldValidationMessage>
                    )}
                  </Pane>
                );
              })}
            </Pane>

            {typeof validationMessage === 'string' && (
              <FormFieldValidationMessage marginTop={4}>
                {validationMessage}
              </FormFieldValidationMessage>
            )}
          </Pane>
        );
      }}
    />
  );
};

export default ListOf;
