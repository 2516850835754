import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const IND_SIGNUP_MUTATION = gql`
  mutation SignupForIndividualWaitlist(
    $input: SignupForIndividualWaitlistInput!
  ) {
    result: signupForIndividualWaitlist(input: $input) {
      errors {
        ...InputError
      }
      waitlistSignup {
        id
        type
        email
      }
    }
  }
  ${InputErrorFragment}
`;

export const ORG_SIGNUP_MUTATION = gql`
  mutation SignupForOrganizationWaitlist(
    $input: SignupForOrganizationWaitlistInput!
  ) {
    result: signupForOrganizationWaitlist(input: $input) {
      errors {
        ...InputError
      }
      waitlistSignup {
        id
        type
        email
      }
    }
  }
  ${InputErrorFragment}
`;
