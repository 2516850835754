import React, { useState, useEffect } from 'react';
import {
  Position,
  Pane,
  Heading,
  SelectMenu,
  Button,
  Paragraph,
  FormFieldValidationMessage
} from 'evergreen-ui';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { filter, includes } from 'lodash';

const CAPABILITIES = gql`
  query CapabilitiesForAuthority($authorityId: ID!) {
    organization(id: $authorityId) {
      hospitalCapabilities(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const HospitalCapabilitiesSelector = ({
  organizationId,
  label,
  description,
  validationMessage,
  value,
  formikBag,
  onChange,
  ...props
}: any) => {
  const [selectedItems, setSelected] = useState(value || []);
  const [getCapabilities, { data }] = useLazyQuery(CAPABILITIES);

  const options =
    data && data.organization && data.organization.hospitalCapabilities
      ? data.organization.hospitalCapabilities.edges.map(
          // @ts-expect-error TS(7031): Binding element 'capability' implicitly has an 'an... Remove this comment to see the full error message
          ({ node: capability }) => ({
            label: capability.name,
            value: capability.id
          })
        )
      : [];

  const selectedOptions = filter(options, item =>
    includes(selectedItems, item.value)
  );

  const setChanges = (selected: any) => {
    setSelected(selected);
    formikBag.setFieldTouched(props.name);
    formikBag.setFieldValue(props.name, selected);
  };

  useEffect(() => {
    if (formikBag.values.authorityId) {
      getCapabilities({
        variables: {
          authorityId: formikBag.values.authorityId
        }
      });
    }
  }, [formikBag.values.authorityId, getCapabilities]);

  return (
    <Pane marginBottom={24}>
      <Pane display="flex">
        <Heading size={400}>
          {label} ({selectedOptions.length})
        </Heading>
        <SelectMenu
          {...props}
          position={Position.BOTTOM_RIGHT}
          title={description}
          options={options}
          selected={selectedItems}
          onSelect={(item: any) => setChanges([...selectedItems, item.value])}
          onDeselect={(item: any) =>
            setChanges(filter(selectedItems, i => i !== item.value))
          }
          isMultiSelect
        >
          <Button
            type="button"
            appearance="minimal"
            marginLeft="auto"
            height={20}
          >
            Edit
          </Button>
        </SelectMenu>
      </Pane>

      {selectedOptions.length > 0 && (
        <Paragraph color="muted" fontFamily="display" size={300}>
          {selectedOptions.map(item => item.label).join(', ')}
        </Paragraph>
      )}

      {typeof validationMessage === 'string' && (
        <FormFieldValidationMessage marginTop={4}>
          {validationMessage}
        </FormFieldValidationMessage>
      )}
    </Pane>
  );
};

export default HospitalCapabilitiesSelector;
