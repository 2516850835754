import gql from 'graphql-tag';
import { ProtocolStepFragment } from 'components/shared/fragments';

export const PROTOCOL_STEPS = gql`
  query ListProtocolSteps($protocolId: ID!, $organizationId: ID!) {
    organization(id: $organizationId) {
      id
      protocol: node(id: $protocolId, type: PROTOCOL) {
        ... on Protocol {
          id
          steps {
            ...ProtocolStep
          }
          parent {
            id
            steps {
              ...ProtocolStep
            }
          }
          updatedAt
        }
      }
    }
  }
  ${ProtocolStepFragment}
`;

export const REORDER_STEP = gql`
  mutation ReorderProtocolStep($input: ReorderStepForProtocolInput!) {
    reorderStepForProtocol(input: $input) {
      protocolStep {
        id
      }
    }
  }
`;

export const RESET_ORDER = gql`
  mutation ResetProtcolStepOrder($input: ResetOrderForProtocolInput!) {
    resetOrderForProtocol(input: $input) {
      protocol {
        id
      }
    }
  }
`;
