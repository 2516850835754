import React, { useState } from 'react';
import gql from 'graphql-tag';
import { pick } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import {
  InputErrorFragment,
  OrganizationFragment
} from 'components/shared/fragments';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { hasPermission } from 'lib/organizations';
import { ProfileForm } from './form';

const UPDATE_PROFILE = gql`
  mutation UpdateOrgProfile($input: UpdateOrganizationProfileInput!) {
    result: updateOrganizationProfile(input: $input) {
      errors {
        ...InputError
      }
      organization {
        ...Organization
      }
    }
  }
  ${InputErrorFragment}
  ${OrganizationFragment}
`;

const UpdateProfile = ({ organization, onComplete }: any) => {
  const [errors, setErrors] = useState({});
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  const canManageProfile = hasPermission(
    organization,
    'organization:update_profile'
  );

  const formData = sanitizeData(
    pick(organization, [
      'id',
      'name',
      'address',
      'website',
      'phoneNumber',
      'supportPhoneNumber',
      'emailAddress',
      'faxNumber',
      'notes',
      'logo'
    ])
  );

  return (
    <FormBuilder
      key={organization?.id ?? -1}
      initialValues={formData}
      form={ProfileForm}
      errors={errors}
      isLoading={loading}
      disabled={!canManageProfile}
      onSubmit={(input: any) => {
        setErrors({});
        updateProfile({
          variables: { input }
        });
      }}
    />
  );
};

export default UpdateProfile;
