import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Pane, TextInputField, majorScale } from 'evergreen-ui';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const initialValues = {
  password: '',
  passwordConfirmation: ''
};

const FormikForm = ({ onSubmit, loading }: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }: any) => (
        <Pane
          marginTop={16}
          textAlign="left"
          is={Form}
          data-testid="reset-password-form"
          onSubmit={handleSubmit}
        >
          <TextInputField
            required
            isInvalid={!!errors.password}
            type="password"
            name="password"
            label={t('common.password')}
            validationMessage={
              errors.password && touched.password && errors.password
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            marginBottom={majorScale(2)}
            size={500}
          />

          <TextInputField
            required
            isInvalid={!!errors.passwordConfirmation}
            type="password"
            name="passwordConfirmation"
            label={t('common.passwordConfirmation')}
            validationMessage={
              errors.passwordConfirmation &&
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            marginBottom={majorScale(2)}
            size={500}
          />
          <Button
            display="flex"
            width="100%"
            justifyContent="center"
            isLoading={loading}
            appearance="primary"
            type="submit"
            height={40}
          >
            {t('forgot_password.submit_btn')}
          </Button>
        </Pane>
      )}
    />
  );
};

export default FormikForm;
