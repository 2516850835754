import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { EquipmentFunctionFragment } from 'components/shared/fragments';

const PUBLISH_EQUIPMENT_FUNCTION = gql`
  mutation PublishEquipmentFunction($input: PublishFunctionForEquipmentInput!) {
    result: publishFunctionForEquipment(input: $input) {
      equipmentFunction {
        ...EquipmentFunction
      }
    }
  }
  ${EquipmentFunctionFragment}
`;

const PublishEquipmentDialog = ({
  isShown,
  equipmentFunction,
  onCloseComplete
}: any) => {
  const [publishFunction, { loading }] = useMutation(
    PUBLISH_EQUIPMENT_FUNCTION,
    {
      variables: { input: { id: equipmentFunction.id } },
      onCompleted: (response: any) => {
        if (response.result.equipmentFunction) {
          toaster.success(`${equipmentFunction.name} successfully published!`);
          onCloseComplete();
        }
      }
    }
  );

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishFunction()}
    >
      <Paragraph>
        Publishing this function will make it available to{' '}
        <strong>all users of the app within this organization</strong>. Please
        only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishEquipmentDialog;
