import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const UPDATE_USER = gql`
  mutation UpdateCurrentUser($input: UpdateProfileInput!) {
    result: updateProfile(input: $input) {
      user {
        id
        name
        email
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;
