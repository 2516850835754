import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';

const ORGANIZATIONS = gql`
  query ListOrganizationsForSelector($filters: FilterOrganizationsInput) {
    organizations(first: 9999, filters: $filters) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const OrganizationDataSource = ({ filters = {}, render }: any) => {
  const { data, loading } = useQuery(ORGANIZATIONS, {
    variables: { filters },
    fetchPolicy: 'network-only'
  });

  // @ts-expect-error TS(7031): Binding element 'org' implicitly has an 'any' type... Remove this comment to see the full error message
  const items = get(data, 'organizations.edges', []).map(({ node: org }) => ({
    label: org.name,
    value: org.id
  }));

  return render({
    items,
    isLoading: loading
  });
};

export default OrganizationDataSource;
