import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import MoleculesList from 'components/admin/MoleculesList';
import { MOLECULES_LIST } from 'components/admin/MoleculesList/MoleculesList';
import CreateOrUpdateMolecule from 'components/admin/CreateOrUpdateMolecule';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DELETE_MOLECULE } from './queries';

export default () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const refetchQuery = {
    query: MOLECULES_LIST,
    variables: { first: 999 }
  };

  const [deleteMolecule, { loading: isDeleteLoading }] = useMutation(
    DELETE_MOLECULE,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.molecule) {
          toaster.success('Molecule deleted successfully');
        } else {
          toaster.danger('Could not delete molcule', {
            description: "Check it doesn't have any medications attached"
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage molecules"
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New Molecule
          </Button>
        }
      />

      <MoleculesList
        onEditClick={(molecule: any) => setShowForm(molecule)}
        onDeleteClick={(molecule: any) => setShowDeleteDialog(molecule)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateMolecule
          molecule={showForm || {}}
          onComplete={() => {
            toaster.success('Molecule saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteMolecule({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
