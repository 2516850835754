import React, { useState, useEffect } from 'react';
import { Heading, Paragraph, Pane, Card, Dialog, Icon } from 'evergreen-ui';
import { StepType } from '@/constants/protocolSteps';

const StepTypeCard = ({
  title,
  icon,
  description,
  isSelected,
  onClick
}: any) => (
  <Card
    elevation={1}
    width={200}
    height={200}
    alignItems="center"
    display="flex"
    border="default"
    borderColor={isSelected ? '#1070CA' : 'default'}
    background={isSelected ? 'tint1' : 'white'}
    margin={8}
    cursor="pointer"
    position="relative"
    onClick={onClick}
  >
    <Pane position="absolute" top={8} right={8}>
      {isSelected && <Icon icon="tick-circle" size={16} color="info" />}
    </Pane>

    <Pane textAlign="center" paddingLeft={16} paddingRight={16}>
      <Icon icon={icon} size={32} marginBottom={16} color="success" />
      <Heading marginBottom={4}>{title}</Heading>
      <Paragraph color="muted">{description}</Paragraph>
    </Pane>
  </Card>
);

const NewStepDialog = ({
  isShown,
  onConfirm,
  onClose,
  canCreateSubprotocolStep
}: any) => {
  const [selectedType, setSelectedType] = useState(StepType.DOSE);

  useEffect(() => {
    setSelectedType(StepType.DOSE);
  }, [isShown, setSelectedType]);

  return (
    <Dialog
      confirmLabel="Continue"
      isShown={isShown}
      hasClose={false}
      hasCancel={false}
      width={900}
      title="Create a new Step"
      onCloseComplete={onClose}
      onConfirm={() => onConfirm(selectedType)}
    >
      <Pane justifyContent="center">
        <Pane textAlign="center" margin={24}>
          <Heading size={600}>
            What kind of step do you want to create for this protocol?
          </Heading>
          <Paragraph color="muted">
            Muru supports 3 different step types right now; empty, referred, and
            dose.
          </Paragraph>
        </Pane>

        <Pane display="flex" justifyContent="center" margin={24}>
          <StepTypeCard
            title="Dose"
            icon="prescription"
            isSelected={selectedType === StepType.DOSE}
            onClick={() => setSelectedType(StepType.DOSE)}
            description="One or more doses to administer to a patient"
          />

          <StepTypeCard
            title="Referred"
            icon="document-open"
            isSelected={selectedType === StepType.REFERRED}
            onClick={() => setSelectedType(StepType.REFERRED)}
            description="References another protocol (or subprotocol)"
          />

          {canCreateSubprotocolStep && (
            <StepTypeCard
              title="Subprotocol"
              icon="git-branch"
              isSelected={selectedType === StepType.SUBPROTOCOL}
              onClick={() => setSelectedType(StepType.SUBPROTOCOL)}
              description="A set of substeps that refers to a more specific condition"
            />
          )}

          <StepTypeCard
            title="Empty"
            icon="square"
            isSelected={selectedType === StepType.BLANK}
            onClick={() => setSelectedType(StepType.BLANK)}
            description="Used when there are no other steps in a protocol"
          />
        </Pane>
      </Pane>
    </Dialog>
  );
};

export default NewStepDialog;
