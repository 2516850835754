import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_ALL = gql`
  mutation PublishAllProtocolSetsForOrg(
    $input: PublishAllProtocolSetsForOrganizationInput!
  ) {
    result: publishAllProtocolSetsForOrganization(input: $input) {
      success
    }
  }
`;

const PublishAllProtocolSetsDialog = ({
  isShown,
  organization,
  refetchQuery,
  onCloseComplete
}: any) => {
  const [publishProtocolSets, { loading }] = useMutation(PUBLISH_ALL, {
    variables: { input: { organizationId: organization.id } },
    refetchQueries: [refetchQuery],
    awaitRefetchQueries: true,
    onCompleted: (response: any) => {
      if (response.result.success) {
        toaster.success(`All protocol sets successfully published`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishProtocolSets()}
    >
      <Paragraph>
        Publishing all protocol sets will also publish all protocols and
        subprotocols underneath for all users who are a descendent of this
        organization.
      </Paragraph>
    </Dialog>
  );
};

export default PublishAllProtocolSetsDialog;
