import gql from 'graphql-tag';
import { PageInfoFragment } from 'components/shared/fragments';

const MedicationListItemFragment = gql`
  fragment MedicationListItem on Medication {
    id
    brandName
    genericName
    updatedAt
    lastPublishedAt
    organizationId
  }
`;

export const MEDICATION_LIST = gql`
  query MedicationList(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationMedicationsInput
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      medications(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...MedicationListItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${MedicationListItemFragment}
`;
