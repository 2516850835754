import React from 'react';
import {
  Pane,
  Avatar,
  Icon,
  Popover,
  majorScale,
  Position
} from 'evergreen-ui';
import styled from 'styled-components';
import UserMenu from '../UserMenu';
import Logo from 'icons/muru-logotype-primary.svg';
import { isSiteAdmin } from 'lib/auth';
import { isInPath } from 'lib/urls';
import { brandColor } from 'constants/colors.ts';

const StyledLogo = styled(Logo)`
  width: 90px;
  height: 33px;
  cursor: pointer;

  g {
    fill: #ffffff;
  }
`;

const TopBar = ({ user, onLogout, history, location: { pathname } }: any) => {
  const isUserAdmin = isSiteAdmin(user);
  const isViewingAdmin = isInPath('/admin', pathname);

  return (
    <Pane
      padding={16}
      display="flex"
      alignItems="center"
      background={brandColor}
      height={56}
    >
      {/* @ts-expect-error StyledLogo issue */}
      <StyledLogo onClick={() => history.push('/')} />
      <Pane
        cursor="pointer"
        marginLeft="auto"
        display="flex"
        alignItems="center"
      >
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={
            <UserMenu
              userId={user.id}
              isUserAdmin={isUserAdmin}
              isViewingAdmin={isViewingAdmin}
              name={user.name}
              email={user.email}
              history={history}
              onLogout={onLogout}
            />
          }
        >
          <Pane
            cursor="pointer"
            marginLeft="auto"
            display="flex"
            alignItems="center"
          >
            <Avatar name={user.name} size={majorScale(4)} marginRight={4} />
            <Icon color="white" icon="caret-down" />
          </Pane>
        </Popover>
      </Pane>
    </Pane>
  );
};

export default TopBar;
