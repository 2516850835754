import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import {
  Avatar,
  Badge,
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Button,
  toaster
} from 'evergreen-ui';
import OrgMenu from './Menu';
import CreateOrUpdateSubscription from 'components/admin/CreateOrUpdateSubscription';
import UpdateOrganization from 'components/admin/CreateOrUpdateOrganization';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import DeleteDialog from 'components/shared/DeleteDialog';
import SideSheet from 'components/shared/SideSheet';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import { OrganizationRolesWithLabels } from '@/constants/organizations';
import DebugDialog from './DebugDialog';
import CloneDialog from './CloneDialog';
import FilterMenu from './FilterMenu';
import { LIST_ORGS, DELETE_ORGANIZATION } from './queries';
import UpdateOrgConfig from '../UpdateOrgConfig';

const OrgsList = ({
  history,
  filters,
  onFilterUpdate,
  onPageInfoUpdate,
  page
}: any) => {
  const queryVariables = { ...page, filters };
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewDebug, setViewDebug] = useState(false);
  const [viewClone, setViewClone] = useState(false);
  const [OrgComponent, setSheetComponent] = useState(null);
  const { loading, data, error } = useQuery(LIST_ORGS, {
    variables: queryVariables,
    fetchPolicy: 'network-only'
  });

  const refetchQuery = {
    query: LIST_ORGS,
    variables: queryVariables
  };

  const [deleteOrganization, { loading: isDeleteLoading }] = useMutation(
    DELETE_ORGANIZATION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.organization) {
          toaster.success('Organization successfully deleted');
        } else {
          toaster.danger('Could not delete organization', {
            description: 'Make sure all dependencies have been resolved first.'
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  const navigateToOrg = (org: any) => history.push(`/orgs/${org.slug}`);
  const organizations = get(data, 'organizations.edges', []);

  return (
    <Pane>
      <Pane display="flex">
        <SearchListControl
          placeholder="Search by organization name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ ...filters, name })}
        />

        <Popover
          bringFocusInside
          shouldCloseOnExternalClick={false}
          content={({ close }: any) => (
            <FilterMenu
              close={close}
              filters={filters}
              onApply={(filters: any) => onFilterUpdate(filters)}
              onClearAll={() => onFilterUpdate({})}
            />
          )}
        >
          <Button marginLeft="auto" iconBefore="filter">
            Filter ({Object.keys(filters).length})
          </Button>
        </Popover>
      </Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Roles</Table.TextHeaderCell>
            <Table.TextHeaderCell>Subscription</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <>
              <Table.Body>
                {/* @ts-expect-error TS(7031): Binding element 'org' implicitly has an 'any' type... Remove this comment to see the full error message */}
                {organizations.map(({ node: org }) => (
                  <Table.Row
                    key={org.id}
                    height="auto"
                    minHeight={64}
                    isSelectable
                    onDoubleClick={() => navigateToOrg(org)}
                  >
                    <Table.Cell>
                      <Avatar src={org.logo && org.logo.url} name={org.name} />
                      <Text marginLeft={8} size={300} fontWeight={500}>
                        {org.name}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {org.type && <Badge>{org.type}</Badge>}
                    </Table.Cell>
                    <Table.Cell>
                      <Pane>
                        {org.roles &&
                          org.roles.map((role: any) => (
                            <Text size={300} key={role} display="block">
                              {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message*/}
                              {OrganizationRolesWithLabels[role]}
                            </Text>
                          ))}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell display="flex" alignItems="center">
                      {org.subscription ? (
                        <Text size={300}>
                          {org.subscription.occupiedSeatCount +
                            org.subscription.pendingInviteCount}{' '}
                          / {org.subscription.totalSeatCount} Seats
                        </Text>
                      ) : (
                        <Text size={300}>N/A</Text>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={org.insertedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <OrgMenu
                            onViewSelect={() => navigateToOrg(org)}
                            onDebugClick={() => setViewDebug(org)}
                            onCloneClick={() => setViewClone(org)}
                            onDeleteClick={() => setShowDeleteDialog(org)}
                            onEditOrganizationSelect={() =>
                              setSheetComponent(
                                // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
                                <UpdateOrganization
                                  organization={org}
                                  refetchQuery={refetchQuery}
                                  onComplete={() => {
                                    toaster.success('Organization updated');
                                    setSheetComponent(null);
                                  }}
                                />
                              )
                            }
                            onEditSubscriptionSelect={() =>
                              setSheetComponent(
                                // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
                                <CreateOrUpdateSubscription
                                  organizationId={org.id}
                                  subscription={org.subscription}
                                  onComplete={() => {
                                    toaster.success('Subscription created');
                                    setSheetComponent(null);
                                  }}
                                  refetchQuery={refetchQuery}
                                />
                              )
                            }
                            onUpdateConfigSelect={() =>
                              setSheetComponent(
                                // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
                                <UpdateOrgConfig
                                  organizationId={org.id}
                                  onComplete={() => {
                                    toaster.success('Configuration updated');
                                    setSheetComponent(null);
                                  }}
                                />
                              )
                            }
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <SideSheet
                isShown={!!OrgComponent}
                onCloseComplete={() => setSheetComponent(null)}
              >
                {OrgComponent || <br />}
              </SideSheet>
            </>
          )}
        </Table>

        {loading && (
          <Pane margin={16}>
            <Spinner size={16} />
          </Pane>
        )}

        {!loading && !error && organizations.length === 0 && <EmptyContent />}
      </Pane>

      <DebugDialog
        organization={viewDebug}
        isShown={viewDebug !== false}
        onClose={() => setViewDebug(false)}
      />

      <CloneDialog
        organization={viewClone}
        isShown={viewClone !== false}
        onClose={() => setViewClone(false)}
        refetchQuery={refetchQuery}
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteOrganization({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />

      <PaginationControls
        pageInfo={data && data.organizations && data.organizations.pageInfo}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default withRouter(OrgsList);
