import React from 'react';
import Media from 'react-media';
import { Pane, Heading, Paragraph, Link } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import AppMagicLogin from 'components/shared/AppMagicLogin';
import { launchIntercomWithMessage } from 'lib/analytics';
import AppDownload from 'images/muru-app-download.png';

const mediaQueries = {
  phone: '(max-width: 499px)',
  everythingElse: '(min-width: 500px)'
};

// @ts-expect-error TS(6133): 'user' is declared but its value is never read.
const AppPrompt = ({ user }: any) => (
  <Media queries={mediaQueries}>
    {(matches: any) => (
      <Pane
        textAlign="center"
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems={matches.phone ? 'flex-start' : 'center'}
      >
        <Pane
          background="white"
          maxWidth={400}
          elevation={matches.phone ? 0 : 1}
          border={matches.phone ? 'none' : true}
        >
          <Pane backgroundColor="#272727" paddingTop={16}>
            <img
              alt="Download the Muru app"
              src={AppDownload}
              width="50%"
              style={{ display: 'block', margin: '0 auto' }}
            />{' '}
          </Pane>

          <Pane padding={32}>
            <Heading marginBottom={8} size={600}>
              Please continue on the Muru app
            </Heading>
            <Paragraph marginBottom={16}>
              To continue you'll need to get the Muru mobile app. The link below
              will help you get the app if you don't already have it, or will
              open the app if you've already downloaded it.
            </Paragraph>

            <AppMagicLogin />

            <Paragraph color="muted" marginTop={16}>
              Need to make changes to{' '}
              <Link is={RouterLink} to={`/account`}>
                your account?
              </Link>{' '}
              Or, if you need any help you can
              {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 0. */}
              <Link href="#" onClick={() => launchIntercomWithMessage()}>
                chat with us here
              </Link>
              .
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
    )}
  </Media>
);

export default AppPrompt;
