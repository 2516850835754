import React from 'react';
import { FormField, Textarea } from 'evergreen-ui';

const TextareaField = ({
  label,
  required,
  description,
  validationMessage,
  formikBag,
  value,
  ...props
}: any) => {
  return (
    <FormField
      label={label}
      isRequired={required}
      description={description}
      validationMessage={validationMessage}
      width="100%"
      marginBottom={24}
    >
      <Textarea {...props} value={value} />
    </FormField>
  );
};

export default TextareaField;
