import { CalendarUnitsWithLabels } from 'constants/units.ts';
import { orgPath, protocolSetPath, protocolPath } from './urls';

export function protocolName(protocol: any) {
  const subprotocolText = protocol.parent ? `(${protocol.parent.name})` : '';
  return `${protocol.name} ${subprotocolText}`;
}

export function combineStepsForProtocol(
  steps: any,
  parentSteps = [],
  // @ts-expect-error TS(6133): 'protocolId' is declared but its value is never re... Remove this comment to see the full error message
  protocolId: any
) {
  return {
    stepsBefore: parentSteps.length > 0 ? [parentSteps[0]] : [],
    steps: steps,
    stepsAfter: parentSteps.length > 0 ? [parentSteps[2]] : []
  };
}

export function generateBreadcrumbs(
  organization: any,
  protocolSet: any,
  protocol: any
) {
  return [
    {
      to: `${orgPath(organization)}/protocol-sets`,
      title: 'Protocol Sets'
    },
    ...(protocolSet
      ? [
          {
            to: `${protocolSetPath(organization, protocolSet)}/protocols`,
            title: protocolSet.name
          }
        ]
      : []),
    ...(protocolSet && protocol && protocol.parent
      ? [
          {
            to: `${protocolPath(organization, protocolSet, protocol.parent)}`,
            title: protocol.parent.name
          }
        ]
      : []),
    ...(protocolSet && protocol
      ? [
          {
            to: `${protocolPath(organization, protocolSet, protocol)}`,
            title: protocol.name
          }
        ]
      : [])
  ];
}

export function formatAgeRange({ minValue, maxValue, unit }: any) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return `${minValue} - ${maxValue} ${CalendarUnitsWithLabels[unit]}`;
}
