import gql from 'graphql-tag';

export const PLAN_BY_CODE = gql`
  query PaymentPlanByCode($code: String!) {
    paymentPlan(code: $code) {
      id
      name
      interval
      amountInCents
      seatAmountInCents
      trialPeriodDays
      requiresCreditCardForSignUp
      type
    }
  }
`;
