import TextInputField from './Fields/TextInputField';
import TagInput from './Fields/TagInput';
import Select from './Fields/Select';
import Combobox from './Fields/Combobox';
import AsyncSelect from './Fields/AsyncSelect';
import MultiSelect from './Fields/MultiSelect';
import ListInput from './Fields/ListInput';
import ListOfSubFields from './Fields/ListOfSubFields';
import Section from './Section';
import SwitchInputField from './Fields/SwitchInput';
import TextareaField from './Fields/TextareaField';
import UploadField from './Fields/UploadField';
import SubFields from './Fields/SubFields';
import SegmentedControl from './Fields/SegmentedControl';

export default function fieldType(type: any) {
  switch (type) {
    case 'section':
      return Section;
    case 'select':
      return Select;
    case 'combobox':
      return Combobox;
    case 'asyncSelect':
      return AsyncSelect;
    case 'multiSelect':
      return MultiSelect;
    case 'tag':
      return TagInput;
    case 'switch':
      return SwitchInputField;
    case 'listOf':
      return ListInput;
    case 'listOfSubFields':
      return ListOfSubFields;
    case 'subFields':
      return SubFields;
    case 'textarea':
      return TextareaField;
    case 'upload':
      return UploadField;
    case 'segmentedControl':
      return SegmentedControl;
    default:
      if (type && typeof type !== 'string') {
        return type;
      }

      return TextInputField;
  }
}
