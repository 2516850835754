import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { setAuthCredentials } from 'lib/auth';

function validateNextPath(next: any) {
  if (next && next.startsWith('/')) {
    return next;
  }

  return '/';
}

const AuthRedirect = () => {
  const location = useLocation();
  const { token, next } = queryString.parse(location.search);

  const nextPath = validateNextPath(next);

  if (token) {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    setAuthCredentials(token);
  }

  return <Redirect to={nextPath} />;
};

export default AuthRedirect;
