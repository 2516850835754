import React from 'react';
import { BackButton, Strong } from 'evergreen-ui';
import queryString from 'query-string';
import PageHeader from 'components/shared/PageHeader';
import RelativeDate from 'components/shared/RelativeDate';
import ReferencingProtocolList from 'components/organizations/ReferencingProtocolList';
import { protocolSetPath, protocolPath } from 'lib/urls';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';
import { generateBreadcrumbs } from 'lib/protocols';

export default ({ history, protocol, protocolSet, organization }: any) => {
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters: { ...filters, publishedState: 'DRAFT' },
    protocolId: protocol.id
  };

  return (
    <>
      <PageHeader
        title={`All protocols that reference ${protocol.name}`}
        subtitle={
          <>
            Part of <Strong>{protocolSet.name}</Strong>, last edited{' '}
            <RelativeDate textSize={400} date={protocol.updatedAt} />
          </>
        }
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(
                `${protocolSetPath(organization, protocolSet)}/protocols`
              )
            }
          />
        }
        breadcrumbs={generateBreadcrumbs(organization, protocolSet, protocol)}
      />

      <ReferencingProtocolList
        protocolId={protocol.id}
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onProtocolSelect={(protocolSet: any, protocol: any) =>
          history.push(protocolPath(organization, protocolSet, protocol))
        }
      />
    </>
  );
};
