import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Table, Icon, Text, Pane, Spinner, Badge } from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import SearchListControl from 'components/shared/SearchListControl';
import PaginationControls from 'components/shared/PaginationControls';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { LIST_REFERENCING_PROTOCOLS } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const ReferencingProtocolList = ({
  filters,
  variables,
  onFilterUpdate,
  onPageInfoUpdate,
  onProtocolSelect
}: any) => {
  const { loading, data, error } = useQuery(LIST_REFERENCING_PROTOCOLS, {
    variables,
    fetchPolicy: 'network-only'
  });

  //const orderBy = filters.orderBy || defaultOrderBy;
  const protocols = get(data, 'protocol.referencingProtocols.edges', []);
  const orderBy = filters.orderBy || defaultOrderBy;

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {protocols.map(({ node: protocol }) => (
                <Table.Row
                  key={protocol.id}
                  onSelect={() =>
                    onProtocolSelect(protocol.protocolSet, protocol)
                  }
                  isSelectable
                  height="auto"
                  minHeight={48}
                >
                  <Table.Cell>
                    <Icon
                      icon="document-open"
                      size={16}
                      marginRight={8}
                      color="muted"
                    />
                    <Text size={300} fontWeight={500}>
                      {protocol.name} ({protocol.protocolSet.name})
                    </Text>
                  </Table.Cell>
                  <Table.Cell
                    width={100}
                    display="flex"
                    alignItems="center"
                    flex="none"
                  >
                    {protocol.updatedAt === protocol.lastPublishedAt ? (
                      <Badge color="green">Published</Badge>
                    ) : (
                      <Badge color="blue">Draft</Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {protocol.lastPublishedAt ? (
                      <RelativeDate date={protocol.lastPublishedAt} />
                    ) : (
                      <Text size={300}>N/A</Text>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={protocol.updatedAt} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && protocols.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'protocolSet.protocols.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default ReferencingProtocolList;
