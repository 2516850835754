import {
  configureStore,
  getDefaultMiddleware,
  Middleware
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import globalSlice from './global';
import onboardingSlice from './onboarding';

const reducer = {
  global: globalSlice.reducer,
  onboarding: onboardingSlice.reducer
};

const middleware: Middleware[] = [
  ...getDefaultMiddleware(),
  logger as Middleware
];

const store = configureStore({
  reducer,
  middleware,
  devTools: !import.meta.env.PROD
});

export default store;
