import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Dialog, Heading, Pane, Paragraph } from 'evergreen-ui';
import ManageSeatQuantities from 'components/shared/ManageSeatsQuantities';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';
import { onCompleted } from 'lib/formHelpers';
import { UPDATE_SEATS } from './queries';

function seatCountInvalid(seatCount: any, minimumSeatCount: any) {
  return seatCount === 0 || seatCount < minimumSeatCount;
}

const ManageSeats = ({
  subscriptionId,
  isShown,
  minimumSeatCount,
  existingSeatCount,
  perSeatCost,
  interval,
  onClose,
  onComplete
}: any) => {
  const [errors, setErrors] = useState(null);
  const [seatCount, setSeatCount] = useState(existingSeatCount);
  const isSeatCountInvalid = seatCountInvalid(seatCount, minimumSeatCount);
  const [updateSeats, { loading }] = useMutation(UPDATE_SEATS, {
    variables: {
      input: {
        id: subscriptionId,
        totalSeatCount: seatCount
      }
    },
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  useEffect(() => {
    setSeatCount(existingSeatCount);
  }, [setSeatCount, isShown, existingSeatCount]);

  return (
    <Dialog
      isShown={isShown}
      title="Manage Seats"
      hasCancel={true}
      hasClose={false}
      isConfirmDisabled={isSeatCountInvalid || seatCount === existingSeatCount}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onCloseComplete={() => onClose()}
      isConfirmLoading={loading}
      onConfirm={() => {
        setErrors(null);
        updateSeats();
      }}
      confirmLabel="Update"
    >
      <Pane>
        <Heading size={400} marginBottom={16}>
          Add / remove seats
        </Heading>

        <ManageSeatQuantities
          minimumSeatCount={minimumSeatCount}
          existingSeatCount={existingSeatCount}
          seatCount={seatCount}
          perSeatCost={perSeatCost}
          interval={interval}
          onSeatCountChange={setSeatCount}
          invalidSeatCount={isSeatCountInvalid}
        />

        {errors && (
          <Pane marginBottom={16}>
            <Heading color="#BF0E08" size={400}>
              There was a problem, please try again
            </Heading>
            <ValidationErrorMessage error={errors} />
          </Pane>
        )}

        <Paragraph size={300} maxWidth={420} color="muted">
          You will be charged today for the new changes, prorated for the
          current billing period. All billing changes will appear in an invoice
          email.
        </Paragraph>
      </Pane>
    </Dialog>
  );
};

export default ManageSeats;
