import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { setAuthCredentials } from 'lib/auth';
import { onCompleted } from 'lib/formHelpers';
import globalSlice from 'redux/global';
import {
  InputErrorFragment,
  SessionFragment
} from 'components/shared/fragments';
import Form from './Form';

const { setCurrentUser } = globalSlice.actions;

const SIGNUP_MUTATION = gql`
  mutation SignUp($input: SignUpInput!) {
    result: signUp(input: $input) {
      errors {
        ...InputError
      }
      session {
        ...Session
      }
    }
  }
  ${InputErrorFragment}
  ${SessionFragment}
`;

const SignupForm = ({ email, onSignup }: any) => {
  const dispatch = useDispatch();
  const [validationErrors, setErrors] = useState({});
  const [signUp, { loading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (response: any) =>
      onCompleted({
        response,
        setErrors,
        onComplete: () => {
          setAuthCredentials(
            response.result.session.token,
            response.result.session.refreshToken
          );
          dispatch(setCurrentUser(response.result.session.user));
          setTimeout(() => onSignup(), 0);
        }
      })
  });

  return (
    <Form
      loading={loading}
      email={email}
      validationErrors={validationErrors}
      onSubmit={(input: any) => {
        setErrors({});
        signUp({ variables: { input } });
      }}
    />
  );
};

export default SignupForm;
