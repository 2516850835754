import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import {
  InputErrorFragment,
  HospitalFragment
} from 'components/shared/fragments';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { HospitalForm } from './form';

const CREATE_HOSPITAL = gql`
  mutation CreateHospital($input: CreateHospitalInput!) {
    result: createHospital(input: $input) {
      errors {
        ...InputError
      }
      hospital {
        ...Hospital
      }
    }
  }
  ${HospitalFragment}
  ${InputErrorFragment}
`;

const UPDATE_HOSPITAL = gql`
  mutation UpdateHospital($input: UpdateHospitalInput!) {
    result: updateHospital(input: $input) {
      errors {
        ...InputError
      }
      hospital {
        ...Hospital
      }
    }
  }
  ${HospitalFragment}
  ${InputErrorFragment}
`;

const initialValues = {
  name: '',
  type: 'CIVILIAN',
  organizationId: null,
  aliases: [],
  sources: [],
  role: null
};

function normalizeAuthority({ authority, assignedCapabilities }: any) {
  return {
    authorityId: authority.id,
    capabilities: assignedCapabilities.map((item: any) => item.id)
  };
}

const CreateOrUpdateHospital = ({
  hospital,
  canChangeOrganization = true,
  organizationId,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const [createOrUpdateHospital, { loading }] = useMutation(
    hospital ? UPDATE_HOSPITAL : CREATE_HOSPITAL,
    {
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  let formData = sanitizeData(hospital, ['lastPublishedAt']) || initialValues;

  // Normalize sources to be a list of id values
  // @ts-expect-error TS(2339): Property 'sources' does not exist on type 'object'... Remove this comment to see the full error message
  formData.sources = formData.sources.map((source: any) => source.id);

  // @ts-expect-error TS(2339): Property 'authorities' does not exist on type 'obj... Remove this comment to see the full error message
  if (formData.authorities) {
    // @ts-expect-error TS(2339): Property 'authorities' does not exist on type 'obj... Remove this comment to see the full error message
    formData.authorities = hospital.authorities.map(normalizeAuthority);
  }

  return (
    <FormBuilder
      key={hospital?.id ?? -1}
      initialValues={formData}
      form={HospitalForm(
        organizationId || (hospital || {}).organizationId,
        canChangeOrganization
      )}
      errors={errors}
      isLoading={loading}
      onSubmit={(input: any) => {
        setErrors({});
        createOrUpdateHospital({ variables: { input } });
      }}
    />
  );
};

export default CreateOrUpdateHospital;
