import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, toaster } from 'evergreen-ui';
import SideSheet from 'components/shared/SideSheet';
import PageHeader from 'components/shared/PageHeader';
import AppDashboardItemList from 'components/organizations/AppDashboardItemList';
import {
  DASHBOARD_ITEM_LIST,
  listVariables
} from 'components/organizations/AppDashboardItemList/AppDashboardItemList';
import CreateOrUpdateAppDashboardItem from 'components/organizations/CreateOrUpdateAppDashboardItem';
import DeleteDialog from 'components/shared/DeleteDialog/DeleteDialog';
import gql from 'graphql-tag';

const DELETE_ITEM = gql`
  mutation DeleteItem($input: DeleteDashboardItemForOrganizationInput!) {
    result: deleteDashboardItemForOrganization(input: $input) {
      dashboardItem {
        id
      }
    }
  }
`;

export default ({ organization }: any) => {
  const [showForm, setShowForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const refetchQuery = {
    query: DASHBOARD_ITEM_LIST,
    variables: listVariables(organization)
  };

  const [deleteItem, { loading }] = useMutation(DELETE_ITEM, {
    refetchQueries: [refetchQuery],
    onCompleted: () => {
      toaster.success('Dashboard item deleted');
      setShowDeleteDialog(false);
    }
  });

  return (
    <>
      <PageHeader
        title="Manage app dashboard items"
        subtitle="Manage the default content the users in the organization will see on the front screen of their app."
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New Dashboard Item
          </Button>
        }
      />

      <AppDashboardItemList
        organization={organization}
        onEditClick={(dashboardItem: any) => setShowForm(dashboardItem)}
        onDeleteClick={(dashboardItem: any) =>
          setShowDeleteDialog(dashboardItem)
        }
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateAppDashboardItem
          dashboardItem={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Dashboard item saved successfully');
            setShowForm(false);
          }}
          refetchQuery={{
            query: DASHBOARD_ITEM_LIST,
            variables: listVariables(organization)
          }}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteItem({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={loading}
      />
    </>
  );
};
