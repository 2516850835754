import React from 'react';
import { Menu } from 'evergreen-ui';

const ProtocolSetMenu = ({
  canBeManaged = false,
  canBeExcluded = true,
  isExcluded = true,
  onEditClick,
  onExcludeToggle,
  onDeleteClick,
  onManageFunctionsClick,
  onPublishClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item icon="document-open" onSelect={onManageFunctionsClick}>
          Manage protocols
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      {canBeExcluded && (
        <>
          <Menu.Group>
            <Menu.Item
              icon={isExcluded ? 'eye-open' : 'eye-off'}
              onSelect={onExcludeToggle}
            >
              {isExcluded ? 'Include' : 'Exclude'} protocol set
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
        </>
      )}
      {canBeManaged && (
        <>
          <Menu.Group>
            <Menu.Item onSelect={onEditClick}>Edit protocol set</Menu.Item>
            <Menu.Item intent="success" onSelect={onPublishClick}>
              Publish protocol set
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onDeleteClick}>
              Delete protocol set...
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default ProtocolSetMenu;
