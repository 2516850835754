import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const PURCHASE_FOR_ORGANIZATION = gql`
  mutation PurchaseForOrganization($input: PurchaseForOrganizationInput!) {
    result: purchaseForOrganization(input: $input) {
      errors {
        ...InputError
      }
      subscription {
        id
        stripePaymentIntentStatus
        stripePaymentIntentSecret
        insertedAt
      }
    }
  }
  ${InputErrorFragment}
`;
