import React from 'react';
import { FormField, TagInput } from 'evergreen-ui';

const TagInputField = ({
  placeholder,
  label,
  required,
  description,
  validationMessage,
  isInvalid,
  formikBag,
  onChange,
  value,
  ...props
}: any) => {
  return (
    <FormField
      label={label}
      isRequired={required}
      description={description}
      validationMessage={validationMessage}
      width="100%"
      marginBottom={24}
    >
      <TagInput
        {...props}
        values={value || []}
        onChange={(values: any) => {
          formikBag.setFieldTouched(props.name);
          formikBag.setFieldValue(props.name, values);
        }}
        width="100%"
        inputProps={{ placeholder: placeholder }}
      />
    </FormField>
  );
};

export default TagInputField;
