import React from 'react';
import {
  Pane,
  Strong,
  Text,
  Popover,
  Avatar,
  Badge,
  IconButton,
  Position
} from 'evergreen-ui';
import { get } from 'lodash';
import {
  SubscriptionStatesToColors,
  SubscriptionStatesToText,
  subscriptionStatus,
  SubscriptionStates
} from 'lib/subscription';
import SubscriptionMenu from './SubscriptionMenu';

const SubscriptionSummary = ({
  seat,
  showBorder,
  onViewClick,
  onLeaveClick
}: any) => {
  const subscription = seat.subscription || seat.organization.subscription;

  let status = subscriptionStatus(subscription);

  // Special extra check to see if the seat itself was cancelled
  // this is only relevant for individual subscriptions where the
  // user choses to remove a seat and not cancel the whole sub
  if (seat.cancelledAt) {
    status = SubscriptionStates.CANCELLED;
  }

  return (
    <Pane paddingY={8} borderTop={!showBorder ? 'none' : 'muted'}>
      <Pane display="flex" alignItems="center">
        <Avatar
          src={get(seat, 'organization.logo.url')}
          name={seat.organization.name}
          size={32}
        />
        <Pane marginLeft={8}>
          <Strong display="block">{seat.organization.name}</Strong>
          <Text color="muted">{get(seat, 'credentials[0].name')}</Text>
        </Pane>
        {/* @ts-expect-error color */}
        <Badge marginLeft="auto" color={SubscriptionStatesToColors[status]}>
          {SubscriptionStatesToText[status]}
        </Badge>
        <Popover
          content={
            <SubscriptionMenu
              onViewClick={onViewClick}
              onLeaveClick={onLeaveClick}
            />
          }
          position={Position.BOTTOM_RIGHT}
        >
          <IconButton
            marginLeft={8}
            icon="more"
            appearance="minimal"
            height={24}
          />
        </Popover>
      </Pane>
    </Pane>
  );
};

export default SubscriptionSummary;
