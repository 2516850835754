import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Heading, Pane, Paragraph, Text, Link } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import SignupForm from 'components/accounts/SignupForm';
import BrandedLayout from 'components/shared/BrandedLayout';
import StyledLogo from 'components/shared/Layout/MuruLogo';
import { parseRedirectPath } from 'lib/urls';

function Signup({ history, location }: any) {
  const { t } = useTranslation();
  const redirectPath = parseRedirectPath(location);
  const { email } = queryString.parse(location.search);

  return (
    <BrandedLayout>
      <Pane width={'100%'} maxWidth={380} textAlign="center">
        <StyledLogo />
        <Helmet>
          <title>{t('signup.title')}</title>
        </Helmet>

        <Heading size={600}>{t('signup.welcome')}</Heading>
        <Text color="muted">
          Create a new account on Muru to access your agencies content.
        </Text>

        <SignupForm
          data-testid="signup-form"
          email={email}
          onSignup={() => history.push(redirectPath)}
        />

        <Paragraph color="muted" marginTop={16}>
          Already have an account?{' '}
          <Link is={RouterLink} to={`/login?from=${redirectPath}`}>
            Login
          </Link>
        </Paragraph>
      </Pane>
    </BrandedLayout>
  );
}

export default Signup;
