import React from 'react';
import { Pane, toaster, BackButton } from 'evergreen-ui';
import PageHeader from 'components/shared/PageHeader';
import CreateOrUpdateMedication from 'components/organizations/CreateOrUpdateMedication';

export default ({ history, medication, organization }: any) => {
  return (
    <>
      <PageHeader
        title="Update medication"
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(`/orgs/${organization.slug}/medications`)
            }
          />
        }
      />
      <Pane width="500px">
        <CreateOrUpdateMedication
          organizationId={organization.id}
          medication={medication}
          onComplete={() => {
            toaster.success('Medication successfully updated');
            history.push(`/orgs/${organization.slug}/medications`);
          }}
        />
      </Pane>
    </>
  );
};
