import { get, map, pick } from 'lodash';
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import { addDays } from 'date-fns';

const TransloaditTemplates = {
  DEFAULT:
    import.meta.env.VITE_TRANSLOADIT_TEMPLATE_ID ||
    '2ae596c97dfb4785b0aa7c350094b305'
};

const transloaditAuth = {
  key: import.meta.env.VITE_TRANSLOADIT_AUTH_TOKEN || ' ',
  expires: addDays(new Date(), 1).toISOString()
};

function uppy(attrs: any, onComplete: any) {
  return new Uppy(attrs)
    .use(Transloadit, {
      waitForEncoding: true,
      params: {
        auth: transloaditAuth,
        template_id: TransloaditTemplates.DEFAULT
      }
    })
    .on('transloadit:complete', (assembly: any) => {
      const files = transformResult(assembly, ':original');
      return onComplete(files);
    });
}

export function transformResult(assembly: any, fileKey: any) {
  return map(get(assembly, `results.${fileKey}`, []), transformResultIntoFile);
}

export function transformResultIntoFile(assemblyResult: any) {
  const { name, ssl_url, size, meta } = assemblyResult;

  return {
    name,
    url: ssl_url,
    size,
    meta: pick(meta, ['width', 'height'])
  };
}

export default uppy;
