import React from 'react';
import {
  Paragraph,
  Button,
  Pane,
  Link,
  UnorderedList,
  ListItem,
  Heading
} from 'evergreen-ui';
import gql from 'graphql-tag';
import { head } from 'lodash';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { clearAuthCredentials } from 'lib/auth';
import { SUPPORT_EMAIL } from '../../../constants';

const ACCEPT_ORG_INVITE = gql`
  mutation AcceptOrganizationInvite($input: AcceptOrganizationInviteInput!) {
    acceptOrganizationInvite(input: $input) {
      id
    }
  }
`;

const AcceptInvite = ({ inviteId, invite, currentUser, onAccepted }: any) => {
  const client = useApolloClient();
  const [acceptInvite, { loading, error }] = useMutation(ACCEPT_ORG_INVITE, {
    variables: { input: { id: inviteId } },
    onCompleted: () => onAccepted()
  });

  const inviteCredential = head(invite.credentials);
  const inviteRole = head(invite.roles);

  return (
    <Pane>
      <Paragraph color="muted">
        Confirm your details are correct, including your credential level (where
        applicable).
      </Paragraph>

      <Pane
        textAlign="left"
        borderTop
        borderBottom
        marginTop={24}
        marginBottom={16}
        paddingTop={16}
        paddingBottom={16}
      >
        <Heading size={100} marginBottom={8}>
          Your details
        </Heading>

        <UnorderedList>
          <ListItem icon="person" iconColor="muted">
            {currentUser.name}
          </ListItem>
          <ListItem icon="envelope" iconColor="muted">
            {currentUser.email}
          </ListItem>
          <ListItem icon="endorsed" iconColor="muted">
            {/* @ts-expect-error TS(2571): Object is of type 'unknown'. */}
            {inviteCredential ? inviteCredential.name : inviteRole.name} at{' '}
            {invite.organization.name}
          </ListItem>
        </UnorderedList>
      </Pane>

      <Button
        height={40}
        display="flex"
        width="100%"
        justifyContent="center"
        marginBottom={16}
        appearance="primary"
        onClick={acceptInvite}
        isLoading={loading}
      >
        Accept Invite
      </Button>

      <Paragraph color="muted">
        Details not right?{' '}
        <Link href={`mailto:${SUPPORT_EMAIL}`}>Contact support</Link> or{' '}
        <Link
          onClick={(e: any) => {
            e.preventDefault();
            client.resetStore();
            clearAuthCredentials();
          }}
        >
          sign out
        </Link>
      </Paragraph>

      {error && <Paragraph color="danger">{error.message}</Paragraph>}
    </Pane>
  );
};

export default AcceptInvite;
