import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Text, Icon, Pane } from 'evergreen-ui';

const Breadcrumb = ({ to, title, isLast }: any) => (
  <Pane display="flex" alignItems="center" maxWidth={200} minWidth={0}>
    {isLast ? (
      <Text
        minWidth={0}
        size={300}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {title}
      </Text>
    ) : (
      <Link
        size={300}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        is={RouterLink}
        to={to}
      >
        {title}
      </Link>
    )}
    {!isLast && (
      <Icon
        size={12}
        icon="chevron-right"
        color="muted"
        marginLeft={4}
        marginRight={4}
      />
    )}
  </Pane>
);

const Breadcrumbs = ({ items }: any) => {
  const totalItems = items.length;

  return (
    <Pane
      paddingTop={8}
      paddingBottom={8}
      borderBottom
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {items.map((item: any, idx: any) => (
        <Breadcrumb key={idx} {...item} isLast={idx === totalItems - 1} />
      ))}
    </Pane>
  );
};

export default Breadcrumbs;
