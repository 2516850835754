import React from 'react';
import { Menu } from 'evergreen-ui';

const ManageMemberMenu = ({
  permissions,
  onUpdateClick,
  onAssignCredentialsClick,
  onRemoveClick,
  onRemoveIndividualClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        {permissions.canManageRoles && (
          <Menu.Item onSelect={onUpdateClick}>Change Role</Menu.Item>
        )}
        {permissions.canManageCredentials && (
          <Menu.Item onSelect={onAssignCredentialsClick}>
            Assign credentials
          </Menu.Item>
        )}
      </Menu.Group>
      {permissions.canManageInvites && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onRemoveClick}>
              Remove member
            </Menu.Item>
          </Menu.Group>
        </>
      )}
      {permissions.canManageIndividualSeat && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onRemoveIndividualClick}>
              Force remove member
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default ManageMemberMenu;
