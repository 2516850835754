import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import { onCompleted } from 'lib/formHelpers';
import FormBuilder from 'components/shared/FormBuilder';
import { OrganizationForm } from './Form';
import { ORG_SIGNUP_MUTATION } from './queries';

const WaitlistForm = ({ onSignup, currentUser, zipCode }: any) => {
  const [errors, setErrors] = useState({});
  const [signUp, { loading }] = useMutation(ORG_SIGNUP_MUTATION, {
    onCompleted: (response: any) =>
      onCompleted({
        response,
        setErrors,
        onComplete: onSignup
      })
  });

  return (
    <Pane>
      <FormBuilder
        key={currentUser.id}
        isInline
        saveButtonTitle="Signup for waitlist"
        initialValues={{
          zipCode: zipCode.zipCode,
          state: zipCode.state,
          name: currentUser && currentUser.name,
          email: currentUser && currentUser.email
        }}
        form={OrganizationForm}
        errors={errors}
        isLoading={loading}
        showErrorHint={false}
        saveButtonProps={{
          width: '100%',
          justifyContent: 'center',
          marginTop: 16,
          display: 'flex',
          height: 40,
          intent: null
        }}
        onSubmit={(input: any) => {
          setErrors({});
          signUp({ variables: { input } });
        }}
      />
    </Pane>
  );
};

export default WaitlistForm;
