import React, { useState } from 'react';
import {
  Button,
  Pane,
  Heading,
  Paragraph,
  FormFieldValidationMessage
} from 'evergreen-ui';
import { FieldArray } from 'formik';
import ReferencedSource from './ReferencedSource';
import SourceSelectionDialog from './SourceSelectionDialog';

const ReferencedSources = ({
  placeholder,
  label,
  description,
  value = [],
  validationMessage,
  isInvalid,
  formikBag,
  name,
  onChange,
  organizationId,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const [currentlyEditing, setShowDialog] = useState(false);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: any) => {
        return (
          <Pane marginBottom={24}>
            <Pane display="flex" alignItems="center" marginBottom={8}>
              <Pane>
                <Heading size={400}>{label}</Heading>
                {description && (
                  <Paragraph color="muted">{description}</Paragraph>
                )}
              </Pane>
              <Button
                type="button"
                appearance="minimal"
                marginLeft="auto"
                height={20}
                onClick={() =>
                  // @ts-expect-error TS(2345): Argument of type '{ index: any; value: string; }' ... Remove this comment to see the full error message
                  setShowDialog({ index: value.length, value: '' })
                }
              >
                Add
              </Button>
            </Pane>

            <Pane borderTop="muted" marginBottom={16}>
              {value.map((id: any, index: any) => (
                <ReferencedSource
                  key={id}
                  id={id}
                  index={index}
                  onEditClick={() => {
                    // @ts-expect-error TS(2345): Argument of type '{ index: any; value: any; }' is ... Remove this comment to see the full error message
                    setShowDialog({ index, value: id });
                  }}
                  onDeleteClick={() => {
                    arrayHelpers.remove(index);
                  }}
                />
              ))}
            </Pane>

            {typeof validationMessage === 'string' && (
              <FormFieldValidationMessage marginTop={4}>
                {validationMessage}
              </FormFieldValidationMessage>
            )}

            <SourceSelectionDialog
              organizationId={organizationId}
              isShown={!!currentlyEditing}
              // @ts-expect-error TS(2339): Property 'value' does not exist on type 'true'.
              source={currentlyEditing && currentlyEditing.value}
              onClose={() => setShowDialog(false)}
              selectSource={(item: any) => {
                // @ts-expect-error TS(2339): Property 'index' does not exist on type 'boolean'.
                arrayHelpers.replace(currentlyEditing.index, item);
                setShowDialog(false);
              }}
            />
          </Pane>
        );
      }}
    />
  );
};

export default ReferencedSources;
