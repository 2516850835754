import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import globalSlice from 'redux/global';
import { CURRENT_USER_QUERY } from './queries';
import LoadingScreen from './LoadingScreen';

const { setCurrentUser } = globalSlice.actions;

const WithCurrentUser = ({ children, shouldRefetch = false, onError }: any) => {
  const dispatch = useDispatch();

  const { loading, data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: shouldRefetch ? 'network-only' : 'cache-first',
    onError
  });

  useEffect(() => {
    if (data) {
      dispatch(setCurrentUser(data.currentUser));
    }
  }, [data]);

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};

export default WithCurrentUser;
