import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { setAuthCredentials } from 'lib/auth';
import globalSlice from 'redux/global';
import { SessionFragment } from 'components/shared/fragments';
import Form from './Form';

const { setCurrentUser } = globalSlice.actions;

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...Session
    }
  }
  ${SessionFragment}
`;

const LoginForm = ({ onLogin }: any) => {
  const dispatch = useDispatch();
  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ login }: any) => {
      setAuthCredentials(login.token, login.refreshToken);
      dispatch(setCurrentUser(login.user));
      setTimeout(() => onLogin(), 0);
    }
  });

  return <Form login={login} loading={loading} />;
};

export default LoginForm;
