import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_SOURCE = gql`
  mutation CreateSource($input: CreateSourceForOrganizationInput!) {
    result: createSourceForOrganization(input: $input) {
      source {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_SOURCE = gql`
  mutation UpdateSource($input: UpdateSourceForOrganizationInput!) {
    result: updateSourceForOrganization(input: $input) {
      source {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const initialValues = {
  name: ''
};

const CreateOrUpdateSource = ({
  organizationId,
  source,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasSource = source && source.id;
  const [createOrUpdateSource, { loading }] = useMutation(
    hasSource ? UPDATE_SOURCE : CREATE_SOURCE,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasSource
    ? sanitizeData(source, ['versionCount', 'organization', 'versions'])
    : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasSource ? 'Update source' : 'Create new source'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={source?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            // We only want to add the organizationId to the case where we're creating
            // new sources and not when we're editing an existing source.
            const variables = hasSource ? input : { ...input, organizationId };

            setErrors({});
            createOrUpdateSource({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateSource;
