import React, { useEffect } from 'react';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { useQuery } from '@apollo/react-hooks';
import { Pane, Heading, Paragraph, Strong } from 'evergreen-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ZipCodeEntry from 'components/onboarding/ZipCodeEntry';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import LoadingScreen from 'components/shared/LoadingScreen';
import onboardingSlice from 'redux/onboarding';
import { page, track } from 'lib/analytics';
import { IS_COVID_FREE_TRIAL } from 'lib/subscription';
import { DEFAULT_PLAN } from 'lib/../constants';
import { PLAN_BY_CODE } from './queries';

const { setResult, setPlan } = onboardingSlice.actions;

const ZipEntry = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const planCode = params.plan || DEFAULT_PLAN;

  const { loading } = useQuery(PLAN_BY_CODE, {
    variables: { code: planCode },
    fetchPolicy: 'network-only',
    onCompleted: ({ paymentPlan }: any) => dispatch(setPlan(paymentPlan))
  });

  useEffect(() => {
    page('Onboarding Started');
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Pane>
      <Helmet>
        <title>Sign up for Muru</title>
      </Helmet>
      <Pane
        marginBottom={24}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MuruLogo />
        <Heading marginBottom={4} size={600}>
          Let’s get you set up
        </Heading>

        {IS_COVID_FREE_TRIAL && (
          <Pane
            background="blueTint"
            marginTop={10}
            marginBottom={16}
            border="muted"
            paddingY={8}
            paddingX={16}
          >
            <Strong>
              The Muru statewide subscription is free to all NYS providers as
              part of our statewide partnership with the DOH Bureau of EMS.
            </Strong>
          </Pane>
        )}

        <Paragraph color="muted">
          Muru is built to adapt to you: your notifications, your protocols,
          your location, your equipment. Over the next few minutes we’ll get all
          this information set up.
        </Paragraph>
      </Pane>

      <ZipCodeEntry
        onCompleted={(result: any) => {
          track('Onboarding Zipcode Entered', {
            total_agencies: result.agencies.length,
            zip_code: result.zipCode.zipCode
          });

          dispatch(setResult(result));

          if (result.agencies.length > 0) {
            history.push('/onboarding/select');
          } else {
            history.push('/onboarding/waitlist');
          }
        }}
      />
    </Pane>
  );
};

export default ZipEntry;
