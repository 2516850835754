import React from 'react';
import {
  Icon,
  Link,
  Text,
  Heading,
  Pane,
  IconButton,
  Popover,
  Position,
  Pill
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import Menu from './Menu';

const SourceCard = ({
  source,
  organization,
  onEditClick,
  onDeleteClick,
  navigateToVersions
}: any) => {
  const isDifferentOrg = source.organization.id !== organization.id;

  return (
    <Pane
      key={source.id}
      display="flex"
      flexDirection="column"
      width={300}
      marginRight={16}
      marginBottom={16}
      border="default"
      borderRadius={3}
      hoverElevation={1}
      onDoubleClick={() => navigateToVersions(source)}
    >
      <Pane padding={16}>
        <Pane display="flex" marginBottom={8}>
          <Pane>
            <Icon icon="document" size={16} color="success" />
            <Pill>{source.versionCount}</Pill>
          </Pane>

          <Pane marginLeft="auto">
            <Popover
              content={
                <Menu
                  onEditClick={() => onEditClick(source)}
                  onDeleteClick={() => onDeleteClick(source)}
                  onManageVersionsClick={() => navigateToVersions(source)}
                />
              }
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton
                icon="more"
                height={24}
                appearance="minimal"
                disabled={isDifferentOrg}
              />
            </Popover>
          </Pane>
        </Pane>
        <Heading size={400}>{source.name}</Heading>
        <Text>
          {source.issuingBody} {' • '} {source.organization.name}
        </Text>

        <Text display="block" color="muted" marginTop={8} size={300}>
          Last updated{' '}
          <RelativeDate styles={{ color: 'muted' }} date={source.updatedAt} />
        </Text>
      </Pane>

      <Pane
        background="tint1"
        marginTop="auto"
        borderTop
        paddingX={16}
        paddingY={8}
        display="flex"
      >
        {source.versions.map((version: any) => (
          <Pane marginRight={8} key={version.id}>
            <Link
              href={version.file ? version.file.url : version.url}
              target="_blank"
              display="inline-block"
              size={300}
              fontWeight={500}
            >
              {version.version}
            </Link>
          </Pane>
        ))}

        {source.versions.length === 0 && (
          <Text size={300} color="muted">
            No versions for this source
          </Text>
        )}
      </Pane>
    </Pane>
  );
};

export default SourceCard;
