import React from 'react';
import { Text, UnorderedList, Icon, Pane } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import DisplayDoseAmount from 'components/shared/DoseAmount/DisplayDoseAmount';
import { protocolPath } from 'lib/urls';
import { StepType, determineStepType } from '@/constants/protocolSteps';

const StyledLink = styled(RouterLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrSeperator = () => (
  <Text
    size={300}
    display="block"
    color="#a9a9a9"
    marginTop={4}
    marginBottom={4}
  >
    -- OR --
  </Text>
);

const DoseStep = ({ doses }: any) => {
  const totalDoses = doses.length;

  return (
    <UnorderedList marginTop={8} marginBottom={8}>
      {doses.map((dose: any, idx: any) => (
        <li key={dose.id}>
          <DisplayDoseAmount {...dose} />
          {totalDoses - 1 !== idx && <OrSeperator />}
        </li>
      ))}
    </UnorderedList>
  );
};

const EmptyStep = () => (
  <Pane marginTop={8} marginBottom={8} display="flex" alignItems="center">
    <Text size={300} color="muted">
      Empty step
    </Text>
  </Pane>
);

const ReferredStep = ({ organization, referredProtocol }: any) => (
  <Pane marginTop={8} marginBottom={8} display="flex" alignItems="center">
    {/* @ts-expect-error StyledLink */}
    <StyledLink
      to={protocolPath(
        organization,
        referredProtocol.protocolSet,
        referredProtocol
      )}
    >
      <Icon
        icon="document-open"
        flexShrink={0}
        size={16}
        marginRight={8}
        color="muted"
      />
      <Text size={300}>{referredProtocol.name}</Text>
    </StyledLink>
  </Pane>
);

const SubprotocolStep = ({ organization, protocolSet, subprotocol }: any) => (
  <Pane marginTop={8} marginBottom={8} display="flex" alignItems="center">
    {/* @ts-expect-error StyledLink */}
    <StyledLink to={protocolPath(organization, protocolSet, subprotocol)}>
      <Icon
        icon="git-branch"
        flexShrink={0}
        size={16}
        marginRight={8}
        color="muted"
      />
      <Text size={300}>{subprotocol.name}</Text>
    </StyledLink>
  </Pane>
);

const ProtocolStepContents = ({
  organization,
  protocolSet,
  // @ts-expect-error TS(6133): 'protocol' is declared but its value is never read... Remove this comment to see the full error message
  protocol,
  step
}: any) => {
  const stepType = determineStepType(step);

  switch (stepType) {
    case StepType.DOSE:
      return <DoseStep {...step} />;
    case StepType.REFERRED:
      return <ReferredStep {...step} organization={organization} />;
    case StepType.SUBPROTOCOL:
      return (
        <SubprotocolStep
          {...step}
          protocolSet={protocolSet}
          organization={organization}
        />
      );
    default:
      return <EmptyStep />;
  }
};

export default ProtocolStepContents;
