import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Provider as ReduxProvider } from 'react-redux';
import Routes from './Router';
import GenericErrorBoundary from 'components/shared/GenericErrorBoundary';
import UpdateAvailableDialog from 'components/shared/UpdateAvailable';
import graphqlClient from './graphql';
import reduxStore from './redux/configureStore';
import { checkForUpdates } from 'lib/checkForUpdates';
import { CurrentOrganizationProvider } from 'pages/organization/CurrentOrganizationContext';
import 'normalize.css/normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
// For the uploader
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

function App() {
  const [showUpdate, setShowUpdate] = useState(false);
  const { t } = useTranslation();

  const onFocus = () => {
    checkForUpdates(() => setShowUpdate(true));
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });

  return (
    <GenericErrorBoundary>
      <ApolloHooksProvider client={graphqlClient}>
        <ReduxProvider store={reduxStore}>
          <CurrentOrganizationProvider>
            <Helmet titleTemplate={t('common.title')} />
            <Router>
              <Routes />
            </Router>
            <UpdateAvailableDialog isShown={showUpdate} />
          </CurrentOrganizationProvider>
        </ReduxProvider>
      </ApolloHooksProvider>
    </GenericErrorBoundary>
  );
}

export default App;
