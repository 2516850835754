import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LoadingScreen from 'components/shared/LoadingScreen';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import { EquipmentFragment } from 'components/shared/fragments';
import EditPage from './EditPage';
import {
  ListPage as ListFunctions,
  ListDosesPage as ListFunctionDoses
} from './Functions';

const EQUIPMENT = gql`
  query Equipment($id: ID!) {
    equipment: node(id: $id, type: EQUIPMENT) {
      ... on Equipment {
        ...Equipment
      }
    }
  }
  ${EquipmentFragment}
`;

export default ({ match, organization }: any) => {
  const { url, params } = match;
  const { id: equipmentId } = params;
  const { data: equipmentData, loading } = useQuery(EQUIPMENT, {
    variables: { id: equipmentId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AuthenticatedRoute
        exact
        path={`${url}/functions`}
        equipment={equipmentData.equipment}
        organization={organization}
        component={ListFunctions}
      />
      <AuthenticatedRoute
        exact
        path={`${url}/functions/:id/doses`}
        equipment={equipmentData.equipment}
        organization={organization}
        component={ListFunctionDoses}
      />
      <AuthenticatedRoute
        exact
        path={`${url}/`}
        equipment={equipmentData.equipment}
        organization={organization}
        component={EditPage}
      />
    </>
  );
};
