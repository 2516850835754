import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_DASHBOARD_ITEM = gql`
  mutation CreateDashboardItem(
    $input: CreateDashboardItemForOrganizationInput!
  ) {
    result: createDashboardItemForOrganization(input: $input) {
      dashboardItem {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_DASHBOARD_ITEM = gql`
  mutation UpdateDashboardItem(
    $input: UpdateDashboardItemForOrganizationInput!
  ) {
    result: updateDashboardItemForOrganization(input: $input) {
      dashboardItem {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const initialValues = {};

const CreateOrUpdateDashboardItem = ({
  organizationId,
  dashboardItem,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasDashboardItem = dashboardItem && dashboardItem.id;
  const [createOrUpdateDashboardItem, { loading }] = useMutation(
    hasDashboardItem ? UPDATE_DASHBOARD_ITEM : CREATE_DASHBOARD_ITEM,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasDashboardItem
    ? sanitizeData(dashboardItem, ['query', 'organization'])
    : initialValues;

  // @ts-expect-error TS(2531): Object is possibly 'null'.
  if (formData.credentials) {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    formData.credentials = formData.credentials.map(({ id }: any) => id);
  }

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasDashboardItem
            ? 'Update dashboard item'
            : 'Create new dashboard item'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={dashboardItem?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form(organizationId)}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            const variables = hasDashboardItem
              ? input
              : { ...input, organizationId };

            setErrors({});
            createOrUpdateDashboardItem({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateDashboardItem;
