import React from 'react';
import { Link } from 'evergreen-ui';
import * as Yup from 'yup';
import uppy from 'lib/uppy';
import { formatEnumToOptions } from 'lib/formHelpers';
import ManufacturerDataSource from 'components/shared/ManufacturerDataSource';
import ReferencedSources from 'components/shared/ReferencedSources';
import { MedicationRoutesWithLabels } from '@/constants/medications';
import MedicationAmount from 'components/shared/MedicationAmount';

const EquipmentSchema = Yup.object().shape({
  name: Yup.string().required(),
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this equipment')
});

const UnitSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .nullable(),
  serialNumber: Yup.string()
    .required()
    .nullable()
});

const EquipmentMetadataSchema = Yup.object().shape({
  key: Yup.string()
    .required()
    .nullable(),
  value: Yup.string()
    .required()
    .nullable()
});

const SizeSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .nullable(),
  size: Yup.object()
    .shape({
      unit: Yup.string()
        .required()
        .nullable(),
      value: Yup.number()
        .required()
        .nullable()
    })
    .required('You must specify a size')
});

export const EquipmentForm = (organizationId: any) => ({
  name: 'equipment',
  validationSchema: EquipmentSchema,

  fields: [
    {
      name: 'name',
      label: 'Name',
      description: 'The full-name of the equipment',
      placeholder: 'Zoll X-Series Monitor',
      required: true
    },
    {
      name: 'category',
      label: 'Category',
      description: 'What kind of equipment is this?',
      placeholder: 'Cardiac Monitor'
    },
    {
      name: 'manufacturerId',
      type: 'asyncSelect',
      label: 'Manufacturer',
      description: 'The manufacturer of the equipment',
      placeholder: 'Select a manufacturer',
      dataSource: (render: any) => <ManufacturerDataSource render={render} />
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    },
    {
      name: 'establishesRoutes',
      label: 'Establishes Routes',
      description: 'Select all that apply',
      type: 'multiSelect',
      values: formatEnumToOptions(MedicationRoutesWithLabels)
    },
    {
      type: 'upload',
      name: 'image',
      label: 'Equipment image',
      description: 'Generic image for the equipment',
      renderPreview: ({ name, size, url }: any) => (
        <Link href={url} target="_blank">
          {name} ({size} bytes)
        </Link>
      ),
      uppyInstance: ({ onComplete }: any) =>
        uppy(
          {
            restrictions: {
              maxNumberOfFiles: 1,
              allowedFileTypes: ['image/*'],
              maxFileSize: 1024 * 1024 * 200
            }
          },
          // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
          ([result]) => onComplete(result)
        )
    },
    {
      type: 'listOfSubFields',
      name: 'units',
      label: 'Units',
      description: 'The units available for this equipment',
      // @ts-expect-error TS(6133): 'index' is declared but its value is never read.
      titleExtractor: (fields: any, index: any) => fields.name,
      validationSchema: UnitSchema,
      reorderable: true,
      fields: [
        {
          name: 'name',
          label: 'Name',
          description: 'The name of the unit'
        },
        {
          name: 'serialNumber',
          label: 'Serial Number',
          description: 'The serial number of the unit'
        }
      ]
    },
    {
      type: 'listOfSubFields',
      name: 'sizes',
      label: 'Sizes',
      description: 'The sizes available for this piece of equipment',
      // @ts-expect-error TS(6133): 'index' is declared but its value is never read.
      titleExtractor: (fields: any, index: any) => fields.name,
      validationSchema: SizeSchema,
      reorderable: true,
      fields: [
        {
          name: 'name',
          label: 'Name',
          placeholder: '15 gtts',
          description: 'The name of the size'
        },
        {
          type: MedicationAmount,
          name: 'size',
          label: 'Size',
          description:
            'The amount of each item, expressed either as a X per amount or as an amount'
        }
      ]
    },
    {
      name: 'metadata',
      label: 'Metadata',
      description:
        'Any additional structured data that should be displayed with this equipment',
      type: 'listOfSubFields',
      titleExtractor: (fields: any) => fields.key,
      validationSchema: EquipmentMetadataSchema,
      reorderable: true,
      fields: [
        {
          name: 'key',
          label: 'Name',
          description: 'The name of the field',
          placeholder: 'Class',
          required: true
        },
        {
          name: 'value',
          label: 'Description',
          description: 'The supporting text',
          type: 'textarea',
          required: true
        }
      ]
    },
    {
      type: 'listOf',
      name: 'notes',
      label: 'General notes',
      description: 'Any generic notes about the equipment (in bullets)',
      subFieldType: 'text'
    },
    {
      type: ReferencedSources,
      name: 'sources',
      label: 'Sources',
      description: 'A list of sources attached to this equipment',
      organizationId: organizationId
    }
  ]
});
