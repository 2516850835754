import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_PROTOCOL_SET = gql`
  mutation PublishProtocol($input: PublishProtocolForProtocolSetInput!) {
    result: publishProtocolForProtocolSet(input: $input) {
      protocol {
        id
        updatedAt
        lastPublishedAt
      }
    }
  }
`;

const PublishProtocolDialog = ({ isShown, protocol, onCloseComplete }: any) => {
  const [publishProtocol, { loading }] = useMutation(PUBLISH_PROTOCOL_SET, {
    variables: { input: { id: protocol.id } },
    onCompleted: (response: any) => {
      if (response.result.protocol) {
        toaster.success(`${protocol.name} successfully published!`);
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishProtocol()}
    >
      <Paragraph>
        Publishing this protocol will make it available to{' '}
        <strong>all users of the app within this organization</strong>. Please
        only do this if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishProtocolDialog;
