import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import pluralize from 'pluralize';
import { Pane, Paragraph, Link, Badge, Strong, Button } from 'evergreen-ui';
import format from 'date-fns/format';
import {
  formatCents,
  PlanIntervalLabels,
  subscriptionStatus,
  SubscriptionStatesToText,
  SubscriptionStatesToColors,
  SubscriptionStates
} from 'lib/subscription';
import { CANCEL_SUB_FOR_USER } from './queries';

const dateFormat = 'MM/DD/YYYY';

const SubscriptionDetails = ({ subscription, user }: any) => {
  const [cancelSubscription, { loading: isCancelling }] = useMutation(
    CANCEL_SUB_FOR_USER,
    {
      variables: { input: { userId: user.id } }
    }
  );

  const status = subscriptionStatus(subscription);

  return (
    <Pane>
      {/* @ts-expect-error Invalid color */}
      <Badge marginBottom={8} color={SubscriptionStatesToColors[status]}>
        {SubscriptionStatesToText[status]}
      </Badge>

      <Pane marginBottom={16}>
        {status === SubscriptionStates.CANCELLED ? (
          <Paragraph size={400}>
            Subscription is cancelled and will be active until{' '}
            <Strong>{format(subscription.cancelAt, dateFormat)}</Strong>
          </Paragraph>
        ) : (
          <Pane>
            <Paragraph marginBottom={8}>
              {subscription.plan.name}, paying $
              {formatCents(subscription.totalAmountInCents)}/
              {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {PlanIntervalLabels[subscription.interval]} for{' '}
              {pluralize('seat', subscription.totalSeatCount, true)}
            </Paragraph>

            <Button
              appearance="minimal"
              intent="danger"
              isLoading={isCancelling}
              onClick={() => cancelSubscription()}
            >
              Cancel subscription
            </Button>
          </Pane>
        )}
      </Pane>

      <Link
        marginTop={8}
        href={`https://dashboard.stripe.com/subscriptions/${subscription.stripeSubscriptionId}`}
        target="_blank"
      >
        View on Stripe
      </Link>
    </Pane>
  );
};

export default SubscriptionDetails;
