import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Link,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Heading
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';

const SourceVersionListItemFragment = gql`
  fragment SourceVersionListItem on SourceVersion {
    id
    version
    url
    file {
      name
      url
      size
    }
    updatedAt
    insertedAt
  }
`;

export const SOURCE_VERSIONS_LIST = gql`
  query SourceVersions($sourceId: ID!) {
    source: node(id: $sourceId, type: SOURCE) {
      ... on Source {
        id
        versions {
          ...SourceVersionListItem
        }
      }
    }
  }
  ${SourceVersionListItemFragment}
`;

export const listSourceVersionsVariables = (source: any) => ({
  sourceId: source.id
});

const SourceVersionList = ({
  source,
  isDifferentOrg,
  onEditClick,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(SOURCE_VERSIONS_LIST, {
    variables: listSourceVersionsVariables(source),
    fetchPolicy: 'network-only'
  });
  const versions = get(data, 'source.versions', []);

  return (
    <Pane>
      <Heading>{source.name}</Heading>
      <Text>Issued by: {source.issuingBody}</Text>

      <Pane
        marginTop={24}
        background="white"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="start"
      >
        {!loading &&
          !error &&
          versions.map((version: any) => (
            <Pane
              key={version.id}
              width={300}
              padding={16}
              marginRight={16}
              marginBottom={16}
              border="default"
              borderRadius={3}
              hoverElevation={1}
            >
              <Pane display="flex">
                <Heading>{version.version}</Heading>

                <Pane marginLeft="auto">
                  <Popover
                    content={
                      <Menu
                        onEditClick={() => onEditClick(version)}
                        onDeleteClick={() => onDeleteClick(version)}
                      />
                    }
                    position={Position.BOTTOM_RIGHT}
                  >
                    <IconButton
                      icon="more"
                      height={24}
                      appearance="minimal"
                      disabled={isDifferentOrg}
                    />
                  </Popover>
                </Pane>
              </Pane>

              <Link
                href={version.file ? version.file.url : version.url}
                target="_blank"
                size={300}
                fontWeight={500}
              >
                {version.file ? version.file.name : version.url}
              </Link>

              <Text display="block" color="muted" marginTop={8} size={300}>
                Last updated{' '}
                <RelativeDate
                  styles={{ color: 'muted' }}
                  date={version.updatedAt}
                />
              </Text>
            </Pane>
          ))}
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && versions.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default SourceVersionList;
