import React, { useState } from 'react';
import pluralize from 'pluralize';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Pane, Alert } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted } from 'lib/formHelpers';
import { SubscriptionForm } from './form';
import { CREATE_SUBSCRIPTION, EXISTING_SEATS } from './queries';

const initialValues = {
  totalSeatCount: 0
};

const CreateSubscription = ({
  organizationId,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});

  const { data } = useQuery(EXISTING_SEATS, {
    variables: { organizationId },
    fetchPolicy: 'network-only'
  });

  const [updateSubscription, { loading }] = useMutation(CREATE_SUBSCRIPTION, {
    refetchQueries: [refetchQuery],
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  const existingSeats = get(data, 'organization.seats.edges', []);
  const totalSeats = existingSeats.length;

  return (
    <Pane>
      {totalSeats > 0 && (
        <Alert
          intent="danger"
          title={`${pluralize(
            'seat',
            totalSeats,
            true
          )} already exist on this organization`}
          marginBottom={24}
        >
          If you create a new subscription for this organization, these seats
          will need to be correctly refunded.
        </Alert>
      )}

      <FormBuilder
        isSubForm
        initialValues={initialValues}
        form={SubscriptionForm}
        errors={errors}
        isLoading={loading}
        onSubmit={(input: any) => {
          setErrors({});

          const variables = {
            input: {
              ...input,
              organizationId
            }
          };

          updateSubscription({ variables });
        }}
      />
    </Pane>
  );
};

export default CreateSubscription;
