import React from 'react';
import { Spinner, toaster } from 'evergreen-ui';
import { useQuery } from '@apollo/react-hooks';
import Question from './Question';
import { GENERATE_QUESTION_FOR_ENTRY } from './queries';

const EditQuestion = ({
  entryType,
  entryId,
  organization,
  close,
  refetchEntries
}: any) => {
  const { data, loading } = useQuery(GENERATE_QUESTION_FOR_ENTRY, {
    variables: { entryType, entryId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <Spinner size={16} />;
  }

  const { question } = data.generateInventoryProfileQuestionFromEntry;

  return (
    <Question
      {...question}
      organization={organization}
      onNextClick={() => {
        toaster.success('Entry saved');
        refetchEntries();
        close();
      }}
      onSkipClick={close}
    />
  );
};

export default EditQuestion;
