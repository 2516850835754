export const StepType = {
  DOSE: 'dose',
  REFERRED: 'referred',
  SUBPROTOCOL: 'subprotocol',
  BLANK: 'blank'
};

export function determineStepType({
  doses,
  referredProtocol,
  subprotocol
}: any) {
  if (referredProtocol) return StepType.REFERRED;
  if (subprotocol) return StepType.SUBPROTOCOL;

  if (doses && doses.length > 0) {
    return StepType.DOSE;
  }

  return StepType.BLANK;
}
