import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Button, Pane, Heading, Paragraph } from 'evergreen-ui';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import { page } from 'lib/analytics';

const WaitlistThanks = () => {
  useEffect(() => {
    page('Onboarding Waitlist Thanks');
  }, []);

  return (
    <Pane>
      <Helmet>
        <title>Thanks for signing up for the waitlist</title>
      </Helmet>
      <Pane
        textAlign="center"
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MuruLogo />

        <Heading size={600} marginBottom={8}>
          You're on the waitlist!
        </Heading>
        <Paragraph color="muted">
          Thanks for signing up for the waitlist. We'll let you know as soon as
          Muru is available for your agency.
        </Paragraph>

        <Button
          height={40}
          appearance="primary"
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          marginBottom={16}
          is="a"
          href="/onboarding"
        >
          Start over
        </Button>
      </Pane>
    </Pane>
  );
};

export default WaitlistThanks;
