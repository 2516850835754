import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EntryCriteria from 'components/shared/EntryCriteria';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { amendmentByType, Types } from 'lib/contentAmendments';
import { hasPermission } from 'lib/organizations';
import ExcludeDialog from './ExcludeDialog';
import ToolsMenu from './ToolsMenu';
import Menu from './Menu';
import PublishDialog from './PublishProtocolSetDialog';
import PublishAllDialog from './PublishAllProtocolSetsDialog';
import { PROTOCOL_SET_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

function canProtocolSetBeExcluded(protocolSet: any, org: any) {
  if (protocolSet.organization.id === org.id) {
    return false;
  }

  return hasPermission(org, 'content_amendments:manage');
}

const ProtocolSetList = ({
  refetchQuery,
  organization,
  onEditClick,
  onDeleteClick,
  history,
  variables,
  filters,
  onFilterUpdate,
  onPageInfoUpdate
}: any) => {
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const [isShowingPublishAllDialog, showPublishAllDialog] = useState(false);
  const [showExcludeDialog, setShowExcludeDialog] = useState(false);

  const { loading, data, error } = useQuery(PROTOCOL_SET_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const protocolSets = get(data, 'organization.protocolSets.edges', []);

  const navigateToProtocolSet = (protocolSet: any) =>
    history.push(
      `/orgs/${organization.slug}/protocol-sets/${protocolSet.id}/protocols`
    );

  return (
    <Pane>
      <Pane background="white">
        <Pane display="flex" alignItems="center">
          <SearchListControl
            placeholder="Search by name"
            initialValue={filters.name}
            onSubmit={(name: any) => onFilterUpdate({ name })}
          />
          <Pane marginLeft="auto">
            <Popover
              // @ts-expect-error TS(6133): 'close' is declared but its value is never read.
              content={({ close }: any) => (
                <ToolsMenu
                  onPublishAllClick={() => showPublishAllDialog(true)}
                />
              )}
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton icon="more" height={24} appearance="minimal" />
            </Popover>
          </Pane>
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Entry Criteria</Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {protocolSets.map(({ node: protocolSet }) => {
                const isDifferentOrg =
                  protocolSet.organization.id !== organization.id;

                const exclusionAmendment = amendmentByType(
                  protocolSet,
                  Types.EXCLUDE
                );

                // Can exclude / include this protocol set when the protocol is on a different
                // org, the user has permission, and the exclusion either doesn't exist OR belongs
                // to this org
                const canManageExclusion =
                  canProtocolSetBeExcluded(protocolSet, organization) &&
                  (!exclusionAmendment ||
                    exclusionAmendment.organization.id === organization.id);

                return (
                  <Table.Row
                    key={protocolSet.id}
                    isSelectable
                    height="auto"
                    minHeight={64}
                    opacity={exclusionAmendment ? 0.5 : 1}
                    onDoubleClick={() => navigateToProtocolSet(protocolSet)}
                  >
                    <Table.Cell>
                      <Pane>
                        <Text size={300} fontWeight={500}>
                          {protocolSet.name}
                        </Text>
                        <Text size={300} display="block">
                          {protocolSet.organization.name}
                        </Text>
                      </Pane>
                    </Table.Cell>
                    <Table.Cell>
                      <Pane
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="flex-start"
                      >
                        {protocolSet.entryCriteria && (
                          <EntryCriteria {...protocolSet.entryCriteria} />
                        )}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell
                      width={100}
                      display="flex"
                      alignItems="center"
                      flex="none"
                    >
                      {protocolSet.updatedAt === protocolSet.lastPublishedAt ? (
                        <Badge color="green">Published</Badge>
                      ) : (
                        <Badge color="blue">Draft</Badge>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {protocolSet.lastPublishedAt ? (
                        <RelativeDate date={protocolSet.lastPublishedAt} />
                      ) : (
                        <Text size={300}>N/A</Text>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={protocolSet.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            canBeManaged={!isDifferentOrg}
                            canBeExcluded={canManageExclusion}
                            isExcluded={!!exclusionAmendment}
                            onExcludeToggle={() => {
                              setShowExcludeDialog({
                                // @ts-expect-error TS(2345): Argument of type '{ protocolSet: any; exclusionAme... Remove this comment to see the full error message
                                protocolSet,
                                exclusionAmendment
                              });
                            }}
                            onManageFunctionsClick={() =>
                              navigateToProtocolSet(protocolSet)
                            }
                            onEditClick={() => onEditClick(protocolSet)}
                            onPublishClick={() =>
                              showPublishDialog(protocolSet)
                            }
                            onDeleteClick={() => onDeleteClick(protocolSet)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && protocolSets.length === 0 && <EmptyContent />}

      <PublishDialog
        protocolSet={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />

      <ExcludeDialog
        // Spread the object as props as it includes the protocol set and maybe the
        // exclusion amendment as well (if that exists)
        // @ts-expect-error TS(2698): Spread constants may only be created from object constants... Remove this comment to see the full error message
        {...(showExcludeDialog !== false ? showExcludeDialog : {})}
        isShown={showExcludeDialog !== false}
        organization={organization}
        refetchQuery={refetchQuery}
        onCloseComplete={() => setShowExcludeDialog(false)}
      />

      <PublishAllDialog
        organization={organization}
        refetchQuery={refetchQuery}
        isShown={isShowingPublishAllDialog !== false}
        onCloseComplete={() => showPublishAllDialog(false)}
      />

      <PaginationControls
        pageInfo={get(data, 'organization.protocolSets.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default withRouter(ProtocolSetList);
