import { TimeUnitsWithLabels, UnitsWithLabels } from 'constants/units';
import { sanitizeData } from './formHelpers';

function normalizeUnitData(units: any) {
  return units.map((unit: any) => {
    let updatedUnit = sanitizeData(unit, [
      'id',
      'medication',
      'equipmentFunction'
    ]);

    if (unit.medication) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      updatedUnit.medicationId = unit.medication.id;
    }

    if (unit.equipmentFunction) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      updatedUnit.equipmentFunctionId = unit.equipmentFunction.id;
    }

    return updatedUnit;
  });
}

export function normalizeDoseData(doses: any) {
  return doses.map((dose: any) => {
    let updatedDose = sanitizeData(dose, ['id']);

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    updatedDose.units = normalizeUnitData(dose.units);

    if (dose.administeringEquipment) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      updatedDose.administeringEquipment = dose.administeringEquipment.id;
    }

    return updatedDose;
  });
}

export function formatUnit(unit: any) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return UnitsWithLabels[unit] || unit.replace('_', ' ').toLowerCase();
}

// @ts-expect-error TS(7023): 'formatAmount' implicitly has return type 'any' be... Remove this comment to see the full error message
export function formatAmount(
  { value, minValue, maxValue, unit, perAmount }: any,
  shouldTruncateAmount = false
) {
  // @ts-expect-error TS(7022): 'perAmountStr' implicitly has type 'any' because i... Remove this comment to see the full error message
  const perAmountStr = perAmount ? `/${formatAmount(perAmount, true)}` : '';
  const amountStr = value && unit ? value : `${minValue}-${maxValue}`;

  if (shouldTruncateAmount && parseInt(amountStr, 10) === 1) {
    return `${formatUnit(unit)}${perAmountStr}`;
  } else {
    return `${amountStr}${formatUnit(unit)}${perAmountStr}`;
  }
}

export function formatDoseAmount(name: any, amount: any, maxAmount?: any) {
  const maxAmountStr = maxAmount ? ` (max ${formatAmount(maxAmount)})` : '';

  return `${name} ${formatAmount(amount)}${maxAmountStr}`;
}

export function formatRepeatInterval({ value, minValue, maxValue, unit }: any) {
  if (value) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `${value} ${TimeUnitsWithLabels[unit]}`;
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return `${minValue} - ${maxValue} ${TimeUnitsWithLabels[unit]}`;
}
