import React from 'react';
import { Link } from 'evergreen-ui';
import * as Yup from 'yup';
import uppy from 'lib/uppy';
import MedicationAmount, {
  AmountSchema,
  AmountPerAmountSchema
} from 'components/shared/MedicationAmount';
import ReferencedSources from 'components/shared/ReferencedSources';
import MoleculeDataSource from 'components/shared/MoleculeDataSource';
import MedicationDataSource from 'components/shared/MedicationDataSource';
import MedicationSelector from 'components/shared/MedicationSelector';
import { formatEnumToOptions } from 'lib/formHelpers';
import {
  StorageVolumeTypesWithLabels,
  ConcentrationDisplayTypesWihLabels
} from '@/constants/medications';
import { UnitsWithLabels } from 'constants/units.ts';

const MedicationSchema = Yup.object().shape({
  brandName: Yup.string().required(),
  genericName: Yup.string().required(),
  concentration: AmountPerAmountSchema,
  aliases: Yup.array()
    .of(Yup.string())
    .required('You must enter in aliases for this medication')
});

const MedicationStorageMethodSchema = Yup.object().shape({
  displayName: Yup.string().nullable(),
  type: Yup.string()
    .required()
    .nullable(),
  volume: AmountSchema.nullable().required(),
  concentration: AmountPerAmountSchema.nullable()
});

const MedicationMetadataSchema = Yup.object().shape({
  key: Yup.string()
    .required()
    .nullable(),
  value: Yup.string()
    .required()
    .nullable()
});

const amountToString = ({
  value,
  unit
}: // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
any) => `${value}${UnitsWithLabels[unit]}`;

const storageMethodToString = (fields: any) => {
  let units = [];

  if (fields.volume) {
    units.push(amountToString(fields.volume));
  }

  // Storage method type
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  units.push(StorageVolumeTypesWithLabels[fields.type] || fields.type);

  if (fields.displayName) {
    units.push(` (${fields.displayName})`);
  }

  // Add the concentration / per amount value (e.g. 1mg / 1mL)
  if (fields.concentration && fields.concentration.perAmount) {
    const concentration = amountToString(fields.concentration);
    const perAmount = amountToString(fields.concentration.perAmount);

    units.push(`@ ${concentration}/${perAmount}`);
  }

  return units.join(' ');
};

export const MedicationForm = (organizationId: any) => ({
  name: 'medication',
  validationSchema: MedicationSchema,

  fields: [
    {
      name: 'brandName',
      label: 'Brand name',
      description: 'The brand name of this medication',
      required: true
    },
    {
      name: 'genericName',
      label: 'Generic name',
      description: 'The generic name of this medication',
      required: true
    },
    {
      name: 'displayName',
      label: 'Display name',
      description:
        'The name to display when referencing this medication in a dose. By default this will be the generic name of the medication.'
    },
    {
      name: 'aliases',
      label: 'Aliases',
      type: 'tag'
    },
    {
      name: 'moleculeId',
      type: 'asyncSelect',
      label: 'Molecule family',
      description: 'The family of medications that this medication belongs to',
      placeholder: 'Select a molecule',
      dataSource: (render: any) => <MoleculeDataSource render={render} />
    },
    {
      name: 'isDilutant',
      label: 'Dilutant?',
      description: 'Is this medication a dilutant?',
      type: 'switch'
    },
    {
      name: 'dilutantId',
      label: "What's this medication diluted with?",
      description:
        'If this medication is diluted with another medication, select that here',
      type: 'asyncSelect',
      placeholder: 'Select a dilutant (if any)',
      dataSource: (render: any) => (
        <MedicationDataSource
          render={render}
          organizationId={organizationId}
          filters={{ isDilutant: true }}
        />
      )
    },
    {
      name: 'reversalAgents',
      label: 'What reversal agents does this medication have?',
      description:
        'If there are reversal agents that counter-act the effects of this medication, select those here',
      type: MedicationSelector,
      organizationId
    },
    {
      type: MedicationAmount,
      name: 'concentration',
      label: 'Concentration',
      description:
        'The concentration of all of the storage methods for this medication. Only add this if the concentration is consistent, like in the case of Epi 1:1000.'
    },
    {
      name: 'concentrationDisplayType',
      label: 'Concentration display type',
      type: 'select',
      values: formatEnumToOptions(ConcentrationDisplayTypesWihLabels),
      description:
        'When set will override the concentration displayed in the dose card.'
    },
    {
      name: 'storageMethods',
      label: 'Storage Methods',
      description: 'Ways in which the medication is stored',
      type: 'listOfSubFields',
      titleExtractor: storageMethodToString,
      validationSchema: MedicationStorageMethodSchema,
      reorderable: true,
      initialState: {
        displayName: null,
        type: null,
        image: null,
        volume: null,
        concentration: null
      },
      fields: [
        {
          name: 'displayName',
          label: 'Display name',
          description:
            'An optional display name to show when outputting this storage method as a dose',
          type: 'text'
        },
        {
          name: 'type',
          label: 'Type',
          description: 'What kind of storage method is this?',
          type: 'select',
          values: formatEnumToOptions(StorageVolumeTypesWithLabels)
        },
        {
          type: 'upload',
          name: 'image',
          label: 'Image',
          description: 'An image that represents the stored medicine',
          renderPreview: ({ name, size, url }: any) => (
            <Link href={url} target="_blank">
              {name} ({size} bytes)
            </Link>
          ),
          uppyInstance: ({ onComplete }: any) =>
            uppy(
              {
                restrictions: {
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['image/*'],
                  maxFileSize: 1024 * 1024 * 200
                }
              },
              // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
              ([result]) => onComplete(result)
            )
        },
        {
          type: MedicationAmount,
          name: 'volume',
          label: 'Volume',
          description: 'The volume of the medication in this method',
          isAbsoluteUnit: true
        },
        {
          type: MedicationAmount,
          name: 'concentration',
          label: 'Concentration',
          description: 'The concentration of the medication in this method',
          shownWhen: ({ __parent: medicationFields, concentration }: any) =>
            // Show when we have an existing concentration or when there's no concentration on med
            concentration ? true : !medicationFields.concentration
        }
      ]
    },
    {
      name: 'metadata',
      label: 'Metadata',
      description:
        'Any additional structured data that should be displayed with a medication',
      type: 'listOfSubFields',
      titleExtractor: (fields: any) => fields.key,
      validationSchema: MedicationMetadataSchema,
      reorderable: true,
      fields: [
        {
          name: 'key',
          label: 'Name',
          description: 'The name of the field',
          placeholder: 'Class',
          required: true
        },
        {
          name: 'value',
          label: 'Description',
          description: 'The supporting text',
          type: 'textarea',
          required: true
        }
      ]
    },
    {
      type: 'listOf',
      name: 'notes',
      label: 'General notes',
      description: 'Any generic notes about the medication (in bullets)',
      subFieldType: 'text'
    },
    {
      type: ReferencedSources,
      name: 'sources',
      label: 'Sources',
      description: 'A list of sources attached to this medication',
      organizationId: organizationId
    }
  ]
});
