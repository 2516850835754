import React from 'react';
import { Menu } from 'evergreen-ui';

const ToolsMenu = ({ onPublishAllClick }: any) => {
  return (
    <Menu>
      {/* @ts-expect-error Invalid title */}
      <Menu.Group title="Tools">
        <Menu.Item intent="success" onSelect={onPublishAllClick}>
          Publish all equipment
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default ToolsMenu;
