import React from 'react';
import { map, includes } from 'lodash';
import { Pane, Badge, Heading } from 'evergreen-ui';

const badgeStyles = {
  alignItems: 'center',
  display: 'inline-flex',
  fontWeight: 400,
  textTransform: 'none'
};

const AliasPill = ({ onClick, isSelected, alias, count }: any) => (
  <Badge
    {...badgeStyles}
    isInteractive={true}
    onClick={onClick}
    marginRight={4}
    marginBottom={4}
    color={isSelected ? 'yellow' : 'neutral'}
  >
    {alias} ({count})
  </Badge>
);

const AliasResults = ({
  aliasesWithCounts,
  aliasesToHighlight,
  onAliasClick
}: any) => (
  <Pane marginBottom={24}>
    <Heading size={400} marginBottom={8}>
      The following aliases overlapped:
    </Heading>

    <Pane maxHeight={200} overflow="scroll">
      {map(aliasesWithCounts, (count, alias) => (
        <AliasPill
          key={alias}
          alias={alias}
          count={count}
          isSelected={includes(aliasesToHighlight, alias)}
          onClick={() => onAliasClick(alias)}
        />
      ))}
    </Pane>
  </Pane>
);

export default AliasResults;
