import { createSlice } from '@reduxjs/toolkit';
import { getAuthToken } from 'lib/auth';
import * as Sentry from '@sentry/browser';
import * as analytics from 'lib/analytics';

const initialState = {
  isAuthenticated: !!getAuthToken(),
  currentUser: null
};

export default createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCurrentUser: (state: any, action: any) => {
      const user = action.payload;

      state.currentUser = user;
      state.isAuthenticated = !!user;

      // Set some global items
      if (user) {
        analytics.identify(user);
        Sentry.getCurrentScope().setUser({
          id: user.id,
          email: user.email
        });
      }
    },
    // @ts-expect-error TS(6133): 'action' is declared but its value is never read.
    logout: (state: any, action: any) => {
      state.currentUser = null;
      state.isAuthenticated = false;
    }
  }
});

export const isAuthenticatedSelector = (state: any) =>
  state.global.isAuthenticated;

export const currentUserSelector = (state: any) => state.global.currentUser;
