import React from 'react';
import { Pane } from 'evergreen-ui';
import { useWindowSize } from 'react-use';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/react-hooks';
import globalSlice, { currentUserSelector } from 'redux/global';
import { clearAuthCredentials } from 'lib/auth';
import * as analytics from 'lib/analytics';
import WithCurrentUser from 'components/shared/WithCurrentUser';
import TopBar from './TopBar';

const { setCurrentUser } = globalSlice.actions;

const Layout = ({ history, location, children }: any) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const { height } = useWindowSize();
  const client = useApolloClient();

  return (
    <WithCurrentUser onError={() => history.push('/login')}>
      <Pane height={height} display="flex" flexDirection="column">
        <TopBar
          history={history}
          location={location}
          user={currentUser || {}}
          onLogout={() => {
            history.push('/login');
            dispatch(setCurrentUser(null));
            client.resetStore();
            clearAuthCredentials();
            analytics.reset();
          }}
        />
        <Pane
          height="calc(100% - 56px)"
          flex={1}
          display="flex"
          flexDirection="row"
          position="relative"
        >
          {children}
        </Pane>
      </Pane>
    </WithCurrentUser>
  );
};

export default withRouter(Layout);
