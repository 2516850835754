import React, { useState } from 'react';
import {
  Pane,
  Heading,
  Strong,
  toaster,
  Text,
  Link,
  Avatar,
  Paragraph
} from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/global';
import CurrentUserProfile from 'components/shared/CurrentUserProfile/UpdateProfileForm';
import EditableSectionCard from './EditableSectionCard';
import SubscriptionsSummary from './SubscriptionsSummary';
import CardOnFile from './CardOnFile';
import LoadingScreen from 'components/shared/LoadingScreen';
import UpdateUsersCreditCard from 'components/shared/UpdateUsersCreditCard';
import { launchIntercomWithMessage } from 'lib/analytics';

const UserPreview = ({ user = {} }) => (
  <Pane display="flex" alignItems="center">
    {/* @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'. */}
    <Avatar name={user.name} size={32} />
    <Pane marginLeft={8}>
      {/* @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'. */}
      <Strong display="block">{user.name}</Strong>
      {/* @ts-expect-error TS(2339): Property 'email' does not exist on type '{} */}
      <Text color="muted">{user.email}</Text>
    </Pane>
  </Pane>
);

const ManageAccount = () => {
  const currentUser = useSelector(currentUserSelector);
  const [profileExpanded, setProfileExpanded] = useState(false);
  const [paymentExpanded, setPaymentExpanded] = useState(false);

  if (!currentUser) {
    return <LoadingScreen />;
  }

  return (
    <Pane
      flex="1"
      flexDirection="column"
      height="100%"
      display="flex"
      maxWidth={600}
      width="100%"
      padding={16}
    >
      <Heading size={600}>Manage Your Account</Heading>

      <EditableSectionCard
        title="Update profile"
        isExpanded={profileExpanded}
        onEditClick={() => setProfileExpanded(!profileExpanded)}
        renderPreview={() => <UserPreview user={currentUser} />}
      >
        <CurrentUserProfile
          user={currentUser}
          onComplete={() => {
            toaster.success('Your profile has been successfully updated');
            setProfileExpanded(false);
          }}
        />
      </EditableSectionCard>

      <EditableSectionCard
        title="Payment information"
        isExpanded={paymentExpanded}
        onEditClick={() => setPaymentExpanded(!paymentExpanded)}
        renderPreview={() => <CardOnFile />}
      >
        <UpdateUsersCreditCard
          buttonProps={{
            width: 60
          }}
          onComplete={() => {
            toaster.success('Your credit card has been updated');
            setPaymentExpanded(false);
          }}
        />
      </EditableSectionCard>

      <SubscriptionsSummary />

      <Paragraph
        size={300}
        marginTop={16}
        marginBottom={40}
        textAlign="center"
        color="muted"
      >
        If you need any help, please
        {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 0. */}
        <Link size={300} href="#" onClick={() => launchIntercomWithMessage()}>
          speak with our support team
        </Link>
      </Paragraph>
    </Pane>
  );
};

export default ManageAccount;
