import React from 'react';
import { Menu } from 'evergreen-ui';

const MedicationMenu = ({ onEditClick, onDeleteClick }: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit medication</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete medication
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default MedicationMenu;
