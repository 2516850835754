export const MedicationRoutes = {
  BUCCAL: 'BUCCAL',
  ENDOTRACHEAL: 'ENDOTRACHEAL',
  EPIGASTRIC: 'EPIGASTRIC',
  INHALED: 'INHALED',
  INTRAMUSCULAR: 'INTRAMUSCULAR',
  INTRANASAL: 'INTRANASAL',
  INTRAOSSEOUS: 'INTRAOSSEOUS',
  INTRAVENOUS: 'INTRAVENOUS',
  NASOGASTRIC: 'NASOGASTRIC',
  NEBULIZER: 'NEBULIZER',
  OCULAR: 'OCULAR',
  ORAL: 'ORAL',
  OTIC: 'OTIC',
  PADS: 'PADS',
  PER_RECTAL: 'PER_RECTAL',
  SUBCUTANEOUS: 'SUBCUTANEOUS',
  SUBLINGUAL: 'SUBLINGUAL',
  SUBLINGUAL_SPRAY: 'SUBLINGUAL_SPRAY',
  TOPICAL: 'TOPICAL',
  TRANSDERMAL: 'TRANSDERMAL',
  VAGINAL: 'VAGINAL'
};

export const MedicationRoutesWithLabels = {
  BUCCAL: 'Buccal',
  ENDOTRACHEAL: 'Endotracheal',
  EPIGASTRIC: 'Epigastric',
  INHALED: 'Inhaled',
  INTRAMUSCULAR: 'Intramuscular',
  INTRANASAL: 'Intranasal',
  INTRAOSSEOUS: 'Intraosseous',
  INTRAVENOUS: 'Intravenous',
  NASOGASTRIC: 'Nasogastric',
  NEBULIZER: 'Nebulizer',
  OCULAR: 'Ocular',
  OTIC: 'Otic',
  ORAL: 'Oral',
  PADS: 'Pads',
  PER_RECTAL: 'Per Rectal',
  SUBCUTANEOUS: 'Subcutaneous',
  SUBLINGUAL: 'Sublingual',
  SUBLINGUAL_SPRAY: 'Sublingual Spray',
  TOPICAL: 'Topical',
  TRANSDERMAL: 'Transdermal',
  VAGINAL: 'Vaginal'
};

export const MedicationShortRoutes = {
  BUCCAL: 'BUC',
  ENDOTRACHEAL: 'ET',
  EPIGASTRIC: 'EG',
  INHALED: 'Inhale',
  INTRAMUSCULAR: 'IM',
  INTRANASAL: 'IN',
  INTRAOSSEOUS: 'IO',
  INTRAVENOUS: 'IV',
  NASOGASTRIC: 'NG',
  NEBULIZER: 'Neb',
  OCULAR: 'OU',
  OTIC: 'AU',
  ORAL: 'PO',
  PADS: 'Pads',
  PER_RECTAL: 'PR',
  SUBCUTANEOUS: 'SQ',
  SUBLINGUAL: 'SL',
  SUBLINGUAL_SPRAY: 'SL Spray',
  TOPICAL: 'Top',
  TRANSDERMAL: 'TD',
  VAGINAL: 'PV'
};

export const StorageVolumeTypes = {
  AEROSOL: 'AEROSOL',
  AMPULE: 'AMPULE',
  AUTOINJECTOR: 'AUTOINJECTOR',
  // TODO: this is spelt incorrectly
  BRISOJET: 'BRISOJET',
  CARPUJECT: 'CARPUJECT',
  FLUID_BAG: 'FLUID_BAG',
  PASTE: 'PASTE',
  PILL: 'PILL',
  PREFILLED_SYRINGES: 'PREFILLED_SYRINGES',
  TANK: 'TANK',
  VIAL: 'VIAL',
  LOZENGE: 'LOZENGE'
};

export const StorageVolumeTypesWithLabels = {
  AEROSOL: 'Aerosol',
  AMPULE: 'Ampule',
  AUTOINJECTOR: 'Autoinjector',
  BRISOJET: 'Bristojet',
  CARPUJECT: 'Carpuject',
  FLUID_BAG: 'Fluid Bag',
  PASTE: 'Paste',
  PILL: 'Pill',
  PREFILLED_SYRINGES: 'Prefilled Syringes',
  TANK: 'Tank',
  VIAL: 'Vial',
  LOZENGE: 'Lozenge'
};

export const ConcentrationDisplayTypes = {
  PERCENTAGE: 'PERCENTAGE',
  RATIO: 'RATIO'
};

export const ConcentrationDisplayTypesWihLabels = {
  PERCENTAGE: 'Percentage (e.g. 0.9%',
  RATIO: 'Ratio (e.g. 1:1000)'
};
