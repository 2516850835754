import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const CREATE_DIRECTORY_CONTACT = gql`
  mutation CreateDirectoryContact(
    $input: CreateDirectoryContactForOrganizationInput!
  ) {
    result: createDirectoryContactForOrganization(input: $input) {
      directoryContact {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

export const UPDATE_DIRECTORY_CONTACT = gql`
  mutation UpdateDirectoryContact(
    $input: UpdateDirectoryContactForOrganizationInput!
  ) {
    result: updateDirectoryContactForOrganization(input: $input) {
      directoryContact {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;
