import React from 'react';
import { Pane, Text } from 'evergreen-ui';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const Capability = ({ id }: any) => {
  const client = useApolloClient();

  const capability = client.readFragment({
    id: `AuthorityHospitalCapability:${id}`,
    fragment: gql`
      fragment AuthorityHospitalCapability on AuthorityHospitalCapability {
        id
        name
      }
    `
  });

  return <>{capability && capability.name}</>;
};

const AuthorityAndCapabilities = ({ authorityId, capabilities }: any) => {
  const client = useApolloClient();

  const authority = client.readFragment({
    id: `MedicalAuthority:${authorityId}`,
    fragment: gql`
      fragment MedicalAuthority on MedicalAuthority {
        id
        name
      }
    `
  });

  const totalCapabilities = capabilities.length - 1;

  return (
    <Pane>
      <Text size={300}>
        {authority && authority.name} (
        {capabilities.map((capabilityId: any, idx: any) => (
          <span key={capabilityId}>
            <Capability id={capabilityId} />
            {totalCapabilities !== idx && ', '}
          </span>
        ))}
        )
      </Text>
    </Pane>
  );
};

export default AuthorityAndCapabilities;
