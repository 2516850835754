import React from 'react';
import Helmet from 'react-helmet';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Layout from 'components/shared/Layout';
import InventoryQuestionnaire from 'components/organizations/InventoryQuestionnaire';

export const ORGANIZATION = gql`
  query OrganizationForInventory($slug: String!) {
    organization(slug: $slug) {
      id
      name
    }
  }
`;

export default ({ match }: any) => {
  const { params } = match;
  const { slug } = params;
  const { data } = useQuery(ORGANIZATION, {
    variables: { slug },
    fetchPolicy: 'network-only'
  });

  const organization = data && data.organization;

  return (
    <Layout>
      <Helmet>
        <title>Set Inventory</title>
      </Helmet>
      {organization && <InventoryQuestionnaire organization={organization} />}
    </Layout>
  );
};
