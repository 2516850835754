import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, toaster, Dialog, Pane } from 'evergreen-ui';
import MemberList, {
  ORGANIZATION_MEMBERS
} from 'components/organizations/MemberList';
import PendingInvitesList from 'components/organizations/PendingInvitesList';
import InviteToOrg from 'components/organizations/InviteToOrg';
import AddIndividualToOrganization from 'components/organizations/AddIndividualToOrganization';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import { seatsRemaining } from 'lib/subscription';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';
import { hasPermission, hasRole } from 'lib/organizations';
import { OrganizationRoles } from '@/constants/organizations';

const Dashboard = ({
  history,
  organization = {},
  refetchOrganization
}: any) => {
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [showPendingInvites, setShowPendingInvites] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);

  const subscription = organization && organization.subscription;
  const totalSeatsRemaining = seatsRemaining(subscription);

  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const permissions = {
    // Only allowed to add an individual if this is not an enterprise subscription
    // and the organization is a medical agency. We do this so that we don't end up
    // adding individuals to other kinds of organizations
    canManageIndividualSeat:
      hasRole(organization, OrganizationRoles.MEDICAL_AGENCY) &&
      hasPermission(organization, 'members:assign_seat') &&
      !subscription,
    canManageRoles: hasPermission(organization, 'members:manage_roles'),
    // Only allow invites if the organization has a subscription
    canManageInvites:
      hasPermission(organization, 'members:manage_invites') && subscription,
    canManageCredentials: hasPermission(
      organization,
      'members:manage_credentials'
    )
  };

  const variables = {
    ...pageInfo,
    filters,
    organizationId: organization.id
  };

  const refetchQuery = {
    query: ORGANIZATION_MEMBERS,
    variables
  };

  return (
    <>
      <PageHeader
        title="Manage members"
        renderButtons={
          <>
            {permissions.canManageIndividualSeat && (
              <Button
                iconBefore="badge"
                onClick={() => setShowAddUser(true)}
                marginRight={16}
              >
                Add user
              </Button>
            )}
            {subscription && subscription.pendingInviteCount > 0 && (
              <Button
                appearance="minimal"
                onClick={() => setShowPendingInvites(true)}
              >
                {subscription.pendingInviteCount} pending invitations
              </Button>
            )}
            <Button
              onClick={() => setShowInviteForm(true)}
              iconBefore="new-person"
              disabled={
                totalSeatsRemaining === 0 || !permissions.canManageInvites
              }
              appearance="primary"
              intent="success"
            >
              Invite new members
            </Button>
          </>
        }
      />

      <MemberList
        organization={organization}
        filters={filters}
        variables={variables}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        permissions={permissions}
      />

      <SideSheet
        isShown={showInviteForm}
        onCloseComplete={() => setShowInviteForm(false)}
      >
        <InviteToOrg
          organization={organization}
          seatsRemaining={totalSeatsRemaining}
          slug={organization.slug}
          onComplete={() => {
            refetchOrganization({ slug: organization.slug });
            toaster.success('Invitations successfully sent', {
              description:
                'You can view the pending invitations under the invites tab'
            });
            setShowInviteForm(false);
          }}
        />
      </SideSheet>

      <SideSheet
        isShown={showAddUser}
        onCloseComplete={() => setShowAddUser(false)}
      >
        <AddIndividualToOrganization
          organization={organization}
          refetchQuery={refetchQuery}
          onComplete={() => {
            toaster.success('User added to organization');
            setShowAddUser(false);
          }}
        />
      </SideSheet>

      <Dialog
        title={`Manage Pending Invites (${subscription &&
          subscription.pendingInviteCount})`}
        isShown={showPendingInvites}
        hasFooter={false}
        contentContainerProps={{ padding: 0 }}
        onCloseComplete={() => setShowPendingInvites(false)}
        width={700}
      >
        <Pane padding={8}>
          <PendingInvitesList
            slug={organization.slug}
            onRemoveComplete={() =>
              refetchOrganization({ slug: organization.slug })
            }
          />
        </Pane>
      </Dialog>
    </>
  );
};

export default Dashboard;
