import React from 'react';
import { Menu } from 'evergreen-ui';

const OrgMenu = ({
  onViewSelect,
  onDebugClick,
  onDeleteClick,
  onCloneClick,
  onEditOrganizationSelect,
  onEditSubscriptionSelect,
  onUpdateConfigSelect
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item icon="document-open" onSelect={onViewSelect}>
          Full organization
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item onSelect={onEditOrganizationSelect}>
          Edit organization
        </Menu.Item>
        <Menu.Item onSelect={onEditSubscriptionSelect}>
          Manage subscription
        </Menu.Item>
        <Menu.Item onSelect={onUpdateConfigSelect}>
          Update configuration
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="warning" onSelect={onDebugClick}>
          Debug information
        </Menu.Item>
        <Menu.Item icon="fork" onSelect={onCloneClick}>
          Clone organization
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete organization
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default OrgMenu;
