import React from 'react';
import { BackButton } from 'evergreen-ui';
import PageHeader from 'components/shared/PageHeader';
import ReferencedDoseList from 'components/organizations/ReferencedDoseList';

export default ({ history, medication, organization }: any) => {
  return (
    <>
      <PageHeader
        title={`All doses for ${medication.genericName}`}
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(`/orgs/${organization.slug}/medications`)
            }
          />
        }
      />

      <ReferencedDoseList medication={medication} organization={organization} />
    </>
  );
};
