const ErrorsFromCode = {
  UNAUTHENTICATED: {
    title: 'You must be signed in to see that resource',
    description: 'Please sign in to continue'
  },
  UNAUTHORIZED: {
    title: "You're not allowed to access that resource",
    description:
      'You may not have permissions to perform that action. Please speak to your organization admin.'
  },
  NOT_FOUND: {
    title: "We could not find the item you're requesting",
    description: 'It may have been deleted. Please try again'
  }
};

export const GenericError = {
  title: 'A server-error occurred when trying to peform your request',
  description: 'The Muru team have been notified about this error'
};

export function formatErrorForToaster(error: any) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return ErrorsFromCode[error.code] || GenericError;
}
