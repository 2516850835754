import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  Pane,
  Heading,
  Paragraph,
  Icon,
  Button,
  UnorderedList,
  ListItem
} from 'evergreen-ui';
import { page } from 'lib/analytics';

const OrgGetStarted = () => {
  useEffect(() => {
    page('Onboarding Organization Get Started');
  }, []);

  return (
    <Pane>
      <Helmet>
        <title>Thanks for signing up for your agency</title>
      </Helmet>
      <Pane textAlign="center" marginBottom={24}>
        <Icon icon="tick-circle" size={32} color="success" marginBottom={8} />

        <Heading size={600} marginBottom={8}>
          Welcome to Muru!
        </Heading>
        <Paragraph color="muted" marginBottom={16}>
          Thanks for signing up for Muru for your agency. There are a few things
          you're going to need to do to get started:
        </Paragraph>

        <Pane textAlign="left">
          <UnorderedList icon="square" iconColor="info">
            <ListItem>Setup the inventory your agency uses</ListItem>
            <ListItem>Invite more providers into Muru</ListItem>
            <ListItem>
              Get the app yourself and start seeing the power of Muru
            </ListItem>
          </UnorderedList>
        </Pane>

        <Button
          height={40}
          appearance="primary"
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          marginBottom={16}
          is="a"
          href="/"
        >
          Get started
        </Button>
      </Pane>
    </Pane>
  );
};

export default OrgGetStarted;
