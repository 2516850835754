import React from 'react';
import { BackButton, Spinner } from 'evergreen-ui';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import ReferencedDoseList from 'components/organizations/ReferencedDoseList';

const EQUIPMENT_FUNCTION = gql`
  query EquipmentFunction($id: ID!) {
    equipmentFunction: node(id: $id, type: EQUIPMENT_FUNCTION) {
      id
      ... on EquipmentFunction {
        name
        equipment {
          id
          name
        }
      }
    }
  }
`;

export default ({ history, match, equipment, organization }: any) => {
  const { data, loading, error } = useQuery(EQUIPMENT_FUNCTION, {
    variables: { id: match.params.id },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <Spinner size={24} />;
  }

  const equipmentFunction = get(data, 'equipmentFunction', {});

  return (
    <>
      <PageHeader
        title={`All doses for ${equipmentFunction.name} (${equipment.name})`}
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(
                `/orgs/${organization.slug}/equipment/${equipment.id}/functions`
              )
            }
          />
        }
      />

      {!loading && !error && (
        <ReferencedDoseList
          equipmentFunction={equipmentFunction}
          organization={organization}
        />
      )}
    </>
  );
};
