import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import { Pane, Button, Link, Heading, Paragraph } from 'evergreen-ui';
import { useHistory, useLocation } from 'react-router-dom';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import { page, launchIntercomWithMessage } from 'lib/analytics';

const NotEligible = () => {
  const history = useHistory();
  const location = useLocation();
  const { agencyId } = queryString.parse(location.search);

  useEffect(() => {
    page('Onboarding Agency Not Eligible', {
      selected_agency_id: agencyId
    });
  }, [agencyId]);

  return (
    <Pane>
      <Helmet>
        <title>Not eligible</title>
      </Helmet>
      <Pane
        textAlign="center"
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MuruLogo />

        <Heading size={600} marginBottom={8}>
          Sorry, but you can't join that agency
        </Heading>
        <Paragraph color="muted">
          We're sorry but the agency you selected is already managed inside of
          Muru. To join you're going to need to speak to the admin there and
          they will be to create an account for you.
        </Paragraph>

        <Paragraph color="muted" marginTop={16}>
          If you think this was an error or need help, please get in touch with{' '}
          {/* @ts-expect-error TS(2554): Expected 1 arguments, but got 0. */}
          <Link href="#" onClick={() => launchIntercomWithMessage()}>
            our support team
          </Link>
          .
        </Paragraph>

        <Button
          height={40}
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          onClick={() => history.push('/onboarding')}
        >
          Start over
        </Button>
      </Pane>
    </Pane>
  );
};

export default NotEligible;
