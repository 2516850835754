import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { map, get, findKey } from 'lodash';
import { onChangeAsNull } from 'lib/formHelpers';
import {
  Pane,
  TextInputField,
  SelectField,
  Button,
  Paragraph,
  Dialog,
  Strong
} from 'evergreen-ui';
import states from '@/constants/states.json';

const validationSchema = ({ credentialRequired }: any) =>
  Yup.object().shape({
    credentialId: credentialRequired
      ? Yup.string()
          .required('You must provide a credential level to continue')
          .nullable()
      : Yup.string().nullable()
  });

const OrganizationRoleForm = ({
  agency,
  selectedState,
  credentialRequired = false,
  onComplete
}: any) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const credentials = map(
    get(agency, 'credentials.edges', []),
    ({ node: credential }) => ({
      label: credential.name,
      value: credential.id
    })
  );

  // We have the state name but we need the state code.
  const selectedStateCode = findKey(states, name => selectedState === name);

  return (
    <Pane textAlign="left" marginTop={32}>
      <Formik
        initialValues={{
          credentialId: null,
          licenseNumber: null,
          issuingState: selectedStateCode
        }}
        validationSchema={validationSchema({ credentialRequired })}
        onSubmit={() => setShowConfirm(true)}
        render={({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        }: any) => {
          const selectedCredential =
            values.credentialId &&
            credentials.find(c => c.value === values.credentialId);

          return (
            // @ts-expect-error Invalid onSubmit
            <Form onSubmit={handleSubmit}>
              <SelectField
                value={values.credentialId}
                label="Credential Level"
                description="Please select your credential level at this agency"
                name="credentialId"
                onChange={onChangeAsNull('credentialId', handleChange)}
                onBlur={handleBlur}
                isRequired={credentialRequired}
                isInvalid={!!errors.credentialId}
                validationMessage={errors.credentialId}
              >
                <option value={''}>N/A</option>
                {credentials.map(value => {
                  return (
                    <option key={value.value} value={value.value}>
                      {value.label}
                    </option>
                  );
                })}
              </SelectField>

              <TextInputField
                label="DOH Certification / License (optional)"
                description="Your issued State Department of Health certification / license"
                placeholder="123456"
                name="licenseNumber"
                value={values.licenseNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <SelectField
                value={values.issuingState}
                label="Which state issued this certification / license? (optional)"
                description="Please select the issuing state"
                name="issuingState"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
                <option value={null}>N/A</option>
                {map(states, (name, code) => (
                  <option value={code}>{name}</option>
                ))}
                <option value="DOD">Department of Defense</option>
              </SelectField>

              <Button
                width="100%"
                justifyContent="center"
                appearance="primary"
                height={40}
                type="submit"
              >
                Next
              </Button>

              <Dialog
                isShown={showConfirm}
                title="Please confirm your information"
                hasClose={false}
                hasCancel={true}
                shouldCloseOnOverlayClick={false}
                onCloseComplete={() => setShowConfirm(false)}
                onConfirm={() => {
                  setShowConfirm(false);
                  onComplete(values);
                }}
                confirmLabel="Yes that's correct"
              >
                <Paragraph>
                  Is this the right information for you at this agency? Please
                  take a moment to confirm it as{' '}
                  <Strong>you won't be able to edit it after this step.</Strong>
                </Paragraph>

                <Paragraph marginTop={16}>
                  <Strong>Credential Level:</Strong>{' '}
                  {selectedCredential
                    ? selectedCredential.label
                    : 'No credential level'}
                  <br />
                  <Strong>DOH Certification/License:</Strong>{' '}
                  {values.issuingState || ''} {values.licenseNumber || 'N/A'}
                </Paragraph>
              </Dialog>
            </Form>
          );
        }}
      />
    </Pane>
  );
};

export default OrganizationRoleForm;
