import { includes, isArray, get, every } from 'lodash';

const ROLE_SEP = ':';
const ALL_OP = '*';
const MEDIC_ROLE = 'Medic';

export function hasRole(organization: any, role: any) {
  return includes(organization.roles, role);
}

export function hasMatchingPermission(permissions: any, permission: any) {
  const [r0, a0] = permission.split(ROLE_SEP);

  return !!permissions.find((permission: any) => {
    const [r1, a1] = permission.split(ROLE_SEP);

    // Same resource but we're allowed to do everything on it
    if (r1 === r0 && a1 === ALL_OP) return true;

    // Same resource and a matching permission
    return r1 === r0 && a1 === a0;
  });
}

export function hasPermission(organization: any, permissionOrPermissions: any) {
  const permissions = get(organization, 'permissions', []);

  if (isArray(permissionOrPermissions)) {
    return permissionOrPermissions.some(perm =>
      hasMatchingPermission(permissions, perm)
    );
  } else {
    return hasMatchingPermission(permissions, permissionOrPermissions);
  }
}

export function onlyAppAccess(seats: any) {
  return every(seats, seat =>
    every(seat.roles, role => role.name === MEDIC_ROLE)
  );
}

export function isChildOrganization(organization: any) {
  return organization && organization.parent && !!organization.parent.id;
}
