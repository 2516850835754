import React from 'react';
import { Pane, Button, Heading, Paragraph } from 'evergreen-ui';

const StartOver = ({ onStartOverClick }: any) => {
  return (
    <Pane textAlign="center" maxWidth={400} padding={24}>
      <Heading size={500} marginBottom={8}>
        You've reached the end of the questions, but you've skipped some
        answers. Do you want to start over?
      </Heading>

      <Paragraph color="muted">
        If you start over we'll show the the questions you skipped again.
      </Paragraph>

      <Button
        marginTop={24}
        appearance="primary"
        width="100%"
        justifyContent="center"
        onClick={onStartOverClick}
      >
        Start Over
      </Button>
    </Pane>
  );
};

export default StartOver;
