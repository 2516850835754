import React from 'react';
import { Dialog } from 'evergreen-ui';
import UpdateOrganizationCreditCard from 'components/shared/UpdateOrganizationCreditCard';

const UpdateCard = ({ organization, isShown, onClose, onComplete }: any) => {
  return (
    <Dialog
      isShown={isShown}
      title="Update Credit Card"
      hasCancel={true}
      hasClose={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onCloseComplete={() => onClose()}
      isConfirmLoading={false}
      hasFooter={false}
      onConfirm={() => {}}
      confirmLabel="Update"
    >
      <UpdateOrganizationCreditCard
        onComplete={onComplete}
        organizationId={organization.id}
        buttonTitle="Update card"
        buttonProps={{ marginLeft: 'auto' }}
        subtitle="Updating your card will charge any pending invoices to this new card."
      />
    </Dialog>
  );
};

export default UpdateCard;
