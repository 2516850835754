import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'evergreen-ui';
import { onCompleted } from 'lib/formHelpers';
import { RESET_PASSWORD_FOR_USER } from './queries';

const ResetPasswordAction = ({ userId, onComplete }: any) => {
  const [resetPasswordForUser, { loading }] = useMutation(
    RESET_PASSWORD_FOR_USER,
    {
      variables: { input: { id: userId } },
      onCompleted: (response: any) => onCompleted({ response, onComplete })
    }
  );

  return (
    <Button
      isLoading={loading}
      onClick={() => {
        resetPasswordForUser();
      }}
    >
      Reset user password
    </Button>
  );
};

export default ResetPasswordAction;
