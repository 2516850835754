import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Text, Menu, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

const UserMenu = ({
  name,
  email,
  isUserAdmin,
  history,
  isViewingAdmin,
  onLogout
}: any) => {
  const [logout] = useMutation(LOGOUT_MUTATION, {
    onCompleted: onLogout
  });

  return (
    <Pane width={200}>
      <Menu>
        <Pane background="tint2" borderBottom padding={16}>
          <Heading size={500}>{name}</Heading>
          <Text color="muted" size={400}>
            {email}
          </Text>
        </Pane>
        {isUserAdmin && (
          <>
            <Menu.Group>
              {isViewingAdmin ? (
                <Menu.Item icon="briefcase" onSelect={() => history.push('/')}>
                  Switch to org view
                </Menu.Item>
              ) : (
                <Menu.Item
                  icon="globe"
                  onSelect={() => history.push('/admin/organizations')}
                >
                  Switch to admin
                </Menu.Item>
              )}
            </Menu.Group>
            <Menu.Divider />
          </>
        )}
        <Menu.Group>
          <Menu.Item icon="person" onSelect={() => history.push('/account')}>
            Edit account
          </Menu.Item>
          <Menu.Item
            icon="help"
            onSelect={() => {
              // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
              window.Intercom && window.Intercom('show');
            }}
          >
            Get help
          </Menu.Item>
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item onSelect={() => logout()}>Logout</Menu.Item>
        </Menu.Group>
      </Menu>
    </Pane>
  );
};

export default UserMenu;
