import React from 'react';
import { Pane, Alert, Table, Heading, IconButton, Text } from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';

const dataHeaderProps = {
  flexBasis: 150,
  flexShrink: 0,
  flexGrow: 0,
  paddingLeft: 0
};

const UpdateSubscription = ({ subscription }: any) => {
  return (
    <Pane>
      <Alert
        title="You cannot make subscription seat changes here"
        color="muted"
      >
        If you wish to modify this subscription, please go to the organizations
        billing page and make changes there.
      </Alert>

      <Pane display="flex" alignItems="center">
        <Heading size={500} marginBottom={0}>
          Details
        </Heading>
        <Pane display="flex" alignItems="center" paddingY={8} marginLeft="auto">
          <Text size={300} color="muted" marginRight={8}>
            Quicklinks
          </Text>

          <IconButton
            appearance="minimal"
            icon="credit-card"
            onClick={() =>
              window.open(
                `https://dashboard.stripe.com/subscriptions/${subscription.stripeSubscriptionId}`,
                '_blank'
              )
            }
            iconSize={12}
          />
        </Pane>
      </Pane>

      <Table borderTop>
        <Table.Body>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Plan
            </Table.TextHeaderCell>
            <Table.TextCell>{subscription.plan.name}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Total Cost
            </Table.TextHeaderCell>
            <Table.TextCell>{subscription.totalAmountInCents}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Total Seats
            </Table.TextHeaderCell>
            <Table.TextCell>{subscription.totalSeatCount}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Occupied Seats
            </Table.TextHeaderCell>
            <Table.TextCell>{subscription.occupiedSeatCount}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Pending Invites
            </Table.TextHeaderCell>
            <Table.TextCell>{subscription.pendingInviteCount}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Next billing date
            </Table.TextHeaderCell>
            <Table.Cell>
              <RelativeDate date={subscription.nextBilledAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Cancelled
            </Table.TextHeaderCell>
            <Table.Cell>
              <RelativeDate date={subscription.cancelledAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Trial ends
            </Table.TextHeaderCell>
            <Table.Cell>
              <RelativeDate date={subscription.trialEndAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Created
            </Table.TextHeaderCell>
            <Table.Cell>
              <RelativeDate date={subscription.insertedAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextHeaderCell {...dataHeaderProps}>
              Last Updated
            </Table.TextHeaderCell>
            <Table.Cell>
              <RelativeDate date={subscription.updatedAt} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Pane>
  );
};

export default UpdateSubscription;
