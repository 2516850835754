import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { MEDICATION_LIST } from 'components/organizations/MedicationList';
import MultiSelect from 'components/shared/FormBuilder/Fields/MultiSelect';
import { toName } from 'lib/medications';

const MedicationSelector = ({ organizationId, ...props }: any) => {
  const { data, loading, error } = useQuery(MEDICATION_LIST, {
    variables: {
      organizationId,
      filters: { publishedState: 'DRAFT' },
      first: 999
    },
    fetchPolicy: 'network-only'
  });

  const options =
    !loading && !error
      ? // @ts-expect-error TS(7031): Binding element 'medication' implicitly has an 'an... Remove this comment to see the full error message
        data.organization.medications.edges.map(({ node: medication }) => ({
          label: toName(medication),
          value: medication.id
        }))
      : [];

  return <MultiSelect {...props} values={options} />;
};

export default MedicationSelector;
