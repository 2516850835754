import * as Yup from 'yup';
import React from 'react';
import { Text, Icon, Pane } from 'evergreen-ui';
import EntryCriteria from 'components/shared/EntryCriteria';
import { formatEnumToOptions } from 'lib/formHelpers';
import { PatientTypeWithLabel } from 'constants/units';

const EntrySchema = Yup.object().shape({
  patientTypes: Yup.array().of(Yup.string()),
  notes: Yup.array()
});

const NoteSchema = Yup.object().shape({
  text: Yup.string().required(),
  important: Yup.boolean().required()
});

const ProtocolForm = (canSetPatientType: any) => ({
  type: 'subFields',
  name: 'entryCriteria',
  label: 'Entry Criteria',
  validationSchema: EntrySchema,

  description:
    'Set any entry criteria that should apply. Entry criteria set on a parent item is inherited and does not need to be readded.',

  initialState: {
    notes: [],
    patientTypes: []
  },

  renderPreview: (entryCriteria: any) =>
    entryCriteria && <EntryCriteria {...entryCriteria} />,

  fields: [
    {
      type: 'multiSelect',
      label: 'Patient Types',
      name: 'patientTypes',
      values: formatEnumToOptions(PatientTypeWithLabel),
      description: !canSetPatientType ? '(disabled as set on a parent)' : '',
      disabled: !canSetPatientType
    },
    {
      type: 'listOfSubFields',
      label: 'Notes',
      name: 'notes',
      validationSchema: NoteSchema,
      titleExtractor: ({ text, important }: any) => (
        <Pane display="flex" alignItems="center">
          {important && (
            <Icon icon="warning-sign" color="warning" marginRight={8} />
          )}
          <Text size={300}>{text}</Text>
        </Pane>
      ),
      initialState: {
        text: '',
        important: false
      },
      fields: [
        {
          type: 'textarea',
          name: 'text',
          label: 'Text',
          placeholder: 'Some important note'
        },
        {
          type: 'switch',
          name: 'important',
          label: 'Important?',
          description:
            'When on this note will be shown next to the condition of use on any doses'
        }
      ]
    }
  ]
});

export default ProtocolForm;
