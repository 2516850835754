const AUTH_KEY = 'muru-auth-token';
const REFRESH_KEY = 'muru-refresh-key';

export function setAuthCredentials(authToken: any, refreshToken: any) {
  localStorage.setItem(AUTH_KEY, authToken);
  localStorage.setItem(REFRESH_KEY, refreshToken);
}

export function getAuthToken() {
  return localStorage.getItem(AUTH_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_KEY);
}

export function clearAuthCredentials() {
  localStorage.removeItem(AUTH_KEY);
  localStorage.removeItem(REFRESH_KEY);
}

export const ROLES = {
  ADMIN: 'ADMIN',
  AGGREGATOR: 'AGGREGATOR'
};

export function isSiteAdmin(user: any) {
  return !!user && user.role === ROLES.ADMIN;
}
