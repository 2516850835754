import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';
import { CREATE_MEDICATION, UPDATE_MEDICATION } from './queries';

const initialValues = {
  brandName: '',
  genericName: '',
  storageMethods: []
};

const CreateOrUpdateMedication = ({
  medication,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const [createOrUpdateMedication, { loading }] = useMutation(
    medication && medication.id ? UPDATE_MEDICATION : CREATE_MEDICATION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData =
    medication && medication.id ? sanitizeData(medication) : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {medication && medication.id
            ? 'Update medication'
            : 'Create new medication'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={medication?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            setErrors({});
            createOrUpdateMedication({ variables: { input } });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateMedication;
