import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import { REMOVE_INDIVIDUAL_FROM_ORG, SUBSCRIPTIONS } from './queries';
import { CURRENT_USER_QUERY } from 'components/shared/queries.ts';

const RemoveIndividualAgencyDialog = ({
  seatToRemove,
  isShown,
  onCloseComplete
}: any) => {
  const [leaveAgency, { loading }] = useMutation(REMOVE_INDIVIDUAL_FROM_ORG, {
    refetchQueries: [{ query: SUBSCRIPTIONS }, { query: CURRENT_USER_QUERY }],
    awaitRefetchQueries: true,
    onCompleted: (response: any) => {
      if (response.result.subscription) {
        toaster.success('Successfully left agency');
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      isShown={isShown}
      title="Are you sure you wish to leave this agency?"
      intent="danger"
      onCloseComplete={onCloseComplete}
      // @ts-expect-error loading issue
      loading={loading}
      onConfirm={() =>
        leaveAgency({
          variables: {
            input: {
              organizationId: seatToRemove.organization.id
            }
          }
        })
      }
      confirmLabel="Leave agency"
    >
      {seatToRemove ? (
        <Paragraph marginBottom={8}>
          You are about to leave{' '}
          <strong>{seatToRemove.organization.name}</strong>. If you change your
          mind later, you can easily rejoin the agency. Please confirm you wish
          to proceed.
        </Paragraph>
      ) : null}
    </Dialog>
  );
};

export default RemoveIndividualAgencyDialog;
