import React from 'react';
import { map } from 'lodash';
import {
  Dialog,
  Pane,
  Heading,
  UnorderedList,
  ListItem,
  Strong
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';

const publishedDates = {
  hospitalsLastPublishedAt: 'Hospitals',
  equipmentLastPublishedAt: 'Equipment',
  medicationsLastPublishedAt: 'Medications',
  aliasesLastPublishedAt: 'Aliases',
  protocolSetsLastPublishedAt: 'Protocol Sets'
};

const Debug = ({ organization }: any) => (
  <Pane>
    <Heading size={100} marginBottom={8}>
      Last Published Dates
    </Heading>

    <UnorderedList>
      {map(publishedDates, (heading, column) => (
        <ListItem>
          <Strong>{heading}</Strong>:{' '}
          <RelativeDate textSize={400} date={organization[column]} />
        </ListItem>
      ))}
    </UnorderedList>
  </Pane>
);

const DebugDialog = ({ isShown, onClose, organization }: any) => (
  <Dialog
    isShown={isShown}
    title={organization ? `Debugging ${organization.name}` : ''}
    onCloseComplete={onClose}
    hasFooter={false}
  >
    {organization ? <Debug organization={organization} /> : <br />}
  </Dialog>
);

export default DebugDialog;
