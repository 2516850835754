import React from 'react';
import {
  Pane,
  Heading,
  Text,
  Menu,
  Popover,
  Position,
  IconButton
} from 'evergreen-ui';
import { toName, storageMethodToString } from 'lib/medications';

const EntryMenu = ({ onEditClick, onDeleteClick }: any) => (
  <Menu>
    <Menu.Group>
      <Menu.Item onSelect={onEditClick}>Change</Menu.Item>
    </Menu.Group>
    <Menu.Divider />
    <Menu.Group>
      <Menu.Item intent="danger" onSelect={onDeleteClick}>
        Delete
      </Menu.Item>
    </Menu.Group>
  </Menu>
);

export const MedicationEntry = ({ entry, onEditClick, onDeleteClick }: any) => (
  <Pane
    display="flex"
    alignItems="center"
    padding={16}
    paddingTop={8}
    paddingBottom={8}
    key={entry.medication.id}
  >
    <Pane flex="1">
      <Heading size={300}>{toName(entry.medication)}</Heading>

      {entry.storageMethods.length > 0 && (
        <Text size={300}>
          {entry.storageMethods.map(storageMethodToString).join(', ')}
        </Text>
      )}
    </Pane>
    <Popover
      content={
        <EntryMenu onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
      }
      position={Position.BOTTOM_RIGHT}
    >
      <IconButton
        marginLeft="auto"
        icon="more"
        height={24}
        appearance="minimal"
      />
    </Popover>
  </Pane>
);

export const EquipmentEntry = ({ entry, onEditClick, onDeleteClick }: any) => (
  <Pane
    display="flex"
    alignItems="center"
    padding={16}
    paddingTop={8}
    paddingBottom={8}
    key={entry.genericEquipment.id}
  >
    <Pane flex="1">
      <Heading size={300}>{entry.equipment.name}</Heading>
      <Text size={300} color="muted">
        {entry.equipment.manufacturer &&
          ` ${entry.equipment.manufacturer.name}`}
      </Text>
    </Pane>
    <Popover
      content={
        <EntryMenu onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
      }
      position={Position.BOTTOM_RIGHT}
    >
      <IconButton
        marginLeft="auto"
        icon="more"
        height={24}
        appearance="minimal"
      />
    </Popover>
  </Pane>
);
