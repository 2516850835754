import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import EquipmentList, {
  EQUIPMENT_LIST
} from 'components/organizations/EquipmentList';
import DeleteDialog from 'components/shared/DeleteDialog';
import useIsChildOrganization from 'hooks/useIsChildOrganization';
import {
  withDefaultPageInfo,
  parseFilters,
  onPageInfoUpdate,
  onFilterUpdate
} from 'lib/pagination';

const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($input: DeleteEquipmentForOrganizationInput!) {
    result: deleteEquipmentForOrganization(input: $input) {
      equipment {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const isViewOnly = useIsChildOrganization();

  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters: { ...filters, publishedState: 'DRAFT' },
    organizationId: organization.id
  };

  const refetchQuery = {
    variables,
    query: EQUIPMENT_LIST
  };

  const [deleteEquipment, { loading: isDeleteLoading }] = useMutation(
    DELETE_EQUIPMENT,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.equipment) {
          toaster.success('Equipment deleted');
        } else {
          toaster.danger('Could not delete equipment', {
            description: 'Check that there are no functions attached'
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage equipment"
        renderButtons={
          <Button
            iconBefore="add"
            marginLeft="auto"
            disabled={isViewOnly}
            onClick={() =>
              history.push(`/orgs/${organization.slug}/equipment/new`)
            }
          >
            New Equipment
          </Button>
        }
      />

      <EquipmentList
        organization={organization}
        filters={filters}
        variables={variables}
        refetchQuery={refetchQuery}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onDeleteClick={(equipment: any) => setShowDeleteDialog(equipment)}
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteEquipment({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
