import React from 'react';
import { SelectField } from 'evergreen-ui';
import { isObject, isFunction } from 'lodash';
import { onChangeAsNull } from 'lib/formHelpers';

const Select = ({ formikBag, name, onChange, values = [], ...props }: any) => {
  const optionValues = isFunction(values) ? values(formikBag.values) : values;

  return (
    <SelectField
      name={name}
      onChange={onChangeAsNull(name, onChange)}
      {...props}
    >
      {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
      <option value={null} />
      {optionValues.map((value: any) => {
        const normalizedVal = isObject(value) ? value : { label: value, value };

        return (
          // @ts-expect-error TS(2339): Property 'value' does not exist on type 'object'.
          <option key={normalizedVal.value} value={normalizedVal.value}>
            {/* @ts-expect-error TS(2339): Property 'label' does not exist on type 'object'. */}
            {normalizedVal.label}
          </option>
        );
      })}
    </SelectField>
  );
};

export default Select;
