import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, Heading, Paragraph, Button } from 'evergreen-ui';
import { page } from 'lib/analytics';

const NotEligible = () => {
  const history = useHistory();

  useEffect(() => {
    page('Add Agency Not Eligible Agency Selected');
  }, []);

  return (
    <Pane>
      <Pane textAlign="center" marginBottom={24} maxWidth={350}>
        <Heading size={600} marginBottom={8}>
          Sorry, you can't join that agency
        </Heading>
        <Paragraph color="muted">
          The agency you selected is already managed internally. If you work at
          this agency, please contact your admin to be added to it.
        </Paragraph>

        <Button
          height={40}
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          marginBottom={16}
          onClick={() => history.push('/')}
        >
          Start again
        </Button>
      </Pane>
    </Pane>
  );
};

export default NotEligible;
