import React from 'react';
import { Menu } from 'evergreen-ui';

const EquipmentFunctionMenu = ({
  onEditClick,
  onPublishClick,
  onListDosesClick,
  onDeleteClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit function</Menu.Item>
        <Menu.Item onSelect={onListDosesClick}>
          List doses for function
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="success" onSelect={onPublishClick}>
          Publish function
        </Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete function
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default EquipmentFunctionMenu;
