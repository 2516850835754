import gql from 'graphql-tag';

export const AVAILABLE_AGENCIES_FOR_ZIP = gql`
  query AvailableAgenciesForZipCode($zipCode: String!) {
    availableAgenciesForZipCode(zipCode: $zipCode) {
      agencies {
        id
        name
        logo {
          url
        }
      }
      zipCode {
        zipCode
        county
        state
      }
    }
  }
`;
