import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import { PageInfoFragment, AliasFragment } from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { isSystemAlias } from 'lib/aliasHelpers';
import AliasMenu from './Menu';

export const ALIASES_LIST = gql`
  query Aliases(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterAliasesInput
  ) {
    aliases(
      first: $first
      after: $after
      before: $before
      last: $last
      filters: $filters
    ) {
      edges {
        node {
          ...Alias
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragment}
  ${AliasFragment}
`;

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const AliasesList = ({
  page,
  filters,
  onFilterUpdate,
  onPageInfoUpdate,
  onEditClick,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(ALIASES_LIST, {
    variables: { ...page, filters },
    fetchPolicy: 'network-only'
  });
  const orderBy = filters.orderBy || defaultOrderBy;
  const aliases = get(data, 'aliases.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by alias"
          initialValue={filters.key}
          onSubmit={(key: any) => onFilterUpdate({ key })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {aliases.map(({ node: alias }) => {
                const systemAlias = isSystemAlias(alias);

                return (
                  <Table.Row key={alias.id} isSelectable>
                    <Table.Cell>
                      <Text size={300} fontWeight={500}>
                        {systemAlias
                          ? `${alias.references}.${alias.key}`
                          : alias.key}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Badge>{systemAlias ? 'System' : 'String'}</Badge>
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={alias.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <AliasMenu
                            onEditClick={() => onEditClick(alias)}
                            onDeleteClick={() => onDeleteClick(alias)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && aliases.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'aliases.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default AliasesList;
