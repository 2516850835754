import React from 'react';
import { Alert } from 'evergreen-ui';

export default () => {
  return (
    <Alert
      intent="danger"
      title="Could not save your submission"
      marginBottom={16}
    >
      There were errors with your data, please correct these and resubmit.
    </Alert>
  );
};
