import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tablist, Heading, Pane, SidebarTab } from 'evergreen-ui';
import {
  LIST_ORGS_PATH,
  LIST_USERS_PATH,
  LIST_HOSPITALS_PATH,
  LIST_MOLECULES_PATH,
  LIST_ALIASES_PATH
} from 'pages/admin/paths';
import { isInPath } from 'lib/urls';

const Sidebar = ({ history, location }: any) => {
  const { pathname } = location;

  return (
    <Pane width={260} borderRight="default" background="tint1">
      <Pane
        display="flex"
        alignItems="center"
        padding={16}
        borderBottom="default"
      >
        <Pane>
          <Heading size={600}>Admin Area</Heading>
        </Pane>
      </Pane>
      <Pane padding={8}>
        <Tablist marginBottom={24}>
          <Heading size={100} margin={8}>
            Accounts
          </Heading>
          <SidebarTab
            onSelect={() => history.push(LIST_ORGS_PATH)}
            isSelected={isInPath(LIST_ORGS_PATH, pathname)}
          >
            Organizations
          </SidebarTab>
          <SidebarTab
            onSelect={() => history.push(LIST_USERS_PATH)}
            isSelected={isInPath(LIST_USERS_PATH, pathname)}
          >
            Users
          </SidebarTab>
        </Tablist>
        <Tablist marginBottom={24}>
          <Heading size={100} margin={8}>
            Content
          </Heading>
          <SidebarTab
            onSelect={() => history.push(LIST_HOSPITALS_PATH)}
            isSelected={isInPath(LIST_HOSPITALS_PATH, pathname)}
          >
            Hospitals
          </SidebarTab>
          <SidebarTab
            onSelect={() => history.push('/admin/medications')}
            isSelected={isInPath('/admin/medications', pathname)}
          >
            Medications
          </SidebarTab>
          <SidebarTab
            onSelect={() => history.push(LIST_MOLECULES_PATH)}
            isSelected={isInPath(LIST_MOLECULES_PATH, pathname)}
          >
            Molecules
          </SidebarTab>
          <SidebarTab
            onSelect={() => history.push(LIST_ALIASES_PATH)}
            isSelected={isInPath(LIST_ALIASES_PATH, pathname)}
          >
            Aliases
          </SidebarTab>
        </Tablist>
      </Pane>
    </Pane>
  );
};

export default withRouter(Sidebar);
