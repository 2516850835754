import React from 'react';
import { Pane, majorScale } from 'evergreen-ui';
import Media from 'react-media';
import { useWindowSize } from 'react-use';
import StyledLogo from './MuruLogo';

const queries = {
  phone: '(max-width: 599px)',
  everythingElse: '(min-width: 600px)'
};

function BoxLayout({ children, hideLogo = false }: any) {
  const { height } = useWindowSize();

  return (
    <Media queries={queries}>
      {(matches: any) => (
        <Pane
          background={matches.phone ? 'white' : 'tint1'}
          height={height}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pane
            backgroundColor="white"
            textAlign="center"
            padding={majorScale(3)}
            margin={8}
            maxWidth={420}
            width="100%"
            border={matches.phone ? 'none' : true}
          >
            {!hideLogo && <StyledLogo />}

            {children}
          </Pane>
        </Pane>
      )}
    </Media>
  );
}

export default BoxLayout;
