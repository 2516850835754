import React from 'react';
import { Pane, toaster, BackButton } from 'evergreen-ui';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import LoadingScreen from 'components/shared/LoadingScreen';
import { HospitalFragment } from 'components/shared/fragments';
import CreateOrUpdateHospital from 'components/admin/CreateOrUpdateHospital';

const HOSPITAL = gql`
  query HospitalForOrganization($id: ID!) {
    hospital: node(id: $id, type: HOSPITAL) {
      ... on Hospital {
        ...Hospital
      }
    }
  }
  ${HospitalFragment}
`;

export default ({ match, history, organization }: any) => {
  const { id: hospitalId } = match.params;
  const { data: hospitalData, loading } = useQuery(HOSPITAL, {
    variables: { id: hospitalId },
    fetchPolicy: 'network-only'
  });
  const redirectToList = () =>
    history.push(`/orgs/${organization.slug}/hospitals`);

  return (
    <>
      <PageHeader
        title="Update hospital"
        renderButtons={
          <BackButton marginLeft="auto" onClick={redirectToList} />
        }
      />

      <Pane width="500px">
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <CreateOrUpdateHospital
              canChangeOrganization={false}
              organizationId={organization.id}
              hospital={hospitalData && hospitalData.hospital}
              onComplete={() => {
                toaster.success('Hospital successfully updated');
                redirectToList();
              }}
            />
          </>
        )}
      </Pane>
    </>
  );
};
