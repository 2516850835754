import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PUBLISH_GLOBAL_ALIASES = gql`
  mutation PublishAliases {
    result: publishAliases {
      success
    }
  }
`;

const PublishDialog = ({ isShown, onCloseComplete }: any) => {
  const [publishAliases, { loading }] = useMutation(PUBLISH_GLOBAL_ALIASES, {
    onCompleted: (response: any) => {
      if (response.result.success) {
        toaster.success('Aliases successfully published');
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish"
      onConfirm={() => publishAliases()}
    >
      <Paragraph>
        Publishing aliases will trigger an updater for all Muru users. Please
        don't do this too often!
      </Paragraph>
    </Dialog>
  );
};

export default PublishDialog;
