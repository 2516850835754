import React, { useState } from 'react';
import { pick } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted } from 'lib/formHelpers';
import { CHANGE_ROLE_FOR_USER } from './queries';

export const Form = {
  name: 'updateProfile',
  fields: [
    {
      type: 'select',
      label: 'System role',
      values: [
        {
          label: 'Admin',
          value: 'ADMIN'
        }
      ],
      name: 'role'
    }
  ]
};

const ChangeUserRole = ({ user, onComplete }: any) => {
  const [errors, setErrors] = useState({});
  const [changeRoleForUser, { loading }] = useMutation(CHANGE_ROLE_FOR_USER, {
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  return (
    <FormBuilder
      key={user.id ?? -1}
      initialValues={pick(user, ['role'])}
      form={Form}
      isInline={true}
      errors={errors}
      isLoading={loading}
      normalizeValuesBeforeSend={false}
      onSubmit={(input: any) => {
        const role = input.role === '' ? null : input.role;

        setErrors({});
        changeRoleForUser({
          variables: { input: { role, id: user.id } }
        });
      }}
    />
  );
};

export default ChangeUserRole;
