import React, { useEffect } from 'react';
import { merge, get } from 'lodash';
import {
  Pane,
  Heading,
  Paragraph,
  TextInput,
  Select,
  SegmentedControl
} from 'evergreen-ui';

const dosingCategory = { label: 'Dose', value: 'DOSING' };

const categories = [
  { label: 'Hospital', value: 'HOSPITAL' },
  { label: 'Equipment', value: 'EQUIPMENT' },
  { label: 'Medication', value: 'MEDICATION' },
  { label: 'Protocol', value: 'PROTOCOL' }
];

const Types = {
  SEARCH: 'SEARCH',
  ITEM: 'ITEM',
  BROWSE: 'BROWSE'
};

const initialState = {
  type: Types.SEARCH,
  metadata: {
    entityId: null,
    subcategory: null,
    searchTerm: null
  }
};

const CategorySelect = ({
  name,
  onBlur,
  onChange,
  value,
  withDosing = false
}: any) => (
  <Select
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    width="100%"
    marginBottom={8}
  >
    <option />
    {categories.concat(withDosing ? [dosingCategory] : []).map(category => (
      <option key={category.value} value={category.value}>
        {category.label}
      </option>
    ))}
  </Select>
);

const IntervalOptions = [
  { label: 'Search', value: Types.SEARCH },
  { label: 'Browse', value: Types.BROWSE },
  { label: 'Specific Item', value: Types.ITEM }
];

const TargetSelector = ({
  name,
  label,
  description,
  validationMessage,
  value,
  formikBag,
  onChange,
  onBlur,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const currentValue = merge({}, initialState, formikBag.values);
  const { setFieldValue } = formikBag;

  const setChanges = (name: any, selected: any) => {
    formikBag.setFieldTouched(name);
    formikBag.setFieldValue(name, selected);
  };

  useEffect(() => {
    if (value === null) {
      setFieldValue('type', Types.SEARCH);
    }
  }, [setFieldValue, value]);

  return (
    <Pane marginBottom={24} borderBottom="muted" paddingBottom={8}>
      <Pane marginBottom={8}>
        <Heading size={400}>{label}</Heading>
        {description && <Paragraph color="muted">{description}</Paragraph>}
      </Pane>

      <Pane marginBottom={8}>
        <SegmentedControl
          height={24}
          options={IntervalOptions}
          value={currentValue.type}
          onChange={(value: any) => {
            setChanges('type', value);
            setChanges('category', null);
            setChanges('metadata.subcategory', null);
            setChanges('metadata.entityId', null);
            setChanges('metadata.searchTerm', null);
          }}
          marginBottom={8}
        />

        {currentValue.type === Types.SEARCH && (
          <>
            <CategorySelect
              onBlur={onBlur}
              onChange={onChange}
              name="category"
              value={currentValue.category}
              withDosing
            />
            <TextInput
              placeholder="Search term"
              name="metadata.searchTerm"
              onBlur={onBlur}
              onChange={onChange}
              value={get(currentValue, 'metadata.searchTerm')}
              width="100%"
            />
          </>
        )}

        {currentValue.type === Types.BROWSE && (
          <>
            <CategorySelect
              onBlur={onBlur}
              onChange={onChange}
              name="category"
              value={currentValue.category}
            />
            <TextInput
              placeholder="Subcategory (e.g. Nearby Hospitals)"
              name="metadata.subcategory"
              onBlur={onBlur}
              onChange={onChange}
              value={get(currentValue, 'metadata.subcategory')}
              width="100%"
            />
          </>
        )}

        {currentValue.type === Types.ITEM && (
          <>
            <CategorySelect
              onBlur={onBlur}
              onChange={onChange}
              name="category"
              value={currentValue.category}
            />
            <TextInput
              placeholder="Entity ID (should be a UUID)"
              name="metadata.entityId"
              onBlur={onBlur}
              onChange={onChange}
              value={get(currentValue, 'metadata.entityId')}
              width="100%"
            />
          </>
        )}
      </Pane>
    </Pane>
  );
};

export default TargetSelector;
