import React, { useEffect } from 'react';
import { Pane, Heading, Paragraph, Icon, Avatar, Button } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { organizationSelector } from 'redux/onboarding';
import { page } from 'lib/analytics';

const Complete = ({ onCloseClick }: any) => {
  const organization = useSelector(organizationSelector);

  useEffect(() => {
    page('Add Agency Purchase Complete');
  }, []);

  return (
    <Pane>
      <Pane textAlign="center" marginBottom={24} maxWidth={350}>
        <Pane
          position="relative"
          width={64}
          marginLeft="auto"
          marginRight="auto"
          marginBottom={16}
        >
          <Avatar
            src={organization.logo && organization.logo.url}
            name={organization.name}
            size={64}
          />
          <Icon
            icon="endorsed"
            size={24}
            right={-8}
            top={-8}
            color="success"
            position="absolute"
          />
        </Pane>

        <Heading size={600} marginBottom={8}>
          You have access to {organization.name}
        </Heading>
        <Paragraph color="muted" marginBottom={16}>
          You can toggle between agencies right away. Just click on your
          agency’s logo within the Muru app.
        </Paragraph>

        <Button
          height={40}
          appearance="primary"
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          onClick={onCloseClick}
        >
          OK, got it
        </Button>
      </Pane>
    </Pane>
  );
};

export default Complete;
