import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Pane, Heading, Text } from 'evergreen-ui';
import queryString from 'query-string';
import BrandedLayout from 'components/shared/BrandedLayout';
import StyledLogo from 'components/shared/Layout/MuruLogo';
import ResetPasswordForm from 'components/accounts/ResetPasswordForm';
import PasswordChanged from 'components/accounts/ResetPasswordForm/PasswordChanged';

function ResetPassword({ location }: any) {
  const { t } = useTranslation();
  const [hasReset, setHasReset] = useState(false);
  const { token } = queryString.parse(location.search);

  return (
    <BrandedLayout>
      <Pane width={'100%'} maxWidth={380} textAlign="center">
        <StyledLogo />
        <Helmet>
          <title>{t('forgot_password.title')}</title>
        </Helmet>

        {hasReset ? (
          <PasswordChanged />
        ) : (
          <>
            <Heading size={600}>{t('forgot_password.welcome')}</Heading>
            <Text color="muted">Enter a new password for your account</Text>
            <ResetPasswordForm
              data-testid="signup-form"
              token={token || 'invalid'}
              onResetPassword={() => setHasReset(true)}
            />
          </>
        )}
      </Pane>
    </BrandedLayout>
  );
}

export default ResetPassword;
