import React, { useState, useEffect } from 'react';
import {
  Position,
  Pane,
  Heading,
  SelectMenu,
  Button,
  Paragraph,
  FormFieldValidationMessage
} from 'evergreen-ui';
import { isFunction, filter, find } from 'lodash';

const MultiSelect = ({
  label,
  description,
  validationMessage,
  value,
  values,
  formikBag,
  onChange,
  disabled,
  ...props
}: any) => {
  const options = isFunction(values) ? values(formikBag.values) : values;
  const [selectedItems, setSelected] = useState(value || []);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const selectedOptions = selectedItems.map((selectedItem: any) =>
      find(options, item => item.value === selectedItem)
    );
    setSelectedOptions(selectedOptions);
  }, [selectedItems, options]);

  const setChanges = (selected: any) => {
    setSelected(selected);
    formikBag.setFieldTouched(props.name);
    formikBag.setFieldValue(props.name, selected);
  };

  return (
    <Pane marginBottom={24}>
      <Pane
        display="flex"
        borderBottom="muted"
        paddingBottom={8}
        marginBottom={8}
      >
        <Pane maxWidth="80%">
          <Heading size={400}>
            {label} ({selectedOptions.length})
          </Heading>
          {description && <Paragraph color="muted">{description}</Paragraph>}
        </Pane>
        <SelectMenu
          {...props}
          position={Position.BOTTOM_RIGHT}
          title={'Select all that apply'}
          options={options}
          selected={selectedItems}
          onSelect={(item: any) => setChanges([...selectedItems, item.value])}
          onDeselect={(item: any) =>
            setChanges(filter(selectedItems, i => i !== item.value))
          }
          isMultiSelect
        >
          <Button
            type="button"
            appearance="minimal"
            marginLeft="auto"
            height={20}
            disabled={!!disabled}
          >
            Edit
          </Button>
        </SelectMenu>
      </Pane>

      <Paragraph color="muted" fontFamily="display" size={300}>
        {selectedOptions && selectedOptions.length > 0
          ? selectedOptions
              // @ts-expect-error TS(2339): Property 'label' does not exist on type 'never'.
              .map(item => (item && item.label ? item.label : ''))
              .join(', ')
          : ''}
      </Paragraph>

      {typeof validationMessage === 'string' && (
        <FormFieldValidationMessage marginTop={4}>
          {validationMessage}
        </FormFieldValidationMessage>
      )}
    </Pane>
  );
};

export default MultiSelect;
