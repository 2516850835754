import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Pane, Heading, Paragraph, Icon, Link } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import { page } from 'lib/analytics';
import AppMagicLogin from 'components/shared/AppMagicLogin';

const GetStarted = () => {
  useEffect(() => {
    page('Onboarding Individual Get Started');
  }, []);

  return (
    <Pane>
      <Helmet>
        <title>Thanks for signing up</title>
      </Helmet>
      <Pane textAlign="center" marginBottom={24}>
        <Icon icon="tick-circle" size={32} color="success" marginBottom={8} />

        <Heading size={600} marginBottom={8}>
          Welcome to Muru!
        </Heading>
        <Paragraph color="muted" marginBottom={16}>
          You're signed up and ready to go! The last thing you need to do is to
          get the Muru app on either iOS or Android. Don't worry if you're not
          currently on your mobile device, you can text yourself a link.
        </Paragraph>

        <AppMagicLogin />

        <Link is={RouterLink} to="/account">
          Manage your account
        </Link>
      </Pane>
    </Pane>
  );
};

export default GetStarted;
