import React from 'react';
import Helmet from 'react-helmet';
import Layout from 'components/shared/Layout';
import { Pane } from 'evergreen-ui';
import ManageAccount from 'components/accounts/ManageAccount';

function Account() {
  return (
    <Layout>
      <Helmet>
        <title>Manage your account | Muru</title>
      </Helmet>

      <Pane
        justifyContent="center"
        overflowY="scroll"
        height="100%"
        display="flex"
        flex={1}
        background="tint1"
      >
        <ManageAccount />
      </Pane>
    </Layout>
  );
}

export default Account;
