import styled from 'styled-components';
import Logo from 'icons/muru-logo.svg';
import { spacing } from 'theme';
import { FC } from 'react';

const StyledLogo = styled(Logo)`
  margin-bottom: ${spacing.m};
  width: auto;
  height: 40px;
`;

export default StyledLogo as FC;
