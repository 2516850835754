import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CredentialList, {
  CREDENTIAL_LIST,
  listRequestVariables
} from 'components/organizations/CredentialList';
import DeleteDialog from 'components/shared/DeleteDialog';
import SideSheet from 'components/shared/SideSheet';
import PageHeader from 'components/shared/PageHeader';
import CreateOrUpdateCredential from 'components/organizations/CreateOrUpdateCredential';
import useIsChildOrganization from 'hooks/useIsChildOrganization';

const DELETE_CREDENTIAL = gql`
  mutation DeleteCredential($input: DeleteCredentialForOrganizationInput!) {
    result: deleteCredentialForOrganization(input: $input) {
      credential {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const isViewOnly = useIsChildOrganization();

  const refetchQuery = {
    query: CREDENTIAL_LIST,
    variables: listRequestVariables(organization)
  };

  const [deleteCredential, { loading: isDeleteLoading }] = useMutation(
    DELETE_CREDENTIAL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.credential) {
          toaster.success('Credential successfully deleted');
        } else {
          toaster.danger('Could not delete credential', {
            description: "Check it's not referenced on a seat"
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage credentials"
        subtitle="Credentials are the qualifications that this organization has available to its medics."
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
            disabled={isViewOnly}
          >
            New Credential
          </Button>
        }
      />

      <CredentialList
        isViewOnly={isViewOnly}
        organization={organization}
        onEditClick={(credential: any) => setShowForm(credential)}
        onDeleteClick={(credential: any) => setShowDeleteDialog(credential)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateCredential
          credential={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Credential saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteCredential({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
