import React from 'react';
import { Pane, Heading, Paragraph, TextInput, Select } from 'evergreen-ui';
import { get } from 'lodash';
import { UnitsWithLabels } from 'constants/units';
import { formatEnumToOptions, onChangeAsNull } from 'lib/formHelpers';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';

const UnitOptions = formatEnumToOptions(UnitsWithLabels);

const UnitSelect = ({ onBlur, onChange, value, name }: any) => (
  <Select name={name} onBlur={onBlur} onChange={onChange} value={value}>
    <option />
    {UnitOptions.map(({ label, value }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ))}
  </Select>
);

const MedicationAmount = ({
  isAbsoluteUnit,
  placeholder,
  label,
  description,
  value,
  validationMessage: errors,
  isInvalid,
  name,
  onChange,
  onBlur,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const perAmountErrors = get(errors, 'perAmount');

  return (
    <Pane marginBottom={24}>
      <Pane marginBottom={8}>
        <Heading size={400}>{label}</Heading>
        {description && <Paragraph color="muted">{description}</Paragraph>}
      </Pane>

      <Pane marginBottom={8}>
        <TextInput
          width={100}
          name={`${name}.value`}
          type="number"
          step="0.001"
          value={get(value, 'value', '')}
          onChange={onChangeAsNull(name, onChange)}
          onBlur={onBlur}
        />
        <UnitSelect
          name={`${name}.unit`}
          value={get(value, 'unit', '')}
          onChange={onChangeAsNull(name, onChange)}
          onBlur={onBlur}
        />

        {!isAbsoluteUnit && (
          <>
            <Heading
              marginLeft={16}
              marginRight={16}
              size={100}
              display="inline-block"
            >
              PER
            </Heading>
            <TextInput
              width={100}
              type="number"
              value={get(value, 'perAmount.value', '')}
              name={`${name}.perAmount.value`}
              step="0.001"
              onChange={onChangeAsNull(`${name}.perAmount`, onChange)}
              onBlur={onBlur}
            />
            <UnitSelect
              name={`${name}.perAmount.unit`}
              value={get(value, 'perAmount.unit', '')}
              onChange={onChangeAsNull(`${name}.perAmount`, onChange)}
              onBlur={onBlur}
            />
          </>
        )}
      </Pane>

      <ValidationErrorMessage error={errors} />
      <ValidationErrorMessage error={perAmountErrors} />
    </Pane>
  );
};

export default MedicationAmount;
