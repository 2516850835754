export const PatientTypesWithLabels = {
  PEDIATRIC: 'Pediatric',
  ADULT: 'Adult',
  CANINE: 'Canine'
};

export const DoseEntryPropertiesWithLabels = {
  PATIENT_AGE: 'Patient Age',
  PATIENT_WEIGHT: 'Patient Weight'
};

export const DoseEntryComparisonOperatorWithLabels = {
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL_TO: '=<',
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '>='
};

export const DoseEntryOperatorsWithLabels = {
  AND: 'and',
  OR: 'or'
};
