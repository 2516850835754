export const SeatRoles = {
  MEDIC: 'MEDIC',
  AUTHOR: 'AUTHOR',
  ADMIN: 'ADMIN'
};

export const UserRoles = [
  { label: 'Medic', value: 'MEDIC' },
  { label: 'Author', value: 'AUTHOR' },
  { label: 'Admin', value: 'ADMIN' }
];

export const OrganizationTypesWithLabels = {
  CIVILIAN: 'Civilian',
  MILITARY: 'Military'
};

export const OrganizationRoles = {
  MEDICAL_AGENCY: 'MEDICAL_AGENCY',
  MEDICAL_PUBLISHER: 'MEDICAL_PUBLISHER',
  MEDICAL_AUTHORITY: 'MEDICAL_AUTHORITY',
  HOSPITAL_NETWORK: 'HOSPITAL_NETWORK',
  MANUFACTURER: 'MANUFACTURER'
};

export const OrganizationRolesWithLabels = {
  MEDICAL_AGENCY: 'Medical Agency',
  MEDICAL_PUBLISHER: 'Medical Publisher',
  MEDICAL_AUTHORITY: 'Medical Authority',
  HOSPITAL_NETWORK: 'Hospital Network',
  MANUFACTURER: 'Manufacturer'
};
