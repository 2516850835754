import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash';

export const PLANS = gql`
  query AvailablePlansDataSource($filters: FilterCurrentPaymentPlansInput) {
    currentPaymentPlans(filters: $filters) {
      id
      name
      interval
      amountInCents
      seatAmountInCents
      trialPeriodDays
      requiresCreditCardForSignUp
      type
    }
  }
`;

const PlanSelectorDataSource = ({ render, filters = {} }: any) => {
  const { data, loading } = useQuery(PLANS, {
    variables: { filters },
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'currentPaymentPlans', []).map((plan: any) => ({
    label: plan.name,
    value: plan.id
  }));

  return render({
    items,
    isLoading: loading
  });
};

export default PlanSelectorDataSource;
