import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Pane,
  Paragraph,
  Spinner,
  Button,
  Text,
  Strong,
  toaster
} from 'evergreen-ui';
import { LIST_CREDIT_CARDS } from './queries';
import UpdateCard from './UpdateCard';

const CardOnFile = ({ organization }: any) => {
  const [showCreditCard, setShowCreditCard] = useState(false);
  const { data, loading, refetch } = useQuery(LIST_CREDIT_CARDS, {
    fetchPolicy: 'network-only',
    variables: { organizationId: organization.id }
  });

  const card = get(data, 'creditCardsForOrganization[0]');

  if (loading) {
    return <Spinner size={16} />;
  }

  return (
    <Pane
      marginBottom={8}
      display="flex"
      alignItems="center"
      flexDirection="row"
    >
      <Pane>
        {card ? (
          <Text display="block">
            Card ending in <Strong>{card.last4}</Strong>
            <br />
            Exp: {card.expMonth}/{card.expYear}
          </Text>
        ) : (
          <Paragraph color="muted">
            We don't have an active credit card on file for you yet.
          </Paragraph>
        )}
      </Pane>

      <Button
        onClick={() => setShowCreditCard(true)}
        marginLeft="auto"
        appearance="minimal"
        as="a"
        href="/"
      >
        Add new card
      </Button>

      <UpdateCard
        isShown={showCreditCard}
        onClose={() => setShowCreditCard(false)}
        onComplete={() => {
          toaster.success('Your card has beren updated');
          setShowCreditCard(false);
          refetch();
        }}
        organization={organization}
      />
    </Pane>
  );
};

export default CardOnFile;
