import gql from 'graphql-tag';
import { PageInfoFragment } from 'components/shared/fragments';

const CredentialListItemFragment = gql`
  fragment CredentialListItem on Credential {
    id
    name
    description
    insertedAt
    updatedAt
    organization {
      id
      name
    }
  }
`;

export const CREDENTIAL_LIST = gql`
  query CredentialsForOrg($first: Int!, $organizationId: ID!) {
    organization(id: $organizationId) {
      id
      credentials(first: $first) {
        edges {
          node {
            ...CredentialListItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${CredentialListItemFragment}
`;
