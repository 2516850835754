import React from 'react';
import { Pane, Heading, Paragraph, Icon } from 'evergreen-ui';

// @ts-expect-error TS(6133): 'noResults' is declared but its value is never rea... Remove this comment to see the full error message
const EmptyContent = ({ noResults = false }) => (
  <Pane textAlign="center" marginTop={40} marginLeft="auto" marginRight="auto">
    <Icon icon="th-derived" size={24} color="muted" />
    <Heading marginTop={8}>No results returned</Heading>
    <Paragraph color="muted">
      Please refine your query or create a new entry
    </Paragraph>
  </Pane>
);

export default EmptyContent;
