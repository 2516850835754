import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Table, Pane, Spinner, Heading } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import EmptyContent from 'components/shared/EmptyContent';
import SearchListControl from 'components/shared/SearchListControl';
import { currentUserSelector } from 'redux/global';
import { isSiteAdmin } from 'lib/auth';
import ResultRow from './ResultRow';
import { SEARCH_CONTENT } from './queries';

const SearchResultsList = ({
  organization,
  variables,
  filters,
  onFilterUpdate
}: any) => {
  const { data, loading, error } = useQuery(SEARCH_CONTENT, {
    variables: { input: variables },
    fetchPolicy: 'no-cache'
  });

  const currentUser = useSelector(currentUserSelector);
  const isAdmin = isSiteAdmin(currentUser);

  const results = get(data, 'results', []);

  return (
    <Pane>
      <Pane background="white">
        <Heading size={100} marginBottom={24}>
          Search content across organization ({results.length})
        </Heading>
        <SearchListControl
          placeholder="Search by name or alias"
          initialValue={filters.searchTerm}
          onSubmit={(searchTerm: any) => onFilterUpdate({ searchTerm })}
        />

        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Result</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell width={400} flex="none">
              Aliases
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {results.map((result: any) => (
              <ResultRow
                key={result.id}
                organization={organization}
                result={result}
                canAccessAllContent={isAdmin}
              />
            ))}
          </Table.Body>
        </Table>

        {loading && (
          <Pane margin={16}>
            <Spinner size={16} />
          </Pane>
        )}

        {!loading && !error && results.length === 0 && <EmptyContent />}
      </Pane>
    </Pane>
  );
};

export default SearchResultsList;
