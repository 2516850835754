import React from 'react';
import { Menu } from 'evergreen-ui';

const AliasMenu = ({ onEditClick, onDeleteClick }: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item onSelect={onEditClick}>Edit alias</Menu.Item>
      </Menu.Group>
      <Menu.Divider />
      <Menu.Group>
        <Menu.Item intent="danger" onSelect={onDeleteClick}>
          Delete alias
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );
};

export default AliasMenu;
