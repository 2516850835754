import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import {
  InputErrorFragment,
  EquipmentFunctionFragment
} from 'components/shared/fragments';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_EQUIPMENT_FUNCTION = gql`
  mutation CreateEquipmentFunction($input: CreateFunctionForEquipmentInput!) {
    result: createFunctionForEquipment(input: $input) {
      errors {
        ...InputError
      }
      equipmentFunction {
        ...EquipmentFunction
      }
    }
  }
  ${InputErrorFragment}
  ${EquipmentFunctionFragment}
`;

const UPDATE_EQUIPMENT_FUNCTION = gql`
  mutation UpdateEquipmentFunction($input: UpdateFunctionForEquipmentInput!) {
    result: updateFunctionForEquipment(input: $input) {
      errors {
        ...InputError
      }
      equipmentFunction {
        ...EquipmentFunction
      }
    }
  }
  ${EquipmentFunctionFragment}
  ${InputErrorFragment}
`;

const initialValues = {
  name: '',
  aliases: []
};

const CreateOrUpdateFunction = ({
  equipmentFunction,
  equipmentId,
  onComplete,
  refetchQuery
}: any) => {
  const [errors, setErrors] = useState({});
  const hasEquipmentFunction = equipmentFunction && equipmentFunction.id;
  const [createOrUpdateFunction, { loading }] = useMutation(
    hasEquipmentFunction
      ? UPDATE_EQUIPMENT_FUNCTION
      : CREATE_EQUIPMENT_FUNCTION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasEquipmentFunction
    ? sanitizeData(equipmentFunction, ['lastPublishedAt', 'equipment'])
    : initialValues;

  return (
    <FormBuilder
      key={equipmentFunction?.id ?? -1}
      isSubForm
      initialValues={formData}
      form={Form}
      errors={errors}
      isLoading={loading}
      onSubmit={(input: any) => {
        const variables = hasEquipmentFunction
          ? input
          : { ...input, equipmentId };

        setErrors({});
        createOrUpdateFunction({
          variables: { input: variables }
        });
      }}
    />
  );
};

export default CreateOrUpdateFunction;
