import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';

export const GET_CONFIG = gql`
  query GetOrganizationConfig($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      configuration {
        isEditable
        patientTypeAgeRanges {
          type
          minValue
          maxValue
        }
      }
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UpdateOrgConfig($input: UpdateOrganizationConfigurationInput!) {
    result: updateOrganizationConfiguration(input: $input) {
      organization {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;
