import * as Yup from 'yup';
import CredentialSelector from 'components/shared/CredentialSelector';
import TargetSelector from './TargetSelector';

const Schema = Yup.object().shape({
  text: Yup.string().required(),
  type: Yup.string().required(),
  credentials: Yup.array()
    .required('Must select one or more applicable credentials')
    .nullable()
});

export const Form = (organizationId: any) => ({
  name: 'dashboardItem',
  validationSchema: Schema,

  initialState: {
    type: 'SEARCH',
    metadata: {
      entityId: null,
      searchTerm: null,
      subcategory: null
    }
  },

  fields: [
    {
      name: 'text',
      label: 'Text',
      description: 'The text to display in the app',
      required: true
    },
    {
      name: 'type',
      label: 'Type',
      type: TargetSelector
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'number',
      description: 'The priority of this dashboard item (lower is higher)'
    },
    {
      name: 'credentials',
      label: 'Applicable credentials',
      description: 'Select which credentials this item applies to',
      type: CredentialSelector,
      organizationId
    },
    {
      name: 'isSearchHelp',
      label: 'Search help?',
      description: 'Should this only be displayed as a search help entry',
      type: 'switch'
    }
  ]
});
