import React, { useState } from 'react';
import { Pane, Heading, Paragraph, IconButton } from 'evergreen-ui';
import fieldType from './fieldType';

const Section = ({ label, description, formikBag, fields, collapsed }: any) => {
  const [isExpanded, setExpanded] = useState(!collapsed);

  return (
    <Pane data-testid={`form-section-${label}`}>
      <Pane display="flex" borderBottom paddingTop={16} paddingBottom={16}>
        <Pane>
          <Heading>{label}</Heading>
          {description && <Paragraph color="muted">{description}</Paragraph>}
        </Pane>
        <IconButton
          type="button"
          icon={isExpanded ? 'chevron-up' : 'chevron-down'}
          appearance="minimal"
          marginLeft="auto"
          onClick={() => setExpanded(!isExpanded)}
        />
      </Pane>

      <Pane
        height={isExpanded ? 'auto' : 0}
        overflow={isExpanded ? 'visible' : 'hidden'}
        borderBottom={isExpanded}
        marginTop={isExpanded ? 16 : 0}
        marginBottom={16}
      >
        {fields.map((field: any) => {
          const FieldComponent = fieldType(field.type);

          if (field.shownWhen && !field.shownWhen(formikBag.values)) {
            return null;
          }

          return (
            <FieldComponent
              {...field}
              key={field.name}
              formikBag={formikBag}
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur}
              required={!!field.required}
              isInvalid={!!formikBag.errors[field.name]}
              value={formikBag.values[field.name]}
              validationMessage={formikBag.errors[field.name]}
            />
          );
        })}
      </Pane>
    </Pane>
  );
};

export default Section;
