import gql from 'graphql-tag';

export const JOINABLE_AGENCY = gql`
  query JoinableAdditionalAgency($id: ID!) {
    joinableAgency(id: $id) {
      id
      name
      logo {
        url
      }
      credentials(first: 999) {
        edges {
          node {
            id
            name
            description
            insertedAt
            updatedAt
          }
        }
      }
    }
  }
`;
