import React from 'react';
import { Combobox, FormField } from 'evergreen-ui';
import { find } from 'lodash';

const emptyCell = {
  label: 'N/A',
  value: null
};

const AsyncSelect = ({
  label,
  name,
  description,
  required,
  validationMessage,
  dataSource: renderDataSource,
  value,
  placeholder,
  formikBag,
  isInvalid,
  ...props
}: any) => (
  <FormField
    label={label}
    isRequired={required}
    description={description}
    validationMessage={validationMessage}
    width="100%"
    marginBottom={24}
  >
    {renderDataSource(({ isLoading, items }: any) => {
      const itemsWithEmptyCell = [emptyCell].concat(items);
      const selectedItem = find(items, item => item.value === value);

      return (
        <Combobox
          {...props}
          name={name}
          openOnFocus
          selectedItem={selectedItem}
          placeholder={placeholder}
          items={itemsWithEmptyCell}
          isLoading={isLoading}
          itemToString={(item: any) => (item ? item.label : '')}
          onChange={(item: any) => {
            formikBag.setFieldTouched(name);
            formikBag.setFieldValue(name, (item || {}).value);
          }}
          width="100%"
        />
      );
    })}
  </FormField>
);

export default AsyncSelect;
