import React, { useEffect } from 'react';
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ZipCodeEntry from 'components/onboarding/ZipCodeEntry';
import onboardingSlice from 'redux/onboarding';
import { page } from 'lib/analytics';
const { setResult } = onboardingSlice.actions;

const ZipEntry = ({ subscription }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    page('Add Agency Started');
  }, []);

  return (
    <Pane>
      <Pane
        marginBottom={24}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading marginBottom={4} size={600}>
          Enter your zip code to get started
        </Heading>
        <Paragraph color="muted">
          Please enter the zip code of the agency that you wish to add.
        </Paragraph>
      </Pane>

      {subscription.plan.supportsMultiAgencyPurchases ? (
        <ZipCodeEntry
          onCompleted={(result: any) => {
            dispatch(setResult(result));

            if (result.agencies.length > 0) {
              history.push('/select-agency');
            } else {
              history.push('/waitlist');
            }
          }}
        />
      ) : (
        <Paragraph color="danger">
          Sorry, but we can't allow you multi-agency purchases just yet.
        </Paragraph>
      )}
    </Pane>
  );
};

export default ZipEntry;
