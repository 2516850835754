import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { get } from 'lodash';
import gql from 'graphql-tag';
import {
  Avatar,
  Heading,
  Icon,
  Spinner,
  Paragraph,
  Button,
  Link,
  Pane
} from 'evergreen-ui';
import Logo from 'icons/muru-logo.svg';
import { getAuthToken } from 'lib/auth';
import * as analytics from 'lib/analytics';
import AcceptInvite from './AcceptInvite';
import InviteNotFound from './InviteNotFound';
import InviteAccepted from './InviteAccepted';

const StyledLogo = styled(Logo)`
  width: 66px;
  height: 40px;
  margin-left: -4px;
`;

const GET_ORG_INVITE = gql`
  query OrganizationInvite($token: String!) {
    organizationInvite(token: $token) {
      id
      roles {
        id
        name
      }
      email
      credentials {
        id
        name
      }
      organization {
        id
        name
        logo {
          url
        }
        slug
      }
    }
  }
`;

const CURRENT_USER = gql`
  query CurrentUserForOrgInvite {
    currentUser {
      id
      name
      email
      intercomIdentityHash(platform: WEB)
    }
  }
`;

const OrgAvatar = ({ logo, name }: any) => (
  <Avatar src={logo && logo.url} name={name} size={48} />
);

function OrgInvite({ token, onSignupClick, onLoginClick }: any) {
  const [isAccepted, setAccepted] = useState(false);
  const authToken = getAuthToken();

  const [
    getCurrentUser,
    { data: currentUserData, loading: isLoadingCurrentUser }
  ] = useLazyQuery(CURRENT_USER);

  const { loading, data, error } = useQuery(GET_ORG_INVITE, {
    variables: { token },
    fetchPolicy: 'network-only'
  });

  // Only fetch the current user if we have an auth token
  useEffect(() => {
    authToken && getCurrentUser();
  }, [authToken, getCurrentUser]);

  const currentUser = get(currentUserData, 'currentUser');

  useEffect(() => {
    currentUser && analytics.identify(currentUser);
  }, [currentUser]);

  const invite = get(data, 'organizationInvite');
  const organization = get(invite, 'organization');

  useEffect(() => {
    invite &&
      analytics.track('Viewed Invite', {
        invite_id: invite.id,
        organization_id: organization.id,
        is_accepted: !!isAccepted
      });
  }, [invite, organization, isAccepted]);

  if (loading || isLoadingCurrentUser) {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Pane>
    );
  }

  if (error || !invite) {
    return <InviteNotFound />;
  }

  if (isAccepted) {
    return <InviteAccepted invite={invite} />;
  }

  return (
    <>
      <Pane
        marginBottom={16}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* @ts-expect-error StyledLogo */}
        <StyledLogo />

        <Icon
          icon="plus"
          size={24}
          marginLeft={8}
          marginRight={8}
          color="muted"
        />

        <OrgAvatar {...organization} />
      </Pane>

      <Heading size={600} marginBottom={8}>
        You've been invited to join {organization.name} on Muru
      </Heading>

      {currentUser ? (
        <AcceptInvite
          inviteId={get(data, 'organizationInvite.id')}
          invite={invite}
          currentUser={currentUser}
          onAccepted={() => setAccepted(true)}
        />
      ) : (
        <Pane>
          <Paragraph color="muted" marginBottom={24}>
            {organization.name} are using Muru to help their providers get
            access to life-saving information. To continue please create a new
            account or login.
          </Paragraph>
          <Button
            height={40}
            onClick={() => onSignupClick(invite.email)}
            appearance="primary"
            display="flex"
            width="100%"
            justifyContent="center"
            marginBottom={16}
          >
            Sign up for a new account
          </Button>

          <Link
            href="/"
            onClick={(e: any) => {
              e.preventDefault();
              onLoginClick();
            }}
          >
            Sign into your existing Muru account
          </Link>
        </Pane>
      )}
    </>
  );
}

export default OrgInvite;
