import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import { PageInfoFragment } from 'components/shared/fragments';
import EmptyContent from 'components/shared/EmptyContent';
import Menu from './Menu';

const DashboardItemListItemFragment = gql`
  fragment DashboardItemListItem on DashboardItem {
    id
    text
    query
    type
    category
    isSearchHelp
    metadata {
      entityId
      subcategory
      searchTerm
    }
    credentials {
      id
      name
    }
    organization {
      id
      name
    }
    priority
    insertedAt
    updatedAt
  }
`;

export const DASHBOARD_ITEM_LIST = gql`
  query DashboardItemsForOrg($first: Int!, $organizationId: ID!) {
    organization(id: $organizationId) {
      id
      dashboardItems(first: $first, filters: { display: ALL }) {
        edges {
          node {
            ...DashboardItemListItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${DashboardItemListItemFragment}
`;

export const listVariables = (organization: any) => ({
  first: 999,
  organizationId: organization.id
});

const DashboardItemList = ({
  organization,
  onEditClick,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(DASHBOARD_ITEM_LIST, {
    variables: listVariables(organization),
    fetchPolicy: 'network-only'
  });
  const dashboardItems = get(data, 'organization.dashboardItems.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Text</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Credentials</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Updated</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {dashboardItems.map(({ node: dashboardItem }) => {
                const isDifferentOrg =
                  dashboardItem.organization.id !== organization.id;

                return (
                  <Table.Row
                    key={dashboardItem.id}
                    isSelectable
                    height="auto"
                    minHeight={60}
                  >
                    <Table.Cell>
                      <Pane>
                        <Text size={300} fontWeight={500} display="block">
                          {dashboardItem.text}
                        </Text>
                        <Text size={300}>
                          {dashboardItem.organization.name}
                        </Text>
                      </Pane>
                    </Table.Cell>
                    <Table.Cell>
                      <Pane>
                        <Badge>
                          {dashboardItem.type}
                          {dashboardItem.category &&
                            ` / ${dashboardItem.category}`}
                        </Badge>
                        {dashboardItem.isSearchHelp && (
                          <Badge color="blue">Search Help</Badge>
                        )}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell>
                      <Pane paddingTop={4} paddingBottom={4}>
                        {dashboardItem.credentials.map((
                          credential: any // Highlight the credential thats selected
                        ) => (
                          <Text size={300} key={credential.id}>
                            {credential.name}
                            <br />
                          </Text>
                        ))}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={dashboardItem.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onEditClick={() => onEditClick(dashboardItem)}
                            onDeleteClick={() => onDeleteClick(dashboardItem)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                          disabled={isDifferentOrg}
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && dashboardItems.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default DashboardItemList;
