import gql from 'graphql-tag';
import { PageInfoFragment } from 'components/shared/fragments';

export const DIRECTORY_CONTACTS_LIST = gql`
  query DirectoryContactsList(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $organizationId: ID!
    $filters: FilterOrganizationDirectoryContactsInput!
  ) {
    organization(id: $organizationId) {
      id
      directoryContacts(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            id
            name
            starred
            phoneNumbers
            faxNumbers
            radioFrequencies
            updatedAt
            insertedAt
            organization {
              id
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const DELETE_DIRECTORY_CONTACT = gql`
  mutation DeleteDirectoryContact(
    $input: DeleteDirectoryContactForOrganizationInput!
  ) {
    result: deleteDirectoryContactForOrganization(input: $input) {
      directoryContact {
        id
      }
      errors {
        message
      }
    }
  }
`;
