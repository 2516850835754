import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';

const MEDICAL_AUTHORITIES = gql`
  query ListMedicalAuthorities {
    medicalAuthorities(first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const MedicalAuthorityDataSource = ({ render }: any) => {
  const { data, loading } = useQuery(MEDICAL_AUTHORITIES, {
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'medicalAuthorities.edges', []).map(
    // @ts-expect-error TS(7031): Binding element 'medicalAuthority' implicitly has ... Remove this comment to see the full error message
    ({ node: medicalAuthority }) => ({
      label: medicalAuthority.name,
      value: medicalAuthority.id
    })
  );

  return render({
    items,
    isLoading: loading
  });
};

export default MedicalAuthorityDataSource;
