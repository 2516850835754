import React from 'react';
import gql from 'graphql-tag';
import {
  Pane,
  Text,
  Icon,
  Tooltip,
  UnorderedList,
  ListItem
} from 'evergreen-ui';
import { useApolloClient } from '@apollo/react-hooks';
import { MedicationShortRoutes } from '@/constants/medications';
import { formatDoseAmount } from 'lib/dosing';
import { formatAgeRange } from 'lib/protocols';
import DoseRepeatInformationList from 'components/organizations/CreateOrUpdateProtocolStep/DoseRepeatInformation';
import Filters from '../EntryCriteria/Filters';

const MedicationFragment = gql`
  fragment MedicationDoseDisplayAmount on Medication {
    id
    brandName
    genericName
  }
`;

const EquipmentFunctionFragment = gql`
  fragment EquipmentFunctionDoseAmount on EquipmentFunction {
    id
    name
    equipment {
      id
      name
    }
  }
`;

function normalizeUnit(unit: any) {
  if (unit.medication) {
    unit.medicationId = unit.medication.id;
  }

  if (unit.equipmentFunction) {
    unit.equipmentFunctionId = unit.equipmentFunction.id;
  }

  return unit;
}

export const DisplayDoseUnit = ({
  medicationId,
  equipmentFunctionId,
  amount,
  maxAmount
}: any) => {
  const client = useApolloClient();

  const fragment = medicationId
    ? MedicationFragment
    : EquipmentFunctionFragment;

  const identifier = medicationId
    ? `Medication:${medicationId}`
    : `EquipmentFunction:${equipmentFunctionId}`;

  const item = client.readFragment({
    id: identifier,
    fragment
  });

  if (medicationId) {
    return (
      <Text size={300}>
        {formatDoseAmount(item.genericName, amount, maxAmount)}
      </Text>
    );
  } else {
    return (
      <Text size={300}>
        {formatDoseAmount(
          `${item.name} (${item.equipment ? item.equipment.name : ''})`,
          amount,
          maxAmount
        )}
      </Text>
    );
  }
};

const DoseConditionsOfUse = ({ filters, patientAgeRanges, notes }: any) => (
  <Tooltip
    appearance="card"
    content={
      <UnorderedList>
        {filters && (
          <ListItem size={300}>
            <Filters {...filters} />
          </ListItem>
        )}

        {(patientAgeRanges || []).map((ageRange: any, idx: any) => (
          <ListItem size={300} key={`age-${idx}`}>
            Age: {formatAgeRange(ageRange)}
          </ListItem>
        ))}

        {(notes || []).map((note: any, idx: any) => (
          <ListItem size={300} key={`note-${idx}`}>
            {note}
          </ListItem>
        ))}
      </UnorderedList>
    }
  >
    <Icon
      icon="warning-sign"
      flexShrink={0}
      size={12}
      color="warning"
      marginRight={6}
    />
  </Tooltip>
);

const DoseRepeatInformation = (props: any) => (
  <Tooltip appearance="card" content={<DoseRepeatInformationList {...props} />}>
    <Icon
      icon="repeat"
      flexShrink={0}
      size={12}
      color="success"
      marginRight={6}
    />
  </Tooltip>
);

const DisplayDoseAmount = ({
  units = [],
  routes = [],
  infusionRateInSeconds,
  supplementalDisplayText,
  // @ts-expect-error TS(6133): 'repeatInterval' is declared but its value is neve... Remove this comment to see the full error message
  repeatInterval,
  entryCriteria,
  repeatInformation
}: any) => {
  const totalUnits = units.length - 1;

  return (
    <Pane alignItems="center" display="flex">
      {entryCriteria && <DoseConditionsOfUse {...entryCriteria} />}
      {repeatInformation && <DoseRepeatInformation {...repeatInformation} />}
      <Text size={300}>
        {totalUnits > 0 && 'Mix '}
        {units.map((unit: any, idx: any) => (
          <Text size={300} key={idx}>
            <DisplayDoseUnit {...normalizeUnit(unit)} />
            {totalUnits !== idx && ' & '}
          </Text>
        ))}
        {infusionRateInSeconds !== null && ` @ ${infusionRateInSeconds}s`}{' '}
        {/* prettier-ignore */}
        <Text size={300} color="#a9a9a9">
          {/* @ts-expect-error TS(7006): Parameter 'route' implicitly has an 'any' type. */}
          ({routes.map((route: any) => MedicationShortRoutes[route]).join(' / ')})
          {supplementalDisplayText && ` – ${supplementalDisplayText}`}
        </Text>
      </Text>
    </Pane>
  );
};

export default DisplayDoseAmount;
