import gql from 'graphql-tag';
import {
  ProtocolFragment,
  PageInfoFragment
} from 'components/shared/fragments';

export const LIST_REFERENCING_PROTOCOLS = gql`
  query ListReferencingProtocols(
    $protocolId: ID!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterReferencingProtocolsForProtocolInput!
  ) {
    protocol: node(id: $protocolId, type: PROTOCOL) {
      ... on Protocol {
        id
        referencingProtocols(
          first: $first
          after: $after
          before: $before
          last: $last
          filters: $filters
        ) {
          edges {
            node {
              ...Protocol
              protocolSet {
                id
                name
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${ProtocolFragment}
  ${PageInfoFragment}
`;
