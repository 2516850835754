import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken } from 'lib/auth';

const AuthenticatedRoute = ({
  // @ts-expect-error TS(7031): Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
  component: Component,
  isAuthorized = true,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const isValid = !!getAuthToken() && isAuthorized;

      return isValid ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location, message: 'Please login to continue' }
          }}
        />
      );
    }}
  />
);

export default AuthenticatedRoute;
