import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { HospitalFragment } from 'components/shared/fragments';

const PUBLISH_HOSPITAL = gql`
  mutation PublishHospital($input: PublishHospitalInput!) {
    publishHospital(input: $input) {
      hospital {
        ...Hospital
      }
    }
  }
  ${HospitalFragment}
`;

const PublishDialog = ({ isShown, hospital, onCloseComplete }: any) => {
  const [publishHospital, { loading }] = useMutation(PUBLISH_HOSPITAL, {
    variables: { input: { id: hospital.id } },
    onCompleted: (response: any) => {
      if (response.publishHospital.hospital) {
        toaster.success(`${hospital.name} successfully published!`, {
          description:
            'Users will start seeing the hospital updates appear in their app soon'
        });

        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="I understand, publish this hospital"
      onConfirm={() => publishHospital()}
    >
      <Paragraph>
        Publishing this hospital will make it available to{' '}
        <strong>all users of the app</strong> as an update. Please only do this
        if you know what you're doing.
      </Paragraph>
    </Dialog>
  );
};

export default PublishDialog;
