import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import Menu from './Menu';
import PublishDialog from './PublishEquipmentDialog';
import PublishAllDialog from './PublishAllEquipmentDialog';
import ToolsMenu from './ToolsMenu';
import { EQUIPMENT_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const EquipmentList = ({
  organization,
  history,
  filters,
  variables,
  refetchQuery,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick
}: any) => {
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const [isShowingPublishAllDialog, showPublishAllDialog] = useState(false);
  const { loading, data, error } = useQuery(EQUIPMENT_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const equipments = get(data, 'organization.equipments.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <Pane display="flex" alignItems="center">
          <SearchListControl
            placeholder="Search by equipment name"
            initialValue={filters.name}
            onSubmit={(name: any) => onFilterUpdate({ name })}
          />
          <Pane marginLeft="auto">
            <Popover
              // @ts-expect-error TS(6133): 'close' is declared but its value is never read.
              content={({ close }: any) => (
                <ToolsMenu
                  onPublishAllClick={() => showPublishAllDialog(true)}
                />
              )}
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton icon="more" height={24} appearance="minimal" />
            </Popover>
          </Pane>
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {equipments.map(({ node: equipment }) => {
                // If the equipment is coming from a different organization
                // e.g. selected during the inventory profile
                const isDifferentOrg =
                  equipment.organization.id !== organization.id;

                return (
                  <Table.Row key={equipment.id} isSelectable>
                    <Table.Cell>
                      <Pane>
                        <Text size={300} fontWeight={500}>
                          {equipment.name}
                        </Text>
                        {isDifferentOrg && (
                          <Text size={300} display="block">
                            {equipment.organization.name}
                          </Text>
                        )}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell
                      width={100}
                      display="flex"
                      alignItems="center"
                      flex="none"
                    >
                      {equipment.updatedAt === equipment.lastPublishedAt ? (
                        <Badge color="green">Published</Badge>
                      ) : (
                        <Badge color="blue">Draft</Badge>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {equipment.lastPublishedAt ? (
                        <RelativeDate date={equipment.lastPublishedAt} />
                      ) : (
                        <Text size={300}>N/A</Text>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={equipment.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onManageFunctionsClick={() => {
                              history.push(
                                `/orgs/${organization.slug}/equipment/${equipment.id}/functions`
                              );
                            }}
                            onEditClick={() =>
                              history.push(
                                `/orgs/${organization.slug}/equipment/${equipment.id}`
                              )
                            }
                            onPublishClick={() => showPublishDialog(equipment)}
                            onDeleteClick={() => onDeleteClick(equipment)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                          disabled={isDifferentOrg}
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && equipments.length === 0 && <EmptyContent />}

      <PublishDialog
        equipment={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />

      <PublishAllDialog
        organization={organization}
        refetchQuery={refetchQuery}
        isShown={isShowingPublishAllDialog !== false}
        onCloseComplete={() => showPublishAllDialog(false)}
      />

      <PaginationControls
        pageInfo={get(data, 'organization.equipments.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default withRouter(EquipmentList);
