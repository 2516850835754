import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from 'components/shared/Layout';
import Sidebar from 'components/admin/Sidebar';
import ContentPanel from 'components/shared/Layout/ContentPanel';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import { ListPage as ListUsers } from 'pages/admin/Users';
import { ListPage as ListOrgs } from 'pages/admin/Organizations';
import { ListPage as ListMolecules } from 'pages/admin/Molecules';
import { ListPage as ListAliases } from 'pages/admin/Aliases';
import { ListPage as ListMedications } from 'pages/admin/Medications';
import {
  ListPage as ListHospitals,
  CreatePage as CreateHospital,
  EditPage as EditHospital
} from 'pages/admin/Hospitals';
import {
  LIST_USERS_PATH,
  LIST_ORGS_PATH,
  LIST_HOSPITALS_PATH,
  CREATE_HOSPITAL_PATH,
  EDIT_HOSPITAL_PATH,
  LIST_MOLECULES_PATH,
  LIST_ALIASES_PATH
} from 'pages/admin/paths';
import { currentUserSelector } from 'redux/global';
import { isSiteAdmin } from 'lib/auth';

const AdminRoutes = ({ location }: any) => {
  const currentUser = useSelector(currentUserSelector);
  const isAuthorized = currentUser && isSiteAdmin(currentUser);

  // Only admin users can see / access these routes
  if (currentUser && !isAuthorized) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location }
        }}
      />
    );
  }

  return (
    <Layout>
      <Sidebar />
      <ContentPanel>
        <AuthenticatedRoute
          exact
          path={LIST_USERS_PATH}
          component={ListUsers}
        />
        <AuthenticatedRoute exact path={LIST_ORGS_PATH} component={ListOrgs} />
        <AuthenticatedRoute
          exact
          path={LIST_MOLECULES_PATH}
          component={ListMolecules}
        />
        <AuthenticatedRoute
          exact
          path={LIST_ALIASES_PATH}
          component={ListAliases}
        />
        <AuthenticatedRoute
          exact
          path={LIST_HOSPITALS_PATH}
          component={ListHospitals}
        />
        <AuthenticatedRoute
          exact
          path={'/admin/medications'}
          component={ListMedications}
        />
        <Switch>
          <AuthenticatedRoute
            exact
            path={CREATE_HOSPITAL_PATH}
            component={CreateHospital}
          />
          <AuthenticatedRoute
            exact
            path={EDIT_HOSPITAL_PATH}
            component={EditHospital}
          />
        </Switch>
      </ContentPanel>
    </Layout>
  );
};

export default AdminRoutes;
