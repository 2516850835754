import React, { useEffect } from 'react';
import { Pane, Heading, Paragraph, Button, Strong } from 'evergreen-ui';
import { page } from 'lib/analytics';

const WaitlistThanks = ({ onCloseClick }: any) => {
  useEffect(() => {
    page('Add Agency Waitlist Submission');
  }, []);

  return (
    <Pane>
      <Pane textAlign="center" marginBottom={24} maxWidth={350}>
        <Heading size={600} marginBottom={8}>
          You're on the waitlist!
        </Heading>
        <Paragraph color="muted">
          Thanks for signing up for the waitlist. We'll let you know as soon as
          Muru is available for your agency.
          <br />
          <br />
          <Strong>
            If there's anything that you think might encourage us to get to your
            agency sooner, send us an email to support@murumed.com.
          </Strong>
        </Paragraph>

        <Button
          height={40}
          appearance="primary"
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop={24}
          marginBottom={16}
          onClick={onCloseClick}
        >
          Done
        </Button>
      </Pane>
    </Pane>
  );
};

export default WaitlistThanks;
