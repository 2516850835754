import React, { useState } from 'react';
import {
  Alert,
  Pane,
  Heading,
  Strong,
  Text,
  Dialog,
  Paragraph,
  Icon,
  toaster
} from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import format from 'date-fns/format';
import {
  formatCents,
  PlanIntervalLabels,
  subscriptionStatus,
  SubscriptionStates,
  trialRemainingMessage
} from 'lib/subscription';
import SubscriptionSummary from './SubscriptionSummary';
import { CANCEL_SUBSCRIPTION, SUBSCRIPTIONS } from './queries';
import { orgPath } from 'lib/urls';
import { useHistory } from 'react-router-dom';
import RemoveIndividualAgencyDialog from './RemoveIndividualAgencyDialog';

const dateFormat = 'MM/DD/YYYY';

const IndividualPlanDetails = ({ seats, subscription, onAddClick }: any) => {
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const [cancelSubscription, { loading }] = useMutation(CANCEL_SUBSCRIPTION, {
    refetchQueries: [{ query: SUBSCRIPTIONS }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setShowCancelDialog(false);
      toaster.success('Your subscription has been cancelled');
    }
  });

  const history = useHistory();

  const status = subscriptionStatus(subscription);
  const totalSeats = subscription.totalSeatCount;
  const isOnTrial = !!subscription.trialEndAt;

  const totalSubscription =
    subscription.plan.amountInCents +
    (subscription.plan.supportsMultiAgencyPurchases
      ? subscription.plan.seatAmountInCents * totalSeats
      : 0);

  return (
    <Pane>
      {isOnTrial && (
        <Alert
          margin={16}
          marginBottom={0}
          intent="none"
          title={trialRemainingMessage(subscription)}
        >
          Please make sure you have a valid credit card added to your account to
          ensure uninterrupted access to Muru.
        </Alert>
      )}

      <Pane padding={16}>
        <Heading size={100} marginBottom={4}>
          Agencies in this subscription
        </Heading>

        {seats.map((seat: any, idx: any) => {
          const onLeaveClick = !!seat.cancelledAt
            ? undefined
            : () => setShowLeaveDialog(seat);

          return (
            <SubscriptionSummary
              key={seat.id}
              seat={seat}
              showBorder={idx !== 0}
              onViewClick={() => history.push(orgPath(seat.organization))}
              onLeaveClick={onLeaveClick}
            />
          );
        })}

        {subscription.plan.supportsMultiAgencyPurchases && (
          <Pane
            paddingY={8}
            onClick={onAddClick}
            opacity={status !== SubscriptionStates.ACTIVE ? 0.5 : 1}
            borderTop={'muted'}
            cursor="pointer"
          >
            <Pane display="flex" alignItems="center">
              <Pane
                width={32}
                height={32}
                background="#eee"
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon icon="plus" size={18} color="muted" />
              </Pane>
              <Pane marginLeft={8}>
                <Strong display="block">Add another agency</Strong>
                <Text color="muted">
                  Get access to another agency you work at
                </Text>
              </Pane>
            </Pane>
          </Pane>
        )}
      </Pane>

      <Pane padding={16}>
        <Heading size={100} marginBottom={8}>
          Subscription summary
        </Heading>

        {status === SubscriptionStates.CANCELLED ? (
          <Paragraph size={400}>
            Your subscription is cancelled and will be active until{' '}
            <Strong>{format(subscription.cancelAt, dateFormat)}</Strong>
            .
            <br />
            After this date you won't be able to access any agencies in the Muru
            app.
          </Paragraph>
        ) : (
          <>
            <Pane
              paddingY={8}
              height="auto"
              display="flex"
              justifyContent="space-between"
            >
              <Pane paddingX={0} textAlign="left">
                <Text size={400}>Individual plan</Text>
              </Pane>
              <Pane paddingX={0} marginLeft="auto">
                <Text size={400}>
                  ${formatCents(subscription.plan.amountInCents, true)}
                </Text>
              </Pane>
            </Pane>
            {subscription.plan.supportsMultiAgencyPurchases && (
              <Pane
                height="auto"
                paddingY={8}
                display="flex"
                justifyContent="space-between"
              >
                <Pane paddingX={0}>
                  <Pane textAlign="left">
                    <Text size={400}>Agency access (x{totalSeats})</Text>
                    <Text size={400} color="muted">
                      (${formatCents(subscription.plan.seatAmountInCents)} per
                      agency)
                    </Text>
                  </Pane>
                </Pane>
                <Pane paddingX={0}>
                  <Text size={400} marginLeft="auto">
                    $
                    {formatCents(
                      subscription.plan.seatAmountInCents * totalSeats,
                      true
                    )}
                  </Text>
                </Pane>
              </Pane>
            )}
            <Pane
              borderBottom="default"
              height="auto"
              paddingY={8}
              display="flex"
              justifyContent="space-between"
            >
              <Pane paddingX={0}>
                <Pane textAlign="left">
                  <Text size={400}>Covid discount</Text>
                </Pane>
              </Pane>
              <Pane paddingX={0}>
                <Text size={400} marginLeft="auto">
                  -$
                  {formatCents(totalSubscription, true)}
                </Text>
              </Pane>
            </Pane>
            <Pane
              height="auto"
              paddingY={8}
              display="flex"
              justifyContent="space-between"
            >
              <Pane paddingX={0}>
                <Pane textAlign="left">
                  <Heading size={400}>
                    {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message*/}
                    Total per {PlanIntervalLabels[subscription.interval]}
                  </Heading>
                </Pane>
              </Pane>
              <Pane paddingX={0}>
                <Strong size={400} marginLeft="auto">
                  $
                  {formatCents(
                    isOnTrial ? 0 : subscription.totalAmountInCents,
                    true
                  )}
                </Strong>
              </Pane>
            </Pane>

            {/* Hide cancel subscription button for now. It confuses users. We can bring it back when we charge users */}
            {/*<Pane paddingY={8} display="flex" alignItems="center">*/}
            {/*  {status !== SubscriptionStates.CANCELLED && (*/}
            {/*    <Text size={400} color="muted">*/}
            {/*      Next billing date:{' '}*/}
            {/*      {format(subscription.nextBilledAt, dateFormat)}*/}
            {/*    </Text>*/}
            {/*  )}*/}
            {/*  <Button*/}
            {/*    appearance="primary"*/}
            {/*    intent="danger"*/}
            {/*    marginLeft="auto"*/}
            {/*    onClick={() => setShowCancelDialog(true)}*/}
            {/*  >*/}
            {/*    Cancel subscription*/}
            {/*  </Button>*/}
            {/*</Pane>*/}
          </>
        )}
      </Pane>

      <Dialog
        isShown={showCancelDialog}
        title="Cancel your subscription"
        intent="danger"
        onCloseComplete={() => setShowCancelDialog(false)}
        isConfirmLoading={loading}
        onConfirm={() => cancelSubscription()}
        confirmLabel="Cancel my subscription"
      >
        <Paragraph>
          Are you sure you want to cancel your subscription? This will stop your
          access to the Muru app for all of the agencies that you currently pay
          for at the end of this billing period.
        </Paragraph>
      </Dialog>

      <RemoveIndividualAgencyDialog
        isShown={showLeaveDialog !== false}
        subscription={subscription}
        seatToRemove={showLeaveDialog}
        onCloseComplete={() => setShowLeaveDialog(false)}
      />
    </Pane>
  );
};

export default IndividualPlanDetails;
