import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { toName } from 'lib/medications';
import Menu from './Menu';
import { MEDICATIONS_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const MedicationsList = ({
  page,
  filters,
  onFilterUpdate,
  onPageInfoUpdate,
  onEditClick,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(MEDICATIONS_LIST, {
    variables: { ...page, filters },
    fetchPolicy: 'network-only'
  });
  const orderBy = filters.orderBy || defaultOrderBy;
  const medications = get(data, 'globalMedications.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by medication name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {medications.map(({ node: medication }) => {
                return (
                  <Table.Row key={medication.id} isSelectable>
                    <Table.Cell>
                      <Text size={300} fontWeight={500}>
                        {toName(medication)}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={medication.updatedAt} />
                    </Table.Cell>
                    <Table.Cell width={48} flex="none">
                      <Popover
                        content={
                          <Menu
                            onEditClick={() => onEditClick(medication)}
                            onDeleteClick={() => onDeleteClick(medication)}
                          />
                        }
                        position={Position.BOTTOM_RIGHT}
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && medications.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'globalMedications.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default MedicationsList;
