import * as Sentry from '@sentry/react';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://91b03a5738b54f34b6070aef356267b8@sentry.io/1552018',
    environment: import.meta.env.VITE_RELEASE_ENVIRONMENT || 'development',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        mask: ['.sensitive-data']
      })
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  });
}
