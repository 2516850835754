import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Avatar, Badge, Table, Text, Pane, Spinner } from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import { OrganizationRolesWithLabels } from '@/constants/organizations';
import { LIST_CHILD_ORGS } from './queries';

const ChildOrganizationList = ({ organization }: any) => {
  const history = useHistory();
  const { loading, data, error } = useQuery(LIST_CHILD_ORGS, {
    variables: { organizationId: organization.id },
    fetchPolicy: 'network-only'
  });

  const navigateToOrg = (org: any) => history.push(`/orgs/${org.slug}`);
  const organizations = get(data, 'organization.children', []);

  return (
    <Pane>
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Roles</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created</Table.TextHeaderCell>
          </Table.Head>
          {!loading && !error && (
            <>
              <Table.Body>
                {organizations.map((org: any) => (
                  <Table.Row
                    key={org.id}
                    height="auto"
                    minHeight={48}
                    isSelectable
                    onDoubleClick={() => navigateToOrg(org)}
                  >
                    <Table.Cell>
                      <Avatar src={org.logo && org.logo.url} name={org.name} />
                      <Text marginLeft={8} size={300} fontWeight={500}>
                        {org.name}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {org.type && <Badge>{org.type}</Badge>}
                    </Table.Cell>
                    <Table.Cell>
                      <Pane>
                        {org.roles &&
                          org.roles.map((role: any) => (
                            <Text size={300} key={role} display="block">
                              {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message*/}
                              {OrganizationRolesWithLabels[role]}
                            </Text>
                          ))}
                      </Pane>
                    </Table.Cell>
                    <Table.Cell>
                      <RelativeDate date={org.insertedAt} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && organizations.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default ChildOrganizationList;
