import React, { useState, useEffect } from 'react';
import {
  Pane,
  Icon,
  Heading,
  Button,
  Checkbox,
  Text,
  SegmentedControl,
  toaster,
  Link
} from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import posed from 'react-pose';
import { reduce, some } from 'lodash';
import { toName, storageMethodToString } from 'lib/medications';
import { sanitizeData } from 'lib/formHelpers';
import { launchIntercomWithMessage } from 'lib/analytics';
import { CREATE_MEDICATION_INVENTORY_PROFILE_ENTRY } from './queries';

function canContinue(isCarried: any, storageOptions: any) {
  if (!isCarried) return true;
  return some(storageOptions, item => !!item);
}

function buildAttributes(
  { medication, organization, storageOptions }: any,
  isCarried: any,
  storageMethodSelections: any
) {
  return {
    isCarried: isCarried,
    medicationId: medication.id,
    organizationId: organization.id,
    storageMethods: reduce(
      storageMethodSelections,
      (acc, isSelected, idx) =>
        // @ts-expect-error TS(2769): No overload matches this call.
        isSelected ? [...acc, sanitizeData(storageOptions[idx])] : acc,
      []
    )
  };
}

const SizesContainer = posed.div({
  expanded: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: {
    height: '1px',
    opacity: 0
  }
});

const MedicationQuestion = ({
  onSkipClick,
  onNextClick,
  medication,
  organization,
  storageOptions
}: any) => {
  const [isCarried, setIsCarried] = useState(true);
  const [storageMethodIdxs, setStorageMethodIdxs] = useState({});
  const [saveAnswer, { loading }] = useMutation(
    CREATE_MEDICATION_INVENTORY_PROFILE_ENTRY,
    {
      onCompleted: ({ result }: any) => {
        if (!result.errors) {
          onNextClick();
        } else {
          toaster.danger('Could not save your answer. Please try again.');
        }
      }
    }
  );

  useEffect(() => {
    setStorageMethodIdxs({});
  }, [medication, isCarried, setStorageMethodIdxs]);

  return (
    <Pane elevation={1} background="white" padding={32}>
      <Heading textAlign="center">Do you carry {toName(medication)}?</Heading>

      <Pane marginTop={16} paddingBottom={24}>
        <SegmentedControl
          options={[
            { label: "We don't carry it", value: false },
            { label: 'We carry it', value: true }
          ]}
          // @ts-expect-error Invalid onChange
          onChange={setIsCarried}
          value={isCarried}
        />

        <SizesContainer
          style={{ overflow: 'hidden' }}
          pose={isCarried ? 'expanded' : 'collapsed'}
        >
          <Heading size={400} marginTop={24} marginBottom={16}>
            Select all sizes that apply
          </Heading>

          {storageOptions.map((option: any, idx: any) => {
            function onClick(checked: any) {
              setStorageMethodIdxs({
                ...storageMethodIdxs,
                [idx]: checked
              });
            }

            return (
              <Pane
                cursor="pointer"
                display="flex"
                alignItems="center"
                userSelect="none"
                key={idx}
                marginBottom={12}
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                onClick={() => onClick(!storageMethodIdxs[idx])}
              >
                <Checkbox
                  name={`check-${idx}`}
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  checked={!!storageMethodIdxs[idx]}
                  onChange={(e: any) => onClick(!e.target.checked)}
                  marginTop={0}
                  marginBottom={0}
                />
                <Text marginLeft={8}>{storageMethodToString(option)}</Text>
              </Pane>
            );
          })}

          <Pane display="flex" alignItems="center" marginTop={8}>
            <Icon icon="help" color="muted" size={16} marginRight={8} />
            <Text size={400} color="muted">
              Don't see your size?{' '}
              <Link
                href="#"
                onClick={() => {
                  launchIntercomWithMessage(
                    `We don't see the size of ${toName(
                      medication
                    )} that we carry when setting up our inventory.\n\n We carry: << What you carry >>`
                  );
                }}
              >
                Contact us and we'll add it
              </Link>
              .
            </Text>
          </Pane>
        </SizesContainer>
      </Pane>

      <Button
        disabled={!canContinue(isCarried, storageMethodIdxs)}
        appearance="primary"
        width="100%"
        justifyContent="center"
        isLoading={loading}
        height={40}
        onClick={() =>
          saveAnswer({
            variables: {
              input: buildAttributes(
                { medication, organization, storageOptions },
                isCarried,
                storageMethodIdxs
              )
            }
          })
        }
      >
        Next
      </Button>

      <Pane
        display="flex"
        position="absolute"
        bottom={-48}
        width="100%"
        left={0}
      >
        <Button
          appearance="minimal"
          marginTop={16}
          marginLeft="auto"
          marginRight="auto"
          textAlign="center"
          onClick={() => onSkipClick({ medicationId: medication.id })}
        >
          Skip this question for now
        </Button>
      </Pane>
    </Pane>
  );
};

export default MedicationQuestion;
