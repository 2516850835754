import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import DeleteDialog from 'components/shared/DeleteDialog';
import SideSheet from 'components/shared/SideSheet';
import AliasesList from 'components/admin/AliasesList';
import PublishAliases from 'components/admin/AliasesList/PublishAliases';
import { ALIASES_LIST } from 'components/admin/AliasesList/AliasesList';
import CreateOrUpdateAlias from 'components/admin/CreateOrUpdateAlias';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

const DELETE_ALIAS = gql`
  mutation DeleteAlias($input: DeleteAliasInput!) {
    result: deleteAlias(input: $input) {
      alias {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters
  };

  const refetchQuery = {
    variables,
    query: ALIASES_LIST
  };

  const [deleteAlias, { loading: isDeleteLoading }] = useMutation(
    DELETE_ALIAS,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.alias) {
          toaster.success('Alias successfully deleted');
        } else {
          toaster.danger('Could not delete alias');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage global aliases"
        renderButtons={
          <>
            <Button
              onClick={() => setShowPublish(true)}
              marginLeft="auto"
              marginRight={8}
              appearance="primary"
              intent="success"
            >
              Publish Aliases
            </Button>
            <Button onClick={() => setShowForm(true)} iconBefore="add">
              New Alias
            </Button>
          </>
        }
      />

      <AliasesList
        page={pageInfo}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onEditClick={(alias: any) => setShowForm(alias)}
        onDeleteClick={(alias: any) => setShowDeleteDialog(alias)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateAlias
          alias={showForm || {}}
          onComplete={() => {
            toaster.success('Alias saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <PublishAliases
        isShown={showPublish}
        onCloseComplete={() => setShowPublish(false)}
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteAlias({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
