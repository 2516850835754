import React from 'react';
import { Switch, Pane, Heading, Paragraph } from 'evergreen-ui';

const SwitchInputField = ({
  placeholder,
  label,
  description,
  value,
  validationMessage,
  isInvalid,
  formikBag,
  ...props
}: any) => {
  return (
    <Pane marginBottom={24} display="flex" alignItems="center">
      <Pane>
        <Heading size={400}>{label}</Heading>
        {description && <Paragraph color="muted">{description}</Paragraph>}
      </Pane>
      <Switch {...props} checked={!!value} marginLeft="auto" />
    </Pane>
  );
};

export default SwitchInputField;
