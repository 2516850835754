import React from 'react';
import * as Yup from 'yup';
import { intersection } from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Text, Icon, Pane } from 'evergreen-ui';
import { formatEnumToOptions } from 'lib/formHelpers';
import {
  OrganizationTypesWithLabels,
  OrganizationRoles,
  OrganizationRolesWithLabels
} from '@/constants/organizations';
import MedicalAuthorityDataSource from 'components/shared/MedicalAuthorityDataSource';
import OrgSelectorDataSource from 'components/shared/OrgSelectorDataSource';

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().required()
});

const Authority = ({ medicalAuthorityId, position }: any) => {
  const client = useApolloClient();

  const authority = client.readFragment({
    id: `MedicalAuthority:${medicalAuthorityId}`,
    fragment: gql`
      fragment MedicalAuthority on MedicalAuthority {
        id
        name
      }
    `
  });

  return (
    <Text size={300}>
      {authority && authority.name} {position ? `(${position})` : ''}
    </Text>
  );
};

const Feature = ({ name, status }: any) => (
  <Pane alignItems="center" display="flex">
    <Icon
      icon={status ? 'tick-circle' : 'ban-circle'}
      color={status ? 'success' : 'danger'}
      marginRight={8}
    />
    <Text size={300}>{name}</Text>
  </Pane>
);

export const OrganizationForm = {
  name: 'organization',
  validationSchema: OrganizationSchema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      description: 'The primary name of the organization',
      placeholder: 'FDNY',
      required: true
    },
    {
      name: 'type',
      label: 'Type',
      description: 'The type of organization',
      type: 'select',
      required: true,
      values: formatEnumToOptions(OrganizationTypesWithLabels)
    },
    {
      name: 'roles',
      label: 'Roles',
      description:
        'Select one or more roles that are applicable for this organization',
      type: 'multiSelect',
      values: formatEnumToOptions(OrganizationRolesWithLabels)
    },
    {
      name: 'parentOrganization',
      label: 'Parent Organization',
      type: 'asyncSelect',
      description: 'Where does this organization inherit its content from?',
      dataSource: (render: any) => <OrgSelectorDataSource render={render} />,
      shownWhen: ({ roles = [] }) => {
        return (
          intersection(roles, [
            OrganizationRoles.MEDICAL_AGENCY,
            OrganizationRoles.MEDICAL_PUBLISHER
          ]).length > 0
        );
      }
    },
    {
      name: 'medicalAuthorities',
      type: 'listOfSubFields',
      label: 'Medical Authorities',
      titleExtractor: (fields: any) => <Authority {...fields} />,
      description:
        'The list of medical authorities that this organization adheres to',
      fields: [
        {
          type: 'asyncSelect',
          name: 'medicalAuthorityId',
          label: 'Medical authority',
          dataSource: (render: any) => (
            <MedicalAuthorityDataSource render={render} />
          )
        },
        {
          type: 'number',
          name: 'position',
          label: 'Position',
          description: 'Heirarchy of the authority (lower is higher priority)',
          placeholder: '0'
        }
      ]
    },
    {
      name: 'availableFeatures',
      label: 'Available Features',
      description:
        'Set any features that should be enabled or disabled for this organization. Note: this inerhits from the global configuration.',
      type: 'listOfSubFields',
      titleExtractor: (fields: any) => <Feature {...fields} />,
      fields: [
        {
          name: 'name',
          label: 'Feature name',
          description: 'A string to be used to identify this feature'
        },
        {
          name: 'status',
          label: 'Active?',
          description: 'Is the feature enabled for this organization?',
          type: 'switch'
        }
      ]
    },
    {
      name: 'serviceCountyCodes',
      label: 'County Codes this agency operates within',
      description: 'These should be FIPS county codes.',
      type: 'listOf',
      subFieldType: 'text'
    }
  ]
};
