import React, { useState } from 'react';
import queryString from 'query-string';
import { BackButton, Button, toaster, Heading, Pane } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import PageHeader from 'components/shared/PageHeader';
import DeleteDialog from 'components/shared/DeleteDialog';
import ProtocolList, {
  PROTOCOL_LIST
} from 'components/organizations/ProtocolList';
import CreateOrUpdateProtocol from 'components/organizations/CreateOrUpdateProtocol';
import EntryCriteria from 'components/shared/EntryCriteria';
import SideSheet from 'components/shared/SideSheet';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';
import { protocolPath } from 'lib/urls';
import { DELETE_PROTOCOL } from './queries';
import { generateBreadcrumbs } from 'lib/protocols';
import { hasPermission } from 'lib/organizations';

export default ({ history, protocolSet, isViewOnly, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters: {
      ...filters,
      publishedState: 'DRAFT',
      type: 'PARENT',
      applyAmendments: !hasPermission(organization, 'content_amendments:manage')
    },
    organizationId: organization.id,
    protocolSetId: protocolSet.id
  };

  const refetchQuery = {
    query: PROTOCOL_LIST,
    variables
  };

  const [deleteProtocol, { loading: isDeleteLoading }] = useMutation(
    DELETE_PROTOCOL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.protocol) {
          toaster.success('Protocol has been successfully deleted');
        } else {
          toaster.danger('Could not delete protocol, please try again');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title={`Protocols within ${protocolSet.name}`}
        subtitle="Protocols represent individual steps of medical instruction"
        renderButtons={
          <>
            <BackButton
              marginLeft="auto"
              marginRight={8}
              onClick={() =>
                history.push(`/orgs/${organization.slug}/protocol-sets`)
              }
            >
              Back to Protocol Sets
            </BackButton>
            <Button
              onClick={() => setShowForm(true)}
              iconBefore="add"
              appearance="primary"
              disabled={isViewOnly}
            >
              New Protocol
            </Button>
          </>
        }
        // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
        breadcrumbs={generateBreadcrumbs(organization, protocolSet)}
      />

      {protocolSet.entryCriteria && (
        <Pane marginBottom={24}>
          <Heading size={100} marginBottom={8}>
            Entry criteria (inherited from {protocolSet.name})
          </Heading>
          <EntryCriteria {...protocolSet.entryCriteria} />
        </Pane>
      )}

      <ProtocolList
        isViewOnly={isViewOnly}
        protocolSet={protocolSet}
        organization={organization}
        onDeleteClick={(protocol: any) => setShowDeleteDialog(protocol)}
        onEditClick={(protocol: any) => setShowForm(protocol)}
        onManageContentClick={(protocol: any) => {
          history.push(protocolPath(organization, protocolSet, protocol));
        }}
        onReferencingProtocolsClick={(protocol: any) => {
          history.push(
            `${protocolPath(
              organization,
              protocolSet,
              protocol
            )}/referencing-protocols`
          );
        }}
        onSubProtocolsClick={(protocol: any) => {
          history.push(
            `${protocolPath(organization, protocolSet, protocol)}/subprotocols`
          );
        }}
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateProtocol
          protocol={showForm || {}}
          protocolSet={protocolSet}
          onComplete={() => {
            toaster.success('Protocol saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteProtocol({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
