import React from 'react';
import { Pane, BackButton, toaster } from 'evergreen-ui';
import PageHeader from 'components/shared/PageHeader';
import CreateOrUpdateEquipment from 'components/organizations/CreateOrUpdateEquipment';

export default ({ history, organization }: any) => {
  return (
    <>
      <PageHeader
        title="Create new equipment"
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() => history.push(`/orgs/${organization.slug}/equipment`)}
          />
        }
      />
      <Pane width="500px">
        <CreateOrUpdateEquipment
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Equipment successfully created');
            history.push(`/orgs/${organization.slug}/equipment`);
          }}
        />
      </Pane>
    </>
  );
};
