import React from 'react';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { Pane, BackButton, Strong, toaster } from 'evergreen-ui';
import { useApolloClient } from '@apollo/react-hooks';
import RelativeDate from 'components/shared/RelativeDate';
import PageHeader from 'components/shared/PageHeader';
import CreateOrUpdateProtocolStep from 'components/organizations/CreateOrUpdateProtocolStep';
import { ProtocolStepFragment } from 'components/shared/fragments';
import { protocolPath } from 'lib/urls';
import { determineStepType } from '@/constants/protocolSteps';
import { generateBreadcrumbs } from 'lib/protocols';

export default ({
  history,
  match,
  protocol,
  protocolSet,
  organization
}: any) => {
  const { params } = match;
  const { id: protocolStepId } = params;
  const client = useApolloClient();

  const protocolStep = client.readFragment({
    id: `ProtocolStep:${protocolStepId}`,
    fragment: ProtocolStepFragment,
    fragmentName: 'ProtocolStep'
  });

  // We're only able to set a patient type entry condition
  // if there isn't already one set on the protocol set
  const canSetPatientTypeOnProtocolStep =
    get(protocolSet, 'entryCriteria.patientTypes', []).length === 0 &&
    get(protocol, 'entryCriteria.patientTypes', []).length === 0;

  if (!protocolStep) {
    return <Redirect to={protocolPath(organization, protocolSet, protocol)} />;
  }

  const stepType = determineStepType(protocolStep);

  return (
    <>
      <PageHeader
        title={`Update ${stepType} step within ${protocol.name}`}
        subtitle={
          <>
            Part of <Strong>{protocolSet.name}</Strong>, last edited{' '}
            <RelativeDate textSize={400} date={protocol.updatedAt} />
          </>
        }
        renderButtons={
          <BackButton
            marginLeft="auto"
            onClick={() =>
              history.push(protocolPath(organization, protocolSet, protocol))
            }
          >
            Back to Protocol
          </BackButton>
        }
        breadcrumbs={generateBreadcrumbs(organization, protocolSet, protocol)}
      />
      <Pane width="500px">
        <CreateOrUpdateProtocolStep
          organizationId={organization.id}
          stepType={stepType}
          protocolStep={protocolStep}
          protocolSetId={protocolSet.id}
          protocolId={protocol.id}
          canSetPatientType={canSetPatientTypeOnProtocolStep}
          onComplete={() => {
            toaster.success('Protocol step updated successfully');
            history.push(protocolPath(organization, protocolSet, protocol));
          }}
        />
      </Pane>
    </>
  );
};
