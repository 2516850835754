import React from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import onboardingSlice from 'redux/onboarding';
import { JOINABLE_AGENCY } from './queries';
import LoadingScreen from 'components/shared/LoadingScreen';

const { setOrganization } = onboardingSlice.actions;

const Organization = () => {
  // @ts-expect-error TS(2339): Property 'organizationId' does not exist on type '... Remove this comment to see the full error message
  const { organizationId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useQuery(JOINABLE_AGENCY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
    onCompleted: ({ joinableAgency }: any) => {
      dispatch(setOrganization(joinableAgency));
      setTimeout(() => history.replace('/onboarding/auth'), 0);
    },
    onError: (error: any) => {
      const errorCode = get(error, 'graphQLErrors[0].code');

      if (errorCode === 'NOT_FOUND') {
        history.replace('/onboarding');
      } else if (errorCode) {
        history.replace(
          `/onboarding/organization-not-eligible?agencyId=${organizationId}`
        );
      }
    }
  });

  return <LoadingScreen />;
};

export default Organization;
