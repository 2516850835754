import React, { useState } from 'react';
import { Dialog } from 'evergreen-ui';
import SelectSource from './SelectSourceForOrganization';

const SourceSelectionDialog = ({
  organizationId,
  selectSource,
  isShown,
  onClose,
  source
}: any) => {
  const [selectedSource, setSelectedSource] = useState(null);

  return (
    <Dialog
      isConfirmDisabled={!selectedSource}
      title="Select Source"
      isShown={isShown}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasClose={false}
      onCloseComplete={onClose}
      onConfirm={() => selectSource(selectedSource)}
    >
      {isShown && (
        <SelectSource
          organizationId={organizationId}
          source={source}
          onSelect={(item: any) =>
            item ? setSelectedSource(item.value) : setSelectedSource(null)
          }
        />
      )}
    </Dialog>
  );
};

export default SourceSelectionDialog;
