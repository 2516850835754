import React, { useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import OrgInviteScreen from 'components/accounts/OrgInvite';
import { showIntercomBubble, hideIntercomBubble } from 'lib/analytics';
import BrandedContent from 'components/shared/BrandedLayout';

function OrgInvite() {
  // @ts-expect-error TS(2339): Property 'token' does not exist on type '{}'.
  const { token } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    showIntercomBubble();
    return () => {
      hideIntercomBubble();
    };
  }, []);

  return (
    <BrandedContent>
      <Pane
        maxWidth={380}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <OrgInviteScreen
          token={token}
          onLoginClick={() => history.push(`/login?from=${location.pathname}`)}
          onSignupClick={(email: any) =>
            history.push(`/signup?from=${location.pathname}&email=${email}`)
          }
        />
      </Pane>
    </BrandedContent>
  );
}

export default OrgInvite;
