import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const CAPABILITIES = gql`
  query CapabilitiesDataSource($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      hospitalCapabilities(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const HospitalCapabilitiesDataSource = ({ render, organizationId }: any) => {
  const { data, loading, error } = useQuery(CAPABILITIES, {
    variables: { organizationId },
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? data.organization.hospitalCapabilities.edges.map(
          // @ts-expect-error TS(7031): Binding element 'capability' implicitly has an 'an... Remove this comment to see the full error message
          ({ node: capability }) => ({
            label: capability.name,
            value: capability.id
          })
        )
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default HospitalCapabilitiesDataSource;
