import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Heading, Pane, Paragraph, Text, Spinner, toaster } from 'evergreen-ui';
import { onCompleted } from 'lib/formHelpers';
import Form from './Form';
import {
  InputErrorFragment,
  SessionFragment
} from 'components/shared/fragments';

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        ...InputError
      }
      session {
        ...Session
      }
    }
  }
  ${InputErrorFragment}
  ${SessionFragment}
`;

const VALIDATE_RESET_TOKEN = gql`
  query ValidateResetPasswordToken($token: String!) {
    validateResetPasswordToken(token: $token) {
      success
    }
  }
`;

const TokenInvalid = () => (
  <>
    <Heading size={500} marginTop={16} marginBottom={16}>
      Invalid token
    </Heading>
    <Paragraph>
      The token you've supplied is invalid or has expired. Please try generating
      a new token by resetting your password again.
    </Paragraph>
  </>
);

const ResetPasswordForm = ({ token, onResetPassword }: any) => {
  const [validationErrors, setErrors] = useState({});
  const { data: validateData, loading: isLoadingToken } = useQuery(
    VALIDATE_RESET_TOKEN,
    {
      variables: { token }
    }
  );
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: (response: any) =>
      onCompleted({
        response,
        resultKey: 'resetPassword',
        setErrors,
        onComplete: () => {
          toaster.success('Your password has been successfully changed');
          onResetPassword();
        }
      })
  });

  if (isLoadingToken) {
    return (
      <Pane
        display="flex"
        margin={16}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size={16} marginRight={16} />
        <Text color="muted" size={300}>
          Validating token
        </Text>
      </Pane>
    );
  }

  if (!get(validateData, 'validateResetPasswordToken.success')) {
    return <TokenInvalid />;
  }

  return (
    <Form
      validationErrors={validationErrors}
      onSubmit={(variables: any) => {
        setErrors({});
        resetPassword({
          variables: { input: { ...variables, token: token } }
        });
      }}
      loading={loading}
    />
  );
};

export default ResetPasswordForm;
