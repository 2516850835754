import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PROTOCOL_SET_SOURCES = gql`
  query ProtocolSetSources($id: ID!) {
    protocolSet: node(id: $id, type: PROTOCOL_SET) {
      ... on ProtocolSet {
        id
        sources {
          id
          name
          version
        }
      }
    }
  }
`;

const ProtocolSetSources = ({ protocolSetId, render }: any) => {
  const { data, loading, error } = useQuery(PROTOCOL_SET_SOURCES, {
    variables: { id: protocolSetId },
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? data.protocolSet.sources.map((source: any) => ({
          label: `${source.name} (${source.version})`,
          value: source.id
        }))
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default ProtocolSetSources;
