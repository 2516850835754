import gql from 'graphql-tag';

export const COLLISIONS = gql`
  query FindAliasCollisions($input: CheckForAliasCollisionsInput!) {
    results: checkForAliasCollisions(input: $input) {
      ... on ProtocolSet {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
      }
      ... on Protocol {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
        protocolSet {
          id
          name
        }
      }
      ... on Medication {
        id
        aliases
        brandName
        genericName
        updatedAt
        lastPublishedAt
      }
      ... on Equipment {
        id
        aliases
        name
        updatedAt
        lastPublishedAt
      }
      ... on EquipmentFunction {
        id
        aliases
        name
        updatedAt
        lastPublishedAt
        equipment {
          id
          name
        }
      }
      ... on Alias {
        id
        key
        references
        aliases
        updatedAt
      }
    }
  }
`;
