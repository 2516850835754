import React from 'react';
import { Pane, IconButton, Text, Link } from 'evergreen-ui';
import { useApolloClient } from '@apollo/react-hooks';
import { ReferencedSourceFragment } from '../fragments';

const SourceLink = ({ url, file, name, version }: any) => (
  <Link size={300} target="_blank" href={file ? file.url : url}>
    {name} ({version})
  </Link>
);

const ReferencedSource = ({ id, onEditClick, onDeleteClick }: any) => {
  const client = useApolloClient();

  // The way the sources end up in the cache is weird but it works! So when
  // we fetch all of the sources and versions for the org, we actually insert them
  // into the referenced source at the same time that way we can consistently read
  // them as ReferencedSources and not as Sources + Versions
  const referencedSource = client.readFragment({
    id: `ReferencedSource:${id}`,
    fragment: ReferencedSourceFragment
  });

  return (
    <Pane
      key={id}
      backgroundColor="white"
      borderBottom="muted"
      padding={8}
      display="flex"
      alignItems="center"
    >
      {referencedSource ? (
        <SourceLink {...referencedSource} />
      ) : (
        <Text size={300}>N/A (save first)</Text>
      )}
      <Pane marginLeft="auto" display="flex">
        {onEditClick && (
          <IconButton
            type="button"
            icon="edit"
            height={24}
            marginRight={8}
            appearance="minimal"
            onClick={onEditClick}
          />
        )}
        <IconButton
          type="button"
          icon="cross"
          height={24}
          appearance="minimal"
          onClick={onDeleteClick}
        />
      </Pane>
    </Pane>
  );
};

export default ReferencedSource;
