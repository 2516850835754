import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import queryString from 'query-string';
import PageHeader from 'components/shared/PageHeader';
import DeleteDialog from 'components/shared/DeleteDialog';
import SideSheet from 'components/shared/SideSheet';
import ProtocolSetList, {
  PROTOCOL_SET_LIST
} from 'components/organizations/ProtocolSetList';
import CreateOrUpdateProtocolSet from 'components/organizations/CreateOrUpdateProtocolSet';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';
import { hasPermission } from 'lib/organizations';

const DELETE_PROTOCOL_SET = gql`
  mutation DeleteProtocolSet($input: DeleteProtocolSetForOrganizationInput!) {
    result: deleteProtocolSetForOrganization(input: $input) {
      protocolSet {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters: {
      ...filters,
      publishedState: 'DRAFT',
      applyAmendments: !hasPermission(organization, 'content_amendments:manage')
    },
    organizationId: organization.id
  };

  const refetchQuery = {
    query: PROTOCOL_SET_LIST,
    variables
  };

  const [deleteProtocolSet, { loading: isDeleteLoading }] = useMutation(
    DELETE_PROTOCOL_SET,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.protocolSet) {
          toaster.success('Protocol set successfully deleted');
        } else {
          toaster.danger('Could not delete protocol set');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage protocol sets"
        subtitle="Protocol sets are the collections of protocols that are bundled together"
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New Protocol Set
          </Button>
        }
      />

      <ProtocolSetList
        refetchQuery={refetchQuery}
        organization={organization}
        onEditClick={(protocolSet: any) => setShowForm(protocolSet)}
        onDeleteClick={(protocolSet: any) => setShowDeleteDialog(protocolSet)}
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateProtocolSet
          protocolSet={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Protocol set saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteProtocolSet({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
