import gql from 'graphql-tag';
import {
  InputErrorFragment,
  MedicationFragment
} from 'components/shared/fragments';

export const CREATE_MEDICATION = gql`
  mutation CreateMedication($input: CreateMedicationForOrganizationInput!) {
    result: createMedicationForOrganization(input: $input) {
      errors {
        ...InputError
      }
      medication {
        ...Medication
      }
    }
  }
  ${InputErrorFragment}
  ${MedicationFragment}
`;

export const UPDATE_MEDICATION = gql`
  mutation UpdateMedication($input: UpdateMedicationForOrganizationInput!) {
    result: updateMedicationForOrganization(input: $input) {
      errors {
        ...InputError
      }
      medication {
        ...Medication
      }
    }
  }
  ${MedicationFragment}
  ${InputErrorFragment}
`;
