import gql from 'graphql-tag';

export const DELETE_PROTOCOL = gql`
  mutation DeleteProtocol($input: DeleteProtocolForProtocolSetInput!) {
    result: deleteProtocolForProtocolSet(input: $input) {
      protocol {
        id
      }
      errors {
        message
      }
    }
  }
`;
