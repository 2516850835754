import React from 'react';
import { Pane, Heading } from 'evergreen-ui';
import CreateSubscription from './CreateSubscription';
import UpdateSubscription from './UpdateSubscription';

const CreateOrUpdateSubscription = ({
  organizationId,
  subscription,
  onComplete,
  refetchQuery
}: any) => {
  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {subscription ? 'Update subscription' : 'Create new subscription'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        {subscription ? (
          <UpdateSubscription
            organizationId={organizationId}
            subscription={subscription}
            refetchQuery={refetchQuery}
          />
        ) : (
          <CreateSubscription
            organizationId={organizationId}
            refetchQuery={refetchQuery}
            onComplete={onComplete}
          />
        )}
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateSubscription;
