import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';

const CREATE_SOURCE_VERSION = gql`
  mutation CreateSourceVersion($input: CreateSourceVersionInput!) {
    result: createSourceVersion(input: $input) {
      sourceVersion {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const UPDATE_SOURCE_VERSION = gql`
  mutation UpdateSourceVersion($input: UpdateSourceVersionInput!) {
    result: updateSourceVersion(input: $input) {
      sourceVersion {
        id
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
`;

const initialValues = {
  version: ''
};

const CreateOrUpdateSourceVersion = ({
  sourceId,
  sourceVersion,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasSourceVersion = sourceVersion && sourceVersion.id;
  const [createOrUpdateSourceVersion, { loading }] = useMutation(
    hasSourceVersion ? UPDATE_SOURCE_VERSION : CREATE_SOURCE_VERSION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasSourceVersion
    ? sanitizeData(sourceVersion)
    : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {hasSourceVersion ? 'Update version' : 'Create new version'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={sourceVersion?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            // We only want to add the sourceId to the case where we're creating
            // new sources and not when we're editing an existing source.
            const variables = hasSourceVersion ? input : { ...input, sourceId };

            setErrors({});
            createOrUpdateSourceVersion({
              variables: { input: variables }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateSourceVersion;
