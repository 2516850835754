import React from 'react';
import { Pane, Heading, Button } from 'evergreen-ui';
import SectionCard from './SectionCard';

const EditableSectionCard = ({
  title,
  isExpanded = false,
  onEditClick,
  children,
  renderPreview = () => {}
}: any) => {
  return (
    <SectionCard>
      <Pane display="flex" padding={16} flexDirection="row">
        <Heading size={500}>{title}</Heading>

        <Button
          type="button"
          appearance="minimal"
          marginLeft="auto"
          height={20}
          onClick={() => onEditClick()}
        >
          {isExpanded ? 'Cancel' : 'Edit'}
        </Button>
      </Pane>
      <Pane borderTop padding={16}>
        {isExpanded ? children : renderPreview()}
      </Pane>
    </SectionCard>
  );
};

export default EditableSectionCard;
