import gql from 'graphql-tag';
import { SubscriptionFragment } from 'components/shared/fragments';

export const JOIN_ORGANIZATION = gql`
  mutation JoinOrganizationAsIndividual(
    $input: JoinOrganizationAsIndividualInput!
  ) {
    result: joinOrganizationAsIndividual(input: $input) {
      errors {
        message
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragment}
`;
