import * as Yup from 'yup';

const IndividualSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .email()
    .required()
});

export const IndividualForm = {
  name: 'organization',
  validationSchema: IndividualSchema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Your name'
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'name@emsagency.org'
    },
    {
      name: 'affiliatedAgencies',
      label: 'Agencies',
      description: 'Which agencies do you work for?',
      type: 'listOf',
      subFieldType: 'text',
      placeholder: 'EMS Agency Name'
    }
  ]
};

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  organizationName: Yup.string().required('Please enter an agency name'),
  numberOfProviders: Yup.number()
});

export const OrganizationForm = {
  name: 'organization',
  validationSchema: OrganizationSchema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Your name'
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'name@emsagency.org'
    },
    {
      name: 'phoneNumber',
      label: 'Phone number',
      placeholder: '516-754-1723'
    },
    {
      name: 'organizationName',
      label: 'Agency name',
      placeholder: 'Vigilant Fire Co.'
    },
    {
      name: 'role',
      label: 'Your role at this agency',
      placeholder: 'Lt Chief of EMS'
    },
    {
      name: 'numberOfProviders',
      label: 'Total providers',
      description: 'How many providers work at this agency (approx)',
      type: 'number',
      placeholder: '50'
    }
  ]
};
