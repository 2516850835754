import React from 'react';
import { Text } from 'evergreen-ui';
import {
  DoseEntryComparisonOperatorWithLabels,
  DoseEntryOperatorsWithLabels
} from '@/constants/doses';
import {
  CalendarUnitsWithLabels,
  WeightUnitsWithLabels
} from 'constants/units.ts';

const AgeFilter = ({ value, unit, comparisonOperator }: any) => {
  return (
    <Text size={300}>
      {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {DoseEntryComparisonOperatorWithLabels[comparisonOperator]} {value} //
      {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {CalendarUnitsWithLabels[unit]}
    </Text>
  );
};

const WeightFilter = ({ value, unit, comparisonOperator }: any) => {
  return (
    <Text size={300}>
      {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {DoseEntryComparisonOperatorWithLabels[comparisonOperator]} {value} //
      {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {WeightUnitsWithLabels[unit]}
    </Text>
  );
};

export const FilterToComponent = {
  PATIENT_AGE: AgeFilter,
  PATIENT_WEIGHT: WeightFilter
};

const Filters = ({ operator, values }: any) => {
  const totalFilters = values.length;

  return (
    <Text size={300}>
      {values.map(({ property, ...filter }: any, idx: any) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const Component = FilterToComponent[property];

        return (
          <>
            <Component {...filter} />
            {totalFilters !== idx + 1 && (
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              <Text size={300}> {DoseEntryOperatorsWithLabels[operator]} </Text>
            )}
          </>
        );
      })}
    </Text>
  );
};

export default Filters;
