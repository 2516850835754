import gql from 'graphql-tag';
import {
  ProtocolSetFragment,
  InputErrorFragment
} from 'components/shared/fragments';

export const CREATE_PROTOCOL_SET = gql`
  mutation CreateProtocolSet($input: CreateProtocolSetForOrganizationInput!) {
    result: createProtocolSetForOrganization(input: $input) {
      protocolSet {
        ...ProtocolSet
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
  ${ProtocolSetFragment}
`;

export const UPDATE_PROTOCOL_SET = gql`
  mutation UpdateProtocolSet($input: UpdateProtocolSetForOrganizationInput!) {
    result: updateProtocolSetForOrganization(input: $input) {
      protocolSet {
        ...ProtocolSet
      }
      errors {
        ...InputError
      }
    }
  }
  ${InputErrorFragment}
  ${ProtocolSetFragment}
`;
