import React, { useState } from 'react';
import { groupBy, map } from 'lodash';
import Media from 'react-media';
import { Pane, SegmentedControl } from 'evergreen-ui';
import IndividualPlan from './IndividualPlan';
import OrganizationPlan from './OrganizationPlan';

const PlansByType = {
  INDIVIDUAL: {
    title: 'Individual',
    description:
      'Pay for your own access to Muru for as many agencies as you work for.',
    icon: 'person'
  },
  ORGANIZATION: {
    title: 'Agency',
    description:
      'Pay for multiple providers to access Muru and centralize their management.',
    icon: 'people',
    perUnit: '/user'
  }
};

const mediaQueries = {
  phone: '(max-width: 499px)',
  everythingElse: '(min-width: 500px)'
};

const PlanSelector = ({ plans, onPlanSelect }: any) => {
  const plansByType = groupBy(plans, 'type');
  const [interval, setIntervalPeriod] = useState('MONTH');

  return (
    <Pane flex={1} marginTop={16}>
      <Media queries={mediaQueries}>
        {(matches: any) => (
          <>
            <Pane maxWidth={400} margin="auto">
              <SegmentedControl
                options={[
                  { label: 'Monthly', value: 'MONTH' },
                  { label: 'Yearly (save 10%)', value: 'YEAR' }
                ]}
                onChange={(interval: any) => setIntervalPeriod(interval)}
                value={interval}
                height={24}
                width="100%"
                margin="auto"
                marginTop={8}
              />
            </Pane>

            <Pane
              marginTop={16}
              display="flex"
              flexDirection={matches.phone ? 'column' : 'row'}
              justifyContent="space-around"
              width={matches.phone ? '100%' : '155%'}
              marginLeft={matches.phone ? 0 : '-27.5%'}
            >
              {map(PlansByType, (data, type) => {
                const plans = plansByType[type];
                const plan = plans.find(plan => plan.interval === interval);
                const otherPlan = plans.find(
                  plan => plan.interval !== interval
                );

                const PlanComponent =
                  type === 'INDIVIDUAL' ? IndividualPlan : OrganizationPlan;

                return (
                  <PlanComponent
                    key={plan.id}
                    {...data}
                    isSmall={!!matches.phone}
                    type={type}
                    plan={plan}
                    alternatePlan={otherPlan}
                    onPlanSelect={() => onPlanSelect(plan)}
                  />
                );
              })}
            </Pane>
          </>
        )}
      </Media>
    </Pane>
  );
};

export default PlanSelector;
