import React from 'react';
import * as Yup from 'yup';
import { FilterToComponent } from 'components/shared/EntryCriteria';
import Filters from 'components/shared/EntryCriteria/Filters';
import {
  WeightUnitsWithLabels,
  CalendarUnitsWithLabels
} from 'constants/units';
import {
  DoseEntryPropertiesWithLabels,
  DoseEntryComparisonOperatorWithLabels,
  DoseEntryOperatorsWithLabels
} from 'constants/doses';
import { formatEnumToOptions } from 'lib/formHelpers';

const ConditionsOfUseFiltersSchema = Yup.object().shape({
  operator: Yup.string()
    .nullable()
    .required(),
  values: Yup.array().required()
});

const ConditionOfUseFilterSchema = Yup.object().shape({
  property: Yup.string()
    .nullable()
    .required(),
  value: Yup.string()
    .nullable()
    .required(),
  unit: Yup.string()
    .nullable()
    .required(),
  comparisonOperator: Yup.string()
    .nullable()
    .required()
});

const FilterConditionsForm = {
  type: 'subFields',
  name: 'filters',
  label: 'Filters',
  formTitle: 'Dose Conditions of Use Filters',
  description: 'Filters to be matched for this dose to be applicable',
  validationSchema: ConditionsOfUseFiltersSchema,
  renderPreview: (filters: any) => filters && <Filters {...filters} />,
  initialState: {
    operator: null,
    values: []
  },
  fields: [
    {
      type: 'select',
      name: 'operator',
      label: 'Operator',
      description: 'Operator to combine the filters with',
      values: formatEnumToOptions(DoseEntryOperatorsWithLabels)
    },
    {
      type: 'listOfSubFields',
      name: 'values',
      formTitle: 'Dose Conditions of Use Filter',
      label: 'Filters',
      description: 'Filters conditions to be met for this dose to be shown',
      validationSchema: ConditionOfUseFilterSchema,
      titleExtractor: ({ property, ...filter }: any) => {
        // @ts-expect-error Invalid FilterToComponent
        const Component = FilterToComponent[property];

        return <Component {...filter} />;
      },
      fields: [
        {
          name: 'property',
          type: 'select',
          label: 'Patient Property',
          values: formatEnumToOptions(DoseEntryPropertiesWithLabels)
        },
        {
          name: 'comparisonOperator',
          type: 'select',
          label: 'Comparison operator',
          values: formatEnumToOptions(DoseEntryComparisonOperatorWithLabels)
        },
        {
          name: 'value',
          type: 'text',
          label: 'Value'
        },
        {
          name: 'unit',
          type: 'select',
          label: 'Unit',

          values: ({ property }: any) => {
            switch (property) {
              case 'PATIENT_AGE':
                return formatEnumToOptions(CalendarUnitsWithLabels);
              case 'PATIENT_WEIGHT':
                return formatEnumToOptions(WeightUnitsWithLabels);
              default:
                return [];
            }
          }
        }
      ]
    }
  ]
};

export default FilterConditionsForm;
