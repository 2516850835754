import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading, SegmentedControl, Paragraph } from 'evergreen-ui';
import gql from 'graphql-tag';
import { InputErrorFragment, AliasFragment } from 'components/shared/fragments';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { isSystemAlias, AliasTypes } from 'lib/aliasHelpers';
import { StringForm, SystemForm } from './form';

const CREATE_ALIAS = gql`
  mutation CreateAlias($input: CreateAliasInput!) {
    result: createAlias(input: $input) {
      alias {
        ...Alias
      }
      errors {
        ...InputError
      }
    }
  }
  ${AliasFragment}
  ${InputErrorFragment}
`;

const UPDATE_ALIAS = gql`
  mutation UpdateAlias($input: UpdateAliasInput!) {
    result: updateAlias(input: $input) {
      alias {
        ...Alias
      }
      errors {
        ...InputError
      }
    }
  }
  ${AliasFragment}
  ${InputErrorFragment}
`;

const initialValues = {
  aliases: []
};

function prepareAlias(alias: any) {
  const data = sanitizeData(alias, ['lastPublishedAt']);

  if (isSystemAlias(data)) {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    data.references = `${data.references}.${data.key}`;
  }

  return data;
}

function prepareAttributes(attrs: any) {
  if (isSystemAlias(attrs)) {
    const [ref, key] = attrs.references.split('.');
    attrs.references = ref;
    attrs.key = key;
  }

  return attrs;
}

const Options = [
  { label: 'String Alias', value: AliasTypes.STRING },
  { label: 'System Alias', value: AliasTypes.SYSTEM }
];

const CreateOrUpdateAlias = ({ alias, refetchQuery, onComplete }: any) => {
  const [errors, setErrors] = useState({});
  const [selectedForm, setSelectedForm] = useState(
    isSystemAlias(alias) ? AliasTypes.SYSTEM : AliasTypes.STRING
  );
  const [createOrUpdateAlias, { loading }] = useMutation(
    alias && alias.id ? UPDATE_ALIAS : CREATE_ALIAS,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = alias && alias.id ? prepareAlias(alias) : initialValues;

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} borderBottom background="tint2">
        <Heading size={500}>
          {alias && alias.id ? 'Update Alias' : 'Create Alias'}
        </Heading>
        <Paragraph color="muted">
          An alias is a mapping between a term and a set of synoyms. It's an
          important attribute of our search engine. Be careful not to introduce
          potentially overlapping aliases.
        </Paragraph>
      </Pane>
      <Pane padding={16}>
        {!(alias && alias.id) && (
          <SegmentedControl
            value={selectedForm}
            onChange={(value: any) => setSelectedForm(value)}
            options={Options}
            marginBottom={24}
          />
        )}

        <FormBuilder
          key={alias?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={selectedForm === AliasTypes.SYSTEM ? SystemForm : StringForm}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            setErrors({});
            createOrUpdateAlias({
              variables: { input: prepareAttributes(input) }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateAlias;
