import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import queryString from 'query-string';
import SideSheet from 'components/shared/SideSheet';
import PageHeader from 'components/shared/PageHeader';
import UsersList from 'components/admin/UsersList';
import UserProfile from 'components/admin/UserProfile';
import ProvisionUser from 'components/admin/ProvisionUser';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

export default ({ history }: any) => {
  const [currentUserId, showUserProfile] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { page: pageInfo, ...qs } = queryString.parse(history.location.search);
  const filters = parseFilters(qs.filters);

  return (
    <>
      <PageHeader
        title="Manage users"
        renderButtons={
          <Button
            onClick={() => setShowCreateForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            Provision user
          </Button>
        }
      />

      <UsersList
        navigateToUser={(userId: any) => showUserProfile(userId)}
        page={withDefaultPageInfo(pageInfo)}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
      />

      <SideSheet
        isShown={!!currentUserId}
        onCloseComplete={() => showUserProfile(null)}
      >
        <UserProfile userId={currentUserId} />
      </SideSheet>

      <SideSheet
        isShown={showCreateForm !== false}
        onCloseComplete={() => setShowCreateForm(false)}
      >
        <ProvisionUser
          onComplete={() => {
            toaster.success('New user has been provisioned');
            setShowCreateForm(false);
          }}
        />
      </SideSheet>
    </>
  );
};
