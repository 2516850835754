import client from '../../../graphql';
import gql from 'graphql-tag';

function authorityOption(authorityId: any) {
  const authority = client.readFragment({
    id: `MedicalAuthority:${authorityId}`,
    fragment: gql`
      fragment MedicalAuthority on MedicalAuthority {
        id
        name
      }
    `
  });

  return authority ? { value: authority.id, label: authority.name } : null;
}

function authorityOptions(authorityIds: any) {
  return authorityIds.map(authorityOption).filter((option: any) => !!option);
}

export default authorityOptions;
