import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';

const MANUFACTURERS = gql`
  query ListManufacturers {
    manufacturers(first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const ManufacturerDataSource = ({ render }: any) => {
  const { data, loading } = useQuery(MANUFACTURERS, {
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'manufacturers.edges', []).map(
    // @ts-expect-error TS(7031): Binding element 'manufacturer' implicitly has an '... Remove this comment to see the full error message
    ({ node: manufacturer }) => ({
      label: manufacturer.name,
      value: manufacturer.id
    })
  );

  return render({
    items,
    isLoading: loading
  });
};

export default ManufacturerDataSource;
