import React from 'react';
import { Link } from 'evergreen-ui';
import * as Yup from 'yup';
import uppy from 'lib/uppy';

const Schema = Yup.object().shape({
  version: Yup.string().required(),
  url: Yup.string()
    .when('file', {
      is: file => !file,
      then: Yup.string()
        .url()
        .required('Either URL or file is required')
        .nullable()
    })
    .nullable(),
  file: Yup.object().nullable()
});

export const Form = {
  name: 'sourceVersion',
  validationSchema: Schema,
  fields: [
    {
      name: 'version',
      label: 'Version',
      placeholder: 'v1.0.0',
      required: true
    },
    {
      type: 'upload',
      name: 'file',
      label: 'File',
      description: 'The file source, usually a document like a PDF',
      renderPreview: ({ name, size, url }: any) => (
        <Link href={url} target="_blank">
          {name} ({size} bytes)
        </Link>
      ),
      uppyInstance: ({ onComplete }: any) =>
        uppy(
          {
            restrictions: {
              maxNumberOfFiles: 1,
              maxFileSize: 1024 * 1024 * 200
            }
          },
          // @ts-expect-error TS(7031): Binding element 'result' implicitly has an 'any' t... Remove this comment to see the full error message
          ([result]) => onComplete(result)
        )
    },
    {
      name: 'url',
      label: 'External URL',
      placeholder: 'https://example.com/my-source/'
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Any notes associated with this version'
    }
  ]
};
