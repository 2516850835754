import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Pane, Heading, Spinner } from 'evergreen-ui';
import { pick } from 'lodash';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted } from 'lib/formHelpers';
import { OrganizationForm } from './form';
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  GET_ORGANIZATION
} from './queries';

const initialValues = {
  name: '',
  type: 'CIVILIAN'
};

const CreateOrUpdateOrganization = ({
  organization,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const [fetchOrganization, { loading: isOrgLoading, data }] = useLazyQuery(
    GET_ORGANIZATION
  );
  const [createOrUpdateOrganization, { loading }] = useMutation(
    organization ? UPDATE_ORGANIZATION : CREATE_ORGANIZATION,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  useEffect(() => {
    if (organization) {
      fetchOrganization({ variables: { orgId: organization.id } });
    }
  }, [organization, fetchOrganization]);

  const formData =
    !isOrgLoading && data && data.organization
      ? pick(data.organization, [
          'id',
          'name',
          'type',
          'medicalAuthorities',
          'roles',
          'availableFeatures',
          'serviceCountyCodes'
        ])
      : initialValues;

  // @ts-expect-error TS(2339): Property 'availableFeatures' does not exist on typ... Remove this comment to see the full error message
  if (formData.availableFeatures) {
    // @ts-expect-error TS(2339): Property 'availableFeatures' does not exist on typ... Remove this comment to see the full error message
    formData.availableFeatures = formData.availableFeatures.map(
      (feature: any) => pick(feature, ['name', 'status'])
    );
  }

  // @ts-expect-error TS(2339): Property 'medicalAuthorities' does not exist on ty... Remove this comment to see the full error message
  if (formData.medicalAuthorities) {
    // @ts-expect-error TS(2339): Property 'medicalAuthorities' does not exist on ty... Remove this comment to see the full error message
    formData.medicalAuthorities = formData.medicalAuthorities.map(
      // @ts-expect-error TS(7006): Parameter 'authority' implicitly has an 'any' type... Remove this comment to see the full error message
      (authority, idx) => ({
        medicalAuthorityId: authority.id,
        position: idx
      })
    );
  }

  if (data && data.organization && data.organization.parent) {
    // @ts-expect-error TS(2339): Property 'parentOrganization' does not exist on ty... Remove this comment to see the full error message
    formData.parentOrganization = data.organization.parent.id;
  }

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} background="tint2" borderBottom display="flex">
        <Heading size={500}>
          {organization ? 'Update organization' : 'Create new organization'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        {isOrgLoading ? (
          <Spinner size={16} />
        ) : (
          <FormBuilder
            key={organization?.id ?? -1}
            isSubForm
            initialValues={formData}
            form={OrganizationForm}
            errors={errors}
            isLoading={loading}
            onSubmit={(input: any) => {
              setErrors({});
              createOrUpdateOrganization({ variables: { input } });
            }}
          />
        )}
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateOrganization;
