import React from 'react';
import * as Yup from 'yup';
import DoseAmount from 'components/shared/DoseAmount';
import MedicationDataSource from 'components/shared/MedicationDataSource';
import EquipmentFunctionDataSource from 'components/shared/EquipmentFunctionDataSource';
import EquipmentDataSource from 'components/shared/EquipmentDataSource';
import PatientAgeConditionOfUse from 'components/shared/DoseConditionOfUse/PatientAge';
import EntryCriteria from 'components/shared/EntryCriteria';
import DisplayDoseAmount, {
  DisplayDoseUnit
} from 'components/shared/DoseAmount/DisplayDoseAmount';
import { MedicationRoutesWithLabels } from '@/constants/medications';
import { CalendarUnits } from 'constants/units';
import { formatEnumToOptions } from 'lib/formHelpers';
import { formatAgeRange } from 'lib/protocols';
import MedicationAmount, {
  AmountSchema
} from 'components/shared/MedicationAmount';
import DoseRepeatIntervalInput from './DoseRepeatIntervalInput';
import DoseRepeatInformation from './DoseRepeatInformation';
import FilterConditionsForm from './filterConditionForm';

const RepeatSchema = Yup.object().shape({
  requiresMedicalControlAuthorizationFromRepeat: Yup.number()
    .min(1)
    .nullable(),
  interval: Yup.object()
    .shape({
      value: Yup.string()
        .min(1)
        .max(9999)
        .nullable(),
      minValue: Yup.string()
        .min(1)
        .nullable(),
      maxValue: Yup.string()
        .min(1)
        .max(9999)
        .nullable(),
      unit: Yup.string()
        .required()
        .nullable()
    })
    .nullable(),
  numberOfTimes: Yup.number()
    .min(1)
    .nullable()
});

const DoseSchema = Yup.object().shape({
  routes: Yup.array()
    .required('You must select one or more routes for this dose')
    .nullable(),
  units: Yup.array()
    .required('You must add one or more medication / equipment')
    .nullable(),
  infusionRateInSeconds: Yup.number()
    .required('Enter in an infusion rate')
    .nullable(),
  supplementalDisplayText: Yup.string()
    .max(55)
    .nullable()
});

const UnitSchema = Yup.object().shape({
  medicationId: Yup.string().nullable(),
  equipmentFunctionId: Yup.string().nullable(),
  medicationOrEquipment: Yup.string()
    .nullable()
    .when(['medicationId', 'equipmentFunctionId'], {
      is: null || undefined,
      then: Yup.string().required('Must specify either medication or equipment')
    }),
  amount: Yup.object()
    .shape({
      unit: Yup.string().required('You must specify a unit for an amount')
    })
    .required('You must enter in an amount')
    .nullable(),
  maxAmount: AmountSchema.nullable()
});

const ConditionOfUseAgeRangeSchema = Yup.object().shape({
  unit: Yup.string()
    .required()
    .nullable(),
  minValue: Yup.number().required(),
  maxValue: Yup.number().required()
});

const DoseForm = ({ organizationId }: any) => ({
  type: 'listOfSubFields',
  name: 'doses',
  label: 'Doses',
  formTitle: 'Manage Dose',
  description: 'Discreet doses that are a part of this step',
  titleExtractor: (dose: any) => <DisplayDoseAmount {...dose} />,
  reorderable: true,
  initialState: {
    routes: [],
    requiresMedicalControlAuthorization: false,
    repeatInterval: null
  },
  validationSchema: DoseSchema,
  fields: [
    {
      name: 'requiresMedicalControlAuthorization',
      label: 'Requires Medical Control Authorization?',
      type: 'switch',
      description:
        'Whether or not this dose requires medical control authorization'
    },
    {
      type: 'listOfSubFields',
      name: 'units',
      label: 'Medication / Equipment',
      description: 'The amount to be given',
      titleExtractor: (unit: any) => <DisplayDoseUnit {...unit} />,
      validationSchema: UnitSchema,
      reorderable: true,
      initialState: {
        maxAmount: null,
        amount: null
      },
      fields: [
        {
          type: 'segmentedControl',
          name: 'medicationOrEquipment',
          label: 'What does this dose refer to?',
          defaultOption: 'medicationId',
          options: [
            {
              name: 'medicationId',
              label: 'Medication',
              type: 'asyncSelect',
              placeholder: 'Select a medication (if any)',
              dataSource: (render: any) => (
                <MedicationDataSource
                  render={render}
                  organizationId={organizationId}
                  filters={{ publishedState: 'DRAFT' }}
                />
              )
            },
            {
              name: 'equipmentFunctionId',
              label: 'Equipment',
              type: 'asyncSelect',
              placeholder: 'Select an equipment function (if any)',
              dataSource: (render: any) => (
                <EquipmentFunctionDataSource
                  render={render}
                  organizationId={organizationId}
                  filters={{ publishedState: 'DRAFT' }}
                />
              )
            }
          ]
        },
        {
          type: DoseAmount,
          name: 'amount',
          label: 'Amount',
          description:
            "The amount of the dose to give, leave the per amount blank if it's not needed"
        },
        {
          name: 'maxAmount',
          label: 'Maximum Total Amount',
          description:
            'The maximum amount of medication a patient can be given, including all repeats',
          type: MedicationAmount
        }
      ]
    },
    {
      name: 'routes',
      label: 'Routes',
      description: 'Select all that apply',
      type: 'multiSelect',
      values: formatEnumToOptions(MedicationRoutesWithLabels)
    },
    {
      name: 'infusionRateInSeconds',
      label: 'Infusion rate (in seconds)',
      description: 'The rate of infusion for this dose – 0 is a bolus',
      placeholder: '0',
      type: 'number'
    },
    {
      name: 'administeringEquipment',
      label: 'Administering Equipment',
      description:
        'The piece of equipment that should be used to administer this dose. Note: can only be an equipment with sizes.',
      type: 'asyncSelect',
      dataSource: (render: any) => (
        <EquipmentDataSource
          render={render}
          organizationId={organizationId}
          filters={{ publishedState: 'DRAFT', hasSizes: true }}
        />
      )
    },
    {
      name: 'repeatInformation',
      label: 'Repeat Information',
      description: `
      Specify repeat information for this dose here, including how many times it
      repeats, the interval between repeats, and if the repeat needs medical control
      authorization.
      `,
      type: 'subFields',
      formTitle: 'Repeat Information',
      validationSchema: RepeatSchema,
      renderPreview: (repeatInfo: any) =>
        repeatInfo && <DoseRepeatInformation {...repeatInfo} />,
      fields: [
        {
          type: 'segmentedControl',
          name: 'repeatType',
          label: 'What kind of repeat is this?',
          defaultOption: 'numberOfTimes',
          options: [
            {
              name: 'numberOfTimes',
              label: 'Fixed number of times',
              description: 'How many times does this dose repeat?',
              placeholder: 'Repeat N times',
              type: 'number'
            },
            {
              name: 'interval',
              label: 'On an interval',
              type: DoseRepeatIntervalInput
            },
            {
              name: 'asNecessary',
              label: 'As necessary',
              description: 'How many times does this dose repeat?',
              type: 'hidden',
              selectedValue: true
            }
          ]
        },
        {
          name: 'requiresMedicalControlAuthorizationFromRepeat',
          label: 'Requires authorization from repeat starting at:',
          description: `
          This dose can be repeated, but requires medical control authorization from the repeating
          starting at the number specified here.
          `,
          type: 'number',
          placeholder: 2
        }
      ]
    },
    {
      type: 'subFields',
      name: 'entryCriteria',
      label: 'Conditions of use',
      formTitle: 'Dose Conditions of Use',
      validationSchema: null,
      description:
        'Stipulates any conditions of use that should be highlighted when presenting this dose',
      initialState: {
        notes: [],
        patientAgeRanges: []
      },
      renderPreview: (entryCriteria: any) =>
        entryCriteria && <EntryCriteria {...entryCriteria} />,
      fields: [
        ...[FilterConditionsForm],
        {
          type: 'listOfSubFields',
          formTitle: 'Patient Age Range',
          label: 'Patient age ranges',
          name: 'patientAgeRanges',
          titleExtractor: formatAgeRange,
          validationSchema: ConditionOfUseAgeRangeSchema,
          initialState: {
            unit: CalendarUnits.YEAR
          },
          fields: [
            {
              type: PatientAgeConditionOfUse
            }
          ]
        },
        {
          type: 'listOf',
          name: 'notes',
          label: 'Unstructured conditions of use',
          description:
            'Any generic unstructured conditions that should be considered when administering this dose',
          subFieldType: 'text'
        }
      ]
    },
    {
      name: 'supplementalDisplayText',
      label: 'Supplemental display text',
      description:
        'Any text that should be displayed on the dosing card when showing this dose. Please keep this short and to vital text only.',
      type: 'text'
    },
    {
      name: 'note',
      label: 'Notes',
      description: 'Any notes associated with this dose',
      type: 'textarea'
    }
  ]
});

export default DoseForm;
