import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Badge, Spinner, Table, Button } from 'evergreen-ui';
import format from 'date-fns/format';
import { formatCents } from 'lib/subscription';
import { INVOICES } from './queries';

const InvoiceList = ({ organization }: any) => {
  const { data, loading } = useQuery(INVOICES, {
    variables: { organizationId: organization.id },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <Spinner size={16} />;
  }

  const invoices = get(data, 'invoicesForOrganization', []);

  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Date</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Invoice #</Table.TextHeaderCell>
        <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
        <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0} />
      </Table.Head>
      <Table.Body>
        {invoices.map((invoice: any) => (
          <Table.Row>
            <Table.TextCell>
              {format(invoice.date, 'MM/DD/YYYY')}
            </Table.TextCell>
            <Table.Cell>
              <Badge>{!invoice.paid ? 'Unpaid' : 'Paid'}</Badge>
            </Table.Cell>
            <Table.TextCell>{invoice.id}</Table.TextCell>
            <Table.TextCell>${formatCents(invoice.amountDue)}</Table.TextCell>
            <Table.Cell flexBasis={80} flexShrink={0} flexGrow={0}>
              <Button
                is="a"
                href={invoice.hostedInvoiceUrl}
                target="_blank"
                marginLeft="auto"
                appearance="minimal"
              >
                View
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default InvoiceList;
