import React from 'react';
import { Heading, Pane, Paragraph, Icon, Link } from 'evergreen-ui';
import { SUPPORT_EMAIL } from '../../../constants';

const InviteNotFound = () => (
  <Pane>
    <Icon icon="warning-sign" size={32} marginBottom={8} color="warning" />

    <Heading size={600} marginBottom={8}>
      Invite not found
    </Heading>

    <Paragraph marginBottom={16}>
      You might have already accepted this invitation, or it was revoked by your
      system administrator. If you already accepted the invite, look for a
      welcome email for next steps.
    </Paragraph>

    <Paragraph color="muted" size={400}>
      If you get stuck email us at{' '}
      <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link> or see our{' '}
      <Link href="https://murumed.com/help">help articles</Link>.
    </Paragraph>
  </Pane>
);

export default InviteNotFound;
