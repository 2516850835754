import React, { useState } from 'react';
import {
  BackButton,
  Button,
  toaster,
  Pane,
  Heading,
  Strong
} from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import RelativeDate from 'components/shared/RelativeDate';
import DeleteDialog from 'components/shared/DeleteDialog';
import EntryCriteria from 'components/shared/EntryCriteria';
import ProtocolStepList, {
  PROTOCOL_STEPS
} from 'components/organizations/ProtocolStepList';
import NewStepDialog from 'components/organizations/CreateOrUpdateProtocolStep/NewStepDialog';
import { protocolName } from 'lib/protocols';
import { protocolPath, protocolSetPath, protocolStepPath } from 'lib/urls';
import { generateBreadcrumbs } from 'lib/protocols';

const DELETE_STEP = gql`
  mutation DeleteStep($input: DeleteStepForProtocolInput!) {
    result: deleteStepForProtocol(input: $input) {
      protocolStep {
        id
      }
    }
  }
`;

const DUPLICATE_STEP = gql`
  mutation DuplicateStep($input: DuplicateStepForProtocolInput!) {
    result: duplicateStepForProtocol(input: $input) {
      protocolStep {
        id
      }
    }
  }
`;

export default ({
  history,
  organization,
  isViewOnly,
  protocol,
  protocolSet
}: any) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const variables = {
    organizationId: organization.id,
    protocolId: protocol.id
  };

  const refetchQuery = {
    query: PROTOCOL_STEPS,
    variables
  };

  const [deleteStep, { loading: isDeleteLoading }] = useMutation(DELETE_STEP, {
    refetchQueries: [refetchQuery],
    onCompleted: () => {
      toaster.success('Protocol step deleted');
      setShowDeleteDialog(false);
    }
  });

  const [duplicateStep] = useMutation(DUPLICATE_STEP, {
    refetchQueries: [refetchQuery],
    onCompleted: () => {
      toaster.success('Protocol step duplicated');
    }
  });

  const isSubprotocol = !!protocol.parent;

  // Conditional back based on whether the user is in a subprotocol or not
  const backToProtocols = () => {
    history.push(
      isSubprotocol
        ? `${protocolPath(
            organization,
            protocolSet,
            protocol.parent
          )}/subprotocols`
        : `${protocolSetPath(organization, protocolSet)}/protocols`
    );
  };

  const onSubProtocolsClick = isSubprotocol
    ? null
    : () =>
        history.push(
          `${protocolPath(organization, protocolSet, protocol)}/subprotocols`
        );

  return (
    <>
      <PageHeader
        title={protocolName(protocol)}
        subtitle={
          <>
            Part of <Strong>{protocolSet.name}</Strong>, last edited{' '}
            <RelativeDate textSize={400} date={protocol.updatedAt} />
          </>
        }
        renderButtons={
          <>
            <BackButton marginLeft="auto" onClick={backToProtocols}>
              Back to Protocols
            </BackButton>
            <Button
              marginLeft={8}
              onClick={() => setShowCreateDialog(true)}
              iconBefore="add"
              appearance="primary"
              disabled={isViewOnly}
            >
              New Step
            </Button>
          </>
        }
        breadcrumbs={generateBreadcrumbs(organization, protocolSet, protocol)}
      />

      {protocolSet.entryCriteria && (
        <Pane marginBottom={24}>
          <Heading size={100} marginBottom={8}>
            Entry criteria (inherited from {protocolSet.name})
          </Heading>
          <EntryCriteria {...protocolSet.entryCriteria} />
        </Pane>
      )}

      {protocol.entryCriteria && (
        <Pane marginBottom={24}>
          <Heading size={100} marginBottom={8}>
            Entry criteria (inherited from {protocol.name})
          </Heading>
          <EntryCriteria {...protocol.entryCriteria} />
        </Pane>
      )}

      <ProtocolStepList
        refetchQuery={refetchQuery}
        variables={variables}
        isViewOnly={isViewOnly}
        canReorderSteps={!isViewOnly}
        organization={organization}
        protocol={protocol}
        protocolSet={protocolSet}
        onEditClick={(step: any) =>
          history.push(
            protocolStepPath(organization, protocolSet, protocol, step)
          )
        }
        onDeleteClick={(step: any) => setShowDeleteDialog(step)}
        onDuplicateClick={(step: any) => {
          if (window.confirm('Are you sure you want to duplicate?')) {
            duplicateStep({ variables: { input: { id: step.id } } });
          }
        }}
        onReferencingProtocolsClick={() =>
          history.push(
            `${protocolPath(
              organization,
              protocolSet,
              protocol
            )}/referencing-protocols`
          )
        }
        onSubProtocolsClick={onSubProtocolsClick}
      />

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteStep({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />

      <NewStepDialog
        isShown={showCreateDialog}
        canCreateSubprotocolStep={!isSubprotocol}
        onClose={() => setShowCreateDialog(false)}
        onConfirm={(type: any) =>
          history.push(
            `${protocolPath(
              organization,
              protocolSet,
              protocol
            )}/steps/new?stepType=${type}`
          )
        }
      />
    </>
  );
};
