import React from 'react';
import Helmet from 'react-helmet';
import { Switch } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Layout, { ContentPanel } from 'components/shared/Layout';
import { OrganizationFragment } from 'components/shared/fragments';
import AuthenticatedRoute from 'components/shared/AuthenticatedRoute';
import Sidebar from 'components/organizations/Sidebar';
import Dashboard from 'pages/organization/Dashboard';
import ListMembers from 'pages/organization/Members';
import { ResultsPage } from 'pages/organization/Search';
import { OverlappingAliases } from 'pages/organization/Tools';
import {
  ListPage as ListEquipment,
  CreatePage as CreateEquipment,
  ShowPage as ShowEquipment
} from 'pages/organization/Equipment';
import {
  ListPage as ListMedication,
  CreatePage as CreateMedication,
  ShowPage as ShowMedication
} from 'pages/organization/Medications';
import {
  ListPage as ListHospitals,
  EditPage as EditHospital
} from 'pages/organization/Hospitals';
import {
  ListPage as ListSources,
  ShowPage as ShowSource
} from 'pages/organization/Sources';
import {
  ListPage as ListProtocolSets,
  ShowPage as ShowProtocolSet
} from 'pages/organization/ProtocolSets';
import { ListPage as ListCredentials } from 'pages/organization/Credentials';
import { ListPage as ListAppDashboardItems } from 'pages/organization/AppDashboardItems';
import { ListPage as ListHospitalCapabilities } from 'pages/organization/HospitalCapabilities';
import { ListPage as ListDirectoryContacts } from 'pages/organization/DirectoryContacts';
import { EditPage as EditProfile } from 'pages/organization/Profile';
import { ListPage as ListChildOrgs } from 'pages/organization/ChildOrganizations';
import ContinueInApp from 'pages/organization/ContinueInApp';
import ManageBilling from 'pages/organization/ManageBilling';
import { currentUserSelector } from 'redux/global';
import { useCurrentOrganizationDispatch } from './CurrentOrganizationContext';

export const ORGANIZATION = gql`
  query Organization($slug: String!) {
    organization(slug: $slug) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;

const OrganizationPage = ({ match }: any) => {
  const { params, url } = match;
  const { slug } = params;
  const setOrganizationId = useCurrentOrganizationDispatch();
  const {
    data: { organization } = {},
    loading,
    error,
    refetch: refetchOrganization
  } = useQuery(ORGANIZATION, {
    variables: { slug },
    fetchPolicy: 'network-only',
    onCompleted: ({
      organization
    }: // @ts-expect-error TS(2571): Object is of type 'unknown'.
    any) => setOrganizationId(organization.id)
  });

  const currentUser = useSelector(currentUserSelector);

  if (error) return null;

  return (
    <Layout>
      {!loading && (
        <>
          <Sidebar organization={organization} currentUser={currentUser} />
          <Helmet>
            <title>{organization.name} | Muru</title>
          </Helmet>
          <ContentPanel>
            <>
              <AuthenticatedRoute
                exact
                path={`${url}/`}
                organization={organization}
                component={Dashboard}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/search`}
                organization={organization}
                refetchOrganization={refetchOrganization}
                component={ResultsPage}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/overlapping-aliases`}
                organization={organization}
                refetchOrganization={refetchOrganization}
                component={OverlappingAliases}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/members`}
                organization={organization}
                refetchOrganization={refetchOrganization}
                component={ListMembers}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/equipment`}
                organization={organization}
                component={ListEquipment}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/medications`}
                organization={organization}
                component={ListMedication}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/hospitals`}
                organization={organization}
                component={ListHospitals}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/sources`}
                organization={organization}
                component={ListSources}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/protocol-sets`}
                organization={organization}
                component={ListProtocolSets}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/credentials`}
                organization={organization}
                component={ListCredentials}
              />

              <AuthenticatedRoute
                exact
                path={`${url}/dashboard-items`}
                organization={organization}
                component={ListAppDashboardItems}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/hospital-capabilities`}
                organization={organization}
                component={ListHospitalCapabilities}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/directory-contacts`}
                organization={organization}
                component={ListDirectoryContacts}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/billing`}
                organization={organization}
                component={ManageBilling}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/profile`}
                organization={organization}
                component={EditProfile}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/continue-in-app`}
                organization={organization}
                component={ContinueInApp}
              />
              <AuthenticatedRoute
                exact
                path={`${url}/child-agencies`}
                organization={organization}
                component={ListChildOrgs}
              />
              <Switch>
                <AuthenticatedRoute
                  exact
                  path={`${url}/equipment/new`}
                  organization={organization}
                  component={CreateEquipment}
                />
                <AuthenticatedRoute
                  path={`${url}/equipment/:id`}
                  organization={organization}
                  component={ShowEquipment}
                />
                <AuthenticatedRoute
                  exact
                  path={`${url}/medications/new`}
                  organization={organization}
                  component={CreateMedication}
                />
                <AuthenticatedRoute
                  path={`${url}/medications/:id`}
                  organization={organization}
                  component={ShowMedication}
                />
                <AuthenticatedRoute
                  path={`${url}/sources/:id`}
                  organization={organization}
                  component={ShowSource}
                />
                <AuthenticatedRoute
                  path={`${url}/protocol-sets/:id`}
                  organization={organization}
                  component={ShowProtocolSet}
                />
                <AuthenticatedRoute
                  exact
                  path={`${url}/hospitals/:id`}
                  organization={organization}
                  component={EditHospital}
                />
              </Switch>
            </>
          </ContentPanel>
        </>
      )}
    </Layout>
  );
};

export default OrganizationPage;
