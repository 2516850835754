import fetch from 'cross-fetch';
import { get } from 'lodash';
import differenceInMinutes from 'date-fns/difference_in_minutes';

const deployFile = '/info.json';

// Store as a global so that we can mutate
// @ts-expect-error TS(2339): Property '__BUILD_STATUS__' does not exist on type... Remove this comment to see the full error message
let status = window.__BUILD_STATUS__;
let lastCheckTime: any;

function fetchUpdateFile() {
  return fetch(deployFile).then((file: any) => file.json());
}

export async function checkForUpdates(onUpdateAvailable: any) {
  const now = new Date();
  const minsSinceLast = differenceInMinutes(now, lastCheckTime);

  // Check at most once every 5 minutes
  if (minsSinceLast && minsSinceLast < 5) {
    return;
  }

  try {
    const nextStatus = await fetchUpdateFile();
    const commitRef = get(status, 'commitRef');
    const nextCommitRef = get(nextStatus, 'commitRef');

    if (commitRef !== nextCommitRef) {
      onUpdateAvailable();
      status = nextStatus;
    }

    lastCheckTime = now;
  } catch (e) {
    console.error(e);
  }
}
