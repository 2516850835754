import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';

const SUBPROTOCOLS_LIST = gql`
  query SubprotocolsForProtocolQuery($protocolId: ID!) {
    protocol: node(id: $protocolId, type: PROTOCOL) {
      ... on Protocol {
        id
        subprotocols {
          id
          name
        }
      }
    }
  }
`;

const SubprotocolDataSource = ({ render, protocolId, filters = {} }: any) => {
  const { data, loading } = useQuery(SUBPROTOCOLS_LIST, {
    variables: { filters, protocolId },
    fetchPolicy: 'network-only'
  });

  const items = get(data, 'protocol.subprotocols', []).map(
    (subprotocol: any) => ({
      label: subprotocol.name,
      value: subprotocol.id
    })
  );

  return render({
    items,
    isLoading: loading
  });
};

export default SubprotocolDataSource;
