import gql from 'graphql-tag';

export const SEARCH_CONTENT = gql`
  query Search($input: DeterministicSearchForEntitiesInput!) {
    results: deterministicSearchForEntities(input: $input) {
      ... on ProtocolSet {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
      }
      ... on Protocol {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
        protocolSet {
          id
          name
        }
      }
      ... on Medication {
        id
        aliases
        brandName
        genericName
        updatedAt
        lastPublishedAt
      }
      ... on Equipment {
        id
        aliases
        name
        updatedAt
        lastPublishedAt
      }
      ... on EquipmentFunction {
        id
        aliases
        name
        updatedAt
        lastPublishedAt
        equipment {
          id
          name
        }
      }
      ... on Molecule {
        id
        name
        aliases
        updatedAt
      }
      ... on Alias {
        id
        key
        references
        aliases
        updatedAt
      }
      ... on Hospital {
        id
        name
        aliases
        updatedAt
        lastPublishedAt
        authorities {
          authority {
            id
            name
          }
          assignedCapabilities {
            id
            name
          }
        }
      }
    }
  }
`;
