import React from 'react';
import {
  Pane,
  Strong,
  Heading,
  Text,
  TextInput,
  FormFieldValidationMessage
} from 'evergreen-ui';
import { formatCents, PlanIntervalLabels } from 'lib/subscription';

const ManageSeatQuantities = ({
  minimumSeatCount,
  seatCount,
  onSeatCountChange,
  invalidSeatCount,
  perSeatCost = 800,
  interval = 'MONTH'
}: any) => {
  return (
    <Pane marginBottom={16} borderBottom paddingBottom={16}>
      <Pane display="flex" alignItems="center">
        <TextInput
          type="number"
          value={seatCount}
          min={minimumSeatCount}
          width={50}
          name="totalSeats"
          onChange={(e: any) =>
            onSeatCountChange(parseInt(e.target.value || 0))
          }
        />
        <Text marginLeft={8}>
          @ <Strong>${formatCents(perSeatCost)}</Strong>/
          {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {PlanIntervalLabels[interval]}
        </Text>

        <Pane marginLeft="auto" textAlign="right">
          <Heading size={100} marginBottom={0} lineHeight={1}>
            Total
          </Heading>
          <Text display="block" lineHeight={1}>
            <Strong size={400}>${formatCents(perSeatCost * seatCount)}</Strong>/
            {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {PlanIntervalLabels[interval]}
          </Text>
        </Pane>
      </Pane>
      {invalidSeatCount && (
        <FormFieldValidationMessage marginTop={8}>
          You can't enter a lower seat count than what's currently assigned (
          {minimumSeatCount})
        </FormFieldValidationMessage>
      )}
    </Pane>
  );
};

export default ManageSeatQuantities;
