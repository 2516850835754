export const colors = {
  lightBackground: '#f5f8fa'
};

export const spacing = {
  xs: '3px',
  s: '5px',
  m: '10px',
  l: '15px',
  xl: '20px'
};
