import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import queryString from 'query-string';
import SideSheet from 'components/shared/SideSheet';
import PageHeader from 'components/shared/PageHeader';
import SourceList, { SOURCE_LIST } from 'components/organizations/SourceList';
import CreateOrUpdateSource from 'components/organizations/CreateOrUpdateSource';
import DeleteDialog from 'components/shared/DeleteDialog';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

const DELETE_SOURCE = gql`
  mutation DeleteSource($input: DeleteSourceForOrganizationInput!) {
    result: deleteSourceForOrganization(input: $input) {
      source {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters,
    organizationId: organization.id
  };

  const refetchQuery = {
    query: SOURCE_LIST,
    variables
  };

  const [deleteSource, { loading: isDeleteLoading }] = useMutation(
    DELETE_SOURCE,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.source) {
          toaster.success('Source has successfully been deleted');
        } else {
          toaster.danger('Could not delete source', {
            description: 'There are likely active versions referenced still'
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage sources"
        subtitle="Sources represented the original content that was gathered by Muru to power content within your organization."
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New Source
          </Button>
        }
      />

      <SourceList
        organization={organization}
        onEditClick={(source: any) => setShowForm(source)}
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        onDeleteClick={(source: any) => setShowDeleteDialog(source)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateSource
          source={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Source saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
          deleteSource({ variables: { input: { id: showDeleteDialog.id } } })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
