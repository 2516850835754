import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Alert, Pane, Heading, Text, Link, Paragraph } from 'evergreen-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import BrandedLayout from 'components/shared/BrandedLayout';
import StyledLogo from 'components/shared/Layout/MuruLogo';
import LoginForm from 'components/accounts/LoginForm';
import { parseRedirectPath } from 'lib/urls';

function Login({ history, location }: any) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const redirectPath = parseRedirectPath(location);

  return (
    <BrandedLayout>
      <Pane width={'100%'} maxWidth={380} textAlign="center">
        <StyledLogo />
        <Helmet>
          <title>{t('login.title')}</title>
        </Helmet>

        <Heading size={600}>{t('login.welcome')}</Heading>
        <Text color="muted">{t('login.please sign in')}</Text>
        {/* @ts-expect-error Invalid state */}
        {state && state.message && (
          <Alert
            intent="none"
            // @ts-expect-error Invalid state
            title={state.message}
            textAlign="left"
            marginTop={16}
          />
        )}
        <LoginForm
          data-testid="login-form"
          onLogin={() => history.push(redirectPath)}
        />

        <Paragraph color="muted" marginTop={16}>
          <Link is={RouterLink} to={`/forgot?from=${redirectPath}`}>
            Forgot your password?
          </Link>
        </Paragraph>

        <Paragraph color="muted" marginTop={4}>
          Don't have an account?{' '}
          <Link is={RouterLink} to={`/signup?from=${redirectPath}`}>
            Get started
          </Link>
        </Paragraph>
      </Pane>
    </BrandedLayout>
  );
}

export default Login;
