import React from 'react';
import ChildOrganizationList from 'components/organizations/ChildOrganizationList';
import PageHeader from 'components/shared/PageHeader';

export default ({ organization }: any) => {
  return (
    <>
      <PageHeader
        title="Child organizations"
        subtitle="Showing all children that belong to this organization"
      />

      <ChildOrganizationList organization={organization} />
    </>
  );
};
