import React from 'react';
import { flatMap } from 'lodash';
import { Redirect } from 'react-router-dom';
import { Tabs } from 'components/organizations/Sidebar/OrgSidebar';
import { hasPermission } from 'lib/organizations';

const fallbackFeature = { path: '/continue-in-app' };

const Dashboard = ({ organization }: any) => {
  // Find the first applicable feature for this organization
  const firstFeature =
    flatMap(Tabs, item => item).find(
      ({ permission, path }) =>
        (permission ? hasPermission(organization, permission) : true) &&
        path !== '/inventory-onboarding'
    ) || fallbackFeature;

  return <Redirect to={`/orgs/${organization.slug}${firstFeature.path}`} />;
};

export default Dashboard;
