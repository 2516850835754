import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Avatar, Table, Text, Pane, Badge, Spinner } from 'evergreen-ui';
import gql from 'graphql-tag';
import RelativeDate from 'components/shared/RelativeDate';
import PaginationControls from 'components/shared/PaginationControls';
import { UserFragment, PageInfoFragment } from 'components/shared/fragments';
import SearchListControl from 'components/shared/SearchListControl';

const USERS_QUERY = gql`
  query ListUsers(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterUsersInput
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
    ) {
      edges {
        node {
          ...User
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${UserFragment}
  ${PageInfoFragment}
`;

const UsersList = ({
  navigateToUser,
  page,
  filters = {},
  onFilterUpdate,
  onPageInfoUpdate
}: any) => {
  const { data, loading, error } = useQuery(USERS_QUERY, {
    variables: { ...page, filters },
    fetchPolicy: 'network-only'
  });
  const users = get(data, 'users.edges', []);

  return (
    <Pane>
      <SearchListControl
        placeholder="Search by name or email"
        initialValue={filters.nameOrEmail}
        onSubmit={(nameOrEmail: any) => onFilterUpdate({ nameOrEmail })}
      />
      <Pane background="white">
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>Role</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created</Table.TextHeaderCell>
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {users.map(({ node: user }) => (
                <Table.Row
                  key={user.id}
                  isSelectable
                  onSelect={() => navigateToUser(user.id)}
                >
                  <Table.Cell>
                    <Avatar name={user.name} />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                      {user.name}
                    </Text>
                  </Table.Cell>
                  <Table.TextCell>{user.email}</Table.TextCell>
                  <Table.Cell display="flex" alignItems="center">
                    {user.role && <Badge color="neutral">{user.role}</Badge>}
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={user.insertedAt} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>

        {loading && (
          <Pane margin={16}>
            <Spinner size={16} />
          </Pane>
        )}
      </Pane>

      <PaginationControls
        pageInfo={data && data.users && data.users.pageInfo}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default UsersList;
