import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { getAuthToken } from 'lib/auth';
import { page } from 'lib/analytics';
import WithCurrentUser from 'components/shared/WithCurrentUser';

const Authenticate = () => {
  const history = useHistory();
  const token = getAuthToken();

  useEffect(() => {
    page('Onboarding Already Authenticated');
  }, []);

  if (!token) {
    return <Redirect to="/onboarding/signup" />;
  }

  return (
    <WithCurrentUser
      shouldRefetch={true}
      onError={() => history.push('/onboarding/signup')}
    >
      <Redirect to="/onboarding/role" />
    </WithCurrentUser>
  );
};

export default Authenticate;
