import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Pane, Heading, Button, Paragraph, Link } from 'evergreen-ui';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import { currentUserSelector } from 'redux/global';
import {
  organizationSelector,
  organizationCredentialsSelector,
  planSelector
} from 'redux/onboarding';
import { onCompleted } from 'lib/formHelpers';
import { formatCents, IS_COVID_FREE_TRIAL } from 'lib/subscription';
import { JOIN_ORGANIZATION } from './queries';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';
import UpdateUsersCreditCard from 'components/shared/UpdateUsersCreditCard';
import { page } from 'lib/analytics';
import { CURRENT_USER_QUERY } from 'components/shared/queries';

const submitButtonProps = {
  appearance: 'primary',
  display: 'flex',
  justifyContent: 'center',
  height: 40,
  marginTop: 24,
  width: '100%'
};

const Individual = () => {
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const currentUser = useSelector(currentUserSelector);
  const organization = useSelector(organizationSelector);
  const credentials = useSelector(organizationCredentialsSelector);
  const plan = useSelector(planSelector);

  const [joinOrganization, { loading }] = useMutation(JOIN_ORGANIZATION, {
    variables: {
      input: {
        planId: plan.id,
        organizationId: organization.id,
        credentialId: credentials.credentialId,
        medicalLicense: {
          number: credentials.licenseNumber,
          issuingState: credentials.issuingState
        }
      }
    },
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    awaitRefetchQueries: true,
    onCompleted: (response: any) =>
      onCompleted({
        response,
        setErrors,
        onComplete: () => {
          history.push('/onboarding/individual/get-started');
        }
      })
  });

  useEffect(() => {
    page('Onboarding Individual Signup', {
      selected_agency_id: organization.id,
      selected_plan_id: plan.id,
      selected_plan_interval: plan.interval,
      selected_plan_type: plan.type
    });
  }, [organization, plan]);

  if (!currentUser) {
    return <Redirect to="/onboarding/auth" />;
  }

  const totalCost = plan.amountInCents + plan.seatAmountInCents;
  const isFreeTrial = !!plan.trialPeriodDays;

  return (
    <Pane>
      <Helmet>
        <title>Sign up for an individual plan</title>
      </Helmet>
      <Pane textAlign="center" marginBottom={24}>
        <MuruLogo />

        <Heading size={600} marginBottom={4}>
          Muru is free
        </Heading>

        {(IS_COVID_FREE_TRIAL || isFreeTrial) && (
          <Pane
            background="blueTint"
            border="muted"
            marginTop={12}
            paddingY={8}
            paddingX={12}
          >
            <Paragraph color="muted">
              Muru has multiple tiers of membership. In support of EMS during
              COVID-19, Muru's individual premium tier has and will remain free
              for 2020-2024. Next year, you will be given the option to enter a
              credit card and stay on the premium version or be automatically
              kept on the NYS Statewide plan. This can be paid for by your
              agency or by each individual provider. Until then, please enjoy
              all Muru has to offer free of charge.
            </Paragraph>
            <Paragraph color="muted" marginTop={12}>
              Thank you for your service,
            </Paragraph>
            <Paragraph color="muted" marginTop={12}>
              The medics and software developers of Muru!
            </Paragraph>
          </Pane>
        )}

        <Pane textAlign="left" marginTop={24}>
          {errors && (
            <Pane marginBottom={16}>
              <Heading color="#BF0E08" size={400}>
                There was a problem, please try again
              </Heading>
              <ValidationErrorMessage error={errors} />
            </Pane>
          )}

          {plan.requiresCreditCardForSignUp ? (
            <UpdateUsersCreditCard
              isLoading={loading}
              buttonTitle="Get Muru"
              buttonProps={submitButtonProps}
              onComplete={() => {
                setErrors(null);
                joinOrganization();
              }}
            />
          ) : (
            // @ts-expect-error Invalid size
            <Button
              {...submitButtonProps}
              isLoading={loading}
              onClick={() => {
                setErrors(null);
                joinOrganization();
              }}
            >
              Complete Signup
            </Button>
          )}
        </Pane>

        <Paragraph color="#A6B1BB" marginTop={16} size={300}>
          {isFreeTrial ? (
            <>
              Your membership will start today.{' '}
              <Link
                href="https://murumed.com/terms-of-service"
                target="_blank"
                size={300}
              >
                Terms of Service
              </Link>
              .
            </>
          ) : (
            <>
              Your membership will start today. I agree for Muru to charge me $
              {formatCents(totalCost)} and renew my subscription at the standard
              price every {plan.interval.toLowerCase()} according to the{' '}
              <Link
                href="https://murumed.com/terms-of-service"
                target="_blank"
                size={300}
              >
                Terms of Service
              </Link>{' '}
              until I cancel my subscription.
            </>
          )}
        </Paragraph>
      </Pane>
    </Pane>
  );
};

export default Individual;
