import React, { useState } from 'react';
import {
  Pane,
  Heading,
  Paragraph,
  TextInput,
  Select,
  SegmentedControl
} from 'evergreen-ui';
import { TimeUnitsWithLabels, TimeUnits } from 'constants/units';
import { formatEnumToOptions } from 'lib/formHelpers';
import ValidationErrorMessage from 'components/shared/ValidationErrorMessage';

const RepeatTypes = {
  FIXED: 'FIXED',
  RANGE: 'RANGE'
};

const initialState = {
  minValue: null,
  maxValue: null,
  value: null,
  unit: TimeUnits.SECOND
};

const IntervalOptions = [
  { label: 'Fixed amount of time', value: RepeatTypes.FIXED },
  { label: 'Varying amount of time', value: RepeatTypes.RANGE }
];

function determineRepeatType(repeatInterval: any) {
  if (repeatInterval && repeatInterval.minValue !== null) {
    return RepeatTypes.RANGE;
  }

  return RepeatTypes.FIXED;
}

const UnitOptions = formatEnumToOptions(TimeUnitsWithLabels);

const UnitSelect = ({ onBlur, onChange, value, name }: any) => (
  <Select name={name} onBlur={onBlur} onChange={onChange} value={value}>
    <option />
    {UnitOptions.map(({ label, value }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ))}
  </Select>
);

const DoseRepeatIntervalInput = ({
  name,
  label,
  description,
  validationMessage,
  value: repeatInterval,
  formikBag,
  onChange,
  onBlur,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const currentValue = repeatInterval || initialState;
  const [repeatType, setRepeatType] = useState(
    determineRepeatType(repeatInterval)
  );

  const setChanges = (name: any, selected: any) => {
    formikBag.setFieldTouched(name);
    formikBag.setFieldValue(name, selected);
  };

  return (
    <Pane marginBottom={24} borderBottom="muted" paddingBottom={8}>
      <Pane marginBottom={8}>
        <Heading size={400}>{label}</Heading>
        {description && <Paragraph color="muted">{description}</Paragraph>}
      </Pane>

      <Pane marginBottom={8}>
        <SegmentedControl
          height={24}
          options={IntervalOptions}
          value={repeatType}
          onChange={(value: any) => {
            setRepeatType(value);

            setChanges(`${name}.value`, null);
            setChanges(`${name}.minValue`, null);
            setChanges(`${name}.maxValue`, null);
          }}
          marginBottom={4}
        />

        {repeatType === RepeatTypes.FIXED && (
          <Pane marginTop={4}>
            <TextInput
              name={`${name}.value`}
              type="number"
              placeholder="Repeat every N"
              value={currentValue.value}
              onChange={onChange}
              width={220}
            />
            <UnitSelect
              name={`${name}.unit`}
              value={currentValue.unit}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Pane>
        )}

        {repeatType === RepeatTypes.RANGE && (
          <Pane marginTop={4}>
            <TextInput
              name={`${name}.minValue`}
              type="number"
              value={currentValue.minValue}
              placeholder="X"
              onChange={onChange}
              width={100}
            />
            <Heading
              marginLeft={8}
              marginRight={8}
              size={300}
              display="inline-block"
            >
              -
            </Heading>
            <TextInput
              name={`${name}.maxValue`}
              type="number"
              placeholder="Y"
              value={currentValue.maxValue}
              onChange={onChange}
              width={100}
            />
            <UnitSelect
              name={`${name}.unit`}
              value={currentValue.unit}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Pane>
        )}
      </Pane>

      <ValidationErrorMessage error={validationMessage} />
    </Pane>
  );
};

export default DoseRepeatIntervalInput;
