import React from 'react';
import {
  Pane,
  Heading,
  Button,
  Text,
  Paragraph,
  Icon,
  UnorderedList,
  ListItem
} from 'evergreen-ui';
import {
  formatCents,
  trialDurationMessage,
  PlanIntervalLabels
} from 'lib/subscription';

const IndividualPlan = ({
  title,
  description,
  icon,
  plan,
  alternatePlan,
  onPlanSelect,
  isSmall
}: any) => (
  <Pane
    width={isSmall ? 'auto' : 300}
    border
    hoverElevation={1}
    onClick={onPlanSelect}
    cursor="pointer"
    position="relative"
    marginBottom={isSmall ? 16 : 0}
  >
    <Pane flex="1" padding={12} paddingTop={24}>
      <Pane
        borderRadius={100}
        width={32}
        height={32}
        padding={4}
        border
        background="tint2"
        margin="auto"
      >
        <Icon icon={icon} color="muted" size={18} />
      </Pane>

      <Heading size={600} marginTop={16} marginBottom={4}>
        {title}
      </Heading>
      <Paragraph marginBottom={16} color="muted">
        {description}
      </Paragraph>

      <Text display="block" size={400} marginBottom={4}>
        {plan.trialPeriodDays && (
          <Text color="info" display="block">
            {trialDurationMessage(plan)}.
          </Text>
        )}
        ${formatCents(plan.amountInCents)} + $
        {formatCents(plan.seatAmountInCents)}
        {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        /agency/{PlanIntervalLabels[plan.interval]}
      </Text>

      {plan.interval === 'YEAR' && (
        <Text size={400} color="muted" display="block">
          <s>
            ${formatCents(alternatePlan.amountInCents * 12)} + $
            {formatCents(alternatePlan.seatAmountInCents * 12)}/agency per //
            {/*@ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {PlanIntervalLabels[plan.interval]}
          </s>
        </Text>
      )}

      <Button
        appearance="primary"
        onClick={onPlanSelect}
        justifyContent="center"
        width="100%"
        marginTop={8}
      >
        {plan.trialPeriodDays ? 'Try now for free' : 'Select plan'}
      </Button>
    </Pane>

    <Pane borderTop width="100%" textAlign="left" padding={12}>
      <Heading size={100} textAlign="center" marginTop={4}>
        What you get
      </Heading>

      <UnorderedList icon="tick" iconColor="success">
        <ListItem>Access to Muru on all your personal devices</ListItem>
        <ListItem>
          Your protocols, equipment, medications at all your agencies
        </ListItem>
        <ListItem>All hospitals for your region</ListItem>
        <ListItem>Dosing and med math</ListItem>
        <ListItem>Offline access</ListItem>
        <ListItem>Always up to date</ListItem>
      </UnorderedList>
    </Pane>
  </Pane>
);

export default IndividualPlan;
