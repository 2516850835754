import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Pane, TextInputField, majorScale } from 'evergreen-ui';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
});

const FormikForm = ({ onSubmit, loading }: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }: any) => (
        <Pane
          marginTop={16}
          textAlign="left"
          is={Form}
          data-testid="forgot-password-form"
          onSubmit={handleSubmit}
        >
          <TextInputField
            required
            isInvalid={!!errors.email}
            placeholder="john@socom.mil"
            type="email"
            name="email"
            label={t('common.email')}
            validationMessage={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            size={400}
            marginBottom={majorScale(2)}
          />
          <Button
            display="flex"
            width="100%"
            justifyContent="center"
            isLoading={loading}
            appearance="primary"
            type="submit"
            height={40}
          >
            {t('forgot_password.submit_btn')}
          </Button>
        </Pane>
      )}
    />
  );
};

export default FormikForm;
