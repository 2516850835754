import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const EQUIPMENT = gql`
  query EquipmentsDataSource(
    $filters: FilterOrganizationEquipmentInput!
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      equipments(first: 999, filters: $filters) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const EquipmentDataSource = ({ render, organizationId, filters = {} }: any) => {
  const { data, loading, error } = useQuery(EQUIPMENT, {
    variables: { filters, organizationId },
    fetchPolicy: 'network-only'
  });

  const items =
    !loading && !error
      ? // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
        data.organization.equipments.edges.map(({ node: equipment }) => ({
          label: equipment.name,
          value: equipment.id
        }))
      : [];

  return render({
    items,
    isLoading: loading
  });
};

export default EquipmentDataSource;
