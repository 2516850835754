import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import DeleteDialog from 'components/shared/DeleteDialog';
import DirectoryContactList, {
  DIRECTORY_CONTACTS_LIST,
  DELETE_DIRECTORY_CONTACT
} from 'components/organizations/DirectoryContactList';
import CreateOrUpdateDirectoryContact from 'components/organizations/CreateOrUpdateDirectoryContact';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters,
    organizationId: organization.id
  };

  const refetchQuery = {
    query: DIRECTORY_CONTACTS_LIST,
    variables
  };

  const [deleteDirectoryContact, { loading: isDeleteLoading }] = useMutation(
    DELETE_DIRECTORY_CONTACT,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.directoryContact) {
          toaster.success('Directory contact successfully deleted');
        } else {
          toaster.danger('Could not delete Directory contact');
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Directory Contacts"
        subtitle="Manage the list of contacts that are available to your medics"
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft={4}
          >
            New Directory Contact
          </Button>
        }
      />

      <DirectoryContactList
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        organization={organization}
        onEditClick={(directoryContact: any) => setShowForm(directoryContact)}
        onDeleteClick={(directoryContact: any) =>
          setShowDeleteDialog(directoryContact)
        }
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateDirectoryContact
          directoryContact={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Directory contact saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteDirectoryContact({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
