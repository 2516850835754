import React from 'react';
import SubprotocolDataSource from 'components/shared/SubprotocolDataSource';

const SubprotocolForm = ({ protocolId }: any) => ({
  name: 'subprotocol',
  label: 'Subprotocol',
  description: 'Which subprotocol does this step hand off to?',
  type: 'asyncSelect',
  dataSource: (render: any) => (
    <SubprotocolDataSource render={render} protocolId={protocolId} />
  )
});

export default SubprotocolForm;
