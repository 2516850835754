import gql from 'graphql-tag';
import { SubscriptionFragment } from 'components/shared/fragments';

export const INVOICES = gql`
  query ListInvoicesForOrganization($organizationId: ID!) {
    invoicesForOrganization(organizationId: $organizationId) {
      amountDue
      amountPaid
      amountRemaining
      date
      hostedInvoiceUrl
      id
      invoicePdf
      paid
    }
  }
`;

export const LIST_CREDIT_CARDS = gql`
  query ListCreditCardsForOrganization($organizationId: ID!) {
    creditCardsForOrganization(organizationId: $organizationId) {
      brand
      last4
      expMonth
      expYear
    }
  }
`;

export const UPDATE_SEATS = gql`
  mutation UpdateSeatsForOrganization(
    $input: UpdateSubscriptionForOrganizationInput!
  ) {
    result: updateSubscriptionForOrganization(input: $input) {
      errors {
        field
        message
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragment}
`;
