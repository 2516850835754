import React from 'react';
import PageHeader from 'components/shared/PageHeader';
import OverlappingAliasList from 'components/organizations/OverlappingAliasList';

export default ({ organization }: any) => {
  const variables = {
    organizationId: organization.id
  };

  return (
    <>
      <PageHeader
        title="Overlapping Aliases"
        subtitle="Finds all places in your content where there are overlapping aliases"
      />

      <OverlappingAliasList organization={organization} variables={variables} />
    </>
  );
};
