import React from 'react';
import * as Yup from 'yup';
import PlanSelectorDataSource from 'components/shared/PlanSelectorDataSource';
import CredentialDataSource from 'components/shared/CredentialSelector/CredentialSelectorDataSource';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
    .nullable(),
  credentialId: Yup.string()
    .required()
    .nullable(),
  planId: Yup.string()
    .required()
    .nullable()
});

export const Form = ({ organizationId }: any) => ({
  name: 'addIndividual',
  validationSchema: Schema,
  fields: [
    {
      name: 'email',
      label: 'Email',
      description: 'Email of an existing user in system',
      placeholder: 'jane.doe@example.org',
      required: true
    },
    {
      name: 'credentialId',
      label: 'Credential level',
      description: 'Select the credential to apply',
      type: 'asyncSelect',
      dataSource: (render: any) => (
        <CredentialDataSource render={render} organizationId={organizationId} />
      )
    },
    {
      name: 'planId',
      label: 'Plan',
      description: 'The plan to put this user on',
      dataSource: (render: any) => (
        <PlanSelectorDataSource
          filters={{ type: 'INDIVIDUAL' }}
          render={render}
        />
      ),
      type: 'asyncSelect'
    },
    {
      name: 'coupon',
      label: 'Coupon code',
      description: 'Coupon code to apply (from Stripe)'
    }
  ]
});
