import React from 'react';
import { Pane, IconButton, Text } from 'evergreen-ui';
import { PAGE_SIZE } from 'lib/pagination';

const PaginationControls = ({ pageInfo, updatePageInfo }: any) => (
  <Pane
    marginTop={32}
    display="flex"
    position="fixed"
    bottom={0}
    right={0}
    left={260}
    padding={16}
    paddingLeft={24}
    paddingRight={24}
    borderTop="default"
    background="tint1"
    alignItems="center"
  >
    <Text size={300} color="muted">
      Showing {PAGE_SIZE} items per page
    </Text>

    <IconButton
      marginLeft="auto"
      marginRight={4}
      icon="chevron-left"
      disabled={!(pageInfo && pageInfo.hasPreviousPage)}
      onClick={() =>
        updatePageInfo({
          before: pageInfo.startCursor,
          last: PAGE_SIZE,
          first: undefined
        })
      }
    />
    <IconButton
      icon="chevron-right"
      disabled={!(pageInfo && pageInfo.hasNextPage)}
      onClick={() =>
        updatePageInfo({
          after: pageInfo.endCursor,
          first: PAGE_SIZE,
          last: undefined
        })
      }
    />
  </Pane>
);

export default PaginationControls;
