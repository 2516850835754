import gql from 'graphql-tag';

export const PLANS = gql`
  query AvailablePlans {
    currentPaymentPlans {
      id
      name
      interval
      amountInCents
      seatAmountInCents
      trialPeriodDays
      requiresCreditCardForSignUp
      type
    }
  }
`;
