import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import MuruLogo from 'components/shared/Layout/MuruLogo';
import LoadingScreen from 'components/shared/LoadingScreen';
import PlanSelector from 'components/onboarding/PlanSelector';
import onboardingSlice from 'redux/onboarding';
import { page, track } from 'lib/analytics';
import { PLANS } from './queries';

const { setPlan } = onboardingSlice.actions;

const PlanType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading } = useQuery(PLANS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    page('Onboarding Select Plan');
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Pane>
      <Helmet>
        <title>Choose your plan</title>
      </Helmet>
      <Pane textAlign="center" marginBottom={24} paddingTop={64}>
        <MuruLogo />

        <Heading size={600} marginBottom={8}>
          Get your EMS Assistant
        </Heading>

        <PlanSelector
          plans={data.currentPaymentPlans}
          onPlanSelect={(plan: any) => {
            track('Onboarding Selected Plan', {
              selected_plan_id: plan.id,
              selected_plan_name: plan.name,
              selected_plan_interval: plan.interval,
              selected_plan_type: plan.type
            });

            dispatch(setPlan(plan));
            setTimeout(() => history.push('/onboarding/auth'), 0);
          }}
        />

        <Pane marginTop={32}>
          <Heading size={600} marginBottom={24}>
            Frequently asked questions
          </Heading>

          <Pane textAlign="left" borderBottom marginTop={16} paddingBottom={16}>
            <Heading size={400}>What if I work at multiple agencies?</Heading>

            <Paragraph>
              You can add as many agencies as you like onto your individual
              subscription. The price is a flat $7/mo plus $1/mo for each agency
              that you add (or save 10% for yearly plans).
            </Paragraph>
          </Pane>

          <Pane textAlign="left" borderBottom marginTop={16} paddingBottom={16}>
            <Heading size={400}>
              My agency may buy me a subscription soon.
            </Heading>

            <Paragraph>
              If your agency buys you a subscription after you already purchased
              one, we'll refund you the remaining time.
            </Paragraph>
          </Pane>

          <Pane textAlign="left" borderBottom marginTop={16} paddingBottom={16}>
            <Heading size={400}>
              I want to add on another agency, but my original agency pays for
              me.
            </Heading>

            <Paragraph>
              Adding on additional agencies won’t interfere with your current
              agency subscription and you do not need their permission. You will
              only be billed personally for any additional agencies you add.
            </Paragraph>
          </Pane>

          <Pane textAlign="left" borderBottom marginTop={16} paddingBottom={16}>
            <Heading size={400}>Can I cancel my plan?</Heading>

            <Paragraph>
              You can cancel your plan at any time. We'll let you keep access
              until the end of the billing cycle.
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default PlanType;
