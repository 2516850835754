import React from 'react';
import { FormField, Combobox } from 'evergreen-ui';

const ComboboxField = ({
  name,
  label,
  required,
  description,
  validationMessage,
  formikBag,
  value,
  values,
  ...props
}: any) => {
  const selectedItem =
    value && values.find((item: any) => item.value === value);

  return (
    <FormField
      label={label}
      isRequired={required}
      description={description}
      validationMessage={validationMessage}
      width="100%"
      marginBottom={24}
    >
      <Combobox
        name={name}
        items={values}
        itemToString={(item: any) => (item ? item.label : '')}
        openOnFocus={true}
        onChange={(item: any) => {
          if (item) {
            formikBag.setFieldTouched(name);
            formikBag.setFieldValue(name, item.value);
          }
        }}
        selectedItem={selectedItem}
        placeholder={props.placeholder}
        width="100%"
      />
    </FormField>
  );
};

export default ComboboxField;
