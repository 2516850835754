import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import {
  Table,
  Text,
  Pane,
  IconButton,
  Spinner,
  Popover,
  Position,
  Badge
} from 'evergreen-ui';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import SortOrder, { Order } from 'components/shared/ListOrdering';
import { toName } from 'lib/medications';
import useIsChildOrganization from 'hooks/useIsChildOrganization';
import Menu from './Menu';
import PublishDialog from './PublishMedicationDialog';
import PublishAllDialog from './PublishAllMedicationsDialog';
import ToolsMenu from './ToolsMenu';
import { MEDICATION_LIST } from './queries';

const defaultOrderBy = {
  column: 'NAME',
  direction: Order.ASC
};

const MedicationList = ({
  organization,
  history,
  variables,
  filters,
  refetchQuery,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick,
  onListDosesClick
}: any) => {
  const [isShowingPublishDialog, showPublishDialog] = useState(false);
  const [isShowingPublishAllDialog, showPublishAllDialog] = useState(false);
  const isChildOrg = useIsChildOrganization();
  const { loading, data, error } = useQuery(MEDICATION_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const orderBy = filters.orderBy || defaultOrderBy;
  const medications = get(data, 'organization.medications.edges', []);

  return (
    <Pane>
      <Pane background="white">
        <Pane display="flex" alignItems="center">
          <SearchListControl
            placeholder="Search by medication name"
            initialValue={filters.name}
            onSubmit={(name: any) => onFilterUpdate({ name })}
          />

          <Pane marginLeft="auto">
            <Popover
              // @ts-expect-error TS(6133): 'close' is declared but its value is never read.
              content={({ close }: any) => (
                <ToolsMenu
                  onPublishAllClick={() => showPublishAllDialog(true)}
                />
              )}
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton
                disabled={isChildOrg}
                icon="more"
                height={24}
                appearance="minimal"
              />
            </Popover>
          </Pane>
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>
              <SortOrder
                column="NAME"
                label="Name"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell width={100} flex="none">
              Status
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="LAST_PUBLISHED_AT"
                label="Last Published"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <SortOrder
                column="UPDATED_AT"
                label="Last Updated"
                ordering={orderBy}
                onChange={(orderBy: any) => onFilterUpdate({ orderBy })}
              />
            </Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex="none" />
          </Table.Head>
          {!loading && !error && (
            <Table.Body>
              {/* @ts-expect-error TS(7053) */}
              {medications.map(({ node: medication }) => (
                <Table.Row key={medication.id} isSelectable>
                  <Table.Cell>
                    <Text size={300} fontWeight={500}>
                      {toName(medication)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell
                    width={100}
                    display="flex"
                    alignItems="center"
                    flex="none"
                  >
                    {medication.updatedAt === medication.lastPublishedAt ? (
                      <Badge color="green">Published</Badge>
                    ) : (
                      <Badge color="blue">Draft</Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {medication.lastPublishedAt ? (
                      <RelativeDate date={medication.lastPublishedAt} />
                    ) : (
                      <Text size={300}>N/A</Text>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <RelativeDate date={medication.updatedAt} />
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <Popover
                      content={
                        <Menu
                          onEditClick={() =>
                            history.push(
                              `/orgs/${organization.slug}/medications/${medication.id}`
                            )
                          }
                          onPublishClick={() => showPublishDialog(medication)}
                          onDeleteClick={() => onDeleteClick(medication)}
                          onListDosesClick={() => onListDosesClick(medication)}
                        />
                      }
                      position={Position.BOTTOM_RIGHT}
                    >
                      <IconButton
                        icon="more"
                        height={24}
                        appearance="minimal"
                        disabled={isChildOrg}
                      />
                    </Popover>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Pane>

      {loading && (
        <Pane margin={16}>
          <Spinner size={16} />
        </Pane>
      )}

      {!loading && !error && medications.length === 0 && <EmptyContent />}

      <PublishDialog
        medication={isShowingPublishDialog || {}}
        isShown={isShowingPublishDialog !== false}
        onCloseComplete={() => showPublishDialog(false)}
      />

      <PublishAllDialog
        organization={organization}
        refetchQuery={refetchQuery}
        isShown={isShowingPublishAllDialog !== false}
        onCloseComplete={() => showPublishAllDialog(false)}
      />

      <PaginationControls
        pageInfo={get(data, 'organization.medications.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default withRouter(MedicationList);
