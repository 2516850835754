import { merge } from 'lodash';

const defaultTrackProperties = {
  app_client: 'WEB'
};

export function identify(user: any) {
  const shouldVerifyIdentity =
    import.meta.env.VITE_INTERCOM_VERIFY_IDENTITY === 'true';

  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics &&
    // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
    window.analytics.identify(
      user.id,
      {
        email: user.email,
        name: user.name,
        app_client: 'WEB'
      },
      // We set the identity hash
      {
        Intercom: {
          user_hash: shouldVerifyIdentity ? user.intercomIdentityHash : null
        }
      }
    );
}

export function track(name: any, attrs = {}) {
  // @ts-expect-error TS(2339): Property 'organization_id' does not exist on type ... Remove this comment to see the full error message
  const organizationId = attrs.organization_id;
  const props = merge({}, attrs, defaultTrackProperties);
  const options = buildOptions(organizationId);

  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics && window.analytics.track(name, props, options);
}

export function page(name: any, attrs = {}) {
  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics && window.analytics.page(name, attrs);
}

export function reset() {
  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics && window.analytics.reset();
}

export function showIntercomBubble() {
  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics.ready(() => {
    // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    window.Intercom &&
      // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      window.Intercom('update', {
        hide_default_launcher: false
      });
  });
}

export function hideIntercomBubble() {
  // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
  window.analytics.ready(() => {
    // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    window.Intercom &&
      // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      window.Intercom('update', {
        hide_default_launcher: true
      });
  });
}

export function launchIntercomWithMessage(message: any) {
  // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
  window.Intercom && window.Intercom('showNewMessage', message);
}

function buildOptions(organizationId: any) {
  return {
    context: {
      groupId: organizationId
    }
  };
}
