import React, { useEffect, useState } from 'react';
import { get, find } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import { PROTOCOL_SET_LIST, PROTOCOL_LIST } from './queries';
import { Pane, Heading, Combobox, Paragraph } from 'evergreen-ui';

const itemToOption = (item: any) => ({
  label: item.name,
  value: item.id
});

const ProtocolSetSelection = ({
  organizationId,
  onSelect,
  protocolSetId
}: any) => {
  const [listProtocolSets, { data: protocolSetData, loading }] = useLazyQuery(
    PROTOCOL_SET_LIST
  );

  useEffect(() => {
    listProtocolSets({ variables: { organizationId } });
  }, [organizationId, listProtocolSets]);

  const options = get(protocolSetData, 'organization.protocolSets.edges', []);
  const formattedOptions = options.map(({ node }: any) => itemToOption(node));

  const selectedOption = find(
    formattedOptions,
    item => item.value === protocolSetId
  );

  return (
    <Pane marginBottom={16}>
      <Pane marginBottom={8}>
        <Heading size={400}>First, select a protocol set</Heading>
        <Paragraph>
          These are all of the protocol sets that are published within your
          organization.
        </Paragraph>
      </Pane>

      <Combobox
        isLoading={loading}
        openOnFocus
        selectedItem={selectedOption}
        items={formattedOptions}
        itemToString={(item: any) => (item ? item.label : '')}
        onChange={(item: any) => item && onSelect(item.value)}
        width="100%"
      />
    </Pane>
  );
};

const ProtocolSelection = ({
  protocolSetId,
  protocolId,
  ignoreProtocolId,
  onSelect
}: any) => {
  const [listProtocols, { data: protocolsData, loading }] = useLazyQuery(
    PROTOCOL_LIST
  );

  useEffect(() => {
    if (protocolSetId) {
      listProtocols({ variables: { protocolSetId } });
    }
  }, [protocolSetId, listProtocols]);

  const options = get(protocolsData, 'protocolSet.protocols.edges', []);

  const formattedOptions = options
    .filter(({ node }: any) => node.id !== ignoreProtocolId)
    .map(({ node }: any) => ({
      label: node.parent
        ? `${node.name} (${node.parent.name})`
        : `${node.name}`,
      value: node.id
    }));

  const selectedOption = find(
    formattedOptions,
    item => item.value === protocolId
  );

  return (
    <Pane marginBottom={16}>
      <Pane marginBottom={8}>
        <Heading size={400}>Select a protocol</Heading>
      </Pane>

      <Combobox
        isLoading={loading}
        disabled={!protocolSetId}
        openOnFocus
        selectedItem={selectedOption}
        items={formattedOptions}
        itemToString={(item: any) => (item ? item.label : '')}
        onChange={(item: any) => item && onSelect(item.value)}
        width="100%"
      />
    </Pane>
  );
};

const Selection = ({
  organizationId,
  protocolSetId,
  protocolId,
  ignoreProtocolId,
  onSelect
}: any) => {
  const [selectedProtocolSetId, setSelectedProtocolSetId] = useState(
    protocolSetId
  );

  return (
    <Pane maxWidth="400px">
      <ProtocolSetSelection
        onSelect={setSelectedProtocolSetId}
        organizationId={organizationId}
        protocolSetId={selectedProtocolSetId}
      />
      <ProtocolSelection
        onSelect={onSelect}
        protocolId={protocolId}
        ignoreProtocolId={ignoreProtocolId}
        protocolSetId={selectedProtocolSetId}
      />
    </Pane>
  );
};

export default Selection;
