import React from 'react';
import { Pane, Text, Link } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { organizationSelector } from 'redux/onboarding';

const OnboardingContext = () => {
  const organization = useSelector(organizationSelector);

  return (
    <Pane position="absolute" top={16} left={16} right={16}>
      <Pane display="flex">
        {organization && (
          <Text size={300} width="50%" textAlign="right" marginLeft="auto">
            {organization.name} (
            <Link size={300} href="/onboarding">
              change
            </Link>
            )
          </Text>
        )}
      </Pane>
    </Pane>
  );
};

export default OnboardingContext;
