import React, { useState, useRef, useEffect } from 'react';
import { DashboardModal } from '@uppy/react';
import { Button, Heading, Paragraph, Pane } from 'evergreen-ui';

const UploadField = ({
  uppyInstance,
  label,
  required,
  description,
  validationMessage,
  formikBag,
  name,
  value,
  renderPreview,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const [isModalOpen, setOpen] = useState(false);

  const setResult = (result: any) => {
    formikBag.setFieldTouched(name);
    formikBag.setFieldValue(name, result);
  };

  const uppy = useRef(
    uppyInstance({
      onComplete: (result: any) => {
        setResult(result);
        setOpen(false);
      }
    })
  );

  useEffect(() => {
    const currentUppy = uppy.current;
    return () => currentUppy.close();
  }, []);

  return (
    <>
      <Pane display="flex" alignItems="center" marginBottom={8}>
        <Pane>
          <Heading size={400}>{label}</Heading>
          {description && <Paragraph color="muted">{description}</Paragraph>}
        </Pane>

        <Pane marginLeft="auto">
          <Button
            type="button"
            appearance="minimal"
            marginLeft="auto"
            height={20}
            onClick={() => setResult(null)}
          >
            Clear
          </Button>
          <Button
            type="button"
            appearance="minimal"
            marginLeft="auto"
            height={20}
            onClick={() => setOpen(true)}
          >
            Select attachment
          </Button>
        </Pane>
      </Pane>
      <Pane display="flex" alignItems="center" marginBottom={16}>
        {value && renderPreview && <Pane>{renderPreview(value)}</Pane>}
      </Pane>

      <DashboardModal
        closeModalOnClickOutside
        open={isModalOpen}
        onRequestClose={() => setOpen(false)}
        uppy={uppy.current}
      />
    </>
  );
};

export default UploadField;
