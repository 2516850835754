import {
  get,
  map,
  mapValues,
  isArray,
  isObject,
  isNil,
  isNull,
  isUndefined,
  every
} from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { inputErrorsToValidationErrors } from './inputErrors';

export function onCompleted({
  response,
  resultKey = 'result',
  setErrors,
  onComplete = () => {}
}: any) {
  const result = response[resultKey];
  const errors = get(result, 'errors');

  if (errors && setErrors) {
    return setErrors(inputErrorsToValidationErrors(errors));
  }

  return onComplete(result);
}

const commonKeysToOmit = ['__typename', 'updatedAt', 'insertedAt'];

export function sanitizeData(data: any, keys: any[] = []) {
  if (!data) return null;
  const allKeys = commonKeysToOmit.concat(keys);

  return omitDeep(data, allKeys);
}

export const formatEnumToOptions = (items: any) =>
  map(items, (label, value) => ({ label, value }));

export function onChangeAsNull(name: any, onChange: any) {
  return (e: any) => {
    if (e.target.value === '') {
      return onChange({ target: { name, value: null } });
    }

    return onChange(e);
  };
}

// @ts-expect-error TS(7023): 'normalizeInputs' implicitly has return type 'any'... Remove this comment to see the full error message
export function normalizeInputs(input: any) {
  // The purpose of this function is to recursively normalize inputs
  // *before* they go to the server, ensuring things like empty strings
  // and empty objects are correctly handled.
  if (isArray(input)) {
    return input.map(normalizeInputs).filter(item => !isNil(item));
  }

  if (isObject(input)) {
    // @ts-expect-error TS(7022): 'normalizedInput' implicitly has type 'any' becaus... Remove this comment to see the full error message
    const normalizedInput = mapValues(input, value => normalizeInputs(value));
    // @ts-expect-error TS(7022): 'keys' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    const keys = Object.keys(normalizedInput);

    return keys.length === 0 ||
      every(
        normalizedInput,
        // If everything inside is empty arrays this object should not be valid
        value => isNull(value) || (isArray(value) && value.length === 0)
      )
      ? null
      : normalizedInput;
  }

  if (isUndefined(input)) {
    return undefined;
  }

  // If the input is empty then mark it as null
  return input === '' || isNil(input) ? null : input;
}
