import * as Yup from 'yup';
import { PatientTypesWithLabels } from '@/constants/doses';
import { formatEnumToOptions } from 'lib/formHelpers';

const Schema = Yup.object().shape({
  type: Yup.string()
    .required()
    .nullable(),
  minValue: Yup.number()
    .required()
    .nullable(),
  maxValue: Yup.number().nullable()
});

const PatientTypes = {
  INFANT: 'Infant',
  ...PatientTypesWithLabels
};

function formatRange({ type, minValue, maxValue }: any) {
  const ageRange = maxValue ? `${minValue} - ${maxValue}` : `${minValue}+`;

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return `${PatientTypes[type]} (${ageRange} years)`;
}

export const Form = {
  name: 'configuration',
  fields: [
    {
      name: 'patientTypeAgeRanges',
      label: 'Patient Type Ages',
      description: 'Overwrite the default age ranges',
      // @ts-expect-error TS(6133): 'index' is declared but its value is never read.
      titleExtractor: (fields: any, index: any) => formatRange(fields),
      validationSchema: Schema,
      type: 'listOfSubFields',
      fields: [
        {
          label: 'Patient Type',
          name: 'type',
          type: 'select',
          values: formatEnumToOptions(PatientTypes)
        },
        {
          label: 'Min Years',
          name: 'minValue',
          type: 'number'
        },
        {
          label: 'Max Years',
          name: 'maxValue',
          type: 'number'
        }
      ]
    }
  ]
};
