import React, { useState } from 'react';
import * as Yup from 'yup';
import { pick } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted } from 'lib/formHelpers';
import { UPDATE_USER } from './queries';
import { CURRENT_USER_QUERY } from 'components/shared/queries.ts';

const UserProfileSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  name: Yup.string().required()
});

export const Form = {
  name: 'updateProfile',
  validationSchema: UserProfileSchema,
  fields: [
    {
      type: 'string',
      label: 'Name',
      name: 'name'
    },
    {
      type: 'string',
      label: 'Email',
      name: 'email'
    }
  ]
};

const UpdateProfileForm = ({ user, onComplete }: any) => {
  const [errors, setErrors] = useState({});
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    onCompleted: (response: any) =>
      onCompleted({ response, setErrors, onComplete })
  });

  return (
    <FormBuilder
      key={user?.id ?? -1}
      initialValues={pick(user, ['name', 'email'])}
      form={Form}
      isInline={true}
      errors={errors}
      isLoading={loading}
      saveButtonTitle="Update profile"
      onSubmit={(input: any) => {
        setErrors({});
        updateUser({ variables: { input } });
      }}
    />
  );
};

export default UpdateProfileForm;
