import React, { useState, useEffect } from 'react';
import { FormField, SegmentedControl } from 'evergreen-ui';
import { find, pick } from 'lodash';
import fieldType from '../fieldType';

function buildControlOptions(options: any) {
  return options.map((option: any) => ({
    label: option.label,
    value: option.name
  }));
}

function gatherRelevantNames(options: any) {
  return options.map((option: any) => option.name);
}

const SegmentedControlInput = ({
  label,
  name,
  description,
  required,
  validationMessage,
  value,
  placeholder,
  formikBag,
  isInvalid,
  options = [],
  defaultOption,
  onChange,
  onBlur,
  // @ts-expect-error TS(6133): 'props' is declared but its value is never read.
  ...props
}: any) => {
  const optionNames = gatherRelevantNames(options);
  const values = pick(formikBag.values, optionNames);
  const errors = pick(formikBag.errors, optionNames);

  // We can determine what's currently active by which value is not empty
  // the idea being that the non empty value should be displayed
  const currentlySelected = find(optionNames, name => !!values[name]);

  const [selectedOption, setSelectedOption] = useState(
    currentlySelected || defaultOption
  );

  const selectedField = find(options, option => option.name === selectedOption);
  const FieldComponent = fieldType(selectedField.type);

  useEffect(() => {
    if (selectedField.selectedValue) {
      onChange({
        target: { name: selectedField.name, value: selectedField.selectedValue }
      });
    }
  }, [selectedField, onChange]);

  return (
    <FormField
      label={label}
      isRequired={required}
      description={description}
      validationMessage={validationMessage}
      width="100%"
      marginBottom={24}
    >
      <SegmentedControl
        width="100%"
        onChange={(value: any) => {
          setSelectedOption(value);
          // Reset all options to be null on change
          optionNames.map((name: any) =>
            onChange({ target: { name, value: null } })
          );
        }}
        value={selectedOption}
        options={buildControlOptions(options)}
        marginBottom={8}
        height={24}
      />
      <FieldComponent
        {...selectedField}
        label=""
        description=""
        formikBag={formikBag}
        onChange={onChange}
        onBlur={onBlur}
        required={!!selectedField.required}
        isInvalid={!!errors[selectedField.name]}
        value={values[selectedField.name]}
        validationMessage={errors[selectedField.name]}
      />
    </FormField>
  );
};

export default SegmentedControlInput;
