import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import posed from 'react-pose';
import { get, groupBy, sortBy, map } from 'lodash';
import { Pane, Spinner, Heading, Icon } from 'evergreen-ui';
import PaginationControls from 'components/shared/PaginationControls';
import SearchListControl from 'components/shared/SearchListControl';
import EmptyContent from 'components/shared/EmptyContent';
import SourceCard from './SourceCard';
import { SOURCE_LIST } from './queries';

const Expandable = posed.div({
  expanded: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: {
    height: 0,
    opacity: 0
  }
});

const Section = ({ children, title }: any) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Pane marginBottom={8}>
      <Pane
        cursor="pointer"
        display="flex"
        paddingTop={8}
        paddingBottom={8}
        alignItems="center"
        borderBottom
        onClick={() => setExpanded(!expanded)}
        marginBottom={16}
      >
        <Heading size={100}>{title}</Heading>
        <Icon
          marginLeft="auto"
          color="muted"
          icon={!expanded ? 'chevron-down' : 'chevron-up'}
          marginRight={4}
        />
      </Pane>
      <Expandable
        pose={expanded ? 'expanded' : 'collapsed'}
        style={{ overflow: 'hidden' }}
      >
        {children}
      </Expandable>
    </Pane>
  );
};

const SourceList = ({
  organization,
  onEditClick,
  history,
  filters,
  variables,
  onFilterUpdate,
  onPageInfoUpdate,
  onDeleteClick
}: any) => {
  const { loading, data, error } = useQuery(SOURCE_LIST, {
    variables,
    fetchPolicy: 'network-only'
  });

  const sources = get(data, 'organization.sources.edges', []);
  const groupedSourcesByOrganization = groupBy(
    sources,
    ({ node: source }) => source.organization.id
  );

  const navigateToVersions = (source: any) =>
    history.push(`/orgs/${organization.slug}/sources/${source.id}/versions`);

  return (
    <Pane>
      <Pane background="white">
        <SearchListControl
          placeholder="Search by name"
          initialValue={filters.name}
          onSubmit={(name: any) => onFilterUpdate({ name })}
        />

        {loading && (
          <Pane margin={16}>
            <Spinner size={16} />
          </Pane>
        )}

        <Pane>
          {!loading &&
            map(groupedSourcesByOrganization, sources => {
              const {
                node: { organization: sourceOrganization }
              } = sources[0];

              const sortedSources = sortBy(
                sources,
                ({ node: source }) => source.name
              );

              return (
                <Section
                  key={sourceOrganization.id}
                  marginBottom={16}
                  title={sourceOrganization.name}
                >
                  <Pane
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="start"
                  >
                    {sortedSources.map(({ node: source }) => (
                      <SourceCard
                        key={source.id}
                        organization={organization}
                        source={source}
                        navigateToVersions={navigateToVersions}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                      />
                    ))}
                  </Pane>
                </Section>
              );
            })}
        </Pane>
      </Pane>

      {!loading && !error && sources.length === 0 && <EmptyContent />}

      <PaginationControls
        pageInfo={get(data, 'organization.sources.pageInfo')}
        updatePageInfo={onPageInfoUpdate}
      />
    </Pane>
  );
};

export default withRouter(SourceList);
