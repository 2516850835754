export const Types = {
  CIVILIAN: 'CIVILIAN',
  MILITARY: 'MILITARY',
  BOTH: 'BOTH'
};

export const TypesWithLabels = {
  [Types.MILITARY]: 'Military',
  [Types.CIVILIAN]: 'Civilian',
  [Types.BOTH]: 'Both'
};

export const Roles = {
  ROLE_1: 'ROLE_1',
  ROLE_2: 'ROLE_2',
  ROLE_3: 'ROLE_3',
  ROLE_4: 'ROLE_4'
};

export const RolesWithLabels = {
  [Roles.ROLE_1]: 1,
  [Roles.ROLE_2]: 2,
  [Roles.ROLE_3]: 3,
  [Roles.ROLE_4]: 4
};
