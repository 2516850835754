import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  issuingBody: Yup.string().required()
});

export const Form = {
  name: 'source',
  validationSchema: Schema,
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'ALS Protocol Set',
      required: true
    },
    {
      name: 'issuingBody',
      label: 'Issuing Body',
      placeholder: 'NYS Semsco',
      required: true
    }
  ]
};
