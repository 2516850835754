import React from 'react';
import { Dialog, Paragraph } from 'evergreen-ui';

const DeleteDialog = ({
  isShown,
  onConfirm,
  isLoading,
  onCloseComplete
}: any) => (
  <Dialog
    isShown={isShown}
    title="Delete item"
    intent="danger"
    onCloseComplete={onCloseComplete}
    isConfirmLoading={isLoading}
    onConfirm={onConfirm}
    confirmLabel="Delete"
  >
    <Paragraph>
      Are you sure you want to delete this item? Please note:{' '}
      <strong>this cannot be undone</strong> so think carefully.
    </Paragraph>
  </Dialog>
);

export default DeleteDialog;
