import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get, groupBy, map } from 'lodash';
import {
  Table,
  Text,
  Pane,
  Spinner,
  UnorderedList,
  ListItem,
  Link,
  Heading,
  Badge
} from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';
import { ProtocolDoseAmountFragment } from 'components/shared/fragments';
import RelativeDate from 'components/shared/RelativeDate';
import EmptyContent from 'components/shared/EmptyContent';
import DisplayDoseAmount from 'components/shared/DoseAmount/DisplayDoseAmount';
import { protocolPath } from 'lib/urls';

const ReferencedDoseFragment = gql`
  fragment ProtocolReferencedDose on ProtocolReferencedDose {
    unit {
      amount {
        ...ProtocolDoseAmount
      }
    }
    dose {
      id
      routes
      infusionRateInSeconds
      requiresMedicalControlAuthorization
    }
    step {
      id
      credentials {
        id
        name
      }
      updatedAt
    }
    protocol {
      id
      name
      protocolSet {
        id
        name
      }
    }
  }
  ${ProtocolDoseAmountFragment}
`;

const REFERENCED_DOSES_FOR_MEDICATION = gql`
  query ReferencedDosesForMedication($id: ID!) {
    item: node(id: $id, type: MEDICATION) {
      id
      ... on Medication {
        referencedDoses {
          ...ProtocolReferencedDose
        }
      }
    }
  }
  ${ReferencedDoseFragment}
`;

const REFERENCED_DOSES_FOR_EQUIPMENT = gql`
  query ReferencedDosesForEquipmentFunction($id: ID!) {
    item: node(id: $id, type: EQUIPMENT_FUNCTION) {
      id
      ... on EquipmentFunction {
        referencedDoses {
          ...ProtocolReferencedDose
        }
      }
    }
  }
  ${ReferencedDoseFragment}
`;

const STEP_WIDTH = 350;

const ReferencedDoseList = ({
  medication,
  equipmentFunction,
  organization
}: any) => {
  const { data, loading, error } = useQuery(
    medication
      ? REFERENCED_DOSES_FOR_MEDICATION
      : REFERENCED_DOSES_FOR_EQUIPMENT,
    {
      variables: { id: medication ? medication.id : equipmentFunction.id }
    }
  );

  if (loading && !error) {
    return <Spinner size={24} />;
  }

  const referencedDoses = get(data, 'item.referencedDoses', []);
  const groupedDosesByProtocol = groupBy(
    referencedDoses,
    referencedDose => referencedDose.protocol.id
  );

  return (
    <Pane>
      {map(groupedDosesByProtocol, (referencedDoses, protocolId) => {
        const { protocol } = referencedDoses[0];
        const dosesByStep = groupBy(referencedDoses, rd => rd.step.id);

        return (
          <Pane key={protocolId} marginBottom={24}>
            <Heading marginBottom={16}>
              <Link
                is={RouterLink}
                to={protocolPath(organization, protocol.protocolSet, protocol)}
                size={400}
              >
                {protocol.name} ({protocol.protocolSet.name})
              </Link>
            </Heading>

            <Table border>
              <Table.Head>
                <Table.TextHeaderCell flex="none" width={STEP_WIDTH}>
                  Step
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Medical Control</Table.TextHeaderCell>
                <Table.TextHeaderCell>Credentials</Table.TextHeaderCell>
                <Table.TextHeaderCell>Last Updated</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {map(dosesByStep, (referencedDoses, stepId) => {
                  const { step } = referencedDoses[0];
                  const medControlStep = referencedDoses.every(
                    rd => rd.dose.requiresMedicalControlAuthorization
                  );

                  return (
                    <Table.Row height="auto" key={stepId} isSelectable>
                      <Table.Cell flex="none" width={STEP_WIDTH}>
                        <UnorderedList>
                          {referencedDoses.map(referencedDose => {
                            const dose = {
                              ...referencedDose.dose,
                              units: [
                                {
                                  ...referencedDose.unit,
                                  medication,
                                  equipmentFunction
                                }
                              ]
                            };

                            return (
                              <ListItem key={dose.id}>
                                <DisplayDoseAmount {...dose} />
                              </ListItem>
                            );
                          })}
                        </UnorderedList>
                      </Table.Cell>
                      <Table.Cell>
                        {medControlStep && (
                          <Badge color="orange">Med Control</Badge>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Pane paddingTop={4} paddingBottom={4}>
                          {step.credentials.map((credential: any) => (
                            <Text size={300} key={credential.id}>
                              {credential.name}
                              <br />
                            </Text>
                          ))}
                        </Pane>
                      </Table.Cell>
                      <Table.Cell>
                        <RelativeDate date={step.updatedAt} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        );
      })}

      {referencedDoses.length === 0 && <EmptyContent />}
    </Pane>
  );
};

export default ReferencedDoseList;
