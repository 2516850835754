import React from 'react';
import { Menu } from 'evergreen-ui';

const ProtocolMenu = ({
  canBeManaged = true,
  canBeExcluded = true,
  isExcluded = false,
  onExcludeToggle,
  onEditClick,
  onManageContentClick,
  onReferencingProtocolsClick,
  onSubProtocolsClick,
  onPublishClick,
  onDeleteClick
}: any) => {
  return (
    <Menu>
      <Menu.Group>
        <Menu.Item icon="manually-entered-data" onSelect={onManageContentClick}>
          Manage content
        </Menu.Item>
        <Menu.Item icon="document-open" onSelect={onReferencingProtocolsClick}>
          Referencing protocols
        </Menu.Item>
        {onSubProtocolsClick && (
          <Menu.Item icon="git-branch" onSelect={onSubProtocolsClick}>
            Subprotocols
          </Menu.Item>
        )}
      </Menu.Group>
      <Menu.Divider />

      {canBeExcluded && (
        <>
          <Menu.Group>
            <Menu.Item
              icon={isExcluded ? 'eye-open' : 'eye-off'}
              onSelect={onExcludeToggle}
            >
              {isExcluded ? 'Include' : 'Exclude'} protocol
            </Menu.Item>
          </Menu.Group>
        </>
      )}

      {canBeManaged && (
        <>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item onSelect={onEditClick}>Edit protocol</Menu.Item>
            <Menu.Item intent="success" onSelect={onPublishClick}>
              Publish protocol
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger" onSelect={onDeleteClick}>
              Delete protocol...
            </Menu.Item>
          </Menu.Group>
        </>
      )}
    </Menu>
  );
};

export default ProtocolMenu;
