import gql from 'graphql-tag';

export const SOURCES_FOR_ORGANIZATION = gql`
  query SourcesWithVersionsForOrg($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      sources(first: 999) {
        edges {
          node {
            id
            name
            versions {
              id
              version
              url
              file {
                url
                name
              }
            }
          }
        }
      }
    }
  }
`;
