import React, { useState } from 'react';
import { uid } from 'react-uid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Pane,
  Heading,
  Icon,
  IconButton,
  Paragraph,
  Text,
  Button,
  Dialog,
  FormFieldValidationMessage,
  toaster
} from 'evergreen-ui';
import { FieldArray } from 'formik';
import SideSheet from 'components/shared/SideSheet';
import FormBuilder from '../FormBuilder';

const emptyState = (fields: any) => {
  return fields.reduce((acc: any, field: any) => {
    return { ...acc, [field.name]: undefined };
  }, {});
};

const ListOfSubFields = ({
  name,
  label,
  initialState,
  fields = [],
  validationSchema,
  validationMessage,
  titleExtractor = () => {},
  description,
  formTitle,
  formikBag,
  reorderable = false
}: any) => {
  const [isDeleteShowing, setShowDelete] = useState(false);
  const [isEditing, setShowForm] = useState(false);

  const { values } = formikBag;
  const fieldValues = values[name] || [];

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: any) => {
        const newState = initialState || emptyState(fields);

        return (
          <>
            <Pane display="flex" alignItems="center" marginBottom={8}>
              <Pane>
                <Heading size={400}>{label}</Heading>
                {description && (
                  <Paragraph color="muted">{description}</Paragraph>
                )}
              </Pane>
              <Button
                type="button"
                appearance="minimal"
                marginLeft="auto"
                height={20}
                onClick={() => {
                  setShowForm({
                    // @ts-expect-error TS(2345): Argument of type '{ value: any; index: any; }' is ... Remove this comment to see the full error message
                    value: newState,
                    index: fieldValues.length
                  });
                }}
              >
                Add
              </Button>
            </Pane>
            <DragDropContext
              onDragEnd={item => {
                if (!item.destination) return;

                arrayHelpers.move(item.source.index, item.destination.index);
              }}
            >
              <Droppable
                droppableId={`droppable-list-${name}`}
                isDropDisabled={!reorderable}
              >
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Pane borderTop="muted" marginBottom={16}>
                      {fieldValues.map((value: any, index: any) => {
                        const title = titleExtractor(value, index);
                        const key = uid(value);

                        return (
                          <Draggable
                            key={key}
                            draggableId={key}
                            index={index}
                            isDragDisabled={!reorderable}
                          >
                            {provided => (
                              <Pane
                                key={index}
                                innerRef={provided.innerRef}
                                backgroundColor="white"
                                borderBottom="muted"
                                padding={8}
                                paddingLeft={0}
                                paddingRight={0}
                                display="flex"
                                alignItems="center"
                                {...provided.draggableProps}
                              >
                                {reorderable && (
                                  <div
                                    {...provided.dragHandleProps}
                                    style={{ marginRight: '8px' }}
                                  >
                                    <Icon
                                      icon="drag-handle-horizontal"
                                      size={16}
                                      color="muted"
                                    />
                                  </div>
                                )}
                                <Text size={300}>{title}</Text>
                                <Pane marginLeft="auto" display="flex">
                                  <IconButton
                                    type="button"
                                    icon="edit"
                                    height={24}
                                    marginRight={8}
                                    appearance="minimal"
                                    onClick={() =>
                                      // @ts-expect-error TS(2345): Argument of type '{ value: any; index: any; }' is ... Remove this comment to see the full error message
                                      setShowForm({ value, index })
                                    }
                                  />
                                  <IconButton
                                    type="button"
                                    icon="cross"
                                    height={24}
                                    appearance="minimal"
                                    onClick={() => setShowDelete(index)}
                                  />
                                </Pane>
                              </Pane>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Pane>
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {typeof validationMessage === 'string' && (
              <FormFieldValidationMessage marginTop={4}>
                {validationMessage}
              </FormFieldValidationMessage>
            )}

            <Dialog
              isShown={isDeleteShowing !== false}
              title="Are you sure?"
              intent="danger"
              onConfirm={() => {
                arrayHelpers.remove(isDeleteShowing);
                setShowDelete(false);
              }}
              onCloseComplete={() => setShowDelete(false)}
              confirmLabel="Delete"
            >
              Are you sure you want to delete this item?
            </Dialog>

            <SideSheet
              isShown={isEditing !== false}
              onCloseComplete={() => setShowForm(false)}
            >
              {isEditing !== false ? (
                <Pane>
                  <Pane background="tint2" borderBottom padding={16}>
                    <Heading size={500}>
                      {formTitle || `Update ${label}`}
                    </Heading>
                  </Pane>
                  <Pane padding={16}>
                    <FormBuilder
                      isSubForm
                      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'true'.
                      initialValues={isEditing.value || {}}
                      parentValues={values}
                      form={{ fields, validationSchema }}
                      onSubmit={(values: any) => {
                        // @ts-expect-error TS(2339): Property 'index' does not exist on type 'true'.
                        arrayHelpers.replace(isEditing.index, values);
                        setShowForm(false);
                        toaster.success(`${label} has been updated`);
                      }}
                    />
                  </Pane>
                </Pane>
              ) : (
                <br />
              )}
            </SideSheet>
          </>
        );
      }}
    />
  );
};

export default ListOfSubFields;
