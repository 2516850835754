import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Pane, Heading } from 'evergreen-ui';
import { get } from 'lodash';
import FormBuilder from 'components/shared/FormBuilder';
import { onCompleted, sanitizeData } from 'lib/formHelpers';
import { Form } from './form';
import { CREATE_PROTOCOL, UPDATE_PROTOCOL } from './queries';

const initialValues = {
  name: '',
  isHiddenFromBrowse: true,
  aliases: []
};

const CreateOrUpdateProtocol = ({
  protocolSet,
  protocol,
  parentProtocol,
  refetchQuery,
  onComplete
}: any) => {
  const [errors, setErrors] = useState({});
  const hasProtocol = protocol && protocol.id;
  const [createOrUpdateProtocol, { loading }] = useMutation(
    hasProtocol ? UPDATE_PROTOCOL : CREATE_PROTOCOL,
    {
      refetchQueries: [refetchQuery],
      onCompleted: (response: any) =>
        onCompleted({ response, setErrors, onComplete })
    }
  );

  const formData = hasProtocol
    ? sanitizeData(protocol, [
        'lastPublishedAt',
        'parent',
        'referencedSources',
        'subprotocols',
        'amendment'
      ])
    : initialValues;

  const canSetPatientTypeEntryCondition = !get(
    protocolSet,
    'entryCriteria.patientTypes'
  );

  return (
    <Pane display="flex" flexDirection="column" flex="1">
      <Pane padding={16} borderBottom background="tint2">
        <Heading size={500}>
          {hasProtocol ? 'Update subprotocol' : 'Create new subprotocol'}
        </Heading>
      </Pane>
      <Pane padding={16}>
        <FormBuilder
          key={protocol?.id ?? -1}
          isSubForm
          initialValues={formData}
          form={Form({
            protocolSetId: protocolSet.id,
            canSetHiddenFromBrowse: false,
            canSetPatientType: canSetPatientTypeEntryCondition
          })}
          errors={errors}
          isLoading={loading}
          onSubmit={(input: any) => {
            // We only want to add the protocolSetId to the case where we're creating
            // new sources and not when we're editing an existing source.
            const variables = hasProtocol
              ? input
              : { ...input, protocolSetId: protocolSet.id };

            setErrors({});
            createOrUpdateProtocol({
              variables: {
                input: {
                  ...variables,
                  parentId: parentProtocol.id
                }
              }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default CreateOrUpdateProtocol;
