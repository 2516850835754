import gql from 'graphql-tag';
import { PageInfoFragment } from 'components/shared/fragments';

const SourceVersionListItemFragment = gql`
  fragment SourceVersionListItem on SourceVersion {
    id
    version
    url
    file {
      url
    }
  }
`;

const SourceListItemFragment = gql`
  fragment SourceListItem on Source {
    id
    name
    issuingBody
    versionCount
    insertedAt
    organization {
      id
      name
    }
    updatedAt
    versions {
      ...SourceVersionListItem
    }
  }
  ${SourceVersionListItemFragment}
`;

export const SOURCE_LIST = gql`
  query SourcesForOrg(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $filters: FilterOrganizationSourcesInput
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      id
      sources(
        first: $first
        after: $after
        before: $before
        last: $last
        filters: $filters
      ) {
        edges {
          node {
            ...SourceListItem
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${SourceListItemFragment}
`;
