import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { merge } from 'lodash';
import { Pane, TextInputField, Button } from 'evergreen-ui';
import { useLazyQuery } from '@apollo/react-hooks';
import { AVAILABLE_AGENCIES_FOR_ZIP } from './queries';

const ZipCodeEntry = ({ onCompleted }: any) => {
  const [requestErrors, setErrors] = useState(null);
  const [getAvailableAgencies, { loading: isLoading }] = useLazyQuery(
    AVAILABLE_AGENCIES_FOR_ZIP,
    {
      onCompleted: (result: any) =>
        onCompleted(result.availableAgenciesForZipCode),
      // @ts-expect-error TS(2345): Argument of type '{ zipCode: string; }' is not ass... Remove this comment to see the full error message
      onError: () => setErrors({ zipCode: "Couldn't find the zip code" })
    }
  );

  return (
    <Formik
      initialValues={{ zipCode: '' }}
      onSubmit={(variables: any) => {
        setErrors(null);
        getAvailableAgencies({ variables });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }: any) => {
        const allErrors = merge({}, errors, requestErrors || {});

        return (
          <Pane is={Form} onSubmit={handleSubmit}>
            <TextInputField
              isInvalid={!!errors.zipCode}
              placeholder="11021"
              type="text"
              name="zipCode"
              label="Your agency's zip code"
              validationMessage={
                allErrors.zipCode && touched.zipCode && allErrors.zipCode
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipCode}
              size={500}
              marginBottom={8}
            />

            <Button
              display="flex"
              width="100%"
              justifyContent="center"
              isLoading={isLoading}
              appearance="primary"
              type="submit"
              height={40}
              marginTop={16}
            >
              Next
            </Button>
          </Pane>
        );
      }}
    />
  );
};

export default ZipCodeEntry;
