import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Dialog, toaster, Spinner } from 'evergreen-ui';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SeatFragment } from 'components/shared/fragments';
import MultiSelect from 'components/shared/FormBuilder/Fields/MultiSelect';

const ASSIGN_ROLES = gql`
  mutation AssignRolesForSeat($input: AssignRolesForSeatInput!) {
    result: assignRolesForSeat(input: $input) {
      seat {
        ...Seat
      }
    }
  }
  ${SeatFragment}
`;

const ROLES_LIST = gql`
  query RolesForOrganization($id: ID!) {
    organization(id: $id) {
      id
      applicableRoles {
        id
        name
      }
    }
  }
`;

const RoleSelector = ({ organization, onSelect, selectedIds }: any) => {
  const { data, loading } = useQuery(ROLES_LIST, {
    variables: { id: organization.id },
    fetchPolicy: 'network-only'
  });

  if (loading) return <Spinner size={16} />;

  const formattedValues = get(data, 'organization.applicableRoles', []).map(
    (role: any) => ({
      label: role.name,
      value: role.id
    })
  );

  return (
    <MultiSelect
      values={formattedValues}
      value={selectedIds}
      label="Assigned roles"
      description="Select roles to assign to this user"
      formikBag={{
        // TODO: we probably shouldn't be imitating the internal API of formik
        setFieldTouched: () => {},
        // @ts-expect-error TS(6133): 'name' is declared but its value is never read.
        setFieldValue: (name: any, value: any) => onSelect(value)
      }}
    />
  );
};

const UpdateRolesDialog = ({
  isShown,
  organization,
  member,
  onCloseComplete
}: any) => {
  const [selectedRoles, setRoles] = useState([]);

  // We want to reset the initial roles whenever the member changes
  // this ensures that when we re-open the component the correct values get set
  useEffect(() => {
    const roleIds = member.roles
      ? member.roles.map((role: any) => role.id)
      : [];
    setRoles(roleIds);
  }, [member]);

  const [changeSeatRoles, { loading }] = useMutation(ASSIGN_ROLES, {
    onCompleted: (response: any) => {
      if (response.result.seat) {
        toaster.success('Roles assigned to user updated');
        onCloseComplete();
      }
    }
  });

  return (
    <Dialog
      title="Assign roles"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      confirmLabel="Update"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onConfirm={() =>
        changeSeatRoles({
          variables: {
            input: { id: member.id, roles: selectedRoles }
          }
        })
      }
    >
      {isShown && (
        <RoleSelector
          onSelect={setRoles}
          organization={organization}
          selectedIds={selectedRoles}
        />
      )}
    </Dialog>
  );
};

export default UpdateRolesDialog;
