import React from 'react';
import { Dialog, Paragraph, toaster } from 'evergreen-ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  SubscriptionFragment,
  InputErrorFragment
} from 'components/shared/fragments';

export const REMOVE_INDIVIDUAL_FROM_ORG = gql`
  mutation RemoveOrgAccessFromIndividualUser(
    $input: RemoveOrganizationAccessFromIndividualInput!
  ) {
    result: removeOrganizationAccessFromIndividuall(input: $input) {
      errors {
        ...InputError
      }
      subscription {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragment}
  ${InputErrorFragment}
`;

const RemoveIndividualMemberDialog = ({
  isShown,
  member,
  organization,
  onCloseComplete,
  onComplete
}: any) => {
  const [removeMember, { loading }] = useMutation(REMOVE_INDIVIDUAL_FROM_ORG, {
    variables: {
      input: {
        userId: member.user && member.user.id,
        organizationId: organization.id,
        cancelImmediately: true
      }
    },
    onCompleted: (response: any) => {
      if (response.result) {
        toaster.success('Seat unassigned from organization');
        onComplete();
      }
    }
  });

  return (
    <Dialog
      title="Are you sure?"
      isShown={isShown}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={loading}
      intent="danger"
      confirmLabel="Remove member"
      onConfirm={() => removeMember()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <Paragraph marginBottom={16}>
        Are you sure you want to remove this member from the organization? Doing
        so will mean they immediately lose access to this organization in the
        app and will affect their subscription.
      </Paragraph>
    </Dialog>
  );
};

export default RemoveIndividualMemberDialog;
