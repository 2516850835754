import React, { useState } from 'react';
import { Button, toaster } from 'evergreen-ui';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PageHeader from 'components/shared/PageHeader';
import SideSheet from 'components/shared/SideSheet';
import DeleteDialog from 'components/shared/DeleteDialog';
import HospitalCapabilityList from 'components/organizations/HospitalCapabilityList';
import { CAPABILITIES_LIST } from 'components/organizations/HospitalCapabilityList/HospitalCapabilityList';
import CreateOrUpdateHospitalCapability from 'components/organizations/CreateOrUpdateHospitalCapability';
import {
  withDefaultPageInfo,
  parseFilters,
  onFilterUpdate,
  onPageInfoUpdate
} from 'lib/pagination';

const DELETE_CAPABILITY = gql`
  mutation DeleteCapapbility(
    $input: DeleteHospitalCapabilityForOrganizationInput!
  ) {
    result: deleteHospitalCapabilityForOrganization(input: $input) {
      hospitalCapability {
        id
      }
      errors {
        message
      }
    }
  }
`;

export default ({ history, organization }: any) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const qs = queryString.parse(history.location.search);
  const pageInfo = withDefaultPageInfo(qs.page);
  const filters = parseFilters(qs.filters);

  const variables = {
    ...pageInfo,
    filters,
    organizationId: organization.id
  };

  const refetchQuery = {
    query: CAPABILITIES_LIST,
    variables
  };

  const [deleteCapability, { loading: isDeleteLoading }] = useMutation(
    DELETE_CAPABILITY,
    {
      refetchQueries: [refetchQuery],
      onCompleted: ({ result }: any) => {
        if (result.hospitalCapability) {
          toaster.success('Hospital capability successfully deleted');
        } else {
          toaster.danger('Could not delete hospital capability', {
            description:
              "Check it's not referenced in a hospital, or as a parent capability"
          });
        }
        setShowDeleteDialog(false);
      }
    }
  );

  return (
    <>
      <PageHeader
        title="Manage capabilities"
        subtitle="Hospital capabilities are the set of capabilities that this medical authority has granted and can be applied to hospitals."
        renderButtons={
          <Button
            onClick={() => setShowForm(true)}
            iconBefore="add"
            marginLeft="auto"
          >
            New Capability
          </Button>
        }
      />

      <HospitalCapabilityList
        variables={variables}
        filters={filters}
        onFilterUpdate={onFilterUpdate(history)}
        onPageInfoUpdate={onPageInfoUpdate(history, filters)}
        organization={organization}
        onEditClick={(capability: any) => setShowForm(capability)}
        onDeleteClick={(capability: any) => setShowDeleteDialog(capability)}
      />

      <SideSheet
        isShown={showForm !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        <CreateOrUpdateHospitalCapability
          capability={showForm || {}}
          organizationId={organization.id}
          onComplete={() => {
            toaster.success('Capability saved successfully');
            setShowForm(false);
          }}
          refetchQuery={refetchQuery}
        />
      </SideSheet>

      <DeleteDialog
        isShown={showDeleteDialog !== false}
        onCloseComplete={() => setShowDeleteDialog(false)}
        onConfirm={() =>
          deleteCapability({
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'boolean'.
            variables: { input: { id: showDeleteDialog.id } }
          })
        }
        isLoading={isDeleteLoading}
      />
    </>
  );
};
