import React, { useState } from 'react';
import { Pane, Heading, Paragraph, Button, toaster } from 'evergreen-ui';
import SideSheet from 'components/shared/SideSheet';
import FormBuilder from '../FormBuilder';

const emptyState = (fields: any) => {
  return fields.reduce((acc: any, field: any) => {
    return { ...acc, [field.name]: undefined };
  }, {});
};

const SubFields = ({
  name,
  label,
  description,
  formikBag,
  fields,
  initialState,
  value,
  renderPreview,
  validationSchema,
  formTitle
}: any) => {
  const [isEditing, setShowForm] = useState(false);
  const newState = initialState || emptyState(fields);

  return (
    <Pane marginBottom={24}>
      <Pane display="flex" alignItems="top" marginBottom={8}>
        <Pane maxWidth="80%">
          <Heading size={400}>{label}</Heading>
          {description && <Paragraph color="muted">{description}</Paragraph>}
        </Pane>
        <Button
          type="button"
          appearance="minimal"
          marginLeft="auto"
          height={20}
          onClick={() => {
            setShowForm(true);
          }}
        >
          Edit
        </Button>
      </Pane>

      {renderPreview && value && renderPreview(value)}

      <SideSheet
        isShown={isEditing !== false}
        onCloseComplete={() => setShowForm(false)}
      >
        {isEditing ? (
          <Pane>
            <Pane background="tint2" borderBottom padding={16}>
              <Heading size={500}>{formTitle || `Update ${label}`}</Heading>
            </Pane>
            <Pane padding={16}>
              <FormBuilder
                isSubForm
                initialValues={value || newState}
                form={{ fields, validationSchema }}
                onSubmit={(values: any) => {
                  formikBag.setFieldTouched(name);
                  formikBag.setFieldValue(name, values);
                  setShowForm(false);
                  toaster.success(`${label} has been updated`);
                }}
              />
            </Pane>
          </Pane>
        ) : (
          <br />
        )}
      </SideSheet>
    </Pane>
  );
};

export default SubFields;
