import React, { useState } from 'react';
import MobileDetect from 'mobile-detect';
import { useHistory } from 'react-router-dom';
import { Heading, Button, Pane, Paragraph } from 'evergreen-ui';
import { APP_STORE_DEEPLINK } from '../../../constants';

const PasswordChanged = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  return (
    <Pane>
      <Heading size={600}>Your password has been changed</Heading>
      <Paragraph color="muted">
        You can now continue by logging in using your email and new password.
      </Paragraph>

      <Button
        height={40}
        appearance="primary"
        width="100%"
        display="flex"
        justifyContent="center"
        marginTop={24}
        marginBottom={16}
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);

          const md = new MobileDetect(window.navigator.userAgent);
          const isMobileMaybe = md.mobile();

          if (isMobileMaybe) {
            window.location.replace(APP_STORE_DEEPLINK);
          } else {
            history.push('/');
          }
        }}
      >
        Continue to Muru
      </Button>

      {isLoading && (
        <Paragraph color="muted">Redirecting you to Muru...</Paragraph>
      )}
    </Pane>
  );
};

export default PasswordChanged;
