import React from 'react';
import { Pane, Icon, Heading, Paragraph } from 'evergreen-ui';

const Completed = () => {
  return (
    <Pane textAlign="center" maxWidth={400} padding={24}>
      <Icon icon="tick" color="success" size={48} marginBottom={8} />

      <Heading size={600} marginBottom={16}>
        You're all done with inventory setup!
      </Heading>

      <Paragraph>
        All of the inventory you entered should be available for everyone within
        your agency within the next hour. If you have any issues, please contact
        our support team.
      </Paragraph>
    </Pane>
  );
};

export default Completed;
